import { Box, Tooltip } from '@mui/material';
import productApi from '@/api/productApi';
import { toast } from 'react-toastify';
import { useEffect, useRef, useState } from 'react';
import images from '@/assets/images';
import styles from './upload.module.scss';
import classNames from 'classnames/bind';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

const cx = classNames.bind(styles);

function ImageUpload({ width = '100%', height = '300px', onDone, url = null }) {
    const refInputUplaod = useRef();
    const [urlImage, setUrlImage] = useState(null);
    useEffect(() => {
        setUrlImage(url);
    }, [url]);
    const handleUploadFile = async e => {
        let files = e.target.files;
        let data = new FormData();
        data.append('file', files[0]);
        var filePath = files[0].name.split('.').pop(); //lấy định dạng file
        var dinhDangChoPhep = ['jpg', 'jpeg', 'png']; //các tập tin cho phép
        const isLt2M = files[0].size / 1024 / 1024 < 20;
        if (!isLt2M) {
            this.form.fileList.pop();
            toast.warning('Kích thước file ảnh tối đa 20Mb!');
            return false;
        }
        if (!dinhDangChoPhep.find(el => el === filePath.toLowerCase())) {
            toast.warning('Tập tin không hợp lệ!');
            return;
        }
        try {
            let url = await productApi.uploadImage(data);
            setUrlImage(url);
            onDone(url);
        } catch (error) {

        }
        refInputUplaod.current.value = null;
    };
    const handleRemoveImage = e => {
        e.stopPropagation();
        setUrlImage(null);
        onDone(null);
    };

    return (
        <Box>
            <input type="file" onChange={e => handleUploadFile(e)} ref={refInputUplaod} style={{ display: 'none' }} />
            <Box>
                {urlImage ? (
                    <Tooltip title="Nhấn để thay đổi hình ảnh">
                        <Box
                            sx={{ backgroundImage: `url('${urlImage}')`, width, height }}
                            className={cx('box-image-single')}
                            onClick={() => refInputUplaod.current.click()}
                        >
                            <Tooltip title="Xóa hình ảnh này">
                                <IconButton
                                    size="large"
                                    sx={{ bgcolor: 'white', mt: 1, ml: 1 }}
                                    onClick={e => handleRemoveImage(e)}
                                >
                                    <DeleteIcon fontSize="inherit" sx={{ color: '#B03A2E' }} />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Tooltip>
                ) : (
                    <Tooltip title="Nhấn để upload hình ảnh">
                        <Box
                            sx={{ width, height }}
                            className={cx('box-upload-single', 'c-flex align-center justify-center')}
                            onClick={() => refInputUplaod.current.click()}
                        >
                            <img src={images.uploadImageIcon} alt="upload" style={{ width: '70%', maxHeight: '70%' }} />
                            <Box>Tải lên hình ảnh</Box>
                        </Box>
                    </Tooltip>
                )}
            </Box>
        </Box>
    );
}
export default ImageUpload;
