import { createSlice } from '@reduxjs/toolkit';
import { POSITION_BACK, POSITION_TOP, TYPE_TEXT } from './constant';
import { productList } from '@/store/design/mockData.js';
export const targetDesgn = createSlice({
    name: 'design',
    initialState: {
        elemetTarget: null,
        iconArray: productList[0].colors[0].images.map(item => ({ imageId: item.id, icons: [] })),
        iconSelected: {
            type: null,
            data: null,
        },
        productList: productList,
        background: productList[0].colors[0].images[0],
        productSelected: {
            productId: productList[0].id,
            colorId: productList[0].colors[0].id,
            imageId: productList[0].colors[0].images[0].id,
        },
        textStyle: {
            fontSize: '18px',
            fontFamily: 'MyFont',
            fontWeight: 'normal',
            fontStyle: 'normal',
            textDecoration: 'none',
            color: '#17202A',
        },
        showBorder: false,
        showGird: false,
    },
    reducers: {
        setElementTarget: (state, action) => {
            state.elemetTarget = action.payload.target;
            state.iconSelected = action.payload.iconSelected;
            if (action.payload.iconSelected.type === TYPE_TEXT) {
                const style = action.payload.iconSelected.data.style;
                state.textStyle = {
                    ...style,
                };
            }
        },
        pushIconDesign: (state, action) => {
            var newData = state.iconArray.map(item => {
                if (state.productSelected.imageId === item.imageId) {
                    return {
                        ...item,
                        icons: [...item.icons, action.payload],
                    };
                } else return item;
            });
            state.iconArray = newData;
        },
        deleteIconDesign: state => {
            state.iconArray = state.iconArray.map(item => {
                if (item.imageId === state.productSelected.imageId) {
                    return {
                        ...item,
                        icons: item.icons.filter(el => el.id !== state.iconSelected.data.id),
                    };
                } else return item;
            });
            state.iconSelected = { type: null, data: null };
            state.elemetTarget = null;
        },
        setStyle: (state, action) => {
            state.iconArray = state.iconArray.map(item => {
                if (item.imageId === state.productSelected.imageId) {
                    var icons = item.icons.map(el => {
                        if (el.id === state.iconSelected.data.id) {
                            return {
                                ...el,
                                style: { ...el.style, ...action.payload },
                            };
                        }
                        return el;
                    });
                    return { ...item, icons };
                }
                return item;
            });
        },
        setPosition: (state, action) => {
            var position = state.iconArray.find(el => el.imageId === state.productSelected.imageId);
            position = position.icons;
            var newArray = position.filter(el => el.id !== state.iconSelected.data.id);
            var elSelect = position.find(el => el.id === state.iconSelected.data.id);
            var newPosition = [];
            if (action.payload === POSITION_BACK) {
                newPosition = [elSelect, ...newArray];
            }
            if (action.payload === POSITION_TOP) {
                newPosition = [...newArray, elSelect];
            }
            state.iconArray = state.iconArray.map(item => {
                if (item.imageId === state.productSelected.imageId) {
                    return {
                        ...item,
                        icons: newPosition,
                    };
                }
                return item;
            });
        },
        changeColor: (state, action) => {
            const product = productList.find(el => el.id === state.productSelected.productId);
            const imagesProduct = product.colors.find(el => el.id === action.payload).images;

            state.iconArray = imagesProduct.map(item => ({ imageId: item.id, icons: [] }));
            state.productSelected.colorId = action.payload;
            state.productSelected.imageId = imagesProduct[0].id;
            state.background = imagesProduct[0];
        },
        changePosition: (state, action) => {
            state.productSelected.imageId = action.payload;
            const product = productList.find(el => el.id === state.productSelected.productId);
            const imagesProduct = product.colors.find(el => el.id === state.productSelected.colorId).images;
            state.background = imagesProduct.find(el => el.id === action.payload);
            state.elemetTarget = null;
        },
        changeProduct: (state, action) => {
            const product = productList.find(el => el.id === action.payload);
            state.iconArray = product.colors[0].images.map(item => ({ imageId: item.id, icons: [] }));
            state.iconSelected = { type: null, data: null };
            state.elemetTarget = null;

            state.background = product.colors[0].images[0];
            state.productSelected = {
                productId: product.id,
                colorId: product.colors[0].id,
                imageId: product.colors[0].images[0].id,
            };
        },
        changeTextStyle: (state, action) => {
            const style = state.textStyle;
            state.textStyle = {
                ...style,
                ...action.payload,
            };
            state.iconArray = state.iconArray.map(item => {
                if (item.imageId === state.productSelected.imageId) {
                    var icons = item.icons.map(el => {
                        if (el.id === state.iconSelected.data.id) {
                            return {
                                ...el,
                                style: {
                                    ...el.style,
                                    ...action.payload,
                                },
                            };
                        }
                        return el;
                    });
                    return { ...item, icons };
                }
                return item;
            });
        },
        changeShowBorder: state => {
            const current = state.showBorder;
            state.showBorder = !current;
        },
        changeShowGird: state => {
            const current = state.showGird;
            state.showGird = !current;
        },
    },
});

export const {
    setElementTarget,
    pushIconDesign,
    deleteIconDesign,
    setStyle,
    setPosition,
    changeColor,
    changePosition,
    changeProduct,
    changeTextStyle,
    changeShowGird,
    changeShowBorder,
} = targetDesgn.actions;

export default targetDesgn.reducer;
