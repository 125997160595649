import images from '@/assets/images';
import { useCallback, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Box, Grid } from '@mui/material';
import dashboardApi from '@/api/dashboardApi';
import Chart from 'react-apexcharts';
import styles from './dashboard.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);
function Dashboard() {
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [indexData, setIndexData] = useState({
        total: {},
        done: {},
        pending: {},
        faild: {},
    });
    const [dataPie, setDataPie] = useState({
        design: {
            series: [],
            options: {
                labels: [],
            },
        },
        product: {
            series: [],
            options: {
                options: {
                    labels: [],
                },
            },
        },
    });
    const getIndexNumber = useCallback(async () => {
        const data = await dashboardApi.getIndex({date: dateRange});
        setIndexData(data);
    }, [dateRange]);
    const getPieData = useCallback(async () => {
        const data = await dashboardApi.getPieData({date: dateRange});
        setDataPie({ design: { ...dataPie.design, ...data.design }, product: { ...dataPie.product, ...data.product } });
    }, [dateRange]);
    useEffect(() => {
        getIndexNumber();
        getPieData();
    }, [getIndexNumber, getPieData]);
    return (
        <Box sx={{ pl: 2, pr: 2 }}>
            <Box className="d-flex" sx={{ mt: 2 }}>
                <div style={{ fontSize: '24px', fontWeight: 'bold', flex: 1 }}>TỔNG QUAN</div>
                <Box sx={{ mr: 2 }}>
                    <DatePicker
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={update => {
                            setDateRange(update);
                        }}
                        isClearable={true}
                        placeholderText='Toàn thời gian'
                    />
                </Box>
            </Box>
            <Box sx={{ mt: 2 }}>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} md={4} lg={4} xl={3} className="d-flex justify-center">
                        <Box className={cx('card-number background1', 'c-flex align-center justify-center')}>
                            <Box>
                                <span style={{ fontSize: '40px' }}>
                                    {indexData.total.thiet_ke + indexData.total.ban_hang}
                                </span>
                                <span>đơn hàng</span>
                            </Box>
                            <Box sx={{ mt: 1 }}>
                                <Box className="d-flex">
                                    <Box className={cx('number-index')}>{indexData.total.thiet_ke}</Box>
                                    <Box>Đơn hàng thiết kế</Box>
                                </Box>
                                <Box sx={{ mt: 1 }} className="d-flex">
                                    <Box className={cx('number-index')}>{indexData.total.ban_hang}</Box>
                                    <Box>Đơn mua hàng</Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} lg={4} xl={3} className="d-flex justify-center">
                        <Box className={cx('card-number background2', 'c-flex align-center justify-center')}>
                            <Box>
                                <span style={{ fontSize: '40px' }}>
                                    {indexData.done.thiet_ke + indexData.done.ban_hang}
                                </span>
                                <span>đơn hoàn thành</span>
                            </Box>
                            <Box sx={{ mt: 1 }}>
                                <Box className="d-flex">
                                    <Box className={cx('number-index')}>{indexData.done.thiet_ke} </Box>
                                    <Box>Đơn hàng thiết kế</Box>
                                </Box>
                                <Box className="d-flex">
                                    <Box className={cx('number-index')}>{indexData.done.ban_hang}</Box>
                                    <Box>Đơn mua hàng</Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} lg={4} xl={3} className="d-flex justify-center">
                        <Box className={cx('card-number background3', 'c-flex align-center justify-center')}>
                            <Box>
                                <span style={{ fontSize: '40px' }}>
                                    {indexData.pending.thiet_ke + indexData.pending.ban_hang}
                                </span>
                                <span>Đang thực hiện</span>
                            </Box>
                            <Box sx={{ mt: 1 }}>
                                <Box className="d-flex">
                                    <Box className={cx('number-index')}>{indexData.pending.thiet_ke} </Box>
                                    <Box>Đơn hàng thiết kế</Box>
                                </Box>
                                <Box className="d-flex">
                                    <Box className={cx('number-index')}>{indexData.pending.ban_hang}</Box>
                                    <Box>Đơn mua hàng</Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} lg={4} xl={3} className="d-flex justify-center">
                        <Box className={cx('card-number background4', 'c-flex align-center justify-center')}>
                            <Box>
                                <span style={{ fontSize: '40px' }}>
                                    {indexData.faild.thiet_ke + indexData.faild.ban_hang}
                                </span>
                                <span>Không hoàn thành</span>
                            </Box>
                            <Box sx={{ mt: 1 }}>
                                <Box className="d-flex">
                                    <Box className={cx('number-index')}>{indexData.faild.thiet_ke} </Box>
                                    <Box>Đơn hàng thiết kế</Box>
                                </Box>
                                <Box className="d-flex">
                                    <Box className={cx('number-index')}>{indexData.faild.ban_hang}</Box>
                                    <Box>Đơn mua hàng</Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ mt: 2 }}>
                <Box sx={{ fontSize: '16px', fontWeight: 'bold' }}>Trạng thái đơn hàng</Box>
                <Grid container spacing={3} sx={{ mt: 1 }}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Chart
                            options={dataPie.product.options || {}}
                            series={dataPie.product.series}
                            type="pie"
                            width="90%"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Chart
                            options={dataPie.design.options || {}}
                            series={dataPie.design.series}
                            type="pie"
                            width="90%"
                        />
                    </Grid>
                </Grid>
            </Box>
            {/* <img src={images.dashBoard} style={{ width: '100%' }} alt="thank" /> */}
        </Box>
    );
}
export default Dashboard;
