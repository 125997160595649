import { Box } from '@mui/material';
import images from '@/assets/images';
import classNames from 'classnames/bind';
import styles from './IconCator.scss';
import Slider from 'react-slick';

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1.1,
    slidesToScroll: 1
};
const cx = classNames.bind(styles);

const goToLinkCartegory = (link) => {
    window.open("http://dl-zabawek.pl/category" + link);
};

const style = {
    widthContentMobile: {
        minWidth: '170px',
        color: 'black',
        fontWeight: 'BOLD',
        paddingLeft: '20px',
        marginLeft: '10px'
    },
};
function IconCator({ height }) {

    return (
        <Box>
            <Box sx={{ paddingTop: '2em', paddingLeft: '90px', paddingRight: '90px' }} className={cx('pc-card-cator')}>
                <Box className={cx('d-flex pc-card-cator234')} sx={{ width: '100%', justifyContent: 'center' }}>
                    <div className={cx('d-flex wrap-cator')}>
                        <img onClick={() => goToLinkCartegory('/t-shirt')} src={images.iconCato1} style={{ width: "100%", cursor: 'pointer' }} alt='product' />
                    </div>
                    <div className={cx('d-flex  wrap-cator')}>
                        <img onClick={() => goToLinkCartegory('/ao-hoddie')} src={images.iconCato2} style={{ width: "100%", cursor: 'pointer' }} alt='product' />
                    </div>
                    <div className={cx('d-flex wrap-cator')}>
                        <img onClick={() => goToLinkCartegory('/quan')} src={images.iconCato3} style={{ width: "100%", cursor: 'pointer' }} alt='product' />
                    </div>
                    <div className={cx('d-flex wrap-cator')}>
                        <img onClick={() => goToLinkCartegory('/tat')} src={images.iconCato4} style={{ width: "100%", cursor: 'pointer' }} alt='product' />
                    </div>
                </Box>
            </Box>
            <Box className={cx('mobile-card-cator')}>
                <div sx={{ paddingTop: '30px !important', paddingLeft: '20px', paddingRight: '0px' }}>
                    <Slider {...settings}>
                        <div >
                            <Box className="d-flex align-center" style={style.widthContentMobile}>
                                <img onClick={() => goToLinkCartegory('/t-shirt')} src={images.iconCato1} style={{ width: "100%", cursor: 'pointer' }} alt='product' />
                            </Box>
                        </div>
                        <div>
                            <Box className="d-flex align-center" style={style.widthContentMobile}>
                                <img onClick={() => goToLinkCartegory('/ao-hoddie')} src={images.iconCato2} style={{ width: "100%", cursor: 'pointer' }} alt='product' />
                            </Box>

                        </div>
                        <div >
                            <Box className="d-flex align-center" style={style.widthContentMobile}>
                                <img onClick={() => goToLinkCartegory('/quan')} src={images.iconCato3} style={{ width: "100%", cursor: 'pointer' }} alt='product' />
                            </Box>

                        </div>
                        <div >
                            <Box className="d-flex align-center" style={style.widthContentMobile}>
                                <img onClick={() => goToLinkCartegory('/tat')} src={images.iconCato4} style={{ width: "100%", cursor: 'pointer' }} alt='product' />
                            </Box>

                        </div>
                    </Slider>
                </div>
            </Box>
        </Box>

    );
}
export default IconCator;
