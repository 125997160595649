import MTable from '@/components/Table';
import { Avatar, Box, InputAdornment, TextField } from '@mui/material';
import { Fragment, useCallback, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import CreateEdit from './CreateEdit';
import { ConfirmDialog } from '@/components/ConfirmDialog';
import { toast } from 'react-toastify';
import SearchIcon from '@mui/icons-material/Search';
import { debounce } from 'lodash';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import userApi from '@/api/userApi';
import Tooltip from '@mui/material/Tooltip';
import { useSelector } from 'react-redux';
import PrintIcon from '@mui/icons-material/Print';

function UserManagement() {
    const onClose = () => setDialogData({ ...dialogData, open: false });
    const onOpenAdd = () => setDialogData({ ...dialogData, open: true, data: {}, type: 'ADD' });
    const onOpenEdit = data =>
        setDialogData({ ...dialogData, open: true, type: 'EDIT', data, title: 'Cập nhật thông tin khách hàng' });
    const [param, setParam] = useState({});
    const [tabeleData, setTableData] = useState([]);
    const [totalPage, setTotalPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const me = useSelector(state => state.auth.me);
    const [tabeleDataSelect, setTableDataSelect] = useState([]);

    const getData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await userApi.listCus(param);
            setLoading(false);
            setTableData(response.data);
            setTotalPage(response.last_page || response?.meta?.last_page);
        } catch (error) {
            setLoading(false);
        }
    }, [param]);
    const [dialogData, setDialogData] = useState({
        open: false,
        title: 'Thêm mới khách hàng',
        onClose: onClose,
        onDone: getData,
    });

    const onChangePage = page => {
        setParam({ ...param, page });
    };
    const onActiveDeactive = item => {
        ConfirmDialog({
            title: item.active ? 'Khóa tài khoản người dùng' : 'Mở khóa tài khoản người dùng',
            html: true,
            body: item.active
                ? `Bạn có chắc chắn muốn khóa người dùng <b>${item.name}</b> không ? <br/> Tài khoản <b>${item.name}</b> sẽ không thể đăng nhập`
                : `Bạn có chắc chắn muốn mở khóa khóa người dùng <b>${item.name}</b> không ? <br/> Tài khoản <b>${item.name}</b> sẽ có thể đăng nhập`,
            action: async onClose => {
                await userApi.activeDeactiveUser(item.id);
                onClose();
                toast.success('Thành công!');
                getData();
            },
        });
    };

    const onSelectItem = itemSelected => {
        let a = []
        itemSelected.forEach((values, keys) => {
            a.push(values.id)
        })
        setTableDataSelect(a);
        console.log(a);
    }

    const goToLinkPrintOnPaper = (link) => {
        const url = 'https://dl-zabawek.pl/api/inhoadonmulty/' + link;
        window.open(url);
    };

    const handleSearch = value =>
        debounce(() => {
            setParam({ ...param, search: value });
        }, 600)();

    useEffect(() => {
        getData();
    }, [getData]);
    return (
        <Box sx={{ padding: '15px' }}>
            <CreateEdit {...dialogData} />
            <Box sx={{ mb: 2 }} className="d-flex justify-space-between align-center">
                <Box sx={{ fontSize: '24px' }}>Danh sách thông tin khách hàng</Box>
                <IconButton aria-label="hide" color="success" onClick={() => goToLinkPrintOnPaper(tabeleDataSelect)}>
                    <PrintIcon fontSize="large" />
                </IconButton>
            </Box>
            <Box sx={{ mb: 1 }} className="d-flex justify-space-between">
                <TextField
                    size="small"
                    placeholder="Tìm kiếm thông tin khách hàng"
                    variant="outlined"
                    style={{ width: '500px' }}
                    onChange={e => handleSearch(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                <Box>
                    <Button onClick={onOpenAdd} variant="contained" disableElevation startIcon={<AddIcon />}>
                        Thêm mới
                    </Button>
                </Box>
            </Box>
            <MTable data={tabeleData} loading={loading} countPage={totalPage} onChangePage={onChangePage} onSelect={onSelectItem}>
                {item => (
                    <Fragment>
                        <div property="name" label="Tên khách hàng">
                            <Box className="d-flex align-cener">
                                <Avatar sx={{ bgcolor: '#5B2C6F' }} src={item.avatar_url}>
                                    {!item.avatar_url && item.name ? item.name.charAt(0) : null}
                                </Avatar>
                                <Box sx={{ ml: 1 }}>
                                    <Box sx={{ fontWeight: 'bold' }}>{item.name}</Box>
                                    <Box sx={{ fontSize: '13px', color: '#626567' }}>{item.email}</Box>
                                </Box>
                            </Box>
                        </div>

                        <div label="Số điện thoại" prop="phone"></div>
                        <div label="Địa chỉ" prop="address"></div>
                        <div label="Ngày tham gia" prop="created_at">

                        </div>
                        <div label="Hành động" sx={{ width: '150px' }}>
                            {me.id !== item.id ? (
                                item.active ? (
                                    <Tooltip title="Khóa người dùng">
                                        <IconButton
                                            aria-label="delete"
                                            color="secondary"
                                            onClick={() => onActiveDeactive(item)}
                                        >
                                            <LockPersonIcon />
                                        </IconButton>
                                    </Tooltip>
                                ) : (
                                    <Tooltip title="Mở khóa tài khoản">
                                        <IconButton
                                            aria-label="delete"
                                            color="secondary"
                                            onClick={() => onActiveDeactive(item)}
                                        >
                                            <LockOpenIcon />
                                        </IconButton>
                                    </Tooltip>
                                )
                            ) : null}
                            <Tooltip title="Cập nhật thông tin">
                                <IconButton aria-label="edit" color="primary" onClick={() => onOpenEdit(item)}>
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </Fragment>
                )}
            </MTable>
        </Box>
    );
}
export default UserManagement;
