import classNames from 'classnames/bind';
import styles from './Footer.module.scss';
import { useNavigate } from 'react-router-dom';
import images from '@/assets/images';
import { Box } from '@mui/material';
import productApi from '@/api/productApi';
import { useCallback, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';

const cx = classNames.bind(styles);

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(1),
}));

function HomeFooter() {
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();
    const goPage = path => {
        navigate(path, { replace: true });
    };
    const Accordion = styled(props => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
        '&:before': {
            display: 'none',
        },
    }));

    const goToLink = link => {
        setAnchorEl(null);
        navigate(link);
    };

    const [categories, setCategories] = useState([]);
    const getCategories = useCallback(async () => {
        let response = await productApi.getCategories({ perPage: 10 });
        setCategories(response.data);
    }, []);
    useEffect(() => {
        getCategories();
    }, [getCategories]);
    var widthView = '100%';
    if (window.location.pathname === '/design') {
        widthView = '1315px';
    }
    return (
        <div className={cx('wrap-footer')} style={{ minWidth: widthView }}>
            <div className={cx('body-footer')}>
                <Box sx={{ pl: 2, mt: 1 }} style={{ maxWidth: '25%' }}>
                    <div className={cx('title-footer')}>
                        <img src={images.logoWidth} style={{ width: '180px' }} alt="logomoi" />
                    </div>
                    <div className={cx('text-footer-char')}>
                        <Box sx={{ mt: 1 }}>Dawid Lee- Zabawki</Box>
                        <Box sx={{ mt: 1 }}>SOC HIGH TECHNOLOGY CO LTD</Box>
                        <Box sx={{ mt: 1 }}>Miło nam służyć</Box>

                    </div>
                </Box>
                <Box sx={{ pl: 2, mt: 1 }}>
                    <div className={cx('title-footer')}>PRODUKTY</div>
                    {categories
                        ? categories.map(item => (
                            <div
                                key={item.id}
                                onClick={() => goPage(`/category/${item.code}`)}
                                className={cx('text-footer')}
                            >
                                {item.name}
                            </div>
                        ))
                        : null}
                </Box>
                <Box sx={{ pl: 2, mt: 1 }}>
                    <div className={cx('title-footer')}>OBSŁUGA KLIENTA</div>
                    <div onClick={() => goPage('/chinh-sach-bao-mat')} className={cx('text-footer')}>
                        Polityka bezpieczeństwa informacji
                    </div>
                    <div onClick={() => goPage('/chinh-sach-dat-hang')} className={cx('text-footer')}>
                        Zasady składania zamówień i dostaw
                    </div>
                    {/* <div onClick={() => goPage('/chinh-sach-thanh-toan')} className={cx('text-footer')}>
                        Zasady płatności
                    </div>
                    <div onClick={() => goPage('/chinh-sach-doi-tra-va-hoan-tien')} className={cx('text-footer')}>
                        30-dniowa polityka zwrotów i zwrotów pieniędzy
                    </div>
                    <div onClick={() => goPage('/chinh-sach-kiem-hang')} className={cx('text-footer')}>
                        Polityka kontroli towarów
                    </div>
                    <div onClick={() => goPage('/dieu-kien-giao-dich-chung')} className={cx('text-footer')}>
                        Ogólne warunki handlowe
                    </div> */}
                </Box>
                {/* <Box sx={{ pl: 2, mt: 1 }}>
                    <div className={cx('title-footer')}>O NAS</div>
                    <div className={cx('text-footer')} onClick={() => goPage('/cau-chuyen-ve-onyou')}>
                        Historia
                    </div>
                    <div className={cx('text-footer')} onClick={() => goPage('/cau-chuyen-ve-unu')}>
                        Inspiracje biznesowe
                    </div>
                </Box> */}
                <Box className={cx('footer-contact')} sx={{ pl: 2, pr: 1 }}>
                    <div className={cx('title-footer')}>KONTAKT</div>
                    <div className={cx('text-footer')}>
                        <a
                            style={{ textDecoration: 'none' }}
                            href="https://www.google.com/maps/dir//onyou/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x3135ad6ae214f427:0x9d6401e812b71d92?sa=X&ved=2ahUKEwicisve-9z9AhXJf94KHQjYCqQQ9Rd6BAhYEAU"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <p className={cx('text-footer')}>
                                ul. Nadrzeczna 16C, GD Centrum, 05-552 Wólka Kosowska
                            </p>
                        </a>
                    </div>
                    <div className={cx('text-footer')}>
                        Infolinia:
                        <a style={{ color: 'black', textDecoration: 'none' }} href="tel:02499959697">
                            <b> +48579185859</b>
                        </a>
                    </div>
                    <div className={cx('text-footer')}>
                        Email:
                        <a style={{ color: 'black', textDecoration: 'none' }} href="mailto:zabawkasl@gmail.com">
                            <b> zabawkasl@gmail.com</b>
                        </a>
                    </div>
                    <div className={cx('text-footer')}>
                        Rekrutacja:
                        <a style={{ color: 'black', textDecoration: 'none' }} href="mailto:zabawkasl@gmail.com">
                            <b>zabawkasl@gmail.com</b>
                        </a>
                    </div>
                    <div className={cx('text-footer')}>
                        Czas pracy:
                        <b> Poniedziałek - Niedziela 8h-16h</b>
                    </div>
                </Box>
            </div>
            <div className={cx('body-footer-mobile')}>
                <div>
                    <Box sx={{ pl: 2, mt: 1 }}>
                        <div className={cx('title-footer')}>
                            <img src={images.logoWidth} style={{ width: '180px' }} alt="logomoi" />
                        </div>
                        <div className={cx('text-footer-char')}>
                            <Box sx={{ mt: 1 }}>Dawid Lee- Zabawki</Box>
                            <Box sx={{ mt: 1 }}>SOC HIGH TECHNOLOGY CO LTD</Box>
                            <Box sx={{ mt: 1 }}>Miło nam służyć</Box>

                        </div>
                    </Box>
                </div>
                <div>
                    <Accordion sx={{ bgcolor: '#613838', color: 'white' }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>PODUKTY</Typography>
                        </AccordionSummary>
                        {categories
                            ? categories.map(item => (
                                <AccordionDetails
                                    key={item.id}
                                    sx={{ pl: 3, fontWeight: '100' }}
                                    onClick={() => goToLink(`/category/${item.code}`)}
                                >
                                    <Typography>
                                        {item.name}
                                    </Typography>

                                </AccordionDetails>
                            ))
                            : null}
                    </Accordion>

                    <Accordion sx={{ boxShadow: 'none', bgcolor: '#613838', color: 'white' }}>
                        <AccordionSummary
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}>
                            <Typography>
                                OBSŁUGA KLIENTA
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ pl: 3 }} onClick={() => goToLink(`/chinh-sach-bao-mat`)}>
                            Polityka bezpieczeństwa informacji
                        </AccordionDetails>
                        <AccordionDetails sx={{ pl: 3 }} onClick={() => goToLink(`/chinh-sach-dat-hang`)}>
                            Zasady składania zamówień i dostaw
                        </AccordionDetails>
                        {/* <AccordionDetails sx={{ pl: 3 }} onClick={() => goToLink(`/chinh-sach-thanh-toan`)}>
                            Zasady płatności
                        </AccordionDetails>
                        <AccordionDetails sx={{ pl: 3 }} onClick={() => goToLink(`/chinh-sach-doi-tra-va-hoan-tien`)}>
                            30-dniowa polityka zwrotów i zwrotów pieniędzy
                        </AccordionDetails>
                        <AccordionDetails sx={{ pl: 3 }} onClick={() => goToLink(`/chinh-sach-kiem-hang`)}>
                            Polityka kontroli towarów
                        </AccordionDetails>
                        <AccordionDetails sx={{ pl: 3 }} onClick={() => goToLink(`/dieu-kien-giao-dich-chung`)}>
                            Ogólne warunki handlowe
                        </AccordionDetails> */}
                    </Accordion>


                    <Accordion sx={{ boxShadow: 'none', bgcolor: '#613838', marginRight: '0px', color: 'white' }}>
                        <AccordionSummary
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}>
                            <Typography >
                                Dawid Lee- Zabawki
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ pl: 3 }} onClick={() => goToLink(`/cau-chuyen-ve-onyou`)}>
                            Historia się zaczyna
                        </AccordionDetails>
                        <AccordionDetails sx={{ pl: 3 }} onClick={() => goToLink(`/cau-chuyen-ve-onyou`)}>
                            Trendy biznesowe
                        </AccordionDetails>
                    </Accordion>

                    <Accordion sx={{ boxShadow: 'none', bgcolor: '#613838', color: 'white' }}>
                        <AccordionSummary
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}>
                            <Typography >
                                KONTAKT
                            </Typography>

                        </AccordionSummary>
                        <AccordionDetails sx={{ pl: 3 }} onClick={() => goToLink(`/contact`)}>
                            <a
                                style={{ textDecoration: 'none' }}
                                href="https://www.google.com/maps/dir//onyou/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x3135ad6ae214f427:0x9d6401e812b71d92?sa=X&ved=2ahUKEwicisve-9z9AhXJf94KHQjYCqQQ9Rd6BAhYEAU"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <p style={{ color: 'white' }}>
                                    ul. Nadrzeczna 16C, GD Centrum, 05-552 Wólka Kosowska
                                </p>
                            </a>
                        </AccordionDetails>
                        <AccordionDetails sx={{ pl: 3 }} onClick={() => goToLink(`/cau-chuyen-ve-onyou`)}>
                            Hotline:
                            <a style={{ color: 'white', textDecoration: 'none' }} href="tel:02499959697">
                                <b> +48579185859</b>
                            </a>
                        </AccordionDetails>
                        <AccordionDetails sx={{ pl: 3 }} onClick={() => goToLink(`/cau-chuyen-ve-onyou`)}>
                            Email:
                            <a style={{ color: 'white', textDecoration: 'none' }} href="mailto:zabawkasl@gmail.com">
                                <b> zabawkasl@gmail.com</b>
                            </a>
                        </AccordionDetails>
                        <AccordionDetails sx={{ pl: 3 }} onClick={() => goToLink(`/cau-chuyen-ve-onyou`)}>
                            Czas pracy:
                            <b> Poniedziałek - Niedziela 8h-16h</b>
                        </AccordionDetails>
                    </Accordion>

                </div>
            </div>

            <Box className={cx('line-card-foot')} >
                <div className={cx('foottitledesign1')}>
                    © 2024 Edem All Rights Reserved. Privacy policy - Dawid Lee- Zabawki
                </div>
                <div className={cx('foottitledesign2')}>

                </div>
                <div className={cx('foottitledesign3')}>

                </div>
                <div className={cx('foottitledesign4')}>
                    <div>
                        <a href="https://www.facebook.com/dawidleez" target="_blank" rel="noopener noreferrer">
                            <img src={images.tiktok} style={{ height: '50px' }} alt="TIKTOK" />
                        </a>
                        <a href="https://www.facebook.com/dawidleez" target="_blank" rel="noopener noreferrer">
                            <img src={images.instalogo} style={{ height: '50px', marginLeft: '8px' }} alt="INSTAGRAM" />
                        </a>
                        <a href="https://www.facebook.com/dawidleez" target="_blank" rel="noopener noreferrer">
                            <img src={images.fblogo} style={{ height: '50px', marginLeft: '15px' }} alt="FACEBOOK" />
                        </a>
                        <a href="https://www.facebook.com/dawidleez" target="_blank" rel="noopener noreferrer">
                            <img src={images.shopee} style={{ height: '50px', marginLeft: '15px' }} alt="SHOPEE" />
                        </a>
                    </div>
                </div>

            </Box>
            <Box className={cx('line-card-foot-mobile')} >
                <div className={cx('foottitledesign1')}>
                    © 2024 Privacy policy - Dawid Lee- Zabawki
                </div>
                <div className={cx('foottitledesign4')}>
                    <div>
                        <a href="https://www.facebook.com/dawidleez" target="_blank" rel="noopener noreferrer">
                            <img src={images.tiktok} style={{ height: '50px' }} alt="TIKTOK" />
                        </a>
                        <a href="https://www.facebook.com/dawidleez" target="_blank" rel="noopener noreferrer">
                            <img src={images.instalogo} style={{ height: '50px', marginLeft: '8px' }} alt="INSTAGRAM" />
                        </a>
                        <a href="https://www.facebook.com/dawidleez" target="_blank" rel="noopener noreferrer">
                            <img src={images.fblogo} style={{ height: '50px', marginLeft: '15px' }} alt="FACEBOOK" />
                        </a>
                        <a href="https://www.facebook.com/dawidleez" target="_blank" rel="noopener noreferrer">
                            <img src={images.shopee} style={{ height: '50px', marginLeft: '15px' }} alt="SHOPEE" />
                        </a>
                    </div>
                </div>
            </Box>
        </div>
    );
}

export default HomeFooter;
