import LoadingButton from '@mui/lab/LoadingButton';
import { useState } from 'react';

function ButtonConfirm({ action }) {
    const [loading, setLoading] = useState(false);
    const handleAction = async () => {
        try {
            setLoading(true);
            await action();
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    return (
        <LoadingButton loading={loading} size="small" variant="contained" onClick={() => handleAction()}>
            Đồng ý
        </LoadingButton>
    );
}

export default ButtonConfirm;
