import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import Card from '@mui/material/Card';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import classNames from 'classnames/bind';
import styles from './confirm.module.scss';
import ButtonConfirm from './ButtonConfirm';

const cx = classNames.bind(styles);
export const ConfirmDialog = ({ title = 'Xác nhận', body = '', type = 'warning', html = false, action }) => {
    confirmAlert({
        overlayClassName: cx('custom-overlay'),
        customUI: ({ onClose }) => {
            return (
                <Card sx={{ width: 500 }}>
                    <div className="d-flex align-center" style={{ paddingLeft: '15px', height: '60px' }}>
                        {type === 'warning' ? (
                            <WarningIcon fontSize="large" sx={{ color: '#e65100' }} />
                        ) : (
                            <InfoIcon fontSize="large" color="primary" />
                        )}
                        <div className={cx('title-box-confirm')}>{title}</div>
                    </div>
                    <CardContent>
                        {html ? <div dangerouslySetInnerHTML={{ __html: body }}></div> : <div>{body}</div>}
                    </CardContent>
                    <CardActions>
                        <div className="d-flex justify-end" style={{ width: '100%' }}>
                            <Button
                                onClick={onClose}
                                variant="outlined"
                                size="small"
                                style={{ marginRight: '20px' }}
                                startIcon={<CheckIcon />}
                            >
                                Hủy bỏ
                            </Button>
                            <ButtonConfirm action={() => action(onClose)}></ButtonConfirm>
                        </div>
                    </CardActions>
                </Card>
            );
        },
    });
};
