import { Box, Divider, Button } from '@mui/material';
import styles from './search.scss';
import classNames from 'classnames/bind';
import MiniInput from '@/components/Input/MiniInput';
import { useCallback, useEffect, useState } from 'react';
import productApi from '@/api/productApi';
import GridProduct from '@/components/GridProduct/GridProductV3';
import SearchOffIcon from '@mui/icons-material/SearchOff';

const cx = classNames.bind(styles);

const Search = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(searchParams.entries());
    const [keyWord, setKeyWord] = useState(params?.search);
    const [products, setProudcts] = useState([]);
    const [page, setPage] = useState([]);

    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [totalPage, setTotalPage] = useState(0);

    const handleSearch = () => {
        window.location.href = encodeURI('/search?search=' + keyWord);
    };
    const handleKeyDown = e => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };
    useEffect(() => {
        const getData = async () => {
            setProudcts([]);
            setLoading(true);
            if (keyWord) {
                let data = await productApi.getProduct({ search: keyWord, perPage: 70, page });
                setProudcts(data.data);
                setTotal(data.meta.total);
                setTotalPage(data.meta.last_page);
            }
            setLoading(false);
        };
        getData();
    }, [page]);
    const onChangePage = page => {
        setPage(page);
    };
    return (
        <div>
            <Box className="c-flex align-center" sx={{ mb: 4 }}>
                <div className={cx('width-container')}>
                    <Box className={cx('search-container-top')} >
                        <Box className={cx('search-text-container')} sx={{ mb: 1, mt: 4 }}>Szukaj</Box>
                        <MiniInput
                            value={keyWord}
                            className={cx('search-input-container')}
                            onKeyDown={handleKeyDown}
                            onChange={e => setKeyWord(e.target.value)}
                        />
                        <Button size="large" variant="contained" sx={{ height: '51px', ml: 2 }} onClick={handleSearch}>
                            Szukaj
                        </Button>
                    </Box>
                    <Divider sx={{ mt: 2, mb: 4 }} />
                    <Box sx={{ mb: 2, mt: 1, fontSize: '20px' }}>Wynik ({total})</Box>
                    <GridProduct
                        products={products}
                        loading={loading}
                        totalPage={totalPage}
                        onChangePage={onChangePage}
                    />
                </div>
            </Box>
        </div>
    );
};
export default Search;
