import Divider from '@mui/material/Divider';
import classNames from 'classnames/bind';
import styles from './Service.scss';
import Box from '@mui/material/Box';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const cx = classNames.bind(styles);

const textData = [
    {
        title: 'Zakupy w naszym sklepie są proste i przyjemne. Nie musisz się REJESTROWAĆ ANI ZAKŁADAĆ KONTA!',
        text1: '',
        line: [
            'Kliknij na pożądany produkt by przeczytać jego opis i zobaczyć dodatkowe zdjęcia.',
            'Wybierz ilość do zamówienia.',
            'Dodaj do koszyka.',
            'W koszyku możesz kliknąć na “Złóż zamówienie”, aby przejść dalej lub “Kontynuuj zakupy”, aby móc dobrać dodatkowe produkty do zamówienia.',
            'Następnie należy podać potrzebne informacje do realizacji zamówienia (Imię i Nazwisko, e-mail, numer telefonu, Adres do Faktury, Adres dostawy).',
            'Wybierz formę płatności.',
            'Na koniec należy sprawdzić poprawność zamówienia, po czym potwierdzić naciskając “Złóż zamówienie”.',
            'Od tego momentu zamówienie jest w naszych rękach. Prosimy sprawdzać e-mail ponieważ będziemy w nim wysyłać ważne informacje na temat wysyłki.',
        ]
    }
];

function Order() {

    return (
        <div className="d-flex justify-center bg-gray">
            <div className={cx('container-service', 'width-container')}>
                <div style={{ width: '100%', textAlign: 'center', fontSize: '24px', fontWeight: 'bold' }}>
                    JAK ZAMAWIAĆ
                </div>
                <Divider sx={{ mb: 1, mt: 1 }} />
                <Box>
                    <Box sx={{ mt: 4, fontSize: '16px' }}>
                        {textData.map((item, index) => (
                            <div key={index}>
                                <Box sx={{ fontSize: '18px', fontWeight: 'bold', mt: 3 }}>
                                    {item.title}
                                </Box>
                                <Box sx={{ mt: 2, mb: 2 }}>{item.text1}</Box>
                                {item.line.map((line, id) => (
                                    <Box key={id} sx={{ pl: 3 }}>
                                        <FiberManualRecordIcon sx={{ fontSize: '13px', mr: 2 }} />
                                        {line}
                                    </Box>
                                ))}
                                <Box sx={{ mt: 2 }}>{item.text2}</Box>
                            </div>
                        ))}
                    </Box>
                </Box>

            </div>
        </div>
    );
}
export default Order;
