import HomeHeader from './Header';
import classNames from 'classnames/bind';
import styles from './homeLayout.scss';
const cx = classNames.bind(styles);
function HomeLayoutNoFooter({ children }) {
    return (
        <div className={cx('wrapper')}>
            <div className={cx('home-footer')}>
                <HomeHeader />
            </div>
            <div className={cx('container')}>
                <div className={cx('container-children')}>{children}</div>
            </div>
        </div>
    );
}

export default HomeLayoutNoFooter;
