import MDialog from '@/components/Dialog';
import { Box, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import navbarApi from '@/api/navbarApi';

function CreateEdit({ open, title, onClose, data, type, onDone }) {
    const [disableFeild, setDisableFeild] = useState(false);
    const [typeVoucher, setTypeVoucher] = useState(null);

    const onSubmit = async () => {
        try {
            if (type === 'EDIT') {
                await navbarApi.updateNavbar(formData.id, formData);
            }
            onClose();
            toast.success('Cập nhật navbar thành công!');
            setErrorSubmit({});
            onDone();
        } catch (error) {
            const errors = error.response.data.errors;
            for (const key in errors) {
                errors[key] = errors[key].join('. ');
            }
            setErrorSubmit(errors);
        }
    };
    const [formData, setFormData] = useState({});
    const [errorSubmit, setErrorSubmit] = useState({});

    useEffect(() => {
        setFormData({});
        setDisableFeild(false);
        setTypeVoucher(null);
        if (type === 'EDIT') {
            setFormData({ ...data });
            setDisableFeild(true);
            setTypeVoucher(data.type);
        }
    }, [type, data]);

    return (
        <div>
            <MDialog open={open} title={title} onClose={onClose} onSubmit={onSubmit} type={type}>
                <Box sx={{ width: '800px' }}>
                    <Box>
                        <TextField
                            sx={{ width: '100%' }}
                            label="Thông tin hiển thị"
                            variant="outlined"
                            value={formData.value}
                            error={errorSubmit.value ? true : false}
                            onChange={e => setFormData({ ...formData, value: e.target.value })}
                            helperText={errorSubmit.value}
                        />
                    </Box>
                    <Box sx={{ paddingTop: '20px' }}>
                        <TextField
                            sx={{ width: '100%' }}
                            label="Button"
                            variant="outlined"
                            value={formData.button}
                            onChange={e => setFormData({ ...formData, button: e.target.value })}
                            helperText={errorSubmit.button}
                        />
                    </Box>
                    <Box>
                        <Box sx={{ mt: 2, mb: 1, fontSize: '16px' }}>Hiển thị</Box>
                        <Box
                            className="d-flex align-center justify-center"
                            sx={{
                                minHeight: '35px',
                                background: formData.bg_color,
                                color: formData.text_color,
                                border: '1px solid gray',
                            }}
                        >
                            {formData.value}
                            <a
                                href={formData.link}
                                style={{
                                    marginLeft: '16px',
                                    background: formData.bg_color,
                                    color: formData.text_color,
                                }}
                            >
                                {formData.button}
                            </a>
                        </Box>
                    </Box>
                    <Box sx={{ paddingTop: '20px' }}>
                        <TextField
                            sx={{ width: '100%' }}
                            label="Màu nền"
                            variant="outlined"
                            value={formData.bg_color}
                            onChange={e => setFormData({ ...formData, bg_color: e.target.value })}
                            helperText={errorSubmit.bg_color}
                        />
                    </Box>
                    <Box sx={{ paddingTop: '20px' }}>
                        <TextField
                            sx={{ width: '100%' }}
                            label="Màu chữ"
                            variant="outlined"
                            value={formData.text_color}
                            onChange={e => setFormData({ ...formData, text_color: e.target.value })}
                            helperText={errorSubmit.text_color}
                        />
                    </Box>
                    <Box sx={{ marginTop: '20px' }}>
                        <TextField
                            sx={{ width: '100%' }}
                            label="Link"
                            variant="outlined"
                            value={formData.link}
                            onChange={e => setFormData({ ...formData, link: e.target.value })}
                            helperText={errorSubmit.link}
                        />
                    </Box>
                </Box>
            </MDialog>
        </div>
    );
}
export default CreateEdit;
