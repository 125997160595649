import classNames from 'classnames/bind';
import styles from './IconTitle.scss';
import { Box } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
const cx = classNames.bind(styles);

function IconTitle({ height }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();
    const goToLink = link => {
        setAnchorEl(null);
        navigate(link);
    };

    return (
        <Box >
            <Box sx={{ paddingTop: '0px' }} className={cx('title-card-pc')} >
                <div className={cx('d-flex titledesign1')}>
                    DUMNI, ŻE JESTEŚMY MARKĄ MADE IN POLSKA!
                    <br></br>
                    LIDER TRENDU - PRZYJAZNY DLA ŚRODOWISKA
                </div>
                <div className={cx('d-flex titledesign2')}>
                    #Dawid Lee- Zabawki
                </div>
                <div className={cx('d-flex titledesign3')}>
                    Produkty w Twoim stylu!
                    <br></br>
                    Stale aktualizowana
                </div>
                <div className={cx('d-flex titledesign4')}>
                    Klienci są zawsze w centrum uwagi
                    <br></br>
                    Różnorodne wzory i kolory
                </div>

            </Box>
            <Box className={cx('mobile-card-title')}>
                <div className="center" sx={{ textAlign: 'center' }}>
                    <a>DUMNI, ŻE JESTEŚMY MARKĄ MADE IN POLSKA!</a>
                    <br></br>
                    <br></br>
                    <a>LIDER TRENDU - PRZYJAZNY DLA ŚRODOWISKA</a>
                    <br></br>
                </div>
            </Box>
        </Box>

    );
}
export default IconTitle;
