import classNames from 'classnames/bind';
import styles from './admin-header.module.scss';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import ListIcon from '@mui/icons-material/List';
import IconButton from '@mui/material/IconButton';
import { useSelector, useDispatch } from 'react-redux';
import { toggleSidbar } from '@/store/sidebar/sidebarSlice';

const cx = classNames.bind(styles);

function AdminHeader() {
    const dispatch = useDispatch();
    const openSidebar = useSelector(state => state.sidebar.openSidebar);

    return (
        <div className={cx('header-container')}>
            <IconButton onClick={() => dispatch(toggleSidbar())} size="large">
                {openSidebar ? <MenuOpenIcon sx={{ fontSize: 40 }} /> : <ListIcon sx={{ fontSize: 40 }}/>}
            </IconButton>
        </div>
    );
}
export default AdminHeader;
