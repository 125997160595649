import { Box, Button, Divider, IconButton, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Avatar from '@mui/material/Avatar';
import { useNavigate, useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import productApi from '@/api/productApi';
import styles from './product.scss';
import classNames from 'classnames/bind';
import Autocomplete from '@mui/material/Autocomplete';
import ImageUpload from '@/components/UploadImage/UploadSingle';
import UploadMultiple from '@/components/UploadImage/UploadMultiple';
import CheckroomIcon from '@mui/icons-material/Checkroom';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import ReactQuill from 'react-quill';
import EditIcon from '@mui/icons-material/Edit';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import SellIcon from '@mui/icons-material/Sell';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import StraightenIcon from '@mui/icons-material/Straighten';
import FunctionsIcon from '@mui/icons-material/Functions';
import 'react-quill/dist/quill.snow.css';

const cx = classNames.bind(styles);

function CreateEditProduct() {
    const navigate = useNavigate();
    const paramPath = useParams();

    const [colorSelected, setColorSelected] = useState([]);
    const [statusSelected, setStatusSelected] = useState({});
    const [formData, setFormData] = useState({});
    const [errorSubmit, setErrorSubmit] = useState({});
    const [categories, setCategories] = useState([]);
    const [oldPage, setOldPage] = useState(1);
    const [isEdit, setIsEdit] = useState(false);
    const [productCategory, setProductCategory] = useState(null);

    const handleStatusColor = async (value) => {
        const selected = value;
        if (selected) {
            setStatusSelected(selected);
            return selected;
        } else {
            return 'Error';
        }
    };

    const getCategories = useCallback(async () => {
        let dataCategories = await productApi.getCategories({ perPage: 99999 });
        setCategories(dataCategories.data);
    }, []);

    useEffect(() => {
        getCategories();
    }, [getCategories]);

    useEffect(() => {
        if (paramPath.id) {
            setIsEdit(true);
            const getDetail = async () => {
                let dataDetail = await productApi.getDetailProduct(paramPath.id);
                setFormData({ ...dataDetail });
                setColorSelected([...dataDetail.colors]);

                setStatusSelected(statusList.find(item => item.name === dataDetail.status));

                if (dataDetail.category_id && categories && categories.length > 0) {
                    setProductCategory(categories.find(item => item.id === dataDetail.category_id));
                } else setProductCategory(null);
            };
            getDetail();
        }
        if (paramPath.oldPage) {
            setOldPage(paramPath.oldPage);
        }
    }, [paramPath, categories]);
    const handleSubmit = async () => {
        try {
            formData.colors = [...colorSelected];
            formData.status = statusSelected.name;
            if (!isEdit) {
                await productApi.addProduct(formData);
                navigate('/san-pham');
            } else {
                await productApi.updateProduct(formData, paramPath.id);
            }
            toast.success('Thành công!');
        } catch (error) {
            const errors = error.response.data.errors;
            for (const key in errors) {
                errors[key] = errors[key].join('. ');
            }
            setErrorSubmit(errors);
        }
    };
    const handleUpload = url => {
        setFormData({ ...formData, url_image: url });
    };

    const statusList = [
        {
            "name": "Warte kupna",
            "code": "rgb(103 21 177)"
        },
        {
            "name": "To prawie koniec",
            "code": "#f37121"
        },
        {
            "name": "Ratować",
            "code": "#e08282"
        },
        {
            "name": "Nowy",
            "code": "#040fda"
        },
        {
            "name": "Sale",
            "code": "#ff3102"
        },
        {
            "name": "Limited",
            "code": "#000000"
        }
    ];

    return (
        <Box sx={{ padding: '15px' }}>
            <Box sx={{ mb: 2 }} className="d-flex justify-space-between align-center">
                <Box className="d-flex">
                    <Avatar onClick={() => navigate(`/san-pham?perPage=${oldPage}`)} sx={{ cursor: 'pointer' }}>
                        <ArrowBackIcon />
                    </Avatar>
                    <Box sx={{ ml: 2, fontSize: '26px' }}>{isEdit ? 'Cập nhật sản phẩm' : 'Thêm mới sản phẩm'}</Box>
                </Box>
                <Box className={cx('product-add-bnt-pc')}>
                    <Button
                        onClick={handleSubmit}
                        variant="contained"
                        disableElevation
                        startIcon={isEdit ? <EditIcon /> : <AddIcon />}
                    >
                        {isEdit ? 'Cập nhật' : 'Thêm mới'}
                    </Button>
                </Box>
            </Box>
            <Divider sx={{ mt: 2 }} />
            <Box className={cx('product-add-content-pc')}>
                <Grid container spacing={4} sx={{ mt: 0 }}>
                    <Grid item xs={9}>
                        <Box className="d-flex align-center" sx={{ paddingBottom: '1em' }}>
                            <div className={cx('form-label')}>Sản phẩm</div>
                            <TextField
                                sx={{ flex: 1 }}
                                label="Tên sản phẩm"
                                placeholder="Tên sản phẩm"
                                variant="outlined"
                                size="small"
                                value={formData.name}
                                error={errorSubmit.name ? true : false}
                                onChange={e => setFormData({ ...formData, name: e.target.value })}
                                helperText={errorSubmit.name}
                                InputProps={{
                                    startAdornment: <DriveFileRenameOutlineIcon sx={{ mr: 1 }} />,
                                }}
                            />
                            <Autocomplete
                                disablePortal
                                sx={{ flex: 1, ml: 4 }}
                                label="Danh mục sản phẩm"
                                variant="outlined"
                                size="small"
                                getOptionLabel={option => option.name}
                                options={categories}
                                value={productCategory}
                                onChange={(e, newValue) => {
                                    setFormData({ ...formData, category_id: newValue.id });
                                    setProductCategory(newValue);
                                }}
                                renderInput={params => <TextField {...params} label="Danh mục sản phẩm" />}
                            />
                        </Box>
                        <Box className="d-flex align-center" sx={{ mt: 2, paddingBottom: '1em' }}>
                            <div className={cx('form-label')}>Giá sản phẩm</div>
                            <TextField
                                sx={{ flex: 1 }}
                                label="Giá bán"
                                variant="outlined"
                                size="small"
                                value={formData.sale_price}
                                type="number"
                                onChange={e => setFormData({ ...formData, sale_price: e.target.value })}
                                InputProps={{
                                    startAdornment: <SellIcon sx={{ mr: 1 }} />,
                                }}
                            />

                            <TextField
                                sx={{ flex: 1, ml: 4 }}
                                label="Số lượng paczka"
                                variant="outlined"
                                size="small"
                                value={formData.paczka}
                                onChange={e => setFormData({ ...formData, paczka: e.target.value })}
                                InputProps={{
                                    startAdornment: <FunctionsIcon sx={{ mr: 1 }} />,
                                }}
                            />
                        </Box>
                        <Box className="d-flex align-center" sx={{ mt: 2 }}>
                            <div className={cx('form-label')}>Trạng thái</div>
                            <Autocomplete
                                disablePortal
                                sx={{ flex: 1 }}
                                label="Trạng thái"
                                variant="outlined"
                                size="meidum"
                                getOptionLabel={option => option.name}
                                renderOption={(props, option) => (
                                    <Box className="d-flex align-cener" {...props}>
                                        <Box sx={{ bgcolor: option.code }} className={cx('color-icon')}></Box>
                                        <Box sx={{ ml: 1 }}>{option.name}</Box>
                                    </Box>
                                )}
                                options={statusList}
                                value={statusSelected}
                                onChange={(e, newValue) => handleStatusColor(newValue)}
                                renderInput={params => <TextField {...params} label="Chọn một trạng thái" />}
                            />
                        </Box>
                        <Box className="d-flex align-center" sx={{ mt: 2, paddingBottom: '1em' }}>
                            <div className={cx('form-label')}>Rozmiary</div>
                            <TextField
                                sx={{ flex: 1 }}
                                label="Rozmiary"
                                variant="outlined"
                                size="small"
                                value={formData.rozmiary}
                                onChange={e => setFormData({ ...formData, rozmiary: e.target.value })}
                                InputProps={{
                                    startAdornment: <StraightenIcon sx={{ mr: 1 }} />,
                                }}
                            />

                            <TextField
                                sx={{ flex: 1, ml: 4 }}
                                label="Kho nào"
                                variant="outlined"
                                size="small"
                                value={formData.kho_van_chuyen}
                                onChange={e => setFormData({ ...formData, kho_van_chuyen: e.target.value })}
                                InputProps={{
                                    startAdornment: <WarehouseIcon sx={{ mr: 1 }} />,
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <ImageUpload onDone={handleUpload} url={formData.url_image} />
                    </Grid>
                </Grid>
                <Divider sx={{ mt: 2 }} />
                <Box className="d-flex justify-space-between">
                    <Button
                        onClick={() => navigate(`/san-pham?perPage=${oldPage}`)}
                        variant="contained"
                        color="error"
                        disableElevation
                        startIcon={<ArrowBackIcon />}
                    >
                        Quay lại
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        variant="contained"
                        disableElevation
                        startIcon={isEdit ? <EditIcon /> : <AddIcon />}
                    >
                        {isEdit ? 'Cập nhật' : 'Đăng sản phẩm'}
                    </Button>
                </Box>
            </Box>
            <Box className={cx('product-add-content-mobile')}>
                <Grid container spacing={4} sx={{ mt: 0 }}>
                    <Grid item xs={12}>
                        <Box className="d-flex align-center">
                            <TextField
                                sx={{ flex: 1 }}
                                label="Tên SP"
                                placeholder="Tên SP"
                                variant="outlined"
                                size="small"
                                value={formData.name}
                                error={errorSubmit.name ? true : false}
                                onChange={e => setFormData({ ...formData, name: e.target.value })}
                                helperText={errorSubmit.name}
                                InputProps={{
                                    startAdornment: <DriveFileRenameOutlineIcon sx={{ mr: 1 }} />,
                                }}
                            />
                            <Autocomplete
                                disablePortal
                                sx={{ flex: 1, ml: 4 }}
                                label="Danh mục"
                                variant="outlined"
                                size="small"
                                getOptionLabel={option => option.name}
                                options={categories}
                                value={productCategory}
                                onChange={(e, newValue) => {
                                    setFormData({ ...formData, category_id: newValue.id });
                                    setProductCategory(newValue);
                                }}
                                renderInput={params => <TextField {...params} label="Danh mục sản phẩm" />}
                            />
                        </Box>
                        <Box className="d-flex align-center" sx={{ mt: 2, paddingBottom: '1em' }}>
                            <TextField
                                sx={{ flex: 1 }}
                                label="Giá bán"
                                variant="outlined"
                                size="small"
                                value={formData.sale_price}
                                type="number"
                                onChange={e => setFormData({ ...formData, sale_price: e.target.value })}
                                InputProps={{
                                    startAdornment: <SellIcon sx={{ mr: 1 }} />,
                                }}
                            />
                            <TextField
                                sx={{ flex: 1, ml: 4 }}
                                label="Số lượng paczka"
                                variant="outlined"
                                size="small"
                                value={formData.paczka}
                                onChange={e => setFormData({ ...formData, paczka: e.target.value })}
                                InputProps={{
                                    startAdornment: <FunctionsIcon sx={{ mr: 1 }} />,
                                }}
                            />
                        </Box>
                        <Box className="d-flex align-center" sx={{ mt: 2 }}>
                            <Autocomplete
                                disablePortal
                                sx={{ flex: 1 }}
                                label="Trạng thái SP"
                                variant="outlined"
                                size="meidum"
                                getOptionLabel={option => option.name}
                                renderOption={(props, option) => (
                                    <Box className="d-flex align-cener" {...props}>
                                        <Box sx={{ bgcolor: option.code }} className={cx('color-icon')}></Box>
                                        <Box sx={{ ml: 1 }}>{option.name}</Box>
                                    </Box>
                                )}
                                options={statusList}
                                value={statusSelected}
                                onChange={(e, newValue) => handleStatusColor(newValue)}
                                renderInput={params => <TextField {...params} label="Chọn một trạng thái" />}
                            />
                        </Box>
                        <Box className="d-flex align-center" sx={{ mt: 2, paddingBottom: '1em' }}>
                            <TextField
                                sx={{ flex: 1 }}
                                label="Rozmiary"
                                variant="outlined"
                                size="small"
                                value={formData.rozmiary}
                                onChange={e => setFormData({ ...formData, rozmiary: e.target.value })}
                                InputProps={{
                                    startAdornment: <StraightenIcon sx={{ mr: 1 }} />,
                                }}
                            />
                        </Box>
                        <Box className="d-flex align-center" sx={{ mt: 1, paddingBottom: '1em' }}>
                            <TextField
                                sx={{ flex: 1 }}
                                label="Kho nào"
                                variant="outlined"
                                size="small"
                                value={formData.kho_van_chuyen}
                                onChange={e => setFormData({ ...formData, kho_van_chuyen: e.target.value })}
                                InputProps={{
                                    startAdornment: <WarehouseIcon sx={{ mr: 1 }} />,
                                }}
                            />
                        </Box>
                        <Box>
                            <ImageUpload onDone={handleUpload} url={formData.url_image} />
                        </Box>
                    </Grid>
                </Grid>
                <Divider sx={{ mt: 2 }} />
                <Box className="d-flex justify-space-between">
                    <Button
                        onClick={() => navigate(`/san-pham?perPage=${oldPage}`)}
                        variant="contained"
                        color="error"
                        disableElevation
                        startIcon={<ArrowBackIcon />}
                    >
                        Quay lại
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        variant="contained"
                        disableElevation
                        startIcon={isEdit ? <EditIcon /> : <AddIcon />}
                    >
                        {isEdit ? 'Cập nhật' : 'Đăng sản phẩm'}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}
export default CreateEditProduct;
