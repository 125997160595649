import images from '@/assets/images';

export const PAYMENTS = [
    {
        image: images.codIcon,
        title: 'Pobraniem',
        body: 'Zapłać gotówką przy odbiorze',
        id: 'cod',
    },
    // {
    //     image: images.banking,
    //     title: 'Przelew (bankowość internetowa)',
    //     body: 'Transfer treści: DUYPHAP_<Kod zamówienia (6 cyfr)>',
    //     id: 'banking',
    // },
    // {
    //     image: images.atmCard,
    //     title: 'Płatność online',
    //     body: 'Karta bankomatowa/karta Visa, MasterCard',
    //     id: 'onepay',
    // }

];

export const ODER_STATUS = {
    PENDING: {
        name: 'Chờ xác nhận',
        color: '#1F618D'
    },
    APPROVED: {
        name: 'Đã xác nhận',
        color: '#5B2C6F'
    },
    SHOP_CANCEL: {
        name: 'Hủy bởi người bán',
        color: '#B03A2E'
    },
    ORDER_CANCEL: {
        name: 'Hủy bởi khách hàng',
        color: '#B03A2E'
    },
    SHIPPING: {
        name: 'Đang vận chuyển',
        color: '#1F618D'
    },
    SUCCESS: {
        name: 'Giao hàng thành công',
        color: '#196F3D'
    },
    FAILD: {
        name: 'Giao hàng thất bại',
        color: '#CB4335'
    },
};
