import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useEffect, useState } from 'react';
import Pagination from '@mui/material/Pagination';
import LinearProgress from '@mui/material/LinearProgress';
import { Box } from '@mui/material';
import { memo } from 'react';
import Checkbox from '@mui/material/Checkbox';

function MTable({ data = [], loading = false, countPage = 0, onChangePage, showPaginate = true, onSelect, children }) {
    const [headers, setHeaders] = useState([]);
    useEffect(() => {
        const elements = children({})?.props?.children;
        const dataHeader = elements.map(item => ({
            property: item?.props?.property | item?.props?.property,
            ...item.props,
        }));
        setHeaders(dataHeader);
    }, [children]);
    const [selected, setSelected] = useState([]);

    useEffect(() => {
        if (onSelect) {
            onSelect(selected);
        }
    }, [selected, onSelect])

    const handleNestObject = (property, row) => {
        try {
            const arrayProperty = property.split('.');
            let value = {};
            arrayProperty.forEach(element => {
                value = value[element] ? value[element] : row[element];
            });
            if (value || value === 0 || value === '0') {
                return typeof value == 'string' ? value : JSON.stringify(value);
            }
            return '';
        } catch (error) {
            return '';
        }
    };
    const handleSelectAllClick = event => {
        if (event.target.checked) {
            //   const newSelected = data.map((n) => n.id);
            setSelected(data);
            return;
        }
        setSelected([]);
    };
    const isSelected = id => selected.find(item => item.id === id) ? true : false;
    const handleClick = (event, row) => {
        let newSelected = [];
        const selectedItem = selected.find(item => item.id === row.id);
        if (selectedItem) {
            newSelected = selected.filter(item => item.id !== row.id)
        } else {
            newSelected = [...selected, row]
        }
        setSelected(newSelected);
        //const selectedIndex = selected.indexOf(id);
        //     if (selectedIndex === -1) {
        //         newSelected = newSelected.concat(selected, id);
        //     } else if (selectedIndex === 0) {
        //         newSelected = newSelected.concat(selected.slice(1));
        //     } else if (selectedIndex === selected.length - 1) {
        //         newSelected = newSelected.concat(selected.slice(0, -1));
        //     } else if (selectedIndex > 0) {
        //         newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        //     }
        //     setSelected(newSelected);
    };
    return (
        <div>
            <TableContainer>
                {loading ? <LinearProgress sx={{ height: '4px' }} /> : <Box sx={{ height: '4px' }}></Box>}
                <Table sx={{ minWidth: 650, border: '1px solid #D7DBDD' }} aria-label="caption table">
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox">
                                <Checkbox
                                    color="primary"
                                    indeterminate={selected.length > 0 && selected.length < data.length}
                                    checked={data.length > 0 && selected.length === data.length}
                                    onChange={handleSelectAllClick}
                                    inputProps={{
                                        'aria-label': 'select all desserts',
                                    }}
                                />
                            </TableCell>
                            {headers.map((item, index) => (
                                <TableCell key={index} sx={{ fontWeight: 'bold', ...item.sx }} align={item.align}>
                                    {item.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row, index) => {
                            const isItemSelected = isSelected(row.id);
                            return (
                                <TableRow
                                    key={index}
                                    hover
                                    onClick={event => handleClick(event, row)}
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    selected={isItemSelected}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            color="primary"
                                            checked={isItemSelected}
                                            inputProps={{
                                                'aria-labelledby': 1,
                                            }}
                                        />
                                    </TableCell>
                                    {children(row).props?.children.map((item, cellIndex) => {
                                        if (item.props.children) {
                                            return (
                                                <TableCell key={cellIndex} align={item.align} sx={{ ...item.sx }}>
                                                    {item.props.children ? item.props.children : ''}
                                                </TableCell>
                                            );
                                        }
                                        return (
                                            <TableCell key={cellIndex} align={item.align}>
                                                {handleNestObject(item.props.property || item.props.prop, row)}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
                {loading ? <LinearProgress sx={{ height: '1px' }} /> : <Box sx={{ height: '1px' }}></Box>}
            </TableContainer>
            {showPaginate ? (
                <Box sx={{ mt: 2 }} className="d-flex justify-center">
                    <Pagination
                        color="primary"
                        count={countPage}
                        variant="outlined"
                        onChange={(e, page) => onChangePage(page)}
                    />
                </Box>
            ) : null}
        </div>
    );
}
export default memo(MTable);
