import axiosClient from '@/utils/axiosClient';

class OrderApi {
    onePayOrder = data => {
        const url = '/order-onepay';
        return axiosClient.post(url, data);
    };
    updateOrder = data => { 
        const url = '/updateOrder';
        return axiosClient.put(url, data);
    };
    onePayOrderDesign = data => {
        const url = '/order-onepay-design';
        return axiosClient.post(url, data);
    };
    createOrder = data => {
        const url = '/orders';
        return axiosClient.post(url, data);
    };
    getBuyOrder = params => {
        const url = '/orders';
        return axiosClient.get(url, { params });
    };
    deleteOrder = id => {
        const url = '/orders/' + id;
        return axiosClient.delete(url);
    };
    viewOrder = code => {
        const url = '/view-order/' + code;
        return axiosClient.get(url);
    };
    getDetailOrder = id => {
        const url = '/orders/' + id;
        return axiosClient.get(url);
    };

    cancelOrder = id => {
        const url = '/cancel-order/' + id;
        return axiosClient.put(url);
    };
    approveOrder = id => {
        const url = '/approve-order/' + id;
        return axiosClient.put(url);
    };
    shipOrder = id => {
        const url = '/ship-order/' + id;
        return axiosClient.put(url);
    };
    finishOrder = id => {
        const url = '/finish-order/' + id;
        return axiosClient.put(url);
    };
    failOrder = id => {
        const url = '/fail-order/' + id;
        return axiosClient.put(url);
    };
    applyVoucher = params => {
        const url = '/apply-voucher';
        return axiosClient.get(url, { params });
    };
    createOrderDesign = data => {
        const url = '/order-design';
        return axiosClient.post(url, data);
    };

    updateOrderMore = (id, data) => {
        const url = `/updateOrderMore/${id}`;
        return axiosClient.put(url, data);
    };
}
const orderApi = new OrderApi();
export default orderApi;
