import classNames from 'classnames/bind';
import styles from './MyDesign.scss';
import images from '@/assets/images';
import { Box, Button as MuiButton } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
const cx = classNames.bind(styles);

function MyDesign({ height }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();
    const goToLink = link => {
        setAnchorEl(null);
        navigate(link);
    };

    return (
        <Box>
            <Box className={cx('mydesign-card')}>
                <div className={cx('mydesign1')}>
                    <img src={images.iconMydesignG2} style={{ width: '100%', height: 'auto', boxShadow: '5px 10px 5px 5px #888888' }} alt="bannerPC" />
                </div>
            </Box>
            <Box >
                <Box className={cx('mobile-card-mydesign-new')}>
                    <div className={cx('mydesign1-mobile')}>
                        <img src={images.myDesignMobileG} style={{ width: '100%', height: 'auto' }} alt="bannerMobile" />
                    </div>
                </Box>
            </Box>
        </Box>

    );
}
export default MyDesign;
