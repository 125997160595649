import { Box } from '@mui/material';
import Radio from '@mui/material/Radio';
import styles from './OrderDialog.module.scss';
import classNames from 'classnames/bind';
import { useState } from 'react';
import { PAYMENTS } from '@/constants/order';
import LoadingButton from '@mui/lab/LoadingButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const cx = classNames.bind(styles);

function CartPayment({ onSubmit, loadingButton = false }) {
    const payments = PAYMENTS.filter(item => item.id !== 'cod');
    const [paymentMethod, setPaymentMethod] = useState('onepay');
    const handleSelectPayment = paymentID => {
        setPaymentMethod(paymentID);
    };
    const [checkAgree, setCheckAgree] = useState(false);
    const handleSubmit = () => onSubmit(paymentMethod);
    return (
        <div className="c-flex" style={{ height: '100%', paddingBottom: '35px' }}>
            <Box sx={{ flex: 1 }}>
                {payments.map((item, index) => (
                    <Box
                        className={cx('payment-method', 'd-flex align-center')}
                        key={index}
                        onClick={() => handleSelectPayment(item.id)}
                        sx={{
                            border: item.id === paymentMethod ? '2px solid #F37121' : '',
                            opacity: item.id === paymentMethod ? 1 : null,
                        }}
                    >
                        <Radio
                            checked={item.id === paymentMethod}
                            sx={{
                                '& .MuiSvgIcon-root': {
                                    fontSize: 28,
                                },
                                mr: 1,
                            }}
                        />
                        <Box sx={{ mr: 2 }}>
                            <img src={item.image} alt="cod" style={{ width: '30px' }} />
                        </Box>
                        <Box>
                            <Box>{item.title}</Box>
                            <Box>{item.body}</Box>
                        </Box>
                    </Box>
                ))}
                {paymentMethod === 'onepay' ? (
                    <Box className={cx('box-dieu-khoan')} sx={{ pl: 3, pt: 2, pb: 1 }}>
                        <Box sx={{ fontSize: '17px', fontWeight: 'bold' }}>
                            Vui lòng đọc và đồng ý với các điều khoản sau trước khi thanh toán
                        </Box>
                        <Box sx={{ mt: 2, fontSize: '16px' }}>
                            <Box className="d-flex align-center">
                                <FiberManualRecordIcon sx={{ fontSize: '14px', mr: 1 }} />
                                <a href="/chinh-sach-doi-tra-va-hoan-tien" target="_blank">
                                    Chính sách 30 ngày đổi trả
                                </a>
                            </Box>
                            <Box sx={{ mt: 1 }} className="d-flex align-center">
                                <FiberManualRecordIcon sx={{ fontSize: '14px', mr: 1 }} />
                                <a href="/chinh-sach-bao-mat" target="_blank">
                                    Chính sách bảo mật
                                </a>
                            </Box>
                        </Box>
                        <Box sx={{ textAlign: 'center' }}>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={checkAgree} onChange={e => setCheckAgree(e.target.checked)} />
                                }
                                label="Tôi đã đọc, đồng ý với điều kiện giao dịch chung và các chính sách"
                            />
                        </Box>
                    </Box>
                ) : null}
            </Box>
            <LoadingButton
                disabled={!checkAgree && paymentMethod === 'onepay'}
                loading={loadingButton}
                sx={{ width: '100%', mt: 4, height: '60px', borderRadius: '15px' }}
                size="large"
                variant="contained"
                onClick={() => handleSubmit()}
            >
                Wyróżniające się cechy
            </LoadingButton>
        </div>
    );
}
export default CartPayment;
