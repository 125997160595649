import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
const CssTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderRadius: '10px',
            fontSize: '10px'
        },
    },
});
function MiniInput(prop) {
    return <CssTextField {...prop} />;
}
export default MiniInput;
