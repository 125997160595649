import classNames from 'classnames/bind';
import Rating from '@mui/material/Rating';
import { Box } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import productApi from '@/api/productApi';
import CircularProgress from '@mui/material/CircularProgress';
import Cookies from 'js-cookie';
import { SP_DA_XEM } from '@/constants/product';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import styles from './Flash.module.scss';
import Countdown from 'react-countdown';
import Slider from 'react-slick';

const cx = classNames.bind(styles);

function FlashSale() {
    const goProductPage = (productId, code) => {
        const spDaXem = Cookies.get(SP_DA_XEM) ? JSON.parse(Cookies.get(SP_DA_XEM)) : [];
        if (spDaXem && spDaXem.length >= 5) {
            spDaXem.pop();
        }
        if (!spDaXem.includes(productId)) {
            spDaXem.unshift(productId);
            Cookies.set(SP_DA_XEM, JSON.stringify(spDaXem), { expires: 2 });
        }
        navigate(`/san-pham-flash/${code}`);
    };
    const navigate = useNavigate();
    const statusProduct = (price, flash_price, status) => {
        if (status === 'Nowy') {
            return <Button style={{ backgroundColor: "red", color: "white", fontWeight: "bold", alignContent: "center", width: "fit-content", height: "19px", minWidth: "40px", padding: "0px 0px 0px 0px", fontSize: "12px" }} variant="contained">-{parseInt((100 * (price - flash_price)) / price)}%</Button>
        }
        if (status === 'Warte kupna') {
            return <Button style={{ backgroundColor: "red", color: "white", fontWeight: "bold", alignContent: "center", width: "fit-content", height: "19px", minWidth: "40px", padding: "0px 0px 0px 0px", fontSize: "12px" }} variant="contained">-{parseInt((100 * (price - flash_price)) / price)}%</Button>
        }
        if (status === 'To prawie koniec') {
            return <Button style={{ backgroundColor: "red", color: "#F51B56", fontWeight: "bold", alignContent: "center", width: "fit-content", height: "19px", minWidth: "40px", padding: "0px 0px 0px 0px", fontSize: "12px" }} variant="contained">-{parseInt((100 * (price - flash_price)) / price)}%</Button>
        }
        if (status === 'Ratować') {
            return <Button style={{ backgroundColor: "red", color: "black", fontWeight: "bold", alignContent: "center", width: "fit-content", height: "19px", minWidth: "40px", padding: "0px 0px 0px 0px", fontSize: "12px" }} variant="contained">-{parseInt((100 * (price - flash_price)) / price)}%</Button>
        }
        if (status === 'Sale') {
            return <Button style={{ backgroundColor: "red", color: "white", fontWeight: "bold", alignContent: "center", width: "fit-content", height: "19px", minWidth: "40px", padding: "0px 0px 0px 0px", fontSize: "12px" }} variant="contained">-{parseInt((100 * (price - flash_price)) / price)}%</Button>
        }
        if (status === 'Limited') {
            return <Button style={{ backgroundColor: "red", color: "#f5ea87", fontWeight: "bold", alignContent: "center", width: "fit-content", height: "19px", minWidth: "40px", padding: "0px 0px 0px 0px", fontSize: "12px" }} variant="contained">-{parseInt((100 * (price - flash_price)) / price)}%</Button>
        }
        else {
            return <Button style={{ backgroundColor: "#2f5acf", color: "white", fontWeight: "bold", alignContent: "center", width: "fit-content", height: "19px", minWidth: "85px", padding: "0px 0px 0px 0px", fontSize: "10px" }} variant="contained">{status}</Button>
        }

    }
    const screenWidth = window.innerWidth;
    const [products, setProducts] = useState([]);
    const [downTime, setDownTime] = useState(5000);
    const [productLoading, setProductLoading] = useState(false);
    const [showResults, setShowResults] = useState(false);

    const Completionist = () => <span>Đã hết thời gian Flashsale!</span>;

    // Renderer callback with condition
    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a complete state
            return <Completionist />;
        } else {
            // Render a countdown
            return (
                <span className={cx('main-clock-card')}>
                    <input style={{ width: "50px", borderRadius: "8px", backgroundColor: "gold", textAlign: "center" }} className={cx('clock-card')} value={hours} /> <input style={{ width: "50px", borderRadius: "8px", backgroundColor: "gold", textAlign: "center" }} className={cx('clock-card')} value={minutes} /> <input style={{ width: "50px", borderRadius: "8px", backgroundColor: "gold", textAlign: "center" }} className={cx('clock-card')} value={seconds} />
                </span>
            );
        }
    };

    var countItemShow = 6;
    if (screenWidth < 500) {
        countItemShow = 1.5
    }
    else if (screenWidth < 600) {
        countItemShow = 3
    }
    else if (screenWidth < 850) {
        countItemShow = 4
    }
    else if (screenWidth < 1200) {
        countItemShow = 5
    }
    const getTopData = useCallback(async () => {
        setProductLoading(true);
        const response = await productApi.getProductFlash({
            perPage: 10,
        });
        if (response.data === undefined || response.data.length == 0) {
        } else {
            setShowResults(true);
            setDownTime(response.down_time);
        }
        setProducts(response.data);
        setProductLoading(false);
    }, []);

    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "0px",
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 4,

        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 8,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1.5,
                    slidesToScroll: 1
                }
            }
        ]
    };

    useEffect(() => {
        getTopData();
    }, [getTopData]);
    const RateItem = ({ id, code, url_image, name, flash_price, status, price }) => {
        return (
            <div className={cx('wrap-card')} onClick={() => goProductPage(id, code)}>
                <div className={cx('image-card-border')}>
                    <div className={cx('image-card')} style={{ backgroundImage: `url('${url_image}')` }}>
                        <div style={{ display: "flex", justifyContent: "center", paddingTop: "10px", paddingLeft: "10px" }}>
                            <div style={{ flexGrow: "1", textAlign: "left" }}>
                                <Rating size="small" value={Math.floor(Math.random() * 5) + 4} readOnly />
                                <b style={{ color: "#2f5acf", fontWeight: "bold" }} >({Math.floor(Math.random() * 45) + Math.floor(Math.random() * 5)})</b>
                            </div>
                            <div style={{ flexGrow: "1", textAlign: "right", paddingRight: "5px" }}>
                                {statusProduct(price, flash_price, status)}
                            </div>
                        </div>
                    </div>
                    <div className="c-flex align-center">
                        <div className={cx('card-title')}>{name}</div>
                        <Box className={cx('card-price')}>
                            <Box className={cx('text-price')}>{parseInt(flash_price).toLocaleString('de-DE')} zł</Box>
                            {flash_price !== price ? (
                                <Box sx={{ marginLeft: '3px' }} className='d-flex'>
                                    <Box className={cx('text-price')} style={{ textDecoration: 'line-through', color: '#A6ACAF' }}>
                                        {parseInt(price).toLocaleString('de-DE')} zł
                                    </Box>
                                    <Box className={cx('text-price')} style={{ color: '#CB4335', paddingLeft: '3px' }}>
                                        -{parseInt((100 * (price - flash_price)) / price)}%
                                    </Box>
                                </Box>
                            ) : null}
                        </Box>
                    </div>
                </div>
            </div>
        );
    };


    return (
        <div className={cx('main-wrap-card')}>
            {showResults ? (
                <div className={cx('title-home')}>OnFLASH Sale  <Countdown date={downTime} renderer={renderer} /></div>
            )
                : null}
            {showResults ? (
                <Box className={cx('list-slide')}>
                    {productLoading ? (
                        <Box className="d-flex justify-center" cx={{ width: '100%', mt: 4 }}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Slider className={cx('main-slide')} {...settings}>
                            {products.map(item => <RateItem id={item.id} code={item.code} url_image={item.url_image} name={item.name} flash_price={item.flash_price} status={item.status} price={item.price} />)}
                        </Slider>
                    )}
                </Box>
            )
                : null}
        </div>
    );
}
export default FlashSale;
