import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import GlobalStyle from '@/components/Global';
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux'
import store from './store'
import { ThemeProvider, createTheme } from "@mui/material/styles";
import './index.css';

const theme = createTheme({
  palette: {
    primary: {
      main: '#e08282'
    },
    secondary: {
      main: '#fff'
    }
  }
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <GlobalStyle>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
        <ToastContainer hideProgressBar />
      </GlobalStyle>
    </Provider>
  </React.StrictMode>
);
reportWebVitals();
