import classNames from 'classnames/bind';
import styles from './Card.module.scss';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { SP_DA_XEM } from '@/constants/product';
import images from '@/assets/images';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';

const cx = classNames.bind(styles);
function ProductCardUI({ data }) {
    const navigate = useNavigate();

    const statusProduct = (status) => {
        if (status === 'Nowy') {
            return <Button style={{ backgroundColor: "#040fda", color: "white", fontWeight: "bold", alignContent: "center", width: "fit-content", height: "19px", minWidth: "40px", padding: "0px 0px 0px 0px", fontSize: "10px" }} variant="contained">{data.status}</Button>
        }
        if (status === 'Warte kupna') {
            return <Button style={{ backgroundColor: "rgb(103 21 177)", color: "white", fontWeight: "bold", alignContent: "center", width: "fit-content", height: "19px", minWidth: "65px", padding: "0px 0px 0px 0px", fontSize: "10px" }} variant="contained">{data.status}</Button>
        }
        if (status === 'To prawie koniec') {
            return <Button style={{ backgroundColor: "#f37121", color: "black", fontWeight: "bold", alignContent: "center", width: "fit-content", height: "19px", minWidth: "65px", padding: "0px 0px 0px 0px", fontSize: "10px" }} variant="contained">{data.status}</Button>
        }
        if (status === 'Ratować') {
            return <Button style={{ backgroundColor: "#e08282", color: "black", fontWeight: "bold", alignContent: "center", width: "fit-content", height: "19px", minWidth: "65px", padding: "0px 0px 0px 0px", fontSize: "10px" }} variant="contained">{data.status}</Button>
        }
        if (status === 'Sale') {
            return <Button style={{ backgroundColor: "#ff3102", color: "white", fontWeight: "bold", alignContent: "center", width: "fit-content", height: "19px", minWidth: "30px", padding: "0px 0px 0px 0px", fontSize: "10px" }} variant="contained">{data.status}</Button>
        }
        if (status === 'Limited') {
            return <Button style={{ backgroundColor: "#000000", color: "#f5ea87", fontWeight: "bold", alignContent: "center", width: "fit-content", height: "19px", minWidth: "65px", padding: "0px 0px 0px 0px", fontSize: "10px" }} variant="contained">{data.status}</Button>
        }
        else {
            return <Button style={{ backgroundColor: "#2f5acf", color: "white", fontWeight: "bold", alignContent: "center", width: "fit-content", height: "19px", minWidth: "85px", padding: "0px 0px 0px 0px", fontSize: "10px" }} variant="contained">{data.status}</Button>
        }

    }
    const goProductPage = (productId, code) => {
        const spDaXem = Cookies.get(SP_DA_XEM) ? JSON.parse(Cookies.get(SP_DA_XEM)) : [];
        if (spDaXem && spDaXem.length >= 5) {
            spDaXem.pop();
        }
        if (!spDaXem.includes(productId)) {
            spDaXem.unshift(productId);
            Cookies.set(SP_DA_XEM, JSON.stringify(spDaXem), { expires: 2 });
        }
        navigate(`/san-pham/${code}`);
    };
    const link_product = 'http://dl-zabawek.pl/san-pham/' + data.code;

    return (
        <div className={cx('wrap-card-ui')} onClick={() => goProductPage(data.id, data.code)}>
            <a style={{ textDecoration: 'none' }}>
                <div className={cx('image-card-border-ui')}>
                    <div className={cx('image-card-ui-productmini')}>
                        <img className={cx('image-card-ui-product-img')} src={data.url_image} alt='product' />
                        <div style={{ display: 'flex', position: 'absolute', top: '3%', justifyContent: 'space-between', width: '100%', paddingLeft: '1em', paddingRight: '1em' }}>
                            <div className={cx('card-title-ui-status')} style={{ flexGrow: "1", textAlign: "right" }}>
                                {statusProduct(data.status)}
                            </div>
                        </div>
                    </div>
                    <div className="c-flex">
                        <div className={cx('card-title-ui')}>{data.name}</div>
                        <Box className={cx('card-price-ui')}>
                            <Box className={cx('text-price')} style={{ color: '#5a84c1', fontWeight: '500', fontSize: '15px' }} >{parseFloat(data.sale_price).toLocaleString('de-DE')} zł</Box>
                        </Box>
                    </div>
                </div>
            </a>

        </div >
    );
}
export default ProductCardUI;
