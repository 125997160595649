import { Box, Tooltip } from '@mui/material';
import productApi from '@/api/productApi';
import { useEffect, useRef, useState } from 'react';
import images from '@/assets/images';
import styles from './upload.module.scss';
import classNames from 'classnames/bind';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

const cx = classNames.bind(styles);

function UploadMultiple({ width = '100px', height = '100px', onDone, list = [] }) {
    const refInputUplaod = useRef();
    const [imageList, setImageList] = useState([]);

    const handleUploadFile = async e => {
        let files = e.target.files;
        let data = new FormData();
        if (!files || files.length === 0) {
            return;
        }
        for (let el of files) {
            const isLt2M = el.size / 1024 / 1024 < 20;
            if (isLt2M) {
                data.append('file[]', el);
            }
        }

        try {
            const dataImage = await productApi.uploadMultiple(data);
            setImageList([...dataImage, ...imageList]);
            onDone([...dataImage, ...imageList]);
        } catch (error) {

        }
        refInputUplaod.current.value = null;
    };
    useEffect(() => {
        setImageList(list)
    }, [list])
    const handleRemoveImage = async image => {
        await productApi.deleteFile(image.id);
        const newImages = imageList.filter(item => item.id !== image.id);
        setImageList(newImages);
        onDone(newImages);
    };

    return (
        <Box>
            <input
                type="file"
                multiple
                onChange={e => handleUploadFile(e)}
                ref={refInputUplaod}
                style={{ display: 'none' }}
            />
            <Box className="d-flex flex-wrap">
                <Tooltip title="Nhấn để upload hình ảnh">
                    <Box
                        sx={{ width, height }}
                        className={cx('box-upload-single', 'c-flex align-center justify-center')}
                        onClick={() => refInputUplaod.current.click()}
                    >
                        <img src={images.uploadImageIcon} alt="upload" style={{ width: '70%', maxHeight: '70%' }} />
                    </Box>
                </Tooltip>
                {imageList.map(image => (
                    <Box
                        key={image.path}
                        sx={{ backgroundImage: `url('${image.path}')`, width, height, ml: 2 }}
                        className={cx('box-image-single')}
                    >
                        <Tooltip title="Xoá hình ảnh này">
                            <IconButton
                                size="small"
                                sx={{ bgcolor: 'white', mt: 1, ml: 1 }}
                                onClick={() => handleRemoveImage(image)}
                            >
                                <DeleteIcon fontSize="inherit" sx={{ color: '#B03A2E' }} />
                            </IconButton>
                        </Tooltip>
                    </Box>
                ))}
            </Box>
        </Box>
    );
}
export default UploadMultiple;
