import axiosClient from '@/utils/axiosClient';
class UserApi {
    getUsers = params => {
        const url = '/users';
        return axiosClient.get(url, { params });
    }; 
    activeDeactiveUser = id => {
        const url = `/active-deactive-user/${id}`;
        return axiosClient.put(url);
    }; 

    listCus = params => {
        const url = '/listCus';
        return axiosClient.get(url, { params });
    }; 
    createCus = data => {
        const url = '/createCus';
        return axiosClient.post(url, data);
    };

    updateCus = (id, data) => {
        const url = `/updateCus/${id}`;
        return axiosClient.put(url, data);
    };
    
}
const userApi = new UserApi();
export default userApi;
