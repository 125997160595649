import Moveable from 'react-moveable';
import { useSelector, useDispatch } from 'react-redux';
import { setElementTarget, setStyle } from '@/store/design/targetDesignSlice';
import { TYPE_IMAGE, TYPE_TEXT } from '@/store/design/constant';
import styles from './Card.module.scss';
import classNames from 'classnames/bind';


const cx = classNames.bind(styles);

function DesignView({dataDesign}) {
    const elementTargetID = useSelector(state => state.targetDesign.elemetTarget);
    const iconSelected = useSelector(state => state.targetDesign.iconSelected);
    const background = useSelector(state => state.targetDesign.background);
    const productSelected = useSelector(state => state.targetDesign.productSelected);
    var dataIcons = useSelector(state => state.targetDesign.iconArray);
    var viewOnly = false;
    if(dataDesign && dataDesign.length > 0){
        dataIcons = dataDesign;
        viewOnly = true
    }
    const iconPosition = dataIcons.find(item => item.imageId === productSelected.imageId);
    const iconListDesign = iconPosition.icons;
    const dispatch = useDispatch();
    const handleClickElement = (event, item) => {
        event.stopPropagation();
        dispatch(setElementTarget({ target: event.currentTarget.id, iconSelected: item }));
    };
    var elementTarget = document.getElementById(elementTargetID);
    const { width, height } = background.cssKhung;
    const boundsRight = Number(width.substring(0, width.length - 2));
    const boundHeight = Number(height.substring(0, height.length - 2));
    const resizable = iconSelected?.data?.resize_able;
    const rotatable = iconSelected?.data?.rotate_able;


    const isShowBorder = useSelector(state => state.targetDesign.showBorder);
    
    const styleWithBorder = {
        ...background.cssKhung,
        border: '1px solid blue',
    };
    const styleWithNoBorder = {
        ...background.cssKhung,
        border: 'none',
    };
    return (
        <div
            id='designview'
            style={{
                width: '700px',
                height: '850px',
                backgroundImage: `url('${background.link}')`,
                ...background.css,
            }}
        >
            <div className={cx('pc-card')} style={isShowBorder ? styleWithBorder : styleWithNoBorder}>
                <div style={{ width: '100%', height: '100%', position: 'relative', zIndex: '1000' }}>
                    {iconListDesign.map((item, index) => {
                        if (item.image_url) {
                            return (
                                <img
                                    key={index}
                                    src={item.image_url}
                                    onClick={e => handleClickElement(e, { type: TYPE_IMAGE, data: item })}
                                    style={item.style}
                                    alt="icon"
                                    tabIndex={index}
                                    id={item.id}
                                />
                            );
                        }
                        if (item.text) {
                            return (
                                <div
                                    key={index}
                                    id={item.id}
                                    style={{ ...item.style, width: 'auto', maxWidth: width, maxHeight: height }}
                                    onClick={e => handleClickElement(e, { type: TYPE_TEXT, data: item })}
                                >
                                    {item.text}
                                </div>
                            );
                        }
                        return null;
                    })}
                    <Moveable
                        target={elementTarget}
                        origin={true}
                        edge={false}
                        draggable={!viewOnly}
                        position="client"
                        snappable={true}
                        bounds={{ left: 0, top: 0, right: boundsRight, bottom: boundHeight }}
                        padding={{ left: 0, top: 0, right: 0, bottom: 0 }}
                        throttleDrag={0}
                        onDrag={({
                            target,
                            beforeDelta,
                            beforeDist,
                            left,
                            top,
                            right,
                            bottom,
                            delta,
                            dist,
                            transform,
                            clientX,
                            clientY,
                        }) => {
                            target.style.left = `${left}px`;
                            target.style.top = `${top}px`;
                        }}
                        onDragEnd={({ target, isDrag, clientX, clientY }) => {
                            dispatch(setStyle({ left: target.style.left, top: target.style.top }));
                        }}
                        keepRatio={false}
                        resizable={resizable && !viewOnly}
                        rotatable={rotatable && !viewOnly}
                        throttleResize={0}
                        onResize={({ target, width, height, dist, delta, direction, clientX, clientY }) => {
                            target.style.width = `${width}px`;
                            target.style.height = `${height}px`;
                        }}
                        onResizeEnd={({ target, isDrag, clientX, clientY }) => {
                            dispatch(setStyle({ width: target.style.width, height: target.style.height }));
                        }}
                        throttleScale={0}
                        scalable={false}
                        onScale={({ target, scale, dist, delta, transform, clientX, clientY }) => {
                            target.style.transform = transform;
                            dispatch(setStyle({ transform }));
                        }}
                        throttleRotate={0}
                        onRotate={({ target, delta, dist, transform, clientX, clientY }) => {
                            target.style.transform = transform;
                            dispatch(setStyle({ transform }));
                        }}
                    />
                </div>
            </div>
        </div>
    );
}
export default DesignView;
