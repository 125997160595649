import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import AddIcon from '@mui/icons-material/Add';
import BorderColorIcon from '@mui/icons-material/BorderColor';
function MDialog({ onClose, open, title, children, onSubmit, type }) {
    return (
        <Dialog open={open} maxWidth="1000px">
            <DialogTitle>
                <Box sx={{ fontSize: '18px', fontWeight: 'bold' }}>{title}</Box>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>{children}</DialogContent>
            <DialogActions sx={{ padding: '15px' }}>
                <Button sx={{ color: 'black' }} onClick={onClose} variant="outlined">
                    Zamknąć
                </Button>
                {onSubmit ? (
                    type === 'ADD' ? (
                        <Button onClick={onSubmit} variant="contained" startIcon={<AddIcon />}>
                            Thêm mới
                        </Button>
                    ) : (
                        <Button onClick={onSubmit} variant="contained" startIcon={<BorderColorIcon />}>
                            Cập nhật
                        </Button>
                    )
                ) : null}
            </DialogActions>
        </Dialog>
    );
}

export default MDialog;
