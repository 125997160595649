import MTable from '@/components/Table';
import { Box, InputAdornment, TextField } from '@mui/material';
import { Fragment, useCallback, useEffect, useState } from 'react';
import ratingApi from '@/api/ratingApi';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CreateEdit from './CreateEdit';
import { ConfirmDialog } from '@/components/ConfirmDialog';
import { toast } from 'react-toastify';
import SearchIcon from '@mui/icons-material/Search';
import { debounce } from 'lodash';
import images from '@/assets/images';

function Categories() {
    const onClose = () => setDialogData({ ...dialogData, open: false });
    const onOpenAdd = () => setDialogData({ ...dialogData, open: true, data: {}, type: 'ADD' });
    const onOpenEdit = data => setDialogData({ ...dialogData, open: true, type: 'EDIT', data, title: 'Cập nhật rating' });
    const [param, setParam] = useState({});
    const [tabeleData, setTableData] = useState([]);
    const [totalPage, setTotalPage] = useState(0);
    const [loading, setLoading] = useState(false);

    const getData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await ratingApi.getRating(param);
            setLoading(false);
            setTableData(response.data);
            setTotalPage(response.last_page || response?.meta?.last_page);
        } catch (error) {
            setLoading(false);
        }
    }, [param]) ;
    const [dialogData, setDialogData] = useState({
        open: false,
        title: 'Thêm mới rating',
        onClose: onClose,
        onDone: getData,
    });

    const onChangePage = page => {
        setParam({ ...param, page });
    };
    const onDelete = item => {
        ConfirmDialog({
            title: 'Xoá rating',
            html: true,
            body: `Bạn có chắc chắn muốn xóa rating của <b>${item.username}</b> không ?`,
            action: async onClose => {
                await ratingApi.deleteRating(item.id);
                onClose();
                toast.success('Đã xóa thành công!');
                getData()
            },
        });
    };
    const handleSearch = (value) => debounce(() => {setParam({...param, search: value})}, 600)()

    useEffect(() => {
        getData();
    }, [getData]);
    return (
        <Box sx={{ padding: '15px' }}>
            <CreateEdit {...dialogData} />
            <Box sx={{ mb: 2 }} className="d-flex justify-space-between align-center">
                <Box sx={{ fontSize: '24px' }}>Danh sách rating</Box>
            </Box>
            <Box sx={{ mb: 1 }} className="d-flex justify-space-between">
                <TextField
                    size="small"
                    placeholder="Tìm kiếm rating"
                    variant="outlined"
                    style={{ width: '500px' }}
                    onChange={(e) => handleSearch(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                <Box>
                    <Button onClick={onOpenAdd} variant="contained" disableElevation startIcon={<AddIcon />}>
                        Thêm mới
                    </Button>
                </Box>
            </Box>
            <MTable data={tabeleData} loading={loading} countPage={totalPage} onChangePage={onChangePage}>
                {item => (
                    <Fragment>
                        <div property="name" label="Người rating">
                        <Box className="d-flex align-cener">
                                <Box sx={{ ml: 1, pt: 2, fontWeight: 'bold' }}>{item.username}</Box>
                            </Box>
                        </div>
                        <div label="Rating star" prop="rate">{item.rate}</div>
                        <div label="Nội dung" prop="content">{item.content}</div>
                        <div label="Sản phẩm" prop="product_id.name"></div>
                        <div label="Thời gian tạo" prop="created_at"></div>
                        <div label="Người tạo" prop="user_created_id.name"></div>
                        <div label="Hành động" sx={{ width: '150px' }}>
                            <IconButton aria-label="delete" color="secondary" onClick={() => onDelete(item)}>
                                <DeleteIcon />
                            </IconButton>
                            <IconButton aria-label="edit" color="primary" onClick={() => onOpenEdit(item)}>
                                <EditIcon />
                            </IconButton>
                        </div>
                    </Fragment>
                )}
            </MTable>
        </Box>
    );
}

export default Categories;
