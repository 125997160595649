import { Autocomplete, Box, Divider, InputAdornment, TextField } from '@mui/material';
import styles from './cart.scss';
import classNames from 'classnames/bind';
import Grid from '@mui/material/Grid';
import ProductItem from './ProductItem';
import Button from '@mui/material/Button';
import { useCallback, useEffect, useState } from 'react';
import productApi from '@/api/productApi';
import { useSelector } from 'react-redux';
import CartPayment from './Payment';
import userApi from '@/api/userApi';
import orderApi from '@/api/orderApi';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { removeAllItem } from '@/store/cart/cartSlice';
import { CART_PRODUCT, INFOR_CART } from '@/constants/product';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import MInput from '@/components/Input/MInput';
import LoadingOverlay from 'react-loading-overlay';
import { debounce } from 'lodash';

const cx = classNames.bind(styles);

function Cart() {
    const SHIP_COST = 1500;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const me = useSelector(state => state.auth.me);
    const [param, setParam] = useState({});
    const [products, setProducts] = useState([]);
    const [users, setUsers] = useState([]);
    const cacheInfo = Cookies.get(INFOR_CART) ? JSON.parse(Cookies.get(INFOR_CART)) : {};
    const voucherCopy = useSelector(state => state.voucher);

    const [form, setForm] = useState(
        me
            ? {
                name: me.name,
                email: me.email,
                phone: me.phone,
            }
            : cacheInfo
    );
    const [totalMoney, setTotalMoney] = useState(0);
    const cartData = useSelector(state => state.cart.cartData);
    const [provisionalMoney, setProvisionalMoney] = useState(0);
    const [costMoney, setCostMoney] = useState(40);
    const [voucher, setVoucher] = useState('');
    const [userSelected, setUserSelected] = useState();
    const [discount, setDiscount] = useState(0);
    const [errorSubmit, setErrorSubmit] = useState({});
    const [loadingSubmit, setLoadingSubmit] = useState(false);

    const onClickCopy = event => {
        navigator.clipboard.writeText(voucherCopy.voucher.voucher);
        setVoucher(voucherCopy.voucher.voucher);
        toast.success('Đã copy mã VOUCHER');
    };
    const timeout = delay => {
        return new Promise(res => setTimeout(res, delay));
    };
    const getProductCart = useCallback(async () => {
        try {
            setLoadingSubmit(true);
            let response = await productApi.getProductCart({ cart: cartData });
            const total = response.reduce((acc, curr) => {
                return Number(acc) + Number(curr.sale_price * curr.quantity);
            }, 0);

            setProvisionalMoney(total);
            if (total > 1500) {
                setTotalMoney(Number(total));
                setCostMoney(Number(0));
            } else {
                setCostMoney(Number(40));
                setTotalMoney(Number(total) + 40);
            }

            setProducts(response);

            response.forEach(item => {
                if (item.note_free_ship) {
                    setTotalMoney(Number(total));
                    setCostMoney(Number(0));
                }
            });
            setLoadingSubmit(false);
        } catch (error) {
            Cookies.remove(CART_PRODUCT);
        }
    });

    useEffect(() => {
        getProductCart();
    }, [cartData]);

    const handleChangForm = (key, value) => {
        setForm({ ...form, [key]: value });
    };

    const handleSelectUser = newUser => {
        setUserSelected(newUser);
        setForm({ ...form, name: newUser.name, phone: newUser.phone, address: newUser.address, email: newUser.email, nip_ue: newUser.nip_ue, firma: newUser.firma });
    };

    const getData = useCallback(async () => {
        try {
            const response = await userApi.listCus(param);
            setUsers(response.data);
        } catch (error) {

        }
    }, [param]);

    const handleSearch = value =>
        debounce(() => {
            setParam({ ...param, search: value });
        }, 600)();

    const date_time = new Date();
    let hour_count = date_time.getHours();

    const handleSubmit = async payment => {
        try {
            setLoadingSubmit(true);
            const formSubmit = {
                ...form,
                payment: payment,
                voucher: voucher,
                UID: '',

                products: products.map(item => ({
                    product_id: item.id,
                    color: item.color,
                    size: item.size,
                    flash_price: item.flash_price,
                    quantity: Number(item.quantity),
                })),
            };
            if (payment === 'onepay') {
                const url = await orderApi.onePayOrder(formSubmit);
                window.location.assign(url);
            } else {
                const respon = await orderApi.createOrder(formSubmit);
                toast.success('Powodzenie!');
                dispatch(removeAllItem());
                Cookies.set(INFOR_CART, JSON.stringify(form), { expires: 365 });
                setForm({});
                navigate(`/tra-cuu-don/${respon.order_code}`);
            }
            setLoadingSubmit(false);
        } catch (error) {
            const errors = error.response.data.errors;
            for (const key in errors) {
                errors[key] = errors[key].join('. ');
            }
            setErrorSubmit(errors);
            setLoadingSubmit(false);
        }
    };
    useEffect(() => {
        getData();
    }, [getData]);
    return (
        <Box sx={{ backgroundColor: 'white' }} className={cx('product-card-img')}>
            <LoadingOverlay active={loadingSubmit} spinner text="Twoje zamówienie jest potwierdzane...">
                <Box className={cx('wrapper-cart', 'd-flex')} sx={{ pb: 4, mt: 2 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={6} lg={5} xl={5} className={cx('product-cart-mobile')}>
                            <Box sx={{ width: '100%' }}>
                                {products
                                    .filter(it => !it.promotion)
                                    .map(item => (
                                        <ProductItem
                                            key={`${item.id}`}
                                            product={item}
                                        />
                                    ))}
                                {products
                                    .filter(it => it.promotion)
                                    .map(item => (
                                        <ProductItem
                                            key={`${item.id}`}
                                            product={item}
                                        />
                                    ))}
                                <br></br>
                                <Box className="d-flex justify-space-between">
                                    <Box
                                        sx={{ color: 'black', fontSize: '18px', fontWeight: '100' }}
                                        className={cx('title-price')}
                                    >
                                        Tymczasowy
                                    </Box>
                                    <Box
                                        sx={{ color: 'black', fontSize: '18px', fontWeight: '100' }}
                                        className={cx('title-price')}
                                    >
                                        {Number(provisionalMoney).toLocaleString('de-DE')} zł
                                    </Box>
                                </Box>
                                <Box sx={{ mt: 1 }} className="d-flex justify-space-between">
                                    <Box
                                        sx={{ color: 'black', fontSize: '18px', fontWeight: '100' }}
                                        className={cx('title-price')}
                                    >
                                        Rabat
                                    </Box>
                                    <Box
                                        sx={{ color: 'black', fontSize: '18px', fontWeight: '100' }}
                                        className={cx('title-price')}
                                    >
                                        {Number(discount).toLocaleString('de-DE')} zł
                                    </Box>
                                </Box>
                                <Box sx={{ mt: 1 }} className="d-flex justify-space-between">
                                    <Box
                                        sx={{ color: 'black', fontSize: '18px', fontWeight: '100' }}
                                        className={cx('title-price')}
                                    >
                                        Opłata transportowa
                                    </Box>
                                    <Box
                                        sx={{ color: 'black', fontSize: '18px', fontWeight: '100' }}
                                        className={cx('title-price')}
                                    >
                                        {Number(costMoney).toLocaleString('de-DE')} zł
                                    </Box>
                                </Box>
                                <Divider sx={{ mt: 2, bgcolor: 'black' }} />
                                <Box className="d-flex justify-space-between" sx={{ mt: 2 }}>
                                    <Box
                                        sx={{ color: 'black', fontSize: '18px', fontWeight: '100' }}
                                        className={cx('title-price')}
                                    >
                                        Całkowita kwota (netto)
                                    </Box>
                                    <Box sx={{ fontSize: '20px', fontWeight: 'bold', color: 'black' }}>
                                        {Number(totalMoney - discount).toLocaleString('de-DE')} zł
                                    </Box>
                                </Box>
                                <Box className="d-flex justify-space-between" sx={{ mt: 2 }}>
                                    <Box
                                        sx={{ color: 'black', fontSize: '18px', fontWeight: '100' }}
                                        className={cx('title-price')}
                                    >
                                        Całkowita kwota (brutto)
                                    </Box>
                                    <Box sx={{ fontSize: '20px', fontWeight: 'bold', color: 'black' }}>
                                        {(Number(totalMoney - discount) * 1.23).toLocaleString('de-DE')} zł
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={7} xl={7} className={cx('customer-cart')} sx={{ pb: 4 }}>
                            <Box className={cx('box-title-cart')} sx={{ mb: 3, marginBottom: '8px' }}>
                                <Box sx={{ paddingTop: '60px', color: 'black' }} className={cx('cart-title')}>
                                    Informacje wysyłkowe
                                </Box>
                                <Box className={cx('cart-title-acc')} sx={{ pt: 1, paddingLeft: '5px' }}>
                                    <b style={{ color: 'black', fontWeight: '200' }}>Czy masz już konto?</b>{' '}
                                    <b style={{ color: '#e08282' }}>Zaloguj się teraz</b>
                                </Box>
                            </Box>
                            <Box className="c-flex">
                                <Grid container spacing={4}>
                                    <Grid item xs={12}>
                                        {
                                            me !== null ?
                                                (
                                                    <Autocomplete
                                                        disablePortal
                                                        sx={{ width: '100%', mr: 1 }}
                                                        variant="outlined"
                                                        size="meidum"
                                                        getOptionLabel={option => option.product_by ? option.product_by.name : option.name}
                                                        renderOption={(props, option) => (
                                                            <Box className="d-flex align-cener" {...props}>
                                                                <Box sx={{ ml: 1 }}>{option.product_by ? option.product_by.name : option.name}</Box>
                                                            </Box>
                                                        )}
                                                        options={users}
                                                        value={userSelected}
                                                        onChange={(e, newValue) => handleSelectUser(newValue)}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Tìm kiếm khách hàng"
                                                                onChange={e => handleSearch(e.target.value)}
                                                            />
                                                        )}
                                                    />
                                                ) : (null)
                                        }
                                    </Grid>
                                </Grid>
                                <Grid container spacing={4}>
                                    <Grid item xs={6}>
                                        <MInput
                                            placeholder="Pełne imię i nazwisko"
                                            onChange={e => handleChangForm('name', e.target.value)}
                                            value={form.name}
                                            variant="outlined"
                                            className={cx('cart-input')}
                                            error={errorSubmit.name ? true : false}
                                            helperText={errorSubmit.name}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <MInput
                                            placeholder="Numer telefonu"
                                            onChange={e => handleChangForm('phone', e.target.value)}
                                            variant="outlined"
                                            className={cx('cart-input')}
                                            error={errorSubmit.phone ? true : false}
                                            value={form.phone}
                                            helperText={errorSubmit.phone}
                                        />
                                    </Grid>
                                </Grid>
                                <MInput
                                    placeholder="Email"
                                    onChange={e => handleChangForm('email', e.target.value)}
                                    variant="outlined"
                                    className={cx('cart-input')}
                                    error={errorSubmit.email ? true : false}
                                    helperText={errorSubmit.email}
                                    value={form.email}
                                    sx={{ mt: 3 }}
                                />
                                <MInput
                                    placeholder="Adres"
                                    onChange={e => handleChangForm('address', e.target.value)}
                                    variant="outlined"
                                    className={cx('cart-input')}
                                    error={errorSubmit.address ? true : false}
                                    helperText={errorSubmit.address}
                                    value={form.address}
                                    sx={{ mt: 3 }}
                                />
                                <MInput
                                    placeholder="Firma (Opcjonalne)"
                                    onChange={e => handleChangForm('firma', e.target.value)}
                                    variant="outlined"
                                    className={cx('cart-input')}
                                    error={errorSubmit.firma ? true : false}
                                    helperText={errorSubmit.firma}
                                    value={form.firma}
                                    sx={{ mt: 3 }}
                                />
                                <MInput
                                    placeholder="NIP UE (Opcjonalne)"
                                    onChange={e => handleChangForm('nip_ue', e.target.value)}
                                    variant="outlined"
                                    className={cx('cart-input')}
                                    error={errorSubmit.nip_ue ? true : false}
                                    helperText={errorSubmit.nip_ue}
                                    value={form.nip_ue}
                                    sx={{ mt: 3 }}
                                />
                                <MInput
                                    placeholder="Notatka"
                                    onChange={e => handleChangForm('note', e.target.value)}
                                    variant="outlined"
                                    className={cx('cart-input')}
                                    sx={{ mt: 3, mb: 2 }}
                                    value={form.note}
                                />
                                {Number(totalMoney - discount) > 800 ? (
                                    <Box>
                                        <Box
                                            className={cx('cart-title')}
                                            sx={{ mt: 4, paddingTop: '0px', paddingBottom: '5px', color: 'black' }}
                                        >
                                            Płatności
                                        </Box>
                                        <Box>
                                            <CartPayment onSubmit={handleSubmit} loadingButton={loadingSubmit} />
                                        </Box>
                                    </Box>
                                ) : (
                                    <Box sx={{ fontSize: '20px', color: 'black', backgroundColor: '#ffe1cb', padding: '0.75rem 1.25rem', borderRadius: '10px' }}>
                                        Minimalny zakup na kwotę 800,00 zł (netto) jest wymagany aby zatwierdzić Twoje zamówienie.
                                    </Box>
                                )}



                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={5} xl={5} className={cx('product-cart')}>
                            <Box sx={{ paddingTop: '60px', color: 'black' }} className={cx('cart-title')}>
                                Wózek
                            </Box>
                            <Box sx={{ mt: 3 }}>
                                {products
                                    .filter(it => !it.promotion)
                                    .map(item => (
                                        <ProductItem
                                            key={`${item.id}`}
                                            product={item}
                                            sx={{ mt: 2 }}
                                        />
                                    ))}
                                {products
                                    .filter(it => it.promotion)
                                    .map(item => (
                                        <ProductItem
                                            key={`${item.id}`}
                                            product={item}
                                            sx={{ mt: 2 }}
                                        />
                                    ))}
                            </Box>
                            {Number(provisionalMoney) > 0 ? (
                                <Box sx={{ color: 'black' }} className={cx('cart-number')}>
                                    <b>{hour_count + 5}</b> osób dodaje ten sam produkt do Twojego koszyka.
                                </Box>
                            ) : (
                                <Box sx={{ color: 'black' }} className={cx('cart-number2')}>
                                    Kontynuuj zakupy na dl-zabawek.pl
                                </Box>
                            )}

                            <Divider sx={{ mt: 2, bgcolor: 'black' }} />
                            {voucherCopy.voucher !== null ? (
                                <Box className={cx('cart-coupon-wishlist')}>
                                    <Box>
                                        Twój kod rabatowy został zapisany w tym obszarze. Kliknij kod, aby natychmiast go wykorzystać.
                                        <br></br>
                                    </Box>
                                    <Box>
                                        Ograniczona ilość zastosowań, dotyczy najszybszych użytkowników!<br></br>
                                    </Box>

                                    <Button
                                        variant="contained"
                                        onClick={onClickCopy}
                                        sx={{
                                            ml: 1,
                                            borderRadius: '20px',
                                            backgroundColor: 'white',
                                            border: '#F37121',
                                            fontWeight: '400',
                                            marginTop: '10px',
                                            width: '150px',
                                            height: '50px',
                                        }}
                                    >
                                        <a>{voucherCopy.voucher.voucher}</a>
                                    </Button>
                                </Box>
                            ) : null}

                            <Box sx={{ mt: 2 }}>
                                <Box className="d-flex justify-space-between">
                                    <Box
                                        sx={{ color: 'black', fontSize: '18px', fontWeight: '100' }}
                                        className={cx('title-price')}
                                    >
                                        Tymczasowy
                                    </Box>
                                    <Box
                                        sx={{ color: 'black', fontSize: '18px', fontWeight: '100' }}
                                        className={cx('title-price')}
                                    >
                                        {Number(provisionalMoney).toLocaleString('de-DE')} zł
                                    </Box>
                                </Box>
                                <Box sx={{ mt: 1 }} className="d-flex justify-space-between">
                                    <Box
                                        sx={{ color: 'black', fontSize: '18px', fontWeight: '100' }}
                                        className={cx('title-price')}
                                    >
                                        Rabat
                                    </Box>
                                    <Box
                                        sx={{ color: 'black', fontSize: '18px', fontWeight: '100' }}
                                        className={cx('title-price')}
                                    >
                                        {Number(discount).toLocaleString('de-DE')} zł
                                    </Box>
                                </Box>
                                <Box sx={{ mt: 1 }} className="d-flex justify-space-between">
                                    <Box
                                        sx={{ color: 'black', fontSize: '18px', fontWeight: '100' }}
                                        className={cx('title-price')}
                                    >
                                        Opłata transportowa
                                    </Box>
                                    <Box
                                        sx={{ color: 'black', fontSize: '18px', fontWeight: '100' }}
                                        className={cx('title-price')}
                                    >
                                        {Number(costMoney).toLocaleString('de-DE')} zł
                                    </Box>
                                </Box>
                                <Divider sx={{ mt: 2, color: 'black', bgcolor: 'black' }} />
                                <Box className="d-flex justify-space-between" sx={{ mt: 2 }}>
                                    <Box
                                        sx={{ color: 'black', fontSize: '18px', fontWeight: '100' }}
                                        className={cx('title-price')}
                                    >
                                        Całkowita kwota (netto)
                                    </Box>
                                    <Box sx={{ fontSize: '24px', fontWeight: 'bold', color: 'black' }}>
                                        {Number(totalMoney - discount).toLocaleString('de-DE')} zł
                                    </Box>
                                </Box>
                                <Box className="d-flex justify-space-between" sx={{ mt: 2 }}>
                                    <Box
                                        sx={{ color: 'black', fontSize: '18px', fontWeight: '100' }}
                                        className={cx('title-price')}
                                    >
                                        Całkowita kwota (brutto)
                                    </Box>
                                    <Box sx={{ fontSize: '20px', fontWeight: 'bold', color: 'black' }}>
                                        {(Number(totalMoney - discount) * 1.23).toLocaleString('de-DE')} zł
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </LoadingOverlay>
        </Box>
    );
}
export default Cart;
