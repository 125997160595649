const { Box, Divider } = require('@mui/material');

function OrderBox({ orders }) {
    return (
        <Box>
            <Box className="d-flex justify-space-between" sx={{ fontSize: '20px' }}>
                <Box>Giá sản phẩm:</Box>
                <Box sx={{ fontWeight: 'bold' }}>{parseInt(orders.productCost).toLocaleString('de-DE')} zł</Box>
            </Box>
            <Box className="d-flex justify-space-between" sx={{ fontSize: '20px', mt: 1, mb: 1 }}>
                <Box>Phí vận chuyển:</Box>
                <Box sx={{ fontWeight: 'bold' }}>{parseInt(orders.shipCost).toLocaleString('de-DE')} zł</Box>
            </Box>
            <Divider />
            <Box sx={{ mt: 1, maxHeight: '400px', overflowY: 'auto' }}>
                {orders.icons && orders.icons.length > 0 ? (
                    <Box sx={{ height: '100%' }}>
                        <Box sx={{ fontSize: '20px', fontWeight: 'bold' }}>Phụ kiện</Box>
                        <Box>
                            {orders.icons.map(el => {
                                const price = el.sale_price ? el.sale_price : el.price;
                                return (
                                    <Box sx={{ mt: 1, mb: 2 }} className="d-flex justify-space-between">
                                        <Box className="d-flex">
                                            <img
                                                src={el.image_url}
                                                style={{ width: '60px', height: 'auto' }}
                                                alt="icon"
                                            />
                                            <Box sx={{ ml: 2 }}>
                                                <Box sx={{ fontSize: '20px' }}>{el.name}</Box>
                                                <Box sx={{ mt: 1, color: '#979A9A' }}>
                                                    {parseInt(price).toLocaleString('de-DE')} zł x{el.quantityBuy}
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box sx={{ fontSize: '18px', color: '#6C3483', fontWeight: 'bold', pr: 1 }}>{parseInt(el.quantityBuy * price).toLocaleString('de-DE')} zł</Box>
                                    </Box>
                                );
                            })}
                        </Box>
                    </Box>
                ) : (
                    <Box>Chưa có phụ kiện nào</Box>
                )}
            </Box>
        </Box>
    );
}
export default OrderBox;
