import MTable from '@/components/Table';
import { Box } from '@mui/material';
import { Fragment, useCallback, useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import CreateEdit from './CreateEdit';
import navbarApi from '@/api/navbarApi';

function Rating() {
    const onClose = () => setDialogData({ ...dialogData, open: false });
    const onOpenEdit = data =>
        setDialogData({ ...dialogData, open: true, type: 'EDIT', data, title: 'Cập nhật Rating' });
    const [param, setParam] = useState({});
    const [tabeleData, setTableData] = useState([]);
    const [totalPage, setTotalPage] = useState(0);
    const [loading, setLoading] = useState(false);

    const getData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await navbarApi.getRatingHome(param);
            setLoading(false);
            setTableData(response.data);
            setTotalPage(response.last_page || response?.meta?.last_page);
        } catch (error) {
            setLoading(false);
        }
    }, [param]);
    const [dialogData, setDialogData] = useState({
        open: false,
        title: 'Cập nhật Rating home',
        onClose: onClose,
        onDone: getData,
    });

    const onChangePage = page => {
        setParam({ ...param, page });
    };

    useEffect(() => {
        getData();
    }, [getData]);

    return (
        <Box sx={{ padding: '15px' }}>
            <CreateEdit {...dialogData} />
            <Box sx={{ mb: 2 }} className="d-flex justify-space-between align-center">
                <Box sx={{ fontSize: '24px' }}>Cài đặt Home Review(Đánh giá của KH trên trang Home)</Box>
            </Box>
            <MTable data={tabeleData} loading={loading} countPage={totalPage} onChangePage={onChangePage}>
                {item => (
                    <Fragment>
                        <div label="Ngày cập nhật" property="updated_at" sx={{ width: '150px' }}></div>
                        <div label="Tên nhân vật" property="info1" sx={{ width: '150px' }}></div>
                        <div label="Nghề nghiệp" property="info2" sx={{ width: '150px' }}></div>
                        <div label="Nội dung" property="info3"></div>
                        <div label="Path" property="path" sx={{ width: '250px' }}></div>
                        <div label="Cập nhật" sx={{ width: '150px' }}>
                            <IconButton aria-label="edit" color="primary" onClick={() => onOpenEdit(item)}>
                                <EditIcon />
                            </IconButton>
                        </div>
                    </Fragment>
                )}
            </MTable>
        </Box>
    );
}
export default Rating;
