import AdminFooter from './Footer';
import AdminHeader from './Header';
import Sidebar from './Sidebar';
import { Outlet } from 'react-router-dom';
import classNames from 'classnames/bind';
import styles from './AdminLayout.scss';
const cx = classNames.bind(styles);

function AdminLayout({ children }) {
    return (
        <div className="d-flex">
            <Sidebar />
            <div style={{ flex: 1 }}>
                <AdminHeader />
                <div className='body-content'>
                    <Outlet />
                    {children}
                </div>

                <div className={cx('admin-footer')}>
                    <AdminFooter />
                </div>
            </div>
        </div>
    );
}
export default AdminLayout;
