import { useParams } from 'react-router-dom';
import RadioColor from '@/components/RadioColor';
import Divider from '@mui/material/Divider';
import { Box } from '@mui/material';
import Button from '@/components/Button';
import { useState, useEffect, useCallback, Fragment } from 'react';
import { Button as MuiButton } from '@mui/material';
import Cookies from 'js-cookie';
import { SP_DA_XEM } from '@/constants/product';
import Grid from '@mui/material/Grid';
import Rating from '@mui/material/Rating';
import Avatar from '@mui/material/Avatar';
import SizeDialog from './SizeDialog';
import images from '@/assets/images';
import IconBar from '../Home/components/IconBar';
import productApi from '@/api/productApi';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { useDispatch } from 'react-redux';
import { pushToCart } from '@/store/cart/cartSlice';
import { toast } from 'react-toastify';
import GridProduct from '@/components/GridProduct/GridProduct';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { randomProductReview } from './reviews';
import StarIcon from '@mui/icons-material/Star';
import MInput from '@/components/Input/MInput';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import styles from './product.scss';

const cx = classNames.bind(styles);
const iconInfo = [
    { name: 'Darmowa wysyłka przy zamówieniach powyżej 1.200 zł netto', icon: images.freeship },
    { name: 'Niezwykle łatwy zwrot, wystarczy numer telefonu', icon: images.doitra },
    { name: 'Szybka dostawa na terenie całego kraju', icon: images.freeshiptoanquoc },
    { name: '30 dni na zwrot bez opłat', icon: images.trahang },
    { name: 'Infolinia obsługi klienta czynna codziennie od 8:00 do 22:00', icon: images.tuvan },
    { name: 'Przyjedź do siebie, aby dokonać zwrotu i zwrotu pieniędzy', icon: images.hoantien },
];
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 8,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#F37121' : '#F37121',
    },
}));

function ProductDetail() {
    const topReview = randomProductReview[Math.floor(Math.random() * randomProductReview.length)];
    const navigate = useNavigate();

    const [styleInfoChooseSize, setStyleInfoChooseSize] = useState(null);
    const { productCode } = useParams();
    const [product, setProduct] = useState({});
    const [imageProduct, setImageProduct] = useState([]);
    const [colors, setColors] = useState([]);
    const [sizes, setSizes] = useState([]);
    const [colorSelect, setColorSelect] = useState(null);
    const [seenProductData, setSeenProductData] = useState([]);
    const [quantity, setQuantity] = useState(1);
    const [sizeSelected, setSizeSelected] = useState();
    const [favoriteProducts, setFavoriteProduct] = useState([]);
    const [openSizeDialog, setOpenSizeDialog] = useState(false);
    const dispatch = useDispatch();
    const getDetail = useCallback(async () => {
        const response = await productApi.getDetailProductByCode(productCode);
        const colorData = response.colors
            ? response.colors.map(el => {
                return { value: el.id, color: el.code, label: el.name, sizes: el.sizes, images: el.images };
            })
            : [];
        setColorSelect(colorData[0]);
        setColors(colorData);
        const sizeData =
            colorData[0] && colorData[0].sizes
                ? colorData[0].sizes.map(el => {
                    return {
                        name: el.name,
                        id: el.id,
                        quantity: el.quantity,
                    };
                })
                : [];
        const imageList =
            colorData[0] && colorData[0].images
                ? colorData[0].images.map(el => ({ original: el.path, thumbnail: el.path }))
                : [];
        imageList.unshift({ original: response.url_image, thumbnail: response.url_image });
        setImageProduct(imageList);
        // const initSizeSelected = sizeData.find(it => it.quantity > 0);
        setSizeSelected(null);
        setSizes(sizeData);
        setProduct(response);

        const spDaXem = Cookies.get(SP_DA_XEM) ? JSON.parse(Cookies.get(SP_DA_XEM)) : [];
        const responseSeen = await productApi.getProduct({
            listID: spDaXem.filter(item => Number(item) !== response.id),
        });
        setSeenProductData(responseSeen.data);
    }, [productCode]);

    const handleSelectSize = size => {
        setSizeSelected(size);
        setStyleInfoChooseSize(null);
    };
    const goReview = () => {
        const rev = document.getElementById('product-reviews');
        rev.scrollIntoView({ behavior: 'smooth' });
    };

    const getTopData = useCallback(async () => {
        const response = await productApi.getProduct({
            perPage: 8,
        });
        setFavoriteProduct(response.data);
    }, []);
    const handleChangeColor = color => {
        setColorSelect(color);
        const sizeData = color.sizes;
        const imageList =
            color && color.images ? color.images.map(el => ({ original: el.path, thumbnail: el.path })) : null;
        setImageProduct(imageList);
        setSizeSelected(null);
        setSizes(sizeData);
    };
    const handleAddToCart = () => {
        if (sizeSelected) {

            const productAdd = {
                id: product.id,
                color_id: colorSelect.value,
                size_id: sizeSelected.id,
                quantity: quantity,
                flash_price: product.flash_price
            };

            dispatch(pushToCart(productAdd));
            toast(
                <Box>
                    <Box sx={{ fontWeight: 'bold', color: 'black', mb: 2, mt: 1 }}>Produkt dodany do koszyka!</Box>
                    <Divider />
                    <Box sx={{ mt: 2 }} className="d-flex">
                        <Box
                            sx={{
                                width: '90px',
                                height: '120px',
                                border: '1px solid #CACFD2',
                                borderRadius: '12px',
                                background: '#f7f7f7',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'contain',
                                backgroundPosition: 'center',
                                backgroundImage: `url('${product.url_image}')`,
                            }}
                        ></Box>
                        <Box sx={{ flex: 1, ml: 1 }}>
                            <Box sx={{ fontWeight: 'bold', color: 'black' }}>{product.name}</Box>
                            {colorSelect && sizeSelected ? (
                                <Box sx={{ mt: 2 }}>
                                    {colorSelect.label} / {sizeSelected.name}
                                </Box>
                            ) : null}
                        </Box>
                    </Box>
                    <Box>
                        <Box
                            className="d-flex align-center justify-center"
                            sx={{
                                mt: 2,
                                width: '100%',
                                borderRadius: '18px',
                                border: '2px solid #F37121',
                                height: '35px',
                                fontWeight: 'bold',
                                color: '#F37121',
                            }}
                            onClick={() => navigate('/gio-hang')}
                        >
                            Zobacz Koszyk
                        </Box>
                    </Box>
                </Box>
            );

        } else {
            setStyleInfoChooseSize({
                border: '1px solid #CB4335',
                pb: 2,
                pt: 2,
                pl: 1,
                borderRadius: '12px',
            });
        }
    };

    useEffect(() => {
        getDetail();
        getTopData();
    }, [getDetail, getTopData]);

    const someValue = Number(product.id) > 0 ? 1 : 0;
    return (
        <div className="c-flex align-center">
            <div className="width-container">
                <Box sx={{ background: 'white' }}>
                    <IconBar />
                    <Divider sx={{ mt: 2 }} />
                    <SizeDialog open={openSizeDialog} onClose={() => setOpenSizeDialog(false)} />

                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={7} lg={7} xl={7} sx={{ minHeight: '429px', mt: 3 }}>
                            <Box className={cx('slide-small')} sx={{ pl: 2, pr: 1 }}>
                                <ImageGallery
                                    showFullscreenButton={false}
                                    showPlayButton={false}
                                    disableThumbnailScroll
                                    items={imageProduct}
                                    thumbnailPosition="bottom"
                                />
                                {someValue > 0}
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={5} lg={5} xl={5} sx={{ paddingTop: '20px' }}>
                            <Box sx={{ pl: 2, pr: 2 }}>
                                <Box sx={{ mb: 2 }} className="d-flex align-center flex-wrap">
                                    <Box onClick={goReview} sx={{ cursor: 'pointer' }}>
                                        <Rating value={Math.floor(Math.random() * 5) + 4} readOnly />
                                    </Box>
                                    <Box sx={{ ml: 1, fontSize: '17px', color: '#626567' }}>
                                        Được đánh giá 4.9 sao trên 100+ phản hồi KH
                                    </Box>
                                </Box>
                                <Box className={cx('name-card')} sx={{ fontWeight: '600', mt: 1 }}>{product.name}</Box>
                                <Box sx={{ mt: 2, mb: 1, fontSize: '24px' }} className="d-flex">
                                    <Box style={{ fontSize: '16px', color: '#121212', fontWeight: '600' }}>
                                        {Number(product.flash_price).toLocaleString('de-DE')} zł
                                    </Box>
                                    {product.flash_price !== product.price ? (
                                        <Fragment>
                                            <Box
                                                sx={{
                                                    textDecoration: 'line-through',
                                                    marginLeft: '6px',
                                                    color: '#A6ACAF',
                                                    fontSize: '16px',
                                                }}
                                            >
                                                {Number(product.price).toLocaleString('de-DE')} zł
                                            </Box>
                                            <Box sx={{ color: '#CB4335', ml: 1, fontSize: '16px' }}>
                                                -
                                                {parseInt((100 * (product.price - product.flash_price)) / product.price)}
                                                %
                                            </Box>
                                        </Fragment>
                                    ) : null}
                                </Box>
                                <BorderLinearProgress variant="determinate" value={100} sx={{ mb: 2 }} />
                                <Box sx={{ fontSize: '20px', mb: 2 }}>
                                    <Box sx={{ mb: 2 }}>
                                        Kolor: <b>{colorSelect?.label}</b>
                                    </Box>
                                    <Box>
                                        <RadioColor
                                            value={colorSelect?.value}
                                            data={colors}
                                            onChangeColor={handleChangeColor}
                                        />
                                    </Box>
                                </Box>

                                <Box sx={{ fontSize: '20px', mb: 1, mt: 1 }} className="d-flex justify-space-between">
                                    <Box>
                                        Size áo: <b>{sizeSelected?.name}</b>
                                    </Box>
                                    <Box
                                        sx={{ color: 'black', fontWeight: 'bold', cursor: 'pointer' }}
                                        onClick={() => setOpenSizeDialog(true)}
                                    >
                                        Instrukcje dotyczące wyboru rozmiaru
                                    </Box>
                                </Box>
                                {styleInfoChooseSize ? (
                                    <Box sx={{ fontWeight: 'bold', color: '#CB4335', mb: 1 }}>Vui lòng chọn Size</Box>
                                ) : null}
                                <Box sx={{ ...styleInfoChooseSize }}>
                                    {sizes.map((item, index) => (
                                        <MuiButton
                                            onClick={() => handleSelectSize(item)}
                                            key={index}
                                            variant={
                                                sizeSelected && sizeSelected.id === item.id ? 'contained' : 'outlined'
                                            }
                                            sx={{ mr: 1, borderRadius: '18px' }}
                                            size="medium"
                                            disabled={!item.quantity || item.quantity < 1}
                                        >
                                            {item.name}
                                        </MuiButton>
                                    ))}
                                </Box>

                                <Box className="d-flex" sx={{ mt: 3 }}>
                                    <Box sx={{ width: '25%', mr: 4 }}>
                                        <MInput
                                            type="number"
                                            label="Số lượng"
                                            size="small"
                                            value={quantity}
                                            onChange={e => setQuantity(e.target.value)}
                                        />
                                    </Box>
                                    <Button
                                        style={{
                                            width: '100%',
                                            borderRadius: '18px',
                                            backgroundColor: '#F37121',
                                            border: '#F37121',
                                        }}
                                        primary
                                        onClick={handleAddToCart}
                                    >
                                        <Box
                                            className="d-flex align-center justify-center"
                                            sx={{ height: '7px', width: '100%' }}
                                        >
                                            <img src={images.cartIcon} style={{ width: '25px' }} alt="icon" />
                                            <Box className={cx('carticon-mobile')} sx={{ mt: 0, ml: 2 }}>
                                                {sizeSelected ? 'Dodaj do koszyka' : 'Wybierz rozmiar produktu'}
                                            </Box>
                                        </Box>
                                    </Button>
                                </Box>
                                <Box className="d-flex align-center justify-space-between flex-wrap" sx={{ mt: 2 }}>
                                    <Box>Phương thức thanh toán:</Box>
                                    <img src={images.thanhToan} alt="pay" height="40px" />
                                </Box>
                                <Box sx={{ padding: '20px', background: '#ECF0F1', borderRadius: '18px', mt: 3 }}>
                                    <Rating value={topReview.rate} size="small" readOnly />
                                    <Box sx={{ mt: 1, fontSize: '16px' }}>{topReview.content}</Box>
                                    <Box className="d-flex align-center" sx={{ mt: 2 }}>
                                        <Avatar sx={{ bgcolor: '#BA4A00' }}>{topReview.name[0]}</Avatar>
                                        <div style={{ fontWeight: 'bold', paddingLeft: '13px', fontSize: '16px' }}>
                                            {topReview.name}
                                        </div>
                                    </Box>
                                </Box>
                                <Divider sx={{ mt: 3 }} />
                                <Box sx={{ mt: 2, fontSize: '18px', fontWeight: 'bold' }}>Đến với OnYou bạn sẽ có</Box>
                                <Box className="d-flex flex-wrap justify-space-between">
                                    {iconInfo.map((item, index) => (
                                        <Box key={index} sx={{ width: '30%' }}>
                                            <Box className="c-flex" sx={{ mt: 4 }}>
                                                <Box sx={{ textAlign: 'center', width: '100%' }}>
                                                    <img src={item.icon} alt="icon" style={{ width: '55px' }} />
                                                </Box>
                                                <Box sx={{ maxWidth: '250px', fontSize: '14px', textAlign: 'center' }}>
                                                    {' '}
                                                    {item.name}
                                                </Box>
                                            </Box>
                                        </Box>
                                    ))}
                                </Box>
                                <Divider sx={{ mt: 2 }} />
                                <Box sx={{ padding: '15px', fontSize: '17px' }}>
                                    <Box sx={{ fontSize: '18px', fontWeight: 'bold' }}>Thông tin sản phẩm</Box>
                                    <Box sx={{ mt: 1 }}>* 100% cotton, trọng lượng 250gsm</Box>
                                    <Box sx={{ mt: 1 }}>* Kiểu dáng: Unisex Oversize</Box>
                                    <Box sx={{ mt: 1 }}>* Cổ áo bo</Box>
                                    <Box sx={{ mt: 1 }}>* 100% sản xuất tại Việt Nam (Từ sợi bông cho đến vải)</Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ pl: 1, pr: 1 }}>
                    <Divider sx={{ mt: 3, mb: 3 }} />
                    <Box className="d-flex align-center">
                        <Box>
                            <Box sx={{ fontWeight: 'bold' }}>{randomProductReview.length} OCENIAĆ</Box>
                        </Box>
                        <Box className="d-flex align-center" sx={{ marginLeft: '20px' }}>
                            <Box sx={{ fontWeight: 'bold', mr: 1 }}>4.9</Box>
                            <StarIcon />
                        </Box>
                    </Box>
                    <Divider sx={{ mt: 3 }} />
                    <Box sx={{ mt: 2 }} id="product-reviews">
                        <Grid container spacing={3}>
                            {randomProductReview.map((item, index) => (
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} key={index}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={5} md={4} lg={4} xl={4}>
                                            <Rating
                                                size="small"
                                                name="read-only"
                                                value={item.rate}
                                                precision={0.1}
                                                readOnly
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={7} md={8} lg={8} xl={8}>
                                            <Box sx={{ fontWeight: 'bold' }}>{item.name}</Box>
                                            <Box sx={{ mt: 1, mb: 2, color: 'gray' }}>Size: {item.size}</Box>
                                            <Box sx={{ mt: 3, minHeight: '60px', lineHeight: '26px' }}>
                                                {item.content}
                                            </Box>
                                            <Box sx={{ mt: 3 }}>{item.date}</Box>
                                        </Grid>
                                    </Grid>
                                    <Divider sx={{ mt: 3 }} />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Box>
                <div className="c-flex align-center justify-center" style={{ height: '300px' }}>
                    <div style={{ marginTop: '26px', fontSize: '20px' }}>Xem thêm về ONYOU trên</div>
                    <img
                        alt="news"
                        style={{ width: '70%' }}
                        src="https://w.ladicdn.com/s1300x450/5b02915e31c8298e7b5d14b5/bao-dai-20230302043001-vafxr.png"
                    />
                </div>
                <Box className="d-flex justify-center">
                    <Box sx={{ mt: 4 }} className="width-container">
                        <Box sx={{ fontWeight: 'bold', fontSize: '18px', mb: 2, textAlign: 'center' }}>
                            CÓ THỂ BẠN SẼ THÍCH
                        </Box>
                        <GridProduct products={favoriteProducts} loading={false} />
                    </Box>
                </Box>

                {seenProductData && seenProductData.length > 0 ? (
                    <Box className="d-flex justify-center">
                        <Box sx={{ mt: 4 }} className="width-container">
                            <Box sx={{ fontWeight: 'bold', fontSize: '18px', mb: 2, textAlign: 'center' }}>
                                OSTATNIO OGLĄDANE PRODUKTY
                            </Box>
                            <GridProduct products={seenProductData} loading={false} />
                        </Box>
                    </Box>
                ) : null}
            </div>
        </div>
    );
}
export default ProductDetail;
