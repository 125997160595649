import axiosClient from '@/utils/axiosClient';

class FlashsaleApi {
    getFlashsale = params => {
        const url = '/flashsale';
        return axiosClient.get(url , { params });
    };

    getFlashsaleDetail = (id) => {
        const url = `/getflashsaledetail/${id}`;
        return axiosClient.get(url);
    };

    updateStatusFlashsale = (id) => {
        const url = `/updatestatus/${id}`;
        return axiosClient.get(url);
    };

    createFlashsale = data => {
        const url = '/flashsale';
        return axiosClient.post(url, data);
    };
    updateFlashsale = (id, data) => {
        const url = `/flashsale/${id}`;
        return axiosClient.put(url, data);
    };

    deleteFlashsale = (id) => {
        const url = `/flashsale/${id}`;
        return axiosClient.delete(url);
    };
}
const flashsaleApi = new FlashsaleApi();
export default flashsaleApi;
