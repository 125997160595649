import axios from 'axios';
import { getToken, removeToken } from "@/utils/token";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
const axiosClient = axios.create({
    baseURL: '/api',
    timeout: 60000,
    headers: {
        'content-type': 'application/json',
    }

});

axiosClient.interceptors.request.use(
    config => {
        config.headers["Authorization"] = "Bearer " + getToken();
        config.mode = "no-cors";
        return config;
    },
    error => {
        return Promise.reject(error);
    }
)

axiosClient.interceptors.response.use((response) => {
    if (response && response.data) {
        return response.data;
    }
    return response;
}, async (error) => {
    if (error && error.response && error.response.data && (error.response.data.code === "token_not_found" || error.response.data.code === 'unauthorized' || error.response.data.name === "TokenExpiredError")) {
        removeToken()
        window.location.assign("/login");
    }
    else {
        //var messageArray = null;
        // if (error.response && error.response.data && error.response.data.errors) {
        //     messageArray = Object.values(error.response.data.errors);
        //     messageArray = messageArray.flat()
        // }
        const message = (error.response && error.response.data && error.response.data.message) ? error.response.data.message : (error.message || 'Có lỗi xảy ra');
        // if (messageArray && messageArray.length > 0) {
        //     messageArray.forEach(info => {
        //         toast.error(info, {
        //             position: 'top-center'
        //         });
        //     })
        // }
        // else {
        //     toast.error(message, {
        //         position: 'top-center'
        //     });
        // }

        toast.error(message, {
            position: 'top-center'
        });

        throw error;
    }

});
export default axiosClient;