import MDialog from '@/components/Dialog';
import { Box, InputAdornment, TextField } from '@mui/material';
import { useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import promotionApi from '@/api/promotionApi';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';
import { debounce } from 'lodash';
import productApi from '@/api/productApi';

function CreateEdit({ open, title, onClose, data, type, onDone }) {
    const [disableFeild, setDisableFeild] = useState(false);
    const [typePromotion, setTypePromotion] = useState(null);
    const [typeProduct, setTypeProduct] = useState(null);
    const [param, setParam] = useState({});
    const [loading, setLoading] = useState(false);
    const [productSelected, setProductSelected] = useState([]);
    const [products, setProducts] = useState([]);
    const onSubmit = async () => {
        if (formData.products == null || formData.code == null || formData.name == null || formData.start_at == null || formData.end_at == null) {
            toast.error('Kiểm tra thông tin đầu vào!');
        }
        try {
            if (type === 'ADD') {
                formData.products = [...productSelected];

                await promotionApi.createPromotion(formData);
            }
            if (type === 'EDIT') {
                formData.products = [...productSelected];
                await promotionApi.updatePromotion(formData.id, formData);
            }
            onClose();
            toast.success('Thành công!');
            setErrorSubmit({});
            onDone();
        } catch (error) {
            const errors = error.response.data.errors;
            for (const key in errors) {
                errors[key] = errors[key].join('. ');
            }
            setErrorSubmit(errors);
        }
    };
    const [formData, setFormData] = useState({});
    const [errorSubmit, setErrorSubmit] = useState({});

    const handleSearch = value =>
        debounce(() => {
            setParam({ ...param, search: value });
        }, 600)();

    const handleSelectProcduct = newProductArray => {
        const arrayProduct = newProductArray.map(item => {
            const selected = productSelected.find(it => it.id === item.id);
            if (selected) {
                return selected;
            }
            return { ...item };
        });
        setProductSelected(arrayProduct);
    };

    const handleSelectTypeProduct = useCallback(async (typeProduct) => {
        setLoading(true);
        try {

            if (typeProduct === 'danh_muc') {
                const response = await productApi.getCategories();
                setProducts(response.data);
                setLoading(false);
            } else {
                // danh sach san pham
                const response = await productApi.getProduct();
                setProducts(response.data);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        }
    }, []);

    const getData = useCallback(async () => {
        setLoading(true);
        try {
            if (typeProduct === 'san_pham') {
                const response = await productApi.getProduct(param);
                setProducts(response.data);
            } else {
                const response = await productApi.getCategories(param);
                setProducts(response.data);
            }

            setLoading(false);

        } catch (error) {
            setLoading(false);
        }
    }, [param]);

    useEffect(() => {
        setFormData({});
        setDisableFeild(false)
        setTypePromotion(null)
        setTypeProduct(null)
        if (type === 'EDIT') {
            setFormData({ ...data });
            setDisableFeild(true);
            setTypePromotion(data.type_promotions)
            setTypeProduct(data.type_products)
            const arrayProduct = data.products.map(item => {
                return { ...item, id: item.id };
            });
            setProductSelected(arrayProduct);
            handleSelectTypeProduct(data.type_products)

        } else {
            setFormData({ ...data });
            setProductSelected([]);
        }
    }, [type, data]);

    useEffect(() => {
        getData();
    }, [getData]);

    return (
        <div>
            <MDialog open={open} title={title} onClose={onClose} onSubmit={onSubmit} type={typePromotion}>
                <Box sx={{ width: '800px' }}>
                    <TextField
                        sx={{ width: '100%' }}
                        label="Tên chương trình khuyến mãi"
                        variant="outlined"
                        value={formData.name}
                        error={errorSubmit.name ? true : false}
                        onChange={e => setFormData({ ...formData, name: e.target.value })}
                        helperText={errorSubmit.name}
                    />
                    <Box className="d-flex" sx={{ mt: 2 }}>
                        <TextField
                            sx={{ width: '100%', mr: 1 }}
                            label="Mã chương trình khuyến mãi"
                            variant="outlined"
                            value={formData.code}
                            error={errorSubmit.code ? true : false}
                            onChange={e => setFormData({ ...formData, code: e.target.value })}
                            helperText={errorSubmit.code}
                            disabled={disableFeild}
                        />
                        <TextField
                            sx={{ width: '100%', ml: 1 }}
                            variant="outlined"
                            type="number"
                            label="Số lượng đơn hàng giới hạn"
                            value={formData.quantity}
                            error={errorSubmit.quantity ? true : false}
                            onChange={e => setFormData({ ...formData, quantity: e.target.value })}
                            helperText={errorSubmit.quantity}
                        />
                    </Box>
                    <Box className="d-flex" sx={{ mt: 2 }}>
                        <Box sx={{ width: '100%', mr: 1 }}>
                            <Box sx={{ mb: 1 }}>Loại khuyến mãi</Box>
                            <Select
                                sx={{ width: '100%', mr: 1, color: 'black' }}
                                value={typePromotion}
                                placeholder="Loại khuyến mãi"
                                onChange={e => { setTypePromotion(e.target.value); setFormData({ ...formData, type_promotions: e.target.value }) }}
                                error={errorSubmit.type_promotions ? true : false}
                                helperText={errorSubmit.type_promotions}
                            >
                                <MenuItem value={'qua_tang'}>Quà tặng kèm</MenuItem>
                                <MenuItem value={'tang_vouncher'}>Tặng voucher</MenuItem>
                            </Select>
                        </Box>
                    </Box>
                    <Box className="d-flex" sx={{ mt: 2 }}>
                        <Box sx={{ width: '100%', mr: 1 }}>
                            <Box sx={{ mb: 1 }}>Giá trị từ ngày</Box>
                            <TextField
                                sx={{ width: '100%' }}
                                variant="outlined"
                                type="datetime-local"
                                value={formData.start_at}
                                error={errorSubmit.start_at ? true : false}
                                onChange={e => setFormData({ ...formData, start_at: e.target.value })}
                                helperText={errorSubmit.start_at}
                            />
                        </Box>
                        <Box sx={{ width: '100%', ml: 1 }}>
                            <Box sx={{ mb: 1 }}>Giá trị đến ngày</Box>
                            <TextField
                                sx={{ width: '100%' }}
                                variant="outlined"
                                type="datetime-local"
                                value={formData.end_at}
                                error={errorSubmit.end_at ? true : false}
                                onChange={e => setFormData({ ...formData, end_at: e.target.value })}
                                helperText={errorSubmit.end_at}
                            />
                        </Box>
                    </Box>
                    <Box className="d-flex" sx={{ mt: 2 }}>
                        <Box sx={{ width: '100%', mr: 1 }}>
                            <Box sx={{ mb: 1 }}>Giảm giá tối đa</Box>
                            <TextField
                                sx={{ width: '100%' }}
                                variant="outlined"
                                type="number"
                                value={formData.max_reduce}
                                error={errorSubmit.max_reduce ? true : false}
                                onChange={e => setFormData({ ...formData, max_reduce: e.target.value })}
                                helperText={errorSubmit.max_reduce}
                            />
                        </Box>
                        <Box sx={{ width: '100%', ml: 1 }}>
                            <Box sx={{ mb: 1 }}>Đơn hàng tối thiểu</Box>
                            <TextField
                                sx={{ width: '100%' }}
                                variant="outlined"
                                type="number"
                                value={formData.min_order}
                                error={errorSubmit.min_order ? true : false}
                                onChange={e => setFormData({ ...formData, min_order: e.target.value })}
                                helperText={errorSubmit.min_order}
                            />
                        </Box>
                    </Box>
                    <TextField
                        sx={{ width: '100%', mt: 2 }}
                        label="Mô tả"
                        variant="outlined"
                        value={formData.description}
                        onChange={e => setFormData({ ...formData, description: e.target.value })}
                    />
                    <Box className="d-flex" sx={{ mt: 2 }}>
                        <Box sx={{ width: '100%', mr: 1 }}>
                            <Box sx={{ mb: 1 }}>Phạm vi áp dụng</Box>
                            <Select
                                sx={{ width: '100%', mr: 1 }}
                                value={typeProduct}
                                placeholder="Phạm vi áp dụng"
                                onChange={e => { setTypeProduct(e.target.value); setFormData({ ...formData, type_products: e.target.value }); handleSelectTypeProduct(e.target.value) }}
                                error={errorSubmit.type_products ? true : false}
                                helperText={errorSubmit.type_products}
                            >
                                <MenuItem value={'danh_muc'}>Theo danh mục sản phẩm </MenuItem>
                                <MenuItem value={'san_pham'}>Theo từng sản phẩm</MenuItem>
                            </Select>
                        </Box>
                        <Box sx={{ width: '100%', ml: 1 }}>
                            <Box sx={{ mb: 1 }}>Lựa chọn sản sản phẩm</Box>
                            <Autocomplete
                                disablePortal
                                multiple
                                sx={{ width: '100%', mr: 1 }}
                                label="Chọn sản phẩm áp dụng"
                                variant="outlined"
                                size="meidum"
                                getOptionLabel={option => option.product_by ? option.product_by.name : option.name}
                                renderOption={(props, option) => (
                                    <Box className="d-flex align-cener" {...props}>
                                        <Box sx={{ ml: 1 }}>{option.product_by ? option.product_by.name : option.name}</Box>
                                    </Box>
                                )}
                                options={products}
                                value={productSelected}
                                onChange={(e, newValue) => handleSelectProcduct(newValue)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Search input"
                                        onChange={e => handleSearch(e.target.value)}
                                    />
                                )}
                            />
                        </Box>
                    </Box>
                </Box>
            </MDialog>
        </div>
    );
}
export default CreateEdit;
