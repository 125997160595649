import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { useSelector, useDispatch } from 'react-redux';
import { changeTextStyle } from '@/store/design/targetDesignSlice';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import { POSITION_TOP, POSITION_BACK } from '@/store/design/constant';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { setPosition, deleteIconDesign } from '@/store/design/targetDesignSlice';
import IconButton from '@mui/material/IconButton';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import { MuiColorInput } from 'mui-color-input';
import { debounce } from 'lodash';
const fonts = [
    'Arial, sans-serif',
    'Verdana, sans-serif',
    'Tahoma, sans-serif',
    "'Trebuchet MS', sans-serif",
    "'Times New Roman', serif",
    'Georgia, serif',
    'Garamond, serif',
    "'Courier New', monospace",
    "'Brush Script MT', cursive",
    'Roboto',
];
const iconStyle = {
    padding: '5px',
    marginRight: '20px',
    border: '1px solid #BDC3C7',
};
const iconStyleActived = {
    padding: '5px',
    marginRight: '20px',
    border: '2px solid #5B2C6F',
    color: '#5B2C6F',
};

function TextDesign() {
    const textStyle = useSelector(state => state.targetDesign.textStyle);
    const iconSelected = useSelector(state => state.targetDesign.iconSelected);
    const fontSize = Number(textStyle.fontSize.substring(0, textStyle.fontSize.length - 2));
    const isBold = textStyle.fontWeight === 'bold';
    const isItalic = textStyle.fontStyle === 'italic';
    const isUnderline = textStyle.textDecoration === 'underline';

    const dispatch = useDispatch();
    const handleChangeFont = event => {
        const value = event.target.value;
        return dispatch(changeTextStyle({ fontFamily: value }));
    };
    const handleChangeFontSize = event => {
        const value = event.target.value;
        return dispatch(changeTextStyle({ fontSize: value + 'px' }));
    };
    const handleChangStyle = (type, data = null) => {
        switch (type) {
            case 'fontWeight':
                dispatch(changeTextStyle({ fontWeight: isBold ? 'normal' : 'bold' }));
                break;
            case 'fontStyle':
                dispatch(changeTextStyle({ fontStyle: isItalic ? 'normal' : 'italic' }));
                break;
            case 'textDecoration':
                dispatch(changeTextStyle({ textDecoration: isUnderline ? 'none' : 'underline' }));
                break;
            case 'color':
                const textElement = document.getElementById(iconSelected.data.id);
                textElement.style.color = data;
                setColor(data);
                break;
            default:
                return;
        }
    };
    const setColor = debounce(data => {
        dispatch(changeTextStyle({ color: data }));
    }, 600);
    return (
        <div>
            <div className="d-flex" style={{ width: '100%' }}>
                <div style={{ flex: 1, marginRight: '20px' }}>
                    <Select
                        variant="outlined"
                        value={textStyle.fontFamily}
                        label="Font chữ"
                        onChange={handleChangeFont}
                        style={{ width: '100%' }}
                    >
                        {fonts.map(item => (
                            <MenuItem key={item} value={item}>
                                <div style={{ fontFamily: item }}>{item}</div>
                            </MenuItem>
                        ))}
                    </Select>
                </div>
                <div style={{ width: '120px' }}>
                    <TextField
                        InputProps={{
                            inputProps: {
                                max: 50,
                                min: 2,
                            },
                        }}
                        label="Cỡ chữ"
                        variant="outlined"
                        type="number"
                        value={fontSize}
                        onChange={handleChangeFontSize}
                    />
                </div>
            </div>
            <div style={{ paddingTop: '20px' }}>
                <div>
                    <IconButton
                        style={isBold ? iconStyleActived : iconStyle}
                        onClick={() => handleChangStyle('fontWeight')}
                    >
                        <FormatBoldIcon />
                    </IconButton>

                    <IconButton
                        style={isItalic ? iconStyleActived : iconStyle}
                        onClick={() => handleChangStyle('fontStyle')}
                    >
                        <FormatItalicIcon />
                    </IconButton>
                    <IconButton
                        style={isUnderline ? iconStyleActived : iconStyle}
                        onClick={() => handleChangStyle('textDecoration')}
                    >
                        <FormatUnderlinedIcon />
                    </IconButton>
                    <MuiColorInput
                        format="hex"
                        size="small"
                        value={textStyle.color}
                        onChange={color => handleChangStyle('color', color)}
                    />
                </div>
                <div style={{ paddingTop: '20px' }}>
                    <Button
                        size="small"
                        variant="contained"
                        onClick={() => dispatch(deleteIconDesign())}
                        startIcon={<DeleteIcon />}
                        style={{ marginRight: '50px' }}
                    >
                        Xóa
                    </Button>
                    <Button
                        onClick={() => dispatch(setPosition(POSITION_TOP))}
                        variant="outlined"
                        startIcon={<KeyboardDoubleArrowUpIcon />}
                        size="small"
                        style={{ marginRight: '10px' }}
                    >
                        Lên trước
                    </Button>
                    <Button
                        onClick={() => dispatch(setPosition(POSITION_BACK))}
                        variant="outlined"
                        startIcon={<KeyboardDoubleArrowDownIcon />}
                        size="small"
                        style={{ marginRight: '10px' }}
                    >
                        Sau cùng
                    </Button>
                </div>
            </div>
        </div>
    );
}
export default TextDesign;
