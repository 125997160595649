import { useDispatch } from 'react-redux';
import { pushIconDesign } from '@/store/design/targetDesignSlice';
import { v4 as uuidv4 } from 'uuid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState, useEffect } from 'react';
import accessoryApi from '@/api/accessoryApi';
import LazyLoad from 'react-lazyload';
import { TextField } from '@mui/material';
import styles from './cart.scss';
import classNames from 'classnames/bind';


const cx = classNames.bind(styles);

function IconImageText() {
    const [expanded, setExpanded] = useState('panel1');
    const [expandAll, setExpandAll] = useState(false);
    const [accessories, setAccessories] = useState([]);
    const [accessoriesFilter, setAccessoriesFilter] = useState([]);
    const dispatch = useDispatch();
    const handlePushIcon = item => {
        var newItem = { ...item, text: null };
        var cRong = 55
        if (item.width != null) {
            cRong = item.width / 6.5 * 55
        }
        var strRong = cRong + 'px'

        newItem.style = { width: strRong, position: 'absolute' };
        newItem.db_id = item.id;
        newItem.id = uuidv4();
        return dispatch(pushIconDesign(newItem));
    };
    const handleChangeExpand = panel => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    useEffect(() => {
        const getItemDesign = async () => {
            const response = await accessoryApi.getItemDesign();
            setAccessories(response);
            setAccessoriesFilter(response);
        };
        getItemDesign();
    }, []);

    const searchItem = textSerach => {
        const listSearch = textSerach
            ? accessories.map(item => {
                const accessories = item.accessories.filter(image =>
                    image.name.toUpperCase().includes(textSerach.toUpperCase())
                );
                return { ...item, accessories };
            })
            : [...accessories];
        setAccessoriesFilter(listSearch);
    };
    return (
        <div>
            <TextField
                placeholder="Tìm kiếm phụ kiện"
                size="small"
                sx={{ width: '90%', paddingLeft: '9%', paddingBottom: '10px' }}
                onChange={e => {
                    searchItem(e.target.value);
                    setExpandAll(true);
                }}
                variant="outlined"
            />
            <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
                {accessoriesFilter.map((item, index) => (
                    <Accordion
                        key={index}
                        expanded={expanded === `panel${index + 1}` || expandAll}
                        onChange={handleChangeExpand(`panel${index + 1}`)}
                    >
                        <AccordionSummary
                            className={cx('title-item')}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <b>{item.name}</b>
                        </AccordionSummary>

                    </Accordion>
                ))}
            </div>
        </div>
    );
}
export default IconImageText;
