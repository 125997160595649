import MTable from '@/components/Table';
import { Box, InputAdornment, TextField } from '@mui/material';
import { Fragment, useCallback, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CreateEdit from './CreateEdit';
import { ConfirmDialog } from '@/components/ConfirmDialog';
import { toast } from 'react-toastify';
import SearchIcon from '@mui/icons-material/Search';
import { debounce } from 'lodash';
import voucherApi from '@/api/voucherApi';
import Chip from '@mui/material/Chip';

function Voucher() {
    const onClose = () => setDialogData({ ...dialogData, open: false });
    const onOpenAdd = () => setDialogData({ ...dialogData, open: true, data: {}, type: 'ADD' });
    const onOpenEdit = data =>
        setDialogData({ ...dialogData, open: true, type: 'EDIT', data, title: 'Cập nhật mã giảm giá' });
    const [param, setParam] = useState({});
    const [tabeleData, setTableData] = useState([]);
    const [totalPage, setTotalPage] = useState(0);
    const [loading, setLoading] = useState(false);

    const getData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await voucherApi.getVoucher(param);
            setLoading(false);
            setTableData(response.data);
            setTotalPage(response.last_page || response?.meta?.last_page);
        } catch (error) {
            setLoading(false);
        }
    }, [param]);
    const [dialogData, setDialogData] = useState({
        open: false,
        title: 'Thêm mới Mã giảm giá',
        onClose: onClose,
        onDone: getData,
    });

    const onChangePage = page => {
        setParam({ ...param, page });
    };
    const onDelete = item => {
        ConfirmDialog({
            title: 'Xoá phân loại phụ kiện',
            html: true,
            body: `Bạn có chắc chắn muốn xóa phân loại <b>${item.name}</b> không ?`,
            action: async onClose => {
                await voucherApi.deleteVoucher(item.id);
                onClose();
                toast.success('Thành công!');
                getData();
            },
        });
    };
    const handleSearch = value =>
        debounce(() => {
            setParam({ ...param, search: value });
        }, 600)();

    useEffect(() => {
        getData();
    }, [getData]);
    const statusVoucher = (status) => { 
        if(status === 'out'){
            return <Chip label="Đã sử dụng hết" color="warning"/>
        }
        if(status === 'valid'){
            return <Chip label="Đang hiệu lực" color="success"/>
        }
        if(status === 'not_start'){
            return <Chip label="Chưa hiệu lực" color="primary" />
        }
        if(status === 'expired'){
            return <Chip label="Hết hiệu lực" color="error">Hết hiệu lực</Chip>
        }
        
    }
    return (
        <Box sx={{ padding: '15px' }}>
            <CreateEdit {...dialogData} />
            <Box sx={{ mb: 2 }} className="d-flex justify-space-between align-center">
                <Box sx={{ fontSize: '24px' }}>Mã giảm giá</Box>
            </Box>
            <Box sx={{ mb: 1 }} className="d-flex justify-space-between">
                <TextField
                    size="small"
                    placeholder="Tìm kiếm"
                    variant="outlined"
                    style={{ width: '500px' }}
                    onChange={e => handleSearch(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                <Box>
                    <Button onClick={onOpenAdd} variant="contained" disableElevation startIcon={<AddIcon />}>
                        Thêm mới
                    </Button>
                </Box>
            </Box>
            <MTable data={tabeleData} loading={loading} countPage={totalPage} onChangePage={onChangePage}>
                {item => (
                    <Fragment>
                        <div property="name" label="Tên"></div>
                        <div property="code" label="Mã giảm giá"></div>
                        <div label="Thời gian" property="time">
                        </div>
                        <div label="Đã dùng / Số lượng">
                            {item.used} / {item.quantity}
                        </div>
                        <div label="Trạng thái">
                            {statusVoucher(item.status)}
                        </div>
                        <div label="Hành động" sx={{ width: '150px' }}>
                            <IconButton aria-label="delete" color="secondary" onClick={() => onDelete(item)}>
                                <DeleteIcon />
                            </IconButton>
                            <IconButton aria-label="edit" color="primary" onClick={() => onOpenEdit(item)}>
                                <EditIcon />
                            </IconButton>
                        </div>
                    </Fragment>
                )}
            </MTable>
        </Box>
    );
}
export default Voucher;
