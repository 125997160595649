import MDialog from '@/components/Dialog';
import { Autocomplete, Box, Checkbox, FormControlLabel, TextField } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ImageUpload from '@/components/UploadImage/UploadSingle';
import accessoryApi from '@/api/accessoryApi';

function CreateEdit({ open, title, onClose, data, type, onDone }) {
    const onSubmit = async () => {
        try {
            if (type === 'ADD') {
                await accessoryApi.addAccessory(formData);
            }
            if (type === 'EDIT') {
                await accessoryApi.updateAccessory(formData, formData.id);
            }
            onClose();
            toast.success('Thành công!');
            setErrorSubmit({});
            onDone();
        } catch (error) {
            const errors = error.response.data.errors;
            for (const key in errors) {
                errors[key] = errors[key].join('. ');
            }
            setErrorSubmit(errors);
        }
    };
    const [formData, setFormData] = useState({});
    const [errorSubmit, setErrorSubmit] = useState({});
    const [types, setTypes] = useState([]);
    const [typeAccessory, setTypeAccessory] = useState({});
    const getType = useCallback(async () => {
        const response = await accessoryApi.getType({ perPage: 9999 });
        setTypes(response.data);
    }, []);
    useEffect(() => {
        setFormData({})
        if (type === 'EDIT') {
            setFormData(data);
            if(data.type_id && types && types.length > 0){
                setTypeAccessory(types.find(item => item.id === data.type_id));
            } else setTypeAccessory({})
        }
    }, [type, data, types]);
    const handleUpload = urlImage => {
        setFormData({ ...formData, image_url: urlImage });
    };
    const handleChangeType = type => {
        setFormData({ ...formData, type_id: type?.id, rotate_able: type?.rotate_able, resize_able: type?.resize_able });
        setTypeAccessory(type)
    };
    useEffect(() => {
        getType();
    }, [getType]);
    return (
        <div>
            <MDialog open={open} title={title} onClose={onClose} onSubmit={onSubmit} type={type}>
                <Box sx={{ width: '800px' }} className="d-flex">
                    <Box sx={{ width: '40%' }}>
                        <ImageUpload onDone={handleUpload} url={formData.image_url} />
                        <Box sx={{ mt: 1, textAlign: 'center'}}>Để tối ưu hãy tải lên hình ảnh PNG</Box>
                        <TextField
                            sx={{ width: '100%', mt: 1 }}
                            size="small"
                            variant="outlined"
                            placeholder='Màu sắc'
                            value={formData.color_name}
                            onChange={e => setFormData({ ...formData, color_name: e.target.value })}
                        />
                    </Box>

                    <Box sx={{ ml: 2, width: '60%' }}>
                        <TextField
                            sx={{ width: '100%' }}
                            size="small"
                            label="Tên hình ảnh/Phụ kiện"
                            variant="outlined"
                            value={formData.name}
                            error={errorSubmit.name ? true : false}
                            onChange={e => setFormData({ ...formData, name: e.target.value })}
                            helperText={errorSubmit.name}
                        />
                        <Autocomplete
                            sx={{ mt: 2 }}
                            disablePortal
                            label="Phân loại"
                            variant="outlined"
                            size="small"
                            getOptionLabel={option => option.name || ''}
                            options={types}
                            value={typeAccessory}
                            onChange={(e, newValue) => handleChangeType(newValue)}
                            renderInput={params => <TextField {...params} label="Phân loại" />}
                        />
                        <Box sx={{ mt: 2 }} className="d-flex justify-space-between">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.rotate_able ?? false}
                                        onChange={e => setFormData({ ...formData, rotate_able: e.target.checked })}
                                    />
                                }
                                label="Có thể xoay"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.resize_able ?? false}
                                        onChange={e => setFormData({ ...formData, resize_able: e.target.checked })}
                                    />
                                }
                                label="Có thể thay đổi kích thước"
                            />
                        </Box>
                        <Box sx={{ mt: 2, mb: 1 }}>Kích thước thực tế (mm)</Box>
                        <Box className="d-flex justify-space-between">
                            <TextField
                                sx={{ width: '47%' }}
                                size="small"
                                label="Rộng"
                                variant="outlined"
                                type="number"
                                value={formData.width}
                                onChange={e => setFormData({ ...formData, width: e.target.value })}
                            />
                            <TextField
                                sx={{ width: '47%' }}
                                size="small"
                                label="Dài"
                                type="number"
                                value={formData.height}
                                onChange={e => setFormData({ ...formData, height: e.target.value })}
                            />
                        </Box>
                        <Box sx={{ mt: 2, mb: 1 }}>Thông tin khác</Box>
                        <Box className="d-flex justify-space-between">
                            <TextField
                                size="small"
                                sx={{ width: '47%' }}
                                label="Số lượng"
                                variant="outlined"
                                type="number"
                                value={formData.quantity}
                                onChange={e => setFormData({ ...formData, quantity: e.target.value })}
                            />
                            <TextField
                                sx={{ width: '47%' }}
                                size="small"
                                label="Giá nhập"
                                type="number"
                                value={formData.cost_price}
                                onChange={e => setFormData({ ...formData, cost_price: e.target.value })}
                            />
                        </Box>

                        <Box className="d-flex justify-space-between" sx={{ mt: 2 }}>
                            <TextField
                                size="small"
                                sx={{ width: '47%' }}
                                label="Giá bán"
                                variant="outlined"
                                type="number"
                                value={formData.price}
                                onChange={e => setFormData({ ...formData, price: e.target.value })}
                            />
                            <TextField
                                sx={{ width: '47%' }}
                                size="small"
                                label="Giá bán khuyến mãi"
                                type="number"
                                value={formData.sale_price}
                                onChange={e => setFormData({ ...formData, sale_price: e.target.value })}
                            />
                        </Box>
                    </Box>
                </Box>
            </MDialog>
        </div>
    );
}
export default CreateEdit;
