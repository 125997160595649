import classNames from 'classnames/bind';
import styles from './IconTalk.scss';
import images from '@/assets/images';
import { Box, Grid, Button as MuiButton } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
const cx = classNames.bind(styles);

const style = {
    widthContent: {
        minWidth: '170px',
        width: '22%',
        fontSize: '14px',
        borderRadius: '9px',
        height: '50px',
        color: 'black',
        fontWeight: 'BOLD'
    },
};
function IconTalk({ height }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();
    const goToLink = link => {
        setAnchorEl(null);
        navigate(link);
    };

    return (
        <Box >
            <Box sx={{ paddingTop: '40px', paddingLeft: '0px', paddingRight: '0px' }}>
                <Box className={cx('talk-card')}>
                    <div className={cx('d-flex talkdesign1')}>
                        CO LUDZIE MÓWIĄ O PRODUKTACH #Dawid Lee- Zabawki
                    </div>
                    <div className={cx('d-flex talkdesign2')}>
                        Podziel się swoimi doświadczeniami z #Dawid Lee- Zabawki
                    </div>
                </Box>
            </Box>
            <Box className={cx('talk-mobile-card')}>
                <div className={cx('talkdesign1mobile')}>
                    CO LUDZIE MÓWIĄ O PRODUKTACH #Dawid Lee- Zabawki
                </div>
                <div className={cx('talkdesign2mobile')}>
                    Podziel się swoimi doświadczeniami z
                    <br></br>
                    #Dawid Lee- Zabawki
                </div>
            </Box>
        </Box>

    );
}
export default IconTalk;
