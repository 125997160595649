import MDialog from '@/components/Dialog';
import { Box, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import navbarApi from '@/api/navbarApi';
import UploadMultiple from '@/components/UploadImage/UploadMultiple';

function CreateEdit({ open, title, onClose, data, type, onDone }) {
    const [disableFeild, setDisableFeild] = useState(false);
    const [images, setImages] = useState([]);
    const handleUploadMultiple = imageList => {
        var imagePath = [];
        imageList.forEach(item => {
            imagePath.push(item.path);
        });
        setFormData({ ...formData, path: JSON.stringify(imagePath) });
    };
    const onSubmit = async () => {
        try {
            if (type === 'EDIT') {
                await navbarApi.updateBanner(formData.id, formData);
            }
            onClose();
            toast.success('Cập nhật banner thành công!');
            setErrorSubmit({});
            onDone();
        } catch (error) {
            const errors = error.response.data.errors;
            for (const key in errors) {
                errors[key] = errors[key].join('. ');
            }
            setErrorSubmit(errors);
        }
    };
    const [formData, setFormData] = useState({});
    const [errorSubmit, setErrorSubmit] = useState({});

    useEffect(() => {
        setFormData({});
        setDisableFeild(false);
        if (type === 'EDIT') {
            setFormData({ ...data });
            setDisableFeild(true);
            let path =  data.path;
            try {
                path = JSON.parse(data.path);
                setImages(path);
            } catch (error) {
                setImages([]);
            }
        }
    }, [type, data]);

    return (
        <div>
            <MDialog open={open} title={title} onClose={onClose} onSubmit={onSubmit} type={type}>
                <Box sx={{ width: '800px' }}>
                    <Box>
                        <UploadMultiple onDone={imageList => handleUploadMultiple(imageList)} list={images} />
                    </Box>
                </Box>
            </MDialog>
        </div>
    );
}
export default CreateEdit;
