import axiosClient from '@/utils/axiosClient';

class RegionApi {
    getProvince = () => {
        const url = '/provinces';
        return axiosClient.get(url);
    };
    getDistrict = params => {
        const url = '/districts';
        return axiosClient.get(url, { params });
    };
    getWard = params => {
        const url = '/wards';
        return axiosClient.get(url, { params });
    };
}
const regionApi = new RegionApi();
export default regionApi;
