import orderApi from '@/api/orderApi';
import { Box, Button, Divider } from '@mui/material';
import { useEffect, useState, Fragment, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import MTable from '@/components/Table';
import { PAYMENTS, ODER_STATUS } from '@/constants/order';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { ConfirmDialog } from '@/components/ConfirmDialog';
import styles from './order-buy.scss';
import classNames from 'classnames/bind';
import { toast } from 'react-toastify';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import images from '@/assets/images';
import MInput from '@/components/Input/MInput';
import IconButton from '@mui/material/IconButton';
import PrintIcon from '@mui/icons-material/Print';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import CreateEditMore from './CreateEditMore';

const cx = classNames.bind(styles);

function DetailOrder() {
    const [order, setOrder] = useState({});
    const { id: orderId } = useParams();
    const [paymentMethod, setPaymentMethod] = useState({});
    const [hotPrice, sethotPrice] = useState(0);
    const [tablePrice, setTablePrice] = useState([]);

    const getOrder = useCallback(async () => {
        const response = await orderApi.getDetailOrder(orderId);
        setTablePrice(response.data.products)
        setOrder(response.data ? response.data : {});
        const payment = PAYMENTS.find(item => item.id === response.data.payment);
        setPaymentMethod(payment);
    }, [orderId]);

    useEffect(() => {
        getOrder();

    }, [getOrder]);
    const OrderStatus = code => {
        try {
            const status = ODER_STATUS[code];
            return <Box sx={{ color: status.color, fontWeight: 'bold' }}>{status.name}</Box>;
        } catch (error) {
            return '';
        }
    };
    const handleUpdateItem = (id, key, value) => {
        switch (key) {
            case 'hotPrice':
                sethotPrice(value);
                var newProductCart = tablePrice.map(item => {
                    if (item.id === id) {
                        return { ...item, price: Number(value) };
                    }
                    return item;
                });
                setTablePrice(newProductCart);
                break;
            case 'quantity':
                sethotPrice(value);
                var newProductCart = tablePrice.map(item => {
                    if (item.id === id) {
                        return { ...item, quantity: Number(value) };
                    }
                    return item;
                });
                setTablePrice(newProductCart);
                break;
            default:
                break;
        }
    };
    const handleSubmitUpdate = async () => {
        try {
            const response_update = await orderApi.updateOrder(tablePrice);
            getOrder();
        } catch (error) {
        }
    };

    const goToLinkPrint = (link) => {
        const url = 'http://dl-zabawek.pl/api/inhoadon/' + link;
        window.open(url);
    };

    const onClose = () => setDialogData({ ...dialogData, open: false });
    const onOpenAdd = () => setDialogData({ ...dialogData, open: true, data: order, type: 'ADD' });

    const [dialogData, setDialogData] = useState({
        open: false,
        title: 'Thêm mới đơn hàng',
        onClose: onClose,
        onDone: getOrder,
    });


    const handleOrder = () => {
        const cancel = (
            <Button
                onClick={() => onClickActionButton('cancel')}
                color="error"
                startIcon={<CloseIcon />}
                variant="contained"
            >
                Huỷ đơn
            </Button>
        );
        const approve = (
            <Button
                onClick={() => onClickActionButton('approve')}
                color="primary"
                startIcon={<DoneIcon />}
                variant="contained"
            >
                Xác nhận đơn
            </Button>
        );
        const shiping = (
            <Button
                onClick={() => onClickActionButton('shiping')}
                color="primary"
                startIcon={<LocalShippingIcon />}
                variant="contained"
            >
                Bàn giao vận chuyển
            </Button>
        );
        const done = (
            <Button
                onClick={() => onClickActionButton('done')}
                color="success"
                startIcon={<DoneAllIcon />}
                variant="contained"
            >
                Hoàn thành đơn
            </Button>
        );
        const fail = (
            <Button
                onClick={() => onClickActionButton('fail')}
                color="error"
                startIcon={<WarningAmberIcon />}
                variant="contained"
            >
                Khách không nhận hàng
            </Button>
        );
        if (order.status === 'SHOP_CANCEL') {
            return <Box>{approve}</Box>;
        }
        if (order.status === 'PENDING') {
            return (
                <Box>
                    {cancel} {approve}
                </Box>
            );
        }
        if (order.status === 'APPROVED') {
            return (
                <Box>
                    {cancel} {shiping}
                </Box>
            );
        }
        if (order.status === 'SHIPPING') {
            return (
                <Box>
                    {done} {fail}
                </Box>
            );
        }
        return '';
    };
    const onClickActionButton = actionName => {
        let title = '';
        let body = '';
        let action = null;
        if (actionName === 'cancel') {
            title = 'Xác nhận huỷ đơn';
            action = orderApi.cancelOrder;
            body = 'Bạn có chắc chắn muốn huỷ đơn hàng này không ? Hành động này không thể hoàn tác';
        } else if (actionName === 'approve') {
            title = 'Xác nhận đơn hàng';
            action = orderApi.approveOrder;
            body = 'Bạn có chắc chắn muốn xác nhận đơn hàng này không ?';
        } else if (actionName === 'shiping') {
            action = orderApi.shipOrder;
            title = 'Xác nhận bàn giao vận chuyển';
            body = 'Đơn hàng của bạn đã bàn giao cho đơn vị vận chuyển ?';
        } else if (actionName === 'done') {
            action = orderApi.finishOrder;
            title = 'Hoàn thành đơn';
            body = 'Đơn hàng của bạn giao đến khách hàng?';
        } else if (actionName === 'fail') {
            action = orderApi.failOrder;
            title = 'Đơn hàng không hoàn thành';
            body = 'Đơn hàng của bạn không hoàn thành do khách không nhận hàng?';
        } else return;
        ConfirmDialog({
            title,
            html: true,
            body,
            action: async onClose => {
                await action(orderId);
                onClose();
                toast.success('Powodzenie!');
                getOrder();
            },
        });
    };
    return (
        <Box sx={{ padding: '20px' }}>
            <CreateEditMore {...dialogData} />
            <Box sx={{ mb: 4 }} className="d-flex justify-space-between align-center">
                <Box sx={{ fontSize: '24px' }}>Thông tin đơn hàng</Box>
                <IconButton style={{ backgroundColor: '#e9ff4e', boxShadow: '2px 1px 4px 0px green' }} aria-label="hide" color="success" >
                    <AddShoppingCartIcon onClick={onOpenAdd} fontSize="large" />
                </IconButton>
                <IconButton aria-label="hide" color="success" onClick={() => goToLinkPrint(order.id)}>
                    <PrintIcon variant="contained" fontSize="large" />
                </IconButton>
            </Box>
            <Box className="d-flex justify-space-between" sx={{ fontSize: '20px', mt: 2 }}>
                <Box>
                    Mã đơn hàng: <b>#{order.code}</b>
                </Box>
                <Box>
                    Thời gian: <b>{order.created_at}</b>
                </Box>
            </Box>
            <Box className="d-flex justify-space-between" sx={{ fontSize: '20px', mt: 2 }}>
                <Box className="d-flex">
                    <Box sx={{ mr: 1 }}>Trạng thái đơn hàng:</Box> {OrderStatus(order.status)}
                </Box>
                <Box>{handleOrder()}</Box>
            </Box>
            <Box sx={{ mt: 3 }} className={cx('box-content-order')}>
                <Box className="d-flex justify-space-between">
                    <Box sx={{ fontSize: '20px', mb: 1, fontWeight: 'bold' }}>Sản phẩm đặt mua</Box>
                    <Box sx={{ fontWeight: 'bold' }}>
                        {order.payed ? (
                            <Box sx={{ color: '#196F3D' }}>ĐÃ THANH TOÁN</Box>
                        ) : (
                            <Box sx={{ color: '#DC192E' }}>CHƯA THANH TOÁN</Box>
                        )}
                    </Box>
                </Box>
                <Box>
                    <MTable data={tablePrice} loading={false} countPage={1} onChangePage={() => { }}>
                        {item => (
                            <Fragment>
                                <div property="product_name" label="Hình ảnh"><Box className="d-flex align-cener">
                                    <Box sx={{ width: '80px' }}>
                                        <img
                                            alt="san-pham"
                                            src={item.image ? item.image : images.noPhoto}
                                            style={{ width: item.image ? '100%' : '90%' }}
                                        />
                                    </Box>
                                    <Box sx={{ ml: 1, pt: 2, fontWeight: 'bold' }}>{item.name}</Box>
                                </Box>
                                </div>
                                <div label="Tên sản phẩm">
                                    {item.product_name}
                                    (#{
                                        item.buy_product ? (item.buy_product.sku_code) : ('0000')
                                    })

                                </div>
                                <div label="Số lượng" sx={{ width: '180px', minWidth: '100px' }}>
                                    <MInput
                                        variant="outlined"
                                        value={item.quantity}
                                        onChange={e => handleUpdateItem(item.id, 'quantity', e.target.value)}
                                        size="small"
                                        type="number"
                                        min="0"
                                    />
                                </div>
                                <div label="giá thương lượng" sx={{ width: '180px', minWidth: '100px' }}>
                                    <MInput
                                        variant="outlined"
                                        value={item.price}
                                        onChange={e => handleUpdateItem(item.id, 'hotPrice', e.target.value)}
                                        size="small"
                                        type="number"
                                        min="0"
                                    />
                                </div>
                                <div label="Kho" property="kho_van_chuyen">
                                </div>
                                <div label="Thành tiền">
                                    {parseFloat(item.price * item.quantity).toLocaleString('de-DE')} zł
                                </div>
                                <div label="Cập nhật">
                                    <Button
                                        onClick={() => handleSubmitUpdate()}
                                        color="success"
                                        startIcon={<DoneIcon />}
                                        variant="contained"
                                    >
                                        Cập nhật
                                    </Button>
                                </div>
                            </Fragment>
                        )}
                    </MTable>
                    <Box className="d-flex justify-space-between" sx={{ mt: 2, fontSize: '17px' }}>
                        <Box>Mã giảm giá</Box>
                        <Box></Box>
                    </Box>
                    <Divider sx={{ mt: 2, mb: 2 }} />
                    <Box className="d-flex justify-space-between" sx={{ fontSize: '17px' }}>
                        <Box>Tổng giá trị sản phẩm(trước thuế)</Box>
                        <Box>{parseFloat(order.total_money / 1.23).toLocaleString('de-DE')} zł</Box>
                    </Box>
                    <Box className="d-flex justify-space-between" sx={{ fontSize: '17px' }}>
                        <Box>Tổng giá trị sản phẩm(sau thuế)</Box>
                        <Box>{parseFloat(order.total_money).toLocaleString('de-DE')} zł</Box>
                    </Box>
                    <Divider sx={{ mt: 2, mb: 2 }} />

                    <Box className="d-flex justify-space-between" sx={{ fontSize: '17px' }}>
                        <Box>Tổng khuyến mãi</Box>
                        <Box>{parseFloat(order.discount_money).toLocaleString('de-DE')} zł</Box>
                    </Box>
                    <Divider sx={{ mt: 2, mb: 2 }} />
                    <Box className="d-flex justify-space-between" sx={{ fontSize: '17px' }}>
                        <Box>Phí giao hàng</Box>
                        <Box>{parseFloat(order.fee_ship).toLocaleString('de-DE')} zł</Box>
                    </Box>
                    <Divider sx={{ mt: 2, mb: 2 }} />
                    <Box className="d-flex justify-space-between" sx={{ fontSize: '17px', fontWeight: 'bold' }}>
                        <Box>Tổng thanh toán</Box>
                        <Box>{parseFloat(order.total_pay).toLocaleString('de-DE')} zł</Box>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ fontSize: '20px', mb: 1, fontWeight: 'bold', mt: 4 }}>Thông tin nhận hàng</Box>
            <Box sx={{ fontSize: '18px' }} className={cx('box-content-order')}>
                <Box sx={{ mb: 1 }}>Tên người nhận: {order.name}</Box>
                <Box sx={{ mb: 1 }}>Email: {order.email}</Box>
                <Box sx={{ mb: 1 }}>Số điện thoại: {order.phone}</Box>
                <Box sx={{ mb: 1 }}>
                    Hình thức thanh toán: {paymentMethod.title} - {paymentMethod.body}
                </Box>
                <Box>Địa chỉ nhận hàng: {order.address}</Box>
                <Box>
                    {order?.province}, {order?.district}, {order?.ward}
                </Box>
            </Box>
        </Box>
    );
}
export default DetailOrder;
