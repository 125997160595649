import axiosClient from '@/utils/axiosClient';

class PromotionApi {
    getPromotion = (params) => {
        const url = '/promotion';
        return axiosClient.get(url, { params });
    };
    getPromotion50 = (params) => {
        const url = '/promotion50';
        return axiosClient.get(url, { params });
    };

    getHomeCollection = (params) => {
        const url = '/home-collection';
        return axiosClient.get(url, { params });
    };

    getPromotionDetail = (id) => {
        const url = `/getpromotiondetail/${id}`;
        return axiosClient.get(url);
    };

    createPromotion = data => {
        const url = '/promotion';
        return axiosClient.post(url, data);
    };
    updatePromotion = (id, data) => {
        const url = `/promotion/${id}`;
        return axiosClient.put(url, data);
    };
    deletePromotion = (id) => {
        const url = `/promotion/${id}`;
        return axiosClient.delete(url);
    };
    showPromotion = (id) => {
        const url = `/showpromotion/${id}`;
        return axiosClient.put(url);
    };
    hidePromotion = (id) => {
        const url = `/hidepromotion/${id}`;
        return axiosClient.put(url);
    };    
}
const promotionApi = new PromotionApi();
export default promotionApi;
