import { Box, MenuItem, Popper } from '@mui/material';
import { useState, useEffect } from 'react';
import classNames from 'classnames/bind';
import styles from './header.module.scss';
import images from '@/assets/images';
import { Navigate } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const cx = classNames.bind(styles);
function OnyouAbout(props) {
    const [anchorCustormer, setAnchorCustomer] = useState(null);
    const [anchorOnyouUNU, setAnchorOnyouUNU] = useState(null);
    const [anchorOnyouCUS, setAnchorOnyouCUS] = useState(null);
    const [anchorOnyouNEW, setAnchorOnyouNEW] = useState(null);
    const [anchorOnyouearly, setAnchorOnyouearly] = useState(null);
    const [anchorOnyouTeam, setAnchorOnyouTeam] = useState(1);
    const [anchorEl, setAnchorEl] = useState(null);

    const onChangeChildStatus = props.onChangeChildStatus;

    const handleMenu = (event, type) => {
        switch (type) {
            case 'onyouUNU':
                setAnchorCustomer(null);
                setAnchorOnyouCUS(null);
                setAnchorOnyouNEW(null);
                setAnchorOnyouTeam(1)
                onChangeChildStatus(anchorOnyouTeam, anchorOnyouTeam);
                if (anchorOnyouUNU !== event.currentTarget) {
                    setAnchorOnyouUNU(event.currentTarget);
                    setAnchorOnyouearly(event.currentTarget);
                }
                break;
            case 'onyouCUS':
                setAnchorCustomer(null);
                setAnchorOnyouUNU(null);
                setAnchorOnyouNEW(null);
                setAnchorOnyouTeam(1)

                if (anchorOnyouUNU !== event.currentTarget) {
                    setAnchorOnyouCUS(event.currentTarget);
                    setAnchorOnyouearly(event.currentTarget);
                }
                break;
            case 'onyouNEW':
                setAnchorOnyouTeam(1)
                setAnchorCustomer(null);
                setAnchorOnyouCUS(null);
                setAnchorOnyouUNU(null);

                if (anchorOnyouUNU !== event.currentTarget) {
                    setAnchorOnyouNEW(event.currentTarget);
                    setAnchorOnyouearly(event.currentTarget);
                }
                break;

            default:
        }
    };
    const handleCloseMenu = () => {
        setAnchorCustomer(null);
        setAnchorOnyouUNU(null);
        setAnchorOnyouCUS(null);
        setAnchorOnyouNEW(null);
        setAnchorOnyouearly(null);
        setAnchorOnyouTeam(0);
    };
    const handleCloseSubMenu = () => {
        if (anchorOnyouTeam == 0) {
            onChangeChildStatus(anchorOnyouTeam, anchorOnyouTeam);
        }
    }
    const handleOpenSubMenu = () => {
        onChangeChildStatus(1, 1);
    }
    const goToLink = link => {
        setAnchorEl(null);
        Navigate(link);
    };
    const [subOnyou, setSubOnyou] = useState([
        {
            id: 1,
            name: 'Historia biznesowa',
            url_image: '/storage/files/Onyoustore.png',
            code: 've-chung-toi',
        },

        {
            id: 2,
            name: 'Zaangażowanie w jakość',
            url_image: '/storage/files/UNUstore.png',
            code: 've-chung-toi',
        },
    ]);

    const [subOnyouCUS, setSubOnyouCUS] = useState([
        {
            id: 1,
            name: 'Polityka bezpieczeństwa informacji',
            url_image: '/storage/files/onyouBack.png',
            code: 've-chung-toi',
        },

        {
            id: 2,
            name: 'Zasady składania zamówień i dostaw',
            url_image: '/storage/files/onyouTrans.png',
            code: 've-chung-toi',
        },
        {
            id: 3,
            name: 'Zasady płatności',
            url_image: '/storage/files/onyouPay.png',
            code: 've-chung-toi',
        },
        {
            id: 4,
            name: '30-dniowa polityka zwrotów i zwrotów pieniędzy',
            url_image: '/storage/files/onyouBack.png',
            code: 've-chung-toi',
        },
        {
            id: 5,
            name: 'Polityka kontroli towarów',
            url_image: '/storage/files/onyouBack.png',
            code: 've-chung-toi',
        },
        {
            id: 6,
            name: 'Ogólne warunki handlowe',
            url_image: '/storage/files/onyouBack.png',
            code: 've-chung-toi',
        },
    ]);

    const [subOnyouNEW, setSubOnyouNEW] = useState([
        {
            id: 1,
            name: 'Aktualności',
            url_image: '/storage/files/onyouNEW.png',
            code: 've-chung-toi',
        },

        {
            id: 2,
            name: 'Rekrutacja',
            url_image: '/storage/files/onyouHR.png',
            code: 've-chung-toi',
        },
    ]);

    useEffect(() => {
        handleCloseSubMenu();
    }, [anchorOnyouTeam]);

    return (
        <div style={{ width: '100%', zIndex: 990 }}>
            <Popper
                anchorEl={anchorOnyouUNU}
                open={Boolean(anchorOnyouUNU)}
                onMouseLeave={handleCloseMenu}
                MenuListProps={{ onMouseLeave: handleCloseMenu }}
            >
                <div
                    style={{
                        width: '100vw',
                        paddingBottom: '50px',
                        paddingTop: '50px',
                        marginTop: '11px',
                        background: 'black',
                        borderStyle: 'solid',
                        borderColor: '#e08282',
                        fontFamily: 'MyFont',
                        zIndex: '990',
                        position: 'relative'
                    }}
                    className="d-flex justify-center"
                >
                    <div style={{ width: '300px' }}>
                        {subOnyou
                            ? subOnyou.map(item => (
                                <MenuItem
                                    key={item.id}
                                    onClick={() => goToLink(`/${item.code}`)}
                                    className={cx('menu-item')}
                                >
                                    {item.name}
                                </MenuItem>
                            ))
                            : null}
                    </div>
                    <div className="d-flex" style={{ paddingTop: '10px' }}>
                        {subOnyou
                            ? subOnyou.map((item, index) => {
                                if (index < 3) {
                                    return (
                                        <Box className={cx('menu-item-image-box')}>
                                            <img
                                                onClick={() => goToLink(`/${item.code}`)}
                                                src={'http://dl-zabawek.pl/' + item.url_image}
                                                alt="a"
                                                className={cx('menu-item-image')}
                                                style={{
                                                    width: '190px',
                                                    height: '190px',
                                                    marginRight: '20px',
                                                    cursor: 'pointer',
                                                    borderRadius: '10px',
                                                }}
                                            />
                                        </Box>
                                    );
                                }
                                return null;
                            })
                            : null}
                    </div>
                </div>
            </Popper>
            <Popper
                anchorEl={anchorOnyouCUS}
                open={Boolean(anchorOnyouCUS)}
                onMouseLeave={handleCloseMenu}
                MenuListProps={{ onMouseLeave: handleCloseMenu }}
            >
                <div
                    style={{
                        width: '100vw',
                        marginTop: '11px',
                        paddingBottom: '50px',
                        paddingTop: '50px',
                        background: 'black',
                        borderStyle: 'solid',
                        borderColor: '#e08282',
                        fontFamily: 'MyFont'
                    }}
                    className="d-flex justify-center"
                >
                    <div style={{ width: '350px' }}>
                        {subOnyouCUS
                            ? subOnyouCUS.map(item => (
                                <MenuItem
                                    key={item.id}
                                    onClick={() => goToLink(`/${item.code}`)}
                                    className={cx('menu-item')}
                                >
                                    {item.name}
                                </MenuItem>
                            ))
                            : null}
                    </div>
                    <div className="d-flex" style={{ paddingTop: '0px' }}>
                        {subOnyouCUS
                            ? subOnyouCUS.map((item, index) => {
                                if (index < 3) {
                                    return (
                                        <Box className={cx('menu-item-image-box')}>
                                            <img
                                                onClick={() => goToLink(`/${item.code}`)}
                                                src={'http://dl-zabawek.pl/' + item.url_image}
                                                alt="a"
                                                className={cx('menu-item-image')}
                                                style={{
                                                    width: '190px',
                                                    height: '190px',
                                                    marginRight: '20px',
                                                    cursor: 'pointer',
                                                    borderRadius: '10px',
                                                }}
                                            />
                                        </Box>
                                    );
                                }
                                return null;
                            })
                            : null}
                    </div>
                </div>
            </Popper>
            <Popper
                anchorEl={anchorOnyouNEW}
                open={Boolean(anchorOnyouNEW)}
                onMouseLeave={handleCloseMenu}
                MenuListProps={{ onMouseLeave: handleCloseMenu }}
            >
                <div
                    style={{
                        width: '100vw',
                        marginTop: '11px',
                        paddingBottom: '50px',
                        paddingTop: '50px',
                        background: 'black',
                        borderStyle: 'solid',
                        borderColor: '#e08282',
                        fontFamily: 'MyFont'
                    }}
                    className="d-flex justify-center"
                >
                    <div style={{ width: '300px' }}>
                        {subOnyouNEW
                            ? subOnyouNEW.map(item => (
                                <MenuItem
                                    key={item.id}
                                    onClick={() => goToLink(`/${item.code}`)}
                                    className={cx('menu-item')}
                                >
                                    {item.name}
                                </MenuItem>
                            ))
                            : null}
                    </div>
                    <div className="d-flex" style={{ paddingTop: '0px' }}>
                        {subOnyouNEW
                            ? subOnyouNEW.map((item, index) => {
                                if (index < 3) {
                                    return (
                                        <Box className={cx('menu-item-image-box')}>
                                            <img
                                                onClick={() => goToLink(`/${item.code}`)}
                                                src={'http://dl-zabawek.pl/' + item.url_image}
                                                alt="a"
                                                className={cx('menu-item-image')}
                                                style={{
                                                    width: '190px',
                                                    height: '190px',
                                                    marginRight: '20px',
                                                    cursor: 'pointer',
                                                    borderRadius: '10px',
                                                }}
                                            />
                                        </Box>
                                    );
                                }
                                return null;
                            })
                            : null}
                    </div>
                </div>
            </Popper>
            <Box onMouseLeave={handleCloseSubMenu} onMouseOver={handleOpenSubMenu} anchorEl={anchorOnyouearly} open={Boolean(anchorOnyouearly)}>
                <div
                    style={{ width: '100%', height: '70px', background: '#DDDDDD', fontSize: '15px !important', fontFamily: 'MyFont' }}
                    className="d-flex justify-center align-center"
                >
                    <div className={cx('top-sub-header')} style={{ width: 'auto' }}>
                        <MenuItem onMouseOver={e => handleMenu(e, 'onyouUNU')} style={{ color: 'black' }}>
                            <b>Polityka prywatności</b>
                            <ExpandMoreIcon sx={{ color: 'black' }} />
                        </MenuItem>
                    </div>
                    <div className={cx('top-sub-header')} style={{ width: 'auto' }}>
                        <MenuItem onMouseOver={e => handleMenu(e, 'onyouCUS')} style={{ color: 'black' }}>
                            <b>Jak zamawiać</b>
                            <ExpandMoreIcon sx={{ color: 'black' }} />
                        </MenuItem>
                    </div>
                    <div className={cx('top-sub-header')} style={{ width: 'auto' }}>
                        <MenuItem onMouseOver={e => handleMenu(e, 'onyouNEW')} style={{ color: 'black' }}>
                            <b>Informacje o sklepie</b>
                            <ExpandMoreIcon sx={{ color: 'black' }} />
                        </MenuItem>
                    </div>
                </div>
            </Box>
        </div>
    );
}
export default OnyouAbout;
