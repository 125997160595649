import { v4 as uuidv4 } from 'uuid';
import images from '@/assets/images';

const iconPrint = [
    {
        id: 1,
        link: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b9/Marvel_Logo.svg/2560px-Marvel_Logo.svg.png',
    },
    {
        id: 2,
        link: 'https://upload.wikimedia.org/wikipedia/vi/thumb/d/d3/Starbucks_Corporation_Logo_2011.svg/1200px-Starbucks_Corporation_Logo_2011.svg.png',
    },
    {
        id: 3,
        link: 'https://www.thietkewebchuanseo.com/images/thiet-ke-logo-game-4842.jpg',
    },
    {
        id: 4,
        link: 'https://images2.thanhnien.vn/Uploaded/tuyenth/2022_04_11/2-7909.png'
    },
    {
        id: 5,
        link: 'https://www.shutterstock.com/image-vector/blue-ornamental-round-decorative-circle-260nw-1484300888.jpg'
    }
];
const stickyIcon = [
    {
        id: 1,
        link: 'https://res.cloudinary.com/dsobei3hp/image/upload/v1675830967/Shop_fashion/sticky/47_cmzefo.png',
    },
    {
        id: 2,
        link: 'https://res.cloudinary.com/dsobei3hp/image/upload/v1675830967/Shop_fashion/sticky/49_krmati.png',
    },
    {
        id: 3,
        link: 'https://res.cloudinary.com/dsobei3hp/image/upload/v1675830967/Shop_fashion/sticky/48_ieyctg.png',
    },
    {
        id: uuidv4(),
        link: 'https://res.cloudinary.com/dsobei3hp/image/upload/v1675830967/Shop_fashion/sticky/46b_pqith9.png',
    },
    {
        id: 4,
        link: 'https://res.cloudinary.com/dsobei3hp/image/upload/v1675830883/Shop_fashion/sticky/52_qyf8jj.png',
    },
    {
        id: 5,
        link: 'https://res.cloudinary.com/dsobei3hp/image/upload/v1675830882/Shop_fashion/sticky/51_fxo71e.png',
    },
    {
        id: 6,
        link: 'https://res.cloudinary.com/dsobei3hp/image/upload/v1675830882/Shop_fashion/sticky/45_gducsp.png',
    },
    {
        id: 7,
        link: 'https://res.cloudinary.com/dsobei3hp/image/upload/v1675830882/Shop_fashion/sticky/44_bn3uo0.png',
    },
    {
        id: 8,
        link: 'https://res.cloudinary.com/dsobei3hp/image/upload/v1675830882/Shop_fashion/sticky/50g_dvkqnh.png',
    },
    {
        id: 9,
        link: 'https://res.cloudinary.com/dsobei3hp/image/upload/v1675830882/Shop_fashion/sticky/46_aq5ozc.png',
    },
];
const productList = [
    {
        id: 1,
        name: 'Áo Phông',
        avatar: images.beTruoc,
        colors: [
            {
                id: 1,
                name: 'Trắng',
                color: '#E5E7E9',
                images: [
                    {
                        id: 1,
                        link: images.trangTruoc,
                        css: {
                            backgroundSize: '100% auto',
                            backgroundRepeat: 'no-repeat',
                        },
                        cssKhung: {
                            width: '700px',
                            height: '590px',
                            border: '1px solid blue',
                            position: 'relative',
                            left: '0px',
                            top: '140px',
                        },
                        name: 'Mặt trước',
                    },
                    {
                        id: 2,
                        link: images.trangSau,
                        css: {
                            backgroundSize: '100% auto',
                            backgroundRepeat: 'no-repeat',
                        },
                        cssKhung: {
                            width: '700px',
                            height: '590px',
                            border: '1px solid blue',
                            position: 'relative',
                            left: '0px',
                            top: '140px',
                        },
                        name: 'Mặt Sau',
                    },
                    {
                        id: 3,
                        link: images.trangTrai,
                        css: {
                            backgroundSize: '100% auto',
                            backgroundRepeat: 'no-repeat',
                        },
                        cssKhung: {
                            width: '120px',
                            height: '200px',
                            border: '1px solid blue',
                            position: 'relative',
                            left: '305px',
                            top: '220px',
                        },
                        name: 'Vai Trái',
                    },
                    {
                        id: 4,
                        link: images.trangPhai,
                        css: {
                            backgroundSize: '100% auto',
                            backgroundRepeat: 'no-repeat',
                        },
                        cssKhung: {
                            width: '120px',
                            height: '200px',
                            border: '1px solid blue',
                            position: 'relative',
                            left: '280px',
                            top: '220px',
                        },
                        name: 'Vai Phải',
                    },
                ],
            },
            {
                id: 2,
                name: 'Đen',
                color: '#1C2833',
                images: [
                    {
                        id: 5,
                        link: images.denTruoc,
                        css: {
                            backgroundSize: '100% auto',
                            backgroundRepeat: 'no-repeat',
                        },
                        cssKhung: {
                            width: '700px',
                            height: '590px',
                            border: '1px solid blue',
                            position: 'relative',
                            left: '0px',
                            top: '140px',
                        },
                        name: 'Mặt trước',
                    },
                    {
                        id: 6,
                        link: images.denSau,
                        css: {
                            backgroundSize: '100% auto',
                            backgroundRepeat: 'no-repeat',
                        },
                        cssKhung: {
                            width: '700px',
                            height: '590px',
                            border: '1px solid blue',
                            position: 'relative',
                            left: '0px',
                            top: '140px',
                        },
                        name: 'Mặt Sau',
                    },
                    {
                        id: 7,
                        link: images.denTrai,
                        css: {
                            backgroundSize: '100% auto',
                            backgroundRepeat: 'no-repeat',
                        },
                        cssKhung: {
                            width: '120px',
                            height: '200px',
                            border: '1px solid blue',
                            position: 'relative',
                            left: '305px',
                            top: '220px',
                        },
                        name: 'Vai Trái',
                    },
                    {
                        id: 8,
                        link: images.denPhai,
                        css: {
                            backgroundSize: '100% auto',
                            backgroundRepeat: 'no-repeat',
                        },
                        cssKhung: {
                            width: '120px',
                            height: '200px',
                            border: '1px solid blue',
                            position: 'relative',
                            left: '280px',
                            top: '220px',
                        },
                        name: 'Vai Phải',
                    },
                ],
            },
            {
                id: 3,
                name: 'Be',
                color: '#e8ded4',
                images: [
                    {
                        id: 9,
                        link: images.beTruoc,
                        css: {
                            backgroundSize: '100% auto',
                            backgroundRepeat: 'no-repeat',
                        },
                        cssKhung: {
                            width: '700px',
                            height: '590px',
                            border: '1px solid blue',
                            position: 'relative',
                            left: '0px',
                            top: '140px',
                        },
                        name: 'Mặt trước',
                    },
                    {
                        id: 10,
                        link: images.beSau,
                        css: {
                            backgroundSize: '100% auto',
                            backgroundRepeat: 'no-repeat',
                        },
                        cssKhung: {
                            width: '700px',
                            height: '590px',
                            border: '1px solid blue',
                            position: 'relative',
                            left: '0px',
                            top: '140px',
                        },
                        name: 'Mặt Sau',
                    },
                    {
                        id: 11,
                        link: images.beTrai,
                        css: {
                            backgroundSize: '100% auto',
                            backgroundRepeat: 'no-repeat',
                        },
                        cssKhung: {
                            width: '120px',
                            height: '200px',
                            border: '1px solid blue',
                            position: 'relative',
                            left: '305px',
                            top: '220px',
                        },
                        name: 'Vai Trái',
                    },
                    {
                        id: 12,
                        link: images.bePhai,
                        css: {
                            backgroundSize: '100% auto',
                            backgroundRepeat: 'no-repeat',
                        },
                        cssKhung: {
                            width: '120px',
                            height: '200px',
                            border: '1px solid blue',
                            position: 'relative',
                            left: '280px',
                            top: '220px',
                        },
                        name: 'Vai Phải',
                    },
                ],
            },
            {
                id: 4,
                name: 'Nâu',
                color: '#795548',
                images: [
                    {
                        id: 13,
                        link: images.nauTruoc,
                        css: {
                            backgroundSize: '100% auto',
                            backgroundRepeat: 'no-repeat',
                        },
                        cssKhung: {
                            width: '700px',
                            height: '590px',
                            border: '1px solid blue',
                            position: 'relative',
                            left: '0px',
                            top: '140px',
                        },
                        name: 'Mặt trước',
                    },
                    {
                        id: 14,
                        link: images.nauSau,
                        css: {
                            backgroundSize: '100% auto',
                            backgroundRepeat: 'no-repeat',
                        },
                        cssKhung: {
                            width: '700px',
                            height: '590px',
                            border: '1px solid blue',
                            position: 'relative',
                            left: '0px',
                            top: '140px',
                        },
                        name: 'Mặt Sau',
                    },
                    {
                        id: 15,
                        link: images.nauTrai,
                        css: {
                            backgroundSize: '100% auto',
                            backgroundRepeat: 'no-repeat',
                        },
                        cssKhung: {
                            width: '120px',
                            height: '200px',
                            border: '1px solid blue',
                            position: 'relative',
                            left: '305px',
                            top: '220px',
                        },
                        name: 'Vai Trái',
                    },
                    {
                        id: 16,
                        link: images.nauPhai,
                        css: {
                            backgroundSize: '100% auto',
                            backgroundRepeat: 'no-repeat',
                        },
                        cssKhung: {
                            width: '120px',
                            height: '200px',
                            border: '1px solid blue',
                            position: 'relative',
                            left: '280px',
                            top: '220px',
                        },
                        name: 'Vai Phải',
                    },
                ],
            },
        ],
    }
];

export { iconPrint, stickyIcon, productList };
