import { Box } from '@mui/material';
import classNames from 'classnames/bind';
import styles from './about.module.scss';
const cx = classNames.bind(styles);
function OnYouStory() {
    return (
        <Box className="d-flex justify-center bg-gray">
            <div className={cx('container-about', 'width-container')} style={{ fontSize: '18px' }}>
                <Box sx={{ fontSize: '24px', fontWeight: 'bold', fontStyle: 'italic' }}>
                    OnYou - Vùng đất tự do sáng tạo và trải nghiệm!
                </Box>
                <Box sx={{ mt: 3 }}>
                    <b>OnYou</b> được thành lập bởi nhóm người yêu mến sự tự do và sáng tạo nghệ thuật, OnYou mong muốn
                    tạo ra những điều đặc biệt và khác lạ trong cuộc sống.
                </Box>
                <Box sx={{ mt: 1 }}>
                    Đến với OnYou, bạn có thể tự do thể hiện suy nghĩ, cảm xúc và tự mình trải nghiệm thông qua sản phẩm
                    sáng tạo mà không bị giới hạn bởi bất kỳ quy tắc nào. OnYou cho phép mọi người trở thành những nghệ
                    sĩ của riêng mình và biểu diễn thông qua những tác phẩm độc nhất. Đây cũng là nơi tuyệt vời giúp bạn
                    thư giãn và giải tỏa căng thẳng bằng việc tập trung vào những tác phẩm đầy cảm hứng.
                </Box>
                <Box sx={{ mt: 2, fontWeight: 'bold', fontStyle: 'italic' }}>Tôn trọng sự sáng tạo của khách hàng:</Box>
                <Box sx={{ mt: 1 }}>
                    OnYou tôn trọng mọi sự sáng tạo đến từ khách hàng. Với quan niệm thời trang là không giới hạn, sản
                    phẩm bạn tạo ra sẽ là duy nhất và mang đậm màu sắc phong cách cá nhân.
                </Box>
                <Box sx={{ mt: 2, fontWeight: 'bold', fontStyle: 'italic' }}>
                    Trải nghiệm khách hàng là ưu tiên hàng đầu của chúng tôi!
                </Box>
                <Box sx={{ mt: 1 }}>
                    Bên cạnh việc tập trung vào chất lượng sản phẩm từ chất liệu vải đến đường may, điều mà OnYou thực
                    sự hướng đến là những trải nghiệm mới lạ và tuyệt vời của khách hàng thông qua việc để họ tự tay
                    thiết kế hình ảnh in ấn, thêu may, gắn nhãn dán và hình 3D trên sản phẩm.
                </Box>
                <Box sx={{ mt: 1 }}>
                    Thế giới thời trang đang chờ đợi những tác phẩm độc đáo đầy cá tính và mang thương hiệu cá nhân của
                    bạn đó. Cùng trải nghiệm với <b>OnYou</b> nhé!
                </Box>
            </div>
        </Box>
    );
}
export default OnYouStory;
