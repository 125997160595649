import MDialog from '@/components/Dialog';
import { Box, Divider, TextField, IconButton } from '@mui/material';
import { useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import flashsaleApi from '@/api/flashsaleApi';
import productApi from '@/api/productApi';
import Autocomplete from '@mui/material/Autocomplete';
import { debounce } from 'lodash';
import classNames from 'classnames/bind';
import styles from './cart.scss';
import Grid from '@mui/material/Grid';
import Cookies from 'js-cookie';
import MInput from '@/components/Input/MInput';
import { useSelector } from 'react-redux';
import { CART_PRODUCT, INFOR_CART } from '@/constants/product';
import CartPayment from './Payment';
import orderApi from '@/api/orderApi';
import { useDispatch } from 'react-redux';
import { removeAllItem } from '@/store/cart/cartSlice';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import ProductItem from './ProductOrderItem';

const cx = classNames.bind(styles);

function CreateEdit({ open, title, onClose, data, type, onDone, total }) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [disableFeild, setDisableFeild] = useState(false)
    const [typeFlashsale, setTypeFlashsale] = useState(null)
    const [province, setProvince] = useState(null);
    const [districts, setDistricts] = useState([]);
    const [district, setDistrict] = useState(null);
    const cartData = useSelector(state => state.cart.cartData);
    const [provisionalMoney, setProvisionalMoney] = useState(0);
    const [costMoney, setCostMoney] = useState(40);
    const [provinces, setProvinces] = useState([]);
    const [voucher, setVoucher] = useState('');
    const [voucherMessage, setVoucherMessage] = useState('');
    const [discount, setDiscount] = useState(0);
    const [wards, setWards] = useState([]);
    const [ward, setWard] = useState(null);
    const [totalMoney, setTotalMoney] = useState(0);
    const [arrayProduct, setArrayProduct] = useState([]);


    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [form, setForm] = useState(
        []
    );
    const me = useSelector(state => state.auth.me);
    const handleChangForm = (key, value) => {
        setForm({ ...form, [key]: value });
    };
    const getVoucher = async () => {
        const data = {
            products: products.map(item => ({
                product_id: item.id,
                color: item.color,
                size: item.size,
                quantity: Number(item.quantity),
            })),
            voucher: voucher,
        };
        let response = await orderApi.applyVoucher(data);
        setTotalMoney(totalMoney - response.discount);
        setDiscount(response.discount);
        setVoucherMessage(response.message);
    };
    const handleSubmit = async payment => {
        try {
            setLoadingSubmit(true);
            const formSubmit = {
                ...form,
                payment: payment,
                voucher: voucher,
                ward_code: ward ? ward.code : null,
                district_code: district ? district.code : null,
                province_code: province ? province.code : null,
                products: products.map(item => ({
                    product_id: item.id,
                    color: item.color,
                    size: item.size,
                    flash_price: item.flash_price,
                    quantity: Number(item.quantity),
                })),
            };
            if (payment === 'onepay') {
                const url = await orderApi.onePayOrder(formSubmit);
                window.location.assign(url);
            } else {
                const respon = await orderApi.createOrder(formSubmit);
                toast.success('Powodzenie!');
                dispatch(removeAllItem());
                form.province = province;
                form.district = district;
                form.ward = ward;
                Cookies.set(INFOR_CART, JSON.stringify(form), { expires: 365 });
                setForm({});
                setProvince(null);
                setDistrict(null);
                setWard(null);
                navigate(`/tra-cuu-don/${respon.order_code}`);
            }
            setLoadingSubmit(false);
        } catch (error) {
            const errors = error.response.data.errors;
            for (const key in errors) {
                errors[key] = errors[key].join('. ');
            }
            setErrorSubmit(errors);
            setLoadingSubmit(false);
        }
    };


    const onSubmit = async () => {
        // check thong tin da dien
        if (formData.products === null || formData.code === null || formData.name === null || formData.start_at === null || formData.end_at === null) {
            toast.error('Kiểm tra thông tin đầu vào!');
        }
        try {
            if (type === 'ADD') {
                formData.products = [...productSelected];
                await flashsaleApi.createFlashsale(formData);
            }
            if (type === 'EDIT') {
                formData.products = [...productSelected];
                await flashsaleApi.updateFlashsale(formData.id, formData);
            }
            onClose();
            toast.success('Powodzenie!');
            setErrorSubmit({});
            onDone();
        } catch (error) {
            const errors = error.response.data.errors;
            for (const key in errors) {
                errors[key] = errors[key].join('. ');
            }
            setErrorSubmit(errors);
        }
    };
    const [formData, setFormData] = useState({});
    const [errorSubmit, setErrorSubmit] = useState({});
    const [products, setProducts] = useState([]);
    const [productSelected, setProductSelected] = useState([]);
    const [param, setParam] = useState({});
    const [loading, setLoading] = useState(false);
    const [colorSelected, setColorSelected] = useState([]);

    const handleSearch = value =>
        debounce(() => {
            setParam({ ...param, search: value });
        }, 600)();

    const handleSelectProcduct = newProductArray => {
        const arrayColor = newProductArray.map(item => {
            const selected = productSelected.find(it => it.id === item.id);
            if (selected) {
                return selected;
            }
            return { ...item, priceFlash: 0 };
        });
        setProductSelected(arrayColor);
    };

    const handleChangePrice = (key, value) => {
        productSelected[key].priceFlash = value;
        productSelected[key].flash_price = value;
        setColorSelected([...productSelected]);
    };
    const handleChangeTotal = (id, type, value, price) => {
        const isFound = arrayProduct.some(element => {
            if (element.id === id) {
                return true;
            }

            return false;
        });
        if (isFound) {
            const newState = arrayProduct.map(obj => {
                if (obj.id === id) {
                    return { ...obj, value: value };
                }
                return obj;
            });

            setArrayProduct(newState);
        } else {
            setArrayProduct(oldArrayProduct => [...oldArrayProduct,
            {
                'id': id,
                'type': type,
                'value': value,
                'price': price
            }
            ]);
        }
    }

    const getData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await productApi.getProduct(param);
            setLoading(false);
            setProducts(response.data);
        } catch (error) {
            setLoading(false);
        }
    }, [param]);

    useEffect(() => {
        setDisableFeild(false)
        setTypeFlashsale(null)

        if (type === 'EDIT') {
            setFormData({ ...data });
            setDisableFeild(true);
            setTypeFlashsale(data.type)
            const arrayColor = data.product_array.map(item => {
                return { ...item, priceFlash: item.flash_price, id: item.product_id };
            });
            setProductSelected(arrayColor);
        } else {
            setFormData({ ...data });
            setProductSelected([]);
        }
    }, [type, data]);

    useEffect(() => {
        getData();
    }, [getData]);

    useEffect(() => {
        var TOTAL_temp = 0;
        arrayProduct.forEach((product) => {
            TOTAL_temp = TOTAL_temp + Number(product.value) * Number(product.price)
        })
        setProvisionalMoney(TOTAL_temp);
        if (TOTAL_temp > 1500) {
            setCostMoney(0);
            setTotalMoney(TOTAL_temp);
        } else {
            setTotalMoney(TOTAL_temp + costMoney);
        }

    }, [arrayProduct]);

    return (
        <div>
            <MDialog open={open} title={title} onClose={onClose} onSubmit={onSubmit} type={type}>
                <div className="d-flex">
                    <Grid item xs={12} sm={12} md={6} lg={7} xl={7} className={cx('order-customer-cart')} sx={{ pb: 4 }}>
                        <Box className="c-flex">
                            <Box className="c-flex">
                                <Grid container spacing={4}>
                                    <Grid item xs={6}>
                                        <MInput
                                            placeholder="Pełne imię i nazwisko"
                                            onChange={e => handleChangForm('name', e.target.value)}
                                            value={form.name}
                                            variant="outlined"
                                            className={cx('cart-input')}
                                            error={errorSubmit.name ? true : false}
                                            helperText={errorSubmit.name}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <MInput
                                            placeholder="Numer telefonu"
                                            onChange={e => handleChangForm('phone', e.target.value)}
                                            variant="outlined"
                                            className={cx('cart-input')}
                                            error={errorSubmit.phone ? true : false}
                                            value={form.phone}
                                            helperText={errorSubmit.phone}
                                        />
                                    </Grid>
                                </Grid>
                                <MInput
                                    placeholder="Email"
                                    onChange={e => handleChangForm('email', e.target.value)}
                                    variant="outlined"
                                    className={cx('cart-input')}
                                    error={errorSubmit.email ? true : false}
                                    helperText={errorSubmit.email}
                                    value={form.email}
                                    sx={{ mt: 3 }}
                                />
                                <MInput
                                    placeholder="Adres"
                                    onChange={e => handleChangForm('address', e.target.value)}
                                    variant="outlined"
                                    className={cx('cart-input')}
                                    error={errorSubmit.address ? true : false}
                                    helperText={errorSubmit.address}
                                    value={form.address}
                                    sx={{ mt: 3 }}
                                />
                                <Grid container spacing={4}>
                                    <Grid
                                        sx={{ paddingTop: '0px' }}
                                        className={cx('cart-input-mobile')}
                                        item
                                        xs={6}
                                    >
                                        <Autocomplete
                                            disablePortal
                                            label="Wybierz prowincję/miasto"
                                            variant="outlined"
                                            getOptionLabel={option => option.name}
                                            options={provinces}
                                            value={province}
                                            onChange={(e, newValue) => {
                                                handleChangForm('province_code', newValue.code);
                                                setProvince(newValue);
                                                setDistrict(null);
                                                setWard(null);
                                            }}
                                            renderInput={params => (
                                                <MInput
                                                    {...params}
                                                    placeholder="Wybierz prowincję/miasto"
                                                    className={cx('cart-input')}
                                                    error={errorSubmit.province_code ? true : false}
                                                    helperText={errorSubmit.province_code}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid
                                        className={cx('cart-input-mobile')}
                                        item
                                        xs={6}
                                    >
                                        <Autocomplete
                                            disablePortal
                                            label="Wybierz hrabstwo/okręg"
                                            variant="outlined"
                                            getOptionLabel={option => option.name}
                                            options={districts}
                                            value={district}
                                            onChange={(e, newValue) => {
                                                handleChangForm('district_code', newValue.code);
                                                setDistrict(newValue);
                                                setWard(null);
                                            }}
                                            renderInput={params => (
                                                <MInput
                                                    {...params}
                                                    placeholder="Wybierz hrabstwo/okręg"
                                                    className={cx('cart-input')}
                                                    error={errorSubmit.district_code ? true : false}
                                                    helperText={errorSubmit.district_code}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                                <MInput
                                    placeholder="Notatka"
                                    onChange={e => handleChangForm('note', e.target.value)}
                                    variant="outlined"
                                    className={cx('cart-input')}
                                    sx={{ mt: 3, mb: 2 }}
                                    value={form.note}
                                />
                            </Box>
                            <Box className={cx('cart-title')} sx={{ mt: 4 }}>
                                Hình thức thanh toán
                            </Box>
                            <Box>
                                <CartPayment onSubmit={handleSubmit} loadingButton={loadingSubmit} />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={5} xl={5} className={cx('order-product-cart')}>
                        <Autocomplete
                            disablePortal
                            multiple
                            sx={{ width: '100%', mr: 1, maxWidth: '600px' }}
                            label="Chọn sản phẩm"
                            variant="outlined"
                            size="meidum"
                            getOptionLabel={option => option.product_by ? option.product_by.name : option.name}
                            renderOption={(props, option) => (
                                <Box className="d-flex align-cener" {...props}>
                                    <Box sx={{ ml: 1 }}>{option.product_by ? option.product_by.name : option.name}</Box>
                                </Box>
                            )}
                            options={products}
                            value={productSelected}
                            onChange={(e, newValue) => handleSelectProcduct(newValue)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Search input"
                                    onChange={e => handleSearch(e.target.value)}
                                />
                            )}
                        />
                        <Box className="d-flex flex-column" sx={{ mt: 2 }}>
                            <Box sx={{ flex: 1 }}>

                                {productSelected.length > 0 ? (

                                    productSelected.map(item => (
                                        <ProductItem
                                            onChangeChild={handleChangeTotal}
                                            key={item.id} product={item}
                                        />
                                    ))

                                ) : (
                                    <Box>Vui lòng chọn sản phẩm</Box>
                                )}
                            </Box>
                        </Box>
                        <Divider sx={{ mt: 2 }} />
                        <Box className="d-flex" sx={{ mt: 2 }}>
                            <Box sx={{ flex: 1 }}>
                                <MInput
                                    helperText={voucherMessage}
                                    variant="outlined"
                                    placeholder="Mã giảm giá"
                                    sx={{ width: '100%' }}
                                    value={voucher}
                                    onChange={e => setVoucher(e.target.value)}
                                />
                            </Box>
                            <Button
                                variant="contained"
                                sx={{ ml: 1, borderRadius: '17px', maxHeight: '55px' }}
                                onClick={getVoucher}
                                style={{ borderRadius: '17px', backgroundColor: '#F37121', border: '#F37121' }}
                            >
                                Áp dụng
                            </Button>
                        </Box>
                        <Divider sx={{ mt: 2 }} />
                        <Box sx={{ mt: 2 }}>
                            <Box className="d-flex justify-space-between">
                                <Box className={cx('title-price')}>Tạm tính</Box>
                                <Box className={cx('title-price')}>{Number(provisionalMoney).toLocaleString('de-DE')} zł</Box>
                            </Box>
                            <Box sx={{ mt: 1 }} className="d-flex justify-space-between">
                                <Box className={cx('title-price')}>Giảm giá</Box>
                                <Box className={cx('title-price')}>{Number(discount).toLocaleString('de-DE')} zł</Box>
                            </Box>
                            <Box sx={{ mt: 1 }} className="d-flex justify-space-between">
                                <Box className={cx('title-price')}>Phí vận chuyển</Box>
                                <Box className={cx('title-price')}>{Number(costMoney).toLocaleString('de-DE')} zł</Box>
                            </Box>
                            <Divider sx={{ mt: 2 }} />
                            <Box className="d-flex justify-space-between" sx={{ mt: 2 }}>
                                <Box className={cx('title-price')}>Tổng tiền</Box>
                                <Box sx={{ fontSize: '24px', fontWeight: 'bold', color: '#CB4335' }}>
                                    {Number(totalMoney).toLocaleString('de-DE')} zł
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </div>
            </MDialog>
        </div>
    );
}
export default CreateEdit;
