var maxDate = new Date();
const timestamp = Math.floor(Math.random() * maxDate);
const randomIndexnow = formatDate(Date(timestamp));
 const REVIEWS = {
    
    HOME: [
        {
            stt: 1,
            name: 'Nguyễn Huy',
            date: randomIndexnow,
            size: 'M',
            content: 'Chất vải ok, mẫu khá độc đáo, tư vấn nhiệt tình. ',
            rate: 4.8,
        },
        {
            stt: 2,
            name: 'Huỳnh Mai',
            date: randomIndexnow,
            size: 'S ',
            content: 'Thiết kế oke, form hơi to so với mình nhưng mặc dáng freesz ok nha ',
            rate: '4.6',
        },
        {
            stt: 3,
            name: 'Lan Ngọc ',
            date: randomIndexnow,
            size: 'S',
            content: 'Mẫu đẹp, chất liệu cũng ok nhé. giao hàng nhanh',
            rate: '4.9',
        },
        {
            stt: 4,
            name: 'Trần Phương',
            date: randomIndexnow,
            size: 'M',
            content: 'Vải đẹp, form thoải mái nên recommend bạn nào người nhỏ thì lùi sizenhé',
            rate: '4.9',
        },
        {
            stt: 5,
            name: 'Hoàng Minh ',
            date: randomIndexnow,
            size: 'M',
            content: 'Hàng order thiết kế mà làm nhanh ghê, ship trước cả ngày dự kiến. chất liệu vs mẫu mã oke. ',
            rate: '4.7',
        },
        {
            stt: 6,
            name: 'Nguyen Mai',
            date: randomIndexnow,
            size: 'S',
            content:
                'Áo  xinh, vải này với mình là dày dặn, có sticker nhưng không bị chảy áo. Lần đầu mua hàng ở 1 shop VN mà cái túi như hàng châu Âu. Đỉnh 10đ ',
            rate: 5,
        },
        {
            stt: 7,
            name: 'Phuong Ly Nguyen ',
            date: randomIndexnow,
            size: 'S',
            content:
                'Vải đứng form, tag mác siêu sịn. Giao hàng nhanh. Có điều bạn tư vấn sizecho mình hơi rộng xíu chắc tại mình gầy quá ',
            rate: '4.6',
        },
        {
            stt: 8,
            name: 'Hoang Hanh',
            date: randomIndexnow,
            size: 'L',
            content: 'Bạn tư vấn nhiệt tình dễ thương ghê, nhận áo ưng. Đóng gói uy tín ',
            rate: '4.9',
        },
        {
            stt: 9,
            name: 'Yến Nguyễn ',
            date: randomIndexnow,
            size: 'M',
            content: 'Ship siêu tốc nha, hoảng hốt nhận hàng mà ưng hết sức ',
            rate: 5,
        },
        {
            stt: 10,
            name: 'Trang Linh Nguyễn ',
            date: randomIndexnow,
            size: 'S',
            content: 'Tiền nào của nấy, khác hẳn mấy cái áo tui mua giá rẻ sọp pe ',
            rate: '4.9',
        },
        {
            stt: 11,
            name: 'Phạm Hưng ',
            date: randomIndexnow,
            size: 'L',
            content:
                'Lần 1 mua ưng quá nên đây là lần 2 rồi :)) Hy vọng sốp phát triển để e có sốp guộc mua quần áo hihi ',
            rate: 5,
        },
        {
            stt: 12,
            name: 'Ngọc Huyền ',
            date: randomIndexnow,
            size: 'M',
            content: 'Chất vải ok như áo thun cotton thông thường thôi. Có điều form áo đẹp vs tây lắm :)))',
            rate: '4.7',
        },
        {
            stt: 13,
            name: 'Mai Lan Anh',
            date: randomIndexnow,
            size: 'S',
            content: 'Đáng tiền nhé, trải nghiệm tự trang trí phụ kiện khá hay. Nhận về tay cũng ok. ',
            rate: '4.8',
        },
        {
            stt: 14,
            name: 'Hoang Tung Anh ',
            date: '11-3-2023',
            size: 'M',
            content:
                'Vải nặng tay khác hẳn các shop khác. Phụ kiện gắn trang trí xinh lắm có điều giặt phải tháo ra nha. Mình được tặng kèm túi giặt in logo shop cưng xỉu ',
            rate: '4.5',
        },
        {
            stt: 15,
            name: 'Minh Kha ',
            date: '8-3-2023',
            size: 'S',
            content:
                'Ý tưởng tuyệt vời, lần đầu tiên tui được tự thiết kế áo cho mình, tuy không đẹp bằng các bạn có Gu hơn nhưng mình thích là được nhé.',
            rate: '4.9',
        },
        {
            stt: 16,
            name: 'Duy Minh ',
            date: '8-3-2023',
            size: 'M',
            content: 'Ý tưởng hay, sáng tạo. Chất vải rất đẹp. Đóng hàng khá chuyên nghiệp ',
            rate: '4.7',
        },
        {
            stt: 17,
            name: 'Tram Bui ',
            date: randomIndexnow,
            size: 'S',
            content: 'Áo đẹp nha mọi người, nhưng ai mà thích gắn nhiều phụ kiện như tui là khá tốn đấy nhé. ',
            rate: '4.8',
        },
        {
            stt: 18,
            name: 'Minh Lan ',
            date: '6-3-2023',
            size: 'S',
            content:
                'Mình cứ sợ hình in k nét và không được bền nhưng đã giặt áo vài lần vẫn đẹp nha. 10 điểm chất lượng ',
            rate: '4.9',
        },
        {
            stt: 19,
            name: 'Duy Anh Pham ',
            date: '10-3-2023',
            size: 'L',
            content:
                'Mua 1c đi rồi lại mua thêm vài chiếc nữa như mình thôi. Nghiện luôn vì ra đường k có ai có áo giống mình hahha',
            rate: 5,
        },
        {
            stt: 20,
            name: 'Tyty Pham',
            date: '9-3-2023',
            size: 'L',
            content:
                'Bạn nv tư vấn nhiệt tình quá luôn đến nỗi phân vân mãi mới chọn được mẫu vì quá nhiều mẫu xinh. Giao hàng đúng hẹn.      ',
            rate: '4.8',
        },
        {
            stt: 21,
            name: 'Mai Anh',
            date: '1-3-2023',
            size: 'S',
            content:
                'Màu áo đẹp như mẫu. Form áo đẹp. Chất vải khá mát và không bị thô, nói chung là mềm mại không bị quá ôm, co dãn tốt. Và đặc biệt là đóng hàng cẩn thận, giao hàng nhanh.      ',
            rate: '5',
        },
        {
            stt: 22,
            name: 'Lan Lan ',
            date: '6-3-2023',
            size: 'S',
            content:
                'May đẹp, cẩn thận, k chỉ thừa, nhìn đường may ở cổ là thấy khác so với những bên khác r. Form unisex nên sẽ rộng, bạn nào muốn mặc ôm ôm thì nên lấy xuống size nhé.',
            rate: '5',
        },
        {
            stt: 23,
            name: 'Huy Vu',
            date: '10-3-2023',
            size: 'M',
            content:
                'Áo đẹp lắm, mình mua mẫu thiết kế sẵn và rất ưng vì bên ngoài các phụ kiện nhìn đẹp và thật hơn.      ',
            rate: '4.8',
        },
        {
            stt: 24,
            name: 'Minh Long',
            date: '9-3-2023',
            size: 'M',
            content:
                'Mình mua lần đầu vì thấy ý tưởng hay, nhưng nhận áo trên tay thì thấy k chỉ ý tưởng hay mà chất cũng rất ok nhé.      ',
            rate: '5',
        },
        {
            stt: 25,
            name: 'Minh Hai ',
            date: '6-3-2023',
            size: 'L',
            content:
                'Áo đẹp, đóng gói cẩn thận, tag mác cũng rất sịn sò, tưởng mua hàng thương hiệu Châu Âu.      ',
            rate: '4.8',
        },
        {
            stt: 26,
            name: 'Hoang Huy ',
            date: '6-3-2023',
            size: 'M',
            content:
                'Sản phẩm xứng đáng với giá tiền. Chất đẹp, màu ok, lên form thoải mái.      ',
            rate: '4.8',
        },
        {
            stt: 27,
            name: 'Hy Cuong ',
            date: '11-3-2023',
            size: 'L',
            content:
                'Mua lần 1 để tặng nên k đánh giá, lần 2 mua lại và nhận trên tay siêu ưng, giao hàng đúng hẹn.      ',
            rate: '5',
        },
        {
            stt: 28,
            name: 'Vu Pham',
            date: '8-3-2023',
            size: 'L',
            content:
                'Áo đẹp, nhưng thích nhất là chất vải, sịn và siêu mát, sờ rất ưng tay.      ',
            rate: '4.9',
        },
        {
            stt: 29,
            name: 'Duc Tai',
            date: '8-3-2023',
            size: 'S',
            content:
                'Chuẩn mẫu, thiết kế rất khác biệt, tư vấn chuẩn size.      ',
            rate: '4.8',
        },
        {
            stt: 30,
            name: 'Hoàng Lê ',
            date: '1-3-2023',
            size: 'M',
            content:
                'Lần đầu tiên mua cái áo mà ai ai cũng hỏi mua ở đâu, rất là ưng thiết kế độc đáo này.      ',
            rate: '4.6',
        },
    ],
    PRODUCT: [
        {
            stt: 1,
            name: 'Hai Dang ',
            date: randomIndexnow,
            size: 'M',
            content: 'Áo xinh vs siêu lạ, mặc đi làm ai cũng hỏi chỗ mua kkaka',
            rate: '4.5',
        },
        {
            stt: 2,
            name: 'Bình Nguyên ',
            date: '2-3-2023',
            size: 'S ',
            content: 'Order mà sốp làm nhanh thật sự, giao hàng trước dự kiến. Đóng gói cẩn thận ',
            rate: '4.6',
        },
        {
            stt: 3,
            name: 'Lyly Phạm ',
            date: '1-3-2023',
            size: 'S',
            content: 'Chất đẹp, sticker thì siêu nổi, hội bạn đang rủ làm áo nhóm đi Đà Lạt nè ',
            rate: '4.8',
        },
        {
            stt: 4,
            name: 'Nguyen Anh ',
            date: '7-3-2023',
            size: 'M',
            content:
                'Không hề thất vọng, chất vải đẹp, form chuẩn, cứ sợ bị chật nhưng không hề nhé, đúng dáng unisex mình thích ',
            rate: '4.5',
        },
        {
            stt: 5,
            name: 'Nguyen Thao Nhi ',
            date: '9-3-2023',
            size: 'S',
            content: 'Nét từ đường may, phụ kiện đẹp mà nhiều lắm, tha hồ chọn, chỉ sợ k có tiền thôi ',
            rate: 5,
        },
        {
            stt: 6,
            name: 'Ngọc Minh',
            date: randomIndexnow,
            size: 'L',
            content:
                'Form to rộng thoải mái, giặt mấy lần k thấy vải có dấu hiệu bị cũ hay mất dáng. Giao hàng đúng hẹn ',
            rate: '4.8',
        },
        {
            stt: 7,
            name: 'Giang Phạm ',
            date: '4-3-2023',
            size: 'L',
            content: 'Tư vấn nhiệt tình, đúng sizeáo, mình mua tặng ny để mặc đôi, độc lạ luôn ',
            rate: '4.9',
        },
        {
            stt: 8,
            name: 'My Tra ',
            date: randomIndexnow,
            size: 'S',
            content:
                'Mình mặc sizeS thoải mái, không bị kích hông nhưng mấy bên khác, ưng nhất cái dáng tay áo vừa phải không quá dài nha ',
            rate: 5,
        },
        {
            stt: 9,
            name: 'Tung Nguyen ',
            date: '2-3-2023',
            size: 'M',
            content:
                'Form đẹp, hình in mình rất ưng. Giặt mấy lần vẫn nét và không bị vỡ hình. Đóng gói là thấy uy tín rồi ',
            rate: '4.9',
        },
        {
            stt: 10,
            name: 'Ngô Thanh ',
            date: randomIndexnow,
            size: 'M',
            content:
                'Chất đẹp, mặc lên thoải mái dễ chịu, mình chọn sticker đơn giản thôi nhưng vẫn rất nổi nha, 10 điểm ',
            rate: '4.9',
        },
        {
            stt: 11,
            name: 'Bùi Hải ',
            date: '4-3-2023',
            size: 'L',
            content: 'Giao hàng nhanh, chất lượng tốt, không có gì để chê :)) ',
            rate: 5,
        },
        {
            stt: 12,
            name: 'Ngọc Châu ',
            date: randomIndexnow,
            size: 'S',
            content:
                'Ý tưởng rất hay, chỉ là nhiều sticker quá cái nào cũng muốn nên hơi bối rối. Sản phẩm về tay ưng ',
            rate: '4.9',
        },
        {
            stt: 13,
            name: 'Ly Nguyen ',
            date: randomIndexnow,
            size: 'S',
            content: 'Quá trời mọi người hỏi mua ở đâu, mẫu áo lạ mắt siêu thích. Sịn từ cái túi ship hàng ',
            rate: '4.8',
        },
        {
            stt: 14,
            name: 'Nguyễn Hưng ',
            date: '5-3-2023',
            size: 'M',
            content: 'Áo ok, nhưng mình mặc hơi rộng xíu. Sticker may đẹp và rất đúng ý mình ',
            rate: '4.6',
        },
        {
            stt: 15,
            name: 'Phạm Mạnh ',
            date: '8-3-2023',
            size: 'M',
            content: 'Về tay thấy phụ kiện đẹp hơn trên ảnh mẫu, màu in cũng nét lắm. Mình sẽ mua thêm',
            rate: 5,
        },
        {
            stt: 16,
            name: 'Lê Anh',
            date: '2-3-2023',
            size: 'L',
            content: 'Tư vấn dễ thương, sản phẩm về tay 10 điểm nha, thích nhất phụ kiện gắn siêu nổi ',
            rate: '4.9',
        },
        {
            stt: 17,
            name: 'Hồ Thu Hằng ',
            date: '5-3-2023',
            size: 'S',
            content:
                'Phụ kiện gắn hơi lệch 1 xíu so với mẫu mình chọn nhưng về tự chỉnh lại là ok nha. chất vải ưng lắm ',
            rate: '4.8',
        },
        {
            stt: 18,
            name: 'Mai Phuong ',
            date: '6-3-2023',
            size: 'S',
            content: 'Vải đẹp, nặng tay, Hình in chất lượng. ',
            rate: '4.8',
        },
        {
            stt: 19,
            name: 'Dang Quoc Dat ',
            date: randomIndexnow,
            size: 'M',
            content: 'Tag mác sịn sò như thương hiệu quốc tế, đỉnh quá đỉnh. ',
            rate: 5,
        },
        {
            stt: 20,
            name: 'Ha Thanh Thao ',
            date: randomIndexnow,
            size: 'M',
            content: 'nét từ cách đóng gói hàng, còn được tặng cả túi giặt, nói chung là ưng ',
            rate: 5,
        },
    ],
};
const getRadomHomeReviews = () => {
    const radomReviews = [];
    const countItem = Math.floor(Math.random() * 6) + 12;
    const homeReviews = REVIEWS.HOME;
    for(let i = 0; i < countItem; i++){
        const randomIndex = Math.floor(Math.random() * homeReviews.length);
        radomReviews.push(homeReviews[randomIndex]);
        homeReviews.splice(randomIndex, 1);
    }
    return radomReviews
}
const getRadomProductReviews = () => {
    const radomReviews = [];
    const countItem = Math.floor(Math.random() * 6) + 12;
    const productReviews = REVIEWS.PRODUCT;
    for(let i = 1; i <= countItem; i++){
        const randomIndex = Math.floor(Math.random() * productReviews.length);
        radomReviews.push(productReviews[randomIndex]);
        productReviews.splice(randomIndex, 1);
    }
    return radomReviews
}
function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [day, month, year].join('-');
}
 
export default REVIEWS;
export const radomHomeReview = getRadomHomeReviews();
export const randomProductReview = getRadomProductReviews();

