import { Box } from '@mui/material';
import images from '@/assets/images';
import classNames from 'classnames/bind';
import styles from './IconPartner.scss';
import Slider from 'react-slick';

const cx = classNames.bind(styles);
const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2,
};

const style = {
    widthContent: {
        minWidth: '170px',
        width: '22%',
        fontSize: '14px',
        borderRadius: '9px',
        height: '50px',
        color: 'black',
        fontWeight: 'BOLD',
    },
};
function IconPartner({ height }) {
    return (
        <Box>
            <Box sx={{ paddingTop: '20px', paddingBottom: '0px' }}>
                <Box sx={{ mb: 4, width: '100%', textAlign: 'center' }}>
                    <img src={images.iconPartner} style={{ width: '100%', maxWidth: '450px' }} alt="product" />
                </Box>
                <Box className="pc-card-partner" >
                    <Box className="d-flex">
                        <div className={cx('d-flex width-container wrap')}>
                            <img src={images.iconKenh} style={{ width: '100%', maxWidth: '150px' }} alt="Kenh14" />
                        </div>
                        <div className={cx('d-flex width-container wrap')}>
                            <img src={images.iconYan} style={{ width: '100%', maxWidth: '150px' }} alt="Yan" />
                        </div>
                        <div className={cx('d-flex width-container wrap')}>
                            <img src={images.iconZing} style={{ width: '100%', maxWidth: '150px' }} alt="Zing" />
                        </div>
                        <div className={cx('d-flex width-container wrap')}>
                            <img src={images.iconStarChanel} style={{ width: '100%', maxWidth: '150px' }} alt="star" />
                        </div>
                        <div className="d-flex width-container">
                            <img src={images.iconYeah} style={{ width: '100%', maxWidth: '150px' }} alt="Yeah" />
                        </div>
                    </Box>
                    <br></br>
                </Box>
            </Box>
            <Box className={cx('mobile-card-partner')}>
                <div sx={{ paddingTop: '10px !important', paddingLeft: '1px', paddingRight: '1px' }}>
                    <Slider {...settings}>
                        <div>
                            <img src={images.iconKenh} style={{ width: '80%' }} alt="product" />
                        </div>
                        <div>
                            <img src={images.iconYan} style={{ width: '80%' }} alt="product" />
                        </div>
                        <div>
                            <img src={images.iconZing} style={{ width: '80%' }} alt="product" />
                        </div>
                        <div>
                            <img src={images.iconStarChanel} style={{ width: '80%' }} alt="product" />
                        </div>
                        <div>
                            <img src={images.iconYeah} style={{ width: '80%' }} alt="product" />
                        </div>
                    </Slider>
                </div>
            </Box>
        </Box>
    );
}
export default IconPartner;
