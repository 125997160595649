import Avatar from '@mui/material/Avatar';
import { Box } from '@mui/material';
import StarRateIcon from '@mui/icons-material/StarRate';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import classNames from 'classnames/bind';
import styles from './IconBar.module.scss';
import Slider from 'react-slick';
import images from '@/assets/images';

const cx = classNames.bind(styles);

const style = {
    widthContent: {
        className: "center",
        minWidth: '170px',
        width: '100%',
        fontSize: '14px',
        borderRadius: '9px',
        height: '62px',
        color: 'black',
        fontWeight: 'BOLD',
        paddingLeft: '40px'
    },
    widthContentMobile: {
        className: "center",
        minWidth: '170px',
        width: '90%',
        fontSize: '14px',
        borderRadius: '9px',
        height: '62px',
        color: 'black',
        fontWeight: 'BOLD',
        paddingLeft: '60px',
        marginLeft: '20px'
    },
};
const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1.2,
    slidesToScroll: 1
};

function IconBar({ height }) {
    return (
        <Box>
            <Box className={cx('pc-card-made')} sx={{ paddingTop: '20px', paddingLeft: '105px', paddingRight: '105px' }}>
                <div className={cx('d-flex width-container wrap-cator')}>
                    <Box sx={{ mt: 2, bgcolor: '#e08282' }} className="d-flex align-center" style={style.widthContent}>
                        <img src={images.iconOne} alt="Logo" style={{ width: '11%' }} />
                        <Box sx={{ ml: 2, fontFamily: 'MyFont', fontWeight: '600', fontSize: '16px' }}>Wyprodukowane w Polsce</Box>
                    </Box>
                </div>
                <div className={cx('d-flex width-container wrap-cator')}>
                    <Box className="d-flex align-center" style={style.widthContent} sx={{ mt: 2, bgcolor: '#e08282' }}>
                        <img src={images.iconTwo} alt="Logo" style={{ width: '11%' }} />
                        <Box sx={{ ml: 2, fontFamily: 'MyFont', fontWeight: '600', fontSize: '16px' }}>30-dniowa polisa na zwrot</Box>
                    </Box>
                </div>
                <div className={cx('d-flex width-container wrap-cator')}>
                    <Box className="d-flex align-center" style={style.widthContent} sx={{ mt: 2, bgcolor: '#e08282' }}>
                        <img src={images.iconThree} alt="Logo" style={{ width: '11%' }} />
                        <Box sx={{ ml: 2, fontFamily: 'MyFont', fontWeight: '600', fontSize: '16px' }}>KOCHANY PRZEZ MŁODYCH LUDZI</Box>
                    </Box>
                </div>
                <div className={cx('d-flex width-container wrap-cator')}>
                    <Box className="d-flex align-center" style={style.widthContent} sx={{ mt: 2, bgcolor: '#e08282' }}>
                        <img src={images.iconFour} alt="Logo" style={{ width: '11%' }} />
                        <Box sx={{ ml: 2, fontFamily: 'MyFont', fontWeight: '600', fontSize: '16px' }}>WSPARCIE KLIENTA 24/7</Box>
                    </Box>
                </div>
            </Box>
            <Box className={cx('mobile-card')}>
                <div sx={{ paddingTop: '10px !important', paddingLeft: '10px', paddingRight: '10px' }}>
                    <Slider {...settings}>
                        <div>
                            <Box sx={{ mt: 2, bgcolor: '#e08282' }} className="d-flex align-center" style={style.widthContentMobile}>
                                <img src={images.iconOne} alt="Logo" style={{ width: '11%' }} />
                                <Box sx={{ ml: 2, fontFamily: 'MyFont', fontWeight: '600', fontSize: '16px', color: 'white' }}>Wyprodukowane w Polsce</Box>
                            </Box>
                        </div>
                        <div>
                            <Box className="d-flex align-center" style={style.widthContentMobile} sx={{ mt: 2, bgcolor: '#e08282' }}>
                                <img src={images.iconTwo} alt="Logo" style={{ width: '11%' }} />
                                <Box sx={{ ml: 2, fontFamily: 'MyFont', fontWeight: '600', fontSize: '16px', color: 'white' }}>30-dniowa polisa na zwrot</Box>
                            </Box>
                        </div>
                        <div>
                            <Box className="d-flex align-center" style={style.widthContentMobile} sx={{ mt: 2, bgcolor: '#e08282' }}>
                                <img src={images.iconThree} alt="Logo" style={{ width: '11%' }} />
                                <Box sx={{ ml: 2, fontFamily: 'MyFont', fontWeight: '600', fontSize: '16px', color: 'white' }}>KOCHANY PRZEZ MŁODYCH LUDZI</Box>
                            </Box>
                        </div>
                        <div>
                            <Box className="d-flex align-center" style={style.widthContentMobile} sx={{ mt: 2, bgcolor: '#e08282' }}>
                                <img src={images.iconFour} alt="Logo" style={{ width: '11%' }} />
                                <Box sx={{ ml: 2, fontFamily: 'MyFont', fontWeight: '600', fontSize: '16px', color: 'white' }}>WSPARCIE KLIENTA 24/7</Box>
                            </Box>
                        </div>
                    </Slider>
                </div>

            </Box>
        </Box>

    );
}
export default IconBar;
