import axiosClient from '@/utils/axiosClient';

class NavbarApi {
    getNavbar = () => {
        const url = '/getnavbar';
        return axiosClient.get(url);
    };
    getBanner = () => {
        const url = '/getBannerHome';
        return axiosClient.get(url);
    };
    getVoucher = () => {
        const url = '/getVoucherHome';
        return axiosClient.get(url);
    };
    getRatingHome = () => {
        const url = '/getRatingHome';
        return axiosClient.get(url);
    };

    getNavbarShow = () => {
        const url = '/getnavbarshow';
        return axiosClient.get(url);
    };
   
    updateNavbar = (id, data) => {
        const url = `/updatenavbar/${id}`;
        return axiosClient.put(url, data);
    };
    updateBanner = (id, data) => {
        const url = `/updateBannerHome/${id}`;
        return axiosClient.put(url, data);
    };
    updateVoucher = (id, data) => {
        const url = `/updateVoucherHome/${id}`;
        return axiosClient.put(url, data);
    };
    updateRating = (id, data) => {
        const url = `/updateRatingHome/${id}`;
        return axiosClient.put(url, data);
    };
}
const navbarApi = new NavbarApi();
export default navbarApi;
