import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import images from '@/assets/images';
import classNames from 'classnames/bind';
import styles from './Service.scss';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const cx = classNames.bind(styles);

const rows = [
    createData('Belgia', '3-4', 155),
    createData('Bulgaria', '3-4', 180),
    createData('Chorwacja', '6-7', 210),
    createData('Czechy', '4-5', 90),
    createData('Dania', '6-7', 125),
    createData('Estonia', '2-3', 120),
    createData('Finlandia', '3-4', 170),
    createData('Francja', '3-4', 160),
    createData('Hiszpania', '4-5', 155),
    createData('Holandia', '3-4', 125),
    createData('Irlandia', '4-5', 160),
    createData('Litwa', '4-5', 120),
    createData('Lotwa', '3-4', 95),
    createData('Niemcy', '4-5', 95),
    createData('Portugalia', '2-3', 185),
    createData('Slowacja', '3-4', 105),
    createData('Slowenia', '2-3', 135),
    createData('Szwecja', '2-3', 175),
    createData('Wlochy', '4-5', 160),
    createData('Rumunia', '4-5', 160),
    createData('Wegry', '4-5', 120),
];
function createData(
    name: string,
    calories: string,
    fat: number
) {
    return { name, calories, fat };
}
function Shipment() {
    return (
        <div className="d-flex justify-center bg-gray">
            <div className={cx('container-service', 'width-container')}>
                <div style={{ width: '100%', textAlign: 'center', fontSize: '16px', fontWeight: 'bold' }}>
                    Kosz wysłki w Polsce:
                </div>
                <div style={{ width: '100%', textAlign: 'center', fontSize: '16px', fontWeight: 'bold' }}>
                    od 500 zł: 35 zł
                </div>
                <div style={{ width: '100%', textAlign: 'center', fontSize: '16px', fontWeight: 'bold' }}>
                    od 1000 zł: 20 zł
                </div>
                <div style={{ width: '100%', textAlign: 'center', fontSize: '16px', fontWeight: 'bold' }}>
                    od 2000 zł: gratis
                </div>
                <div style={{ width: '100%', textAlign: 'center', fontSize: '16px', fontWeight: 'bold' }}>
                    Wysyłamy do całej Europy!!!
                </div>
                <div style={{ width: '100%', textAlign: 'center', fontSize: '16px', fontWeight: 'bold' }}>
                    Ceny dostaw do pozostałych krajów podajemy przez telefon lub e-mail
                </div>
                <Divider sx={{ mb: 3, mt: 1 }} />
                <Box>
                    <Box style={{ textAlign: 'center', fontSize: '24px' }} >
                        <Table sx={{ width: '100%' }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ fontSize: '16px', fontWeight: '700' }}>Kraj docelowy</TableCell>
                                    <TableCell style={{ fontSize: '16px', fontWeight: '700' }} align="right">Czas dostawy (dni)</TableCell>
                                    <TableCell style={{ fontSize: '16px', fontWeight: '700' }} align="right">Kosz (EURO) – paczka {'<'}31,5 kg</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow
                                        key={row.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row" style={{ fontSize: '16px' }}>
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="right" style={{ fontSize: '16px' }}>{row.calories}</TableCell>
                                        <TableCell align="right" style={{ fontSize: '16px' }}>{row.fat}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <Divider sx={{ mb: 3, mt: 1 }} />
                    </Box>
                </Box>
            </div>
        </div>
    );
}
export default Shipment;
