import { useCallback, useEffect, useState } from 'react';
import productApi from '@/api/productApi';
import classNames from 'classnames/bind';
import styles from './product.scss';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Divider } from '@mui/material';
import { Carousel } from '@trendyol-js/react-carousel';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useNavigate } from 'react-router-dom';
import GridProduct from '@/components/GridProduct/GridProduct';

const cx = classNames.bind(styles);

function Product() {
    const [categories, setCategories] = useState([]);
    const navigate = useNavigate();
    const [loadingCategory, setLoadingCategory] = useState(false);
    const [productLoading, setProductLoading] = useState(true);
    const [products, setProducts] = useState([]);
    const [totalPage, setTotalPage] = useState(0);
    const [param, setParam] = useState({ perPage: 40 });
    const getProduct = useCallback(async () => {
        setProductLoading(true);
        let response = await productApi.getProductShow(param);
        setProducts(response.data);
        setTotalPage(response.last_page || response?.meta?.last_page);
        setProductLoading(false);
    }, [param]);
    const getCategories = useCallback(async () => {
        setLoadingCategory(true);
        let response = await productApi.getCategories({ perPage: 100 });
        setCategories(response.data);
        setLoadingCategory(false);
    }, []);
    const onChangePage = page => {
        setParam({ ...param, page });
    };
    useEffect(() => {
        getProduct();
        getCategories();
    }, [getProduct, getCategories]);
    const screenWidth = window.innerWidth;
    var countItemShow = 3.5;
    if (screenWidth < 500) {
        countItemShow = 1
    }
    else if (screenWidth < 710) {
        countItemShow = 1.5
    }
    else if (screenWidth < 1000) {
        countItemShow = 2
    }
    else if (screenWidth < 1250) {
        countItemShow = 2.5
    }

    return (
        <div>
            <Box className="c-flex align-center">
                <div className={cx('width-container')}>
                    <Box sx={{ pl: 1, fontSize: '28px', fontWeight: 'bold', paddingTop: '20px', paddingBottom: '10px' }}>
                        Danh mục
                    </Box>
                    <Divider sx={{ mt: 2, mb: 4 }} />
                </div>
                <Box sx={{ width: '100%', paddingLeft: '0px', paddingRight: '0px' }}>
                    {loadingCategory ? (
                        <Box className="d-flex justify-center" cx={{ width: '100%', mt: 4 }}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Carousel
                            useArrowKeys
                            show={countItemShow}
                            slide={2}
                            transition={0.5}
                            swiping={true}
                            rightArrow={
                                <div style={{ height: '100%' }} className="d-flex align-center">
                                    <ArrowForwardIosIcon sx={{ fontSize: '40px', cursor: 'pointer' }} />
                                </div>
                            }
                            leftArrow={
                                <div style={{ height: '100%' }} className="d-flex align-center">
                                    <ArrowBackIosNewIcon sx={{ fontSize: '40px', cursor: 'pointer' }} />
                                </div>
                            }
                        >
                            {categories.map((item, index) => (
                                <Box onClick={() => navigate(`/category/${item.code}`)}>
                                    <Box
                                        key={index}
                                        style={{ background: `url('${item.url_image}')` }}
                                        className={cx('carosel-categories')}
                                    >
                                    </Box>
                                    <Box sx={{ fontWeight: 'bold', mt: 2, ml: 2, fontSize: '18px' }}>{item.name}</Box>
                                </Box>
                            ))}
                        </Carousel>
                    )}
                </Box>
            </Box>
            <Box className="c-flex align-center" sx={{ mb: 4 }}>
                <div className={cx('width-container')}>
                    <Box sx={{ pl: 1, fontSize: '28px', fontWeight: 'bold', paddingTop: '50px', paddingBottom: '10px' }}>
                        Danh sách sản phẩm
                    </Box>
                    <Divider sx={{ mt: 2, mb: 4 }} />
                    <GridProduct
                        products={products}
                        loading={productLoading}
                        totalPage={totalPage}
                        onChangePage={onChangePage}
                    />
                </div>
            </Box>
        </div>
    );
}
export default Product;
