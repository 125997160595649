import { useSelector, useDispatch } from 'react-redux';
import { BORDER_SELECTED, BORDER_NO_SELECT } from '@/store/design/constant';
import { changePosition, changeProduct } from '@/store/design/targetDesignSlice';
import imagesSource from '@/assets/images';
import { useCallback, useEffect, useState } from 'react';

const titelCss = {
    marginBottom: '0px',
    backgroundColor: 'black',
    width: '25%',
    textAlign: 'center',
    justifyContent: 'center',
    marginLeft: '38%',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    zIndex: '990 !important'
};
const titelCssUp = {
    backgroundColor: 'black',
    width: '70px',
    textAlign: 'center',
    justifyContent: 'center',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    display: 'block',
    position: 'fixed',
    left: '49vw',
    bottom: '1vh',
    zIndex: '990'
};

function ProductBottom() {

    const productSelected = useSelector(state => state.targetDesign.productSelected);

    const productList = useSelector(state => state.targetDesign.productList);
    const product = productList.find(el => el.id === productSelected.productId);
    const iconDesign = useSelector(state => state.targetDesign.iconArray);

    const [showUpbottom, setShowUpbottom] = useState(true);
    const [showDownbottom, setShowDownbottom] = useState(false);

    var hasItem = false;
    iconDesign.forEach(item => {
        if (item.icons && item.icons.length > 0) {
            hasItem = true;
        }
    });
    const { images } = product.colors.find(el => el.id === productSelected.colorId);
    const dispatch = useDispatch();

    const openSubBottom = () => {
        setShowDownbottom(false);
        setShowUpbottom(true);
    }
    const closeSubBottom = () => {
        setShowDownbottom(true);
        setShowUpbottom(false);
    }

    return (
        <div>
            <div>

                {showDownbottom == true ? (
                    <div onClick={() => openSubBottom()} style={titelCssUp}>
                        <img src={imagesSource.iconUpWhite} style={{ cursor: 'pointer', height: '10px' }} />
                    </div>
                ) : null
                }
                {showUpbottom == true ? (
                    <div>
                        <div onClick={() => closeSubBottom()} style={titelCss}>
                            <img src={imagesSource.iconDownWhite} style={{ cursor: 'pointer', height: '10px' }} />
                        </div>
                        <div className="d-flex" style={{ backgroundColor: 'white', padding: '15px 5px 15px 15px', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>
                            {images.map((item, index) => (
                                <div
                                    key={index}
                                    onClick={() => dispatch(changePosition(item.id))}
                                    style={{
                                        border: item.id === productSelected.imageId ? BORDER_SELECTED : BORDER_NO_SELECT,
                                        marginRight: '10px',
                                        cursor: 'pointer',
                                    }}
                                >
                                    <img src={item.link} alt="Vi tri" style={{ height: '70px' }} />
                                    <h2 style={{ fontWeight: '300', textAlign: 'center', fontSize: '12px' }}>{item.name}</h2>
                                </div>
                            ))}
                        </div>
                    </div>
                ) : null
                }
            </div>
        </div>
    );
}

export default ProductBottom;
