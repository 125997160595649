import { configureStore } from '@reduxjs/toolkit';
import targetDesign from './design/targetDesignSlice';
import sidebarAction from './sidebar/sidebarSlice';
import authSlice from './auth/authSlice';
import createSagaMiddleware from 'redux-saga'
import rootSaga from './rootSaga';
import cartAction from './cart/cartSlice';
import SmaxAction from './smax/smaxSlice';
import VoucherAction from './voucher/voucherSlice';

const sagaMiddleware = createSagaMiddleware()

export default configureStore({
    reducer: {
        targetDesign: targetDesign,
        sidebar: sidebarAction,
        auth: authSlice,
        cart: cartAction,
        smax: SmaxAction,
        voucher: VoucherAction
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga)