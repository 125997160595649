import classNames from 'classnames/bind';
import styles from './Card.module.scss';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { SP_DA_XEM } from '@/constants/product';
import images from '@/assets/images';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';

const cx = classNames.bind(styles);
function ProductCard({ data }) {
    const navigate = useNavigate();

    const statusProduct = (status) => {
        if (status === 'Nowy') {
            return <Button style={{ backgroundColor: "#040fda", color: "white", fontWeight: "bold", alignContent: "center", width: "fit-content", height: "19px", minWidth: "40px", padding: "0px 0px 0px 0px", fontSize: "10px" }} variant="contained">{data.status}</Button>
        }
        if (status === 'Warte kupna') {
            return <Button style={{ backgroundColor: "rgb(103 21 177)", color: "white", fontWeight: "bold", alignContent: "center", width: "fit-content", height: "19px", minWidth: "65px", padding: "0px 0px 0px 0px", fontSize: "10px" }} variant="contained">{data.status}</Button>
        }
        if (status === 'To prawie koniec') {
            return <Button style={{ backgroundColor: "#f37121", color: "black", fontWeight: "bold", alignContent: "center", width: "fit-content", height: "19px", minWidth: "65px", padding: "0px 0px 0px 0px", fontSize: "10px" }} variant="contained">{data.status}</Button>
        }
        if (status === 'Ratować') {
            return <Button style={{ backgroundColor: "#e08282", color: "black", fontWeight: "bold", alignContent: "center", width: "fit-content", height: "19px", minWidth: "65px", padding: "0px 0px 0px 0px", fontSize: "10px" }} variant="contained">{data.status}</Button>
        }
        if (status === 'Sale') {
            return <Button style={{ backgroundColor: "#ff3102", color: "white", fontWeight: "bold", alignContent: "center", width: "fit-content", height: "19px", minWidth: "30px", padding: "0px 0px 0px 0px", fontSize: "10px" }} variant="contained">{data.status}</Button>
        }
        if (status === 'Limited') {
            return <Button style={{ backgroundColor: "#000000", color: "#f5ea87", fontWeight: "bold", alignContent: "center", width: "fit-content", height: "19px", minWidth: "65px", padding: "0px 0px 0px 0px", fontSize: "10px" }} variant="contained">{data.status}</Button>
        }
        else {
            return <Button style={{ backgroundColor: "#2f5acf", color: "white", fontWeight: "bold", alignContent: "center", width: "fit-content", height: "19px", minWidth: "85px", padding: "0px 0px 0px 0px", fontSize: "10px" }} variant="contained">{data.status}</Button>
        }

    }
    const goProductPage = (productId, code) => {
        const spDaXem = Cookies.get(SP_DA_XEM) ? JSON.parse(Cookies.get(SP_DA_XEM)) : [];
        if (spDaXem && spDaXem.length >= 5) {
            spDaXem.pop();
        }
        if (!spDaXem.includes(productId)) {
            spDaXem.unshift(productId);
            Cookies.set(SP_DA_XEM, JSON.stringify(spDaXem), { expires: 2 });
        }
        navigate(`/san-pham/${code}`);
    };
    const link_product = 'http://dl-zabawek.pl/san-pham/' + data.code;

    return (
        <div className={cx('wrap-card')} onClick={() => goProductPage(data.id, data.code)}>
            <a style={{ textDecoration: 'none' }}>
                <div className={cx('image-card-border')}>
                    <div className={cx('image-card')} style={{ backgroundImage: `url('${data.url_image}')` }}>
                        <div style={{ display: "flex", justifyContent: "center", paddingTop: "10px", paddingLeft: "10px" }}>
                            {/* <div style={{ flexGrow: "1", textAlign: "left" }}>
                                <b style={{ paddingRight: '2px', fontSize: '15px', color: 'black' }}>{Math.floor(Math.random() * 2) + 4}</b>
                                <img src={images.iconStar} alt="star" style={{ width: '6%' }} />
                                <a style={{ color: "#2f5acf", fontWeight: "600", fontFamily: 'MyFont' }} >({Math.floor(Math.random() * 45) + Math.floor(Math.random() * 5)})</a>
                            </div> */}
                            <div style={{ flexGrow: "1", textAlign: "right", paddingRight: "10px" }}>
                                {statusProduct(data.status)}
                            </div>
                        </div>
                    </div>
                    <div className="c-flex align-center">
                        <div className={cx('card-title')}>{data.name}</div>
                        <Box className={cx('card-price')}>
                            <Box className={cx('text-price')} style={{ color: '#5a84c1', fontWeight: '500', fontSize: '15px' }} >{parseFloat(data.sale_price).toLocaleString('de-DE')} zł</Box>
                        </Box>
                    </div>
                </div>
            </a>

        </div >
    );
}
export default ProductCard;
