import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import { POSITION_TOP, POSITION_BACK } from '@/store/design/constant';
import { useSelector, useDispatch } from 'react-redux';
import { setPosition, deleteIconDesign } from '@/store/design/targetDesignSlice';
import Divider from '@mui/material/Divider';

function ImageDesign() {
    const iconSelected = useSelector(state => state.targetDesign.iconSelected);
    const dispatch = useDispatch();
    return (
        <div style={{ height: '100%', borderLeft: '2px solid #bbbbbb', paddingTop: '30px' }}>
            <div style={{ margin: '0px 5px 5px 5px', border: '2px solid #236DFF', borderRadius: '10px' }} className="d-flex justify-space-between">
                <div style={{ display: 'flex', direction: 'column', margin: '5px 0px 5px 10px' }}>
                    <div>
                        <img src={iconSelected?.data?.image_url} alt="design" style={{ width: '40px' }} />
                    </div>
                    <div direction="row" style={{ lineHeight: '1.5' }}>
                        <div>
                            {iconSelected?.data?.name}
                        </div>
                        <div>
                            {
                                iconSelected?.data?.rotate_able ? (
                                    <a style={{ fontSize: '14px', color: '#00B812', fontWeight: '300', textAlign: 'left' }}>Có thể xoay</a>
                                ) : ('Khoong theer xoay')
                            }

                        </div>
                    </div>
                </div>
                <Divider orientation="vertical" variant="middle" flexItem style={{ backgroundColor: 'black' }} />

                <div style={{ margin: '5px 0px 5px 0px' }}>
                    <div direction="row" style={{ lineHeight: '1.5' }}>
                        <div>
                            SIZE
                        </div>
                        <div style={{ fontSize: '14px', fontWeight: '300', textAlign: 'left' }}>
                            R: {iconSelected?.data?.width}cm x D: {iconSelected?.data?.height}cm
                        </div>
                    </div>
                </div>
                <Divider orientation="vertical" variant="middle" flexItem style={{ backgroundColor: 'black' }} />
                <div style={{ margin: '5px 10px 5px 0px' }}>
                    <div direction="row" style={{ lineHeight: '1.5' }}>
                        <div>
                            GIÁ BÁN
                        </div>
                        <div style={{ fontSize: '14px', fontWeight: '300', textAlign: 'left' }}>
                            {parseInt(iconSelected?.data?.price).toLocaleString('de-DE')} zł
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-space-between" style={{ margin: '15px 15px 15px 15px' }}>
                <div>
                    <Button
                        size="small"
                        variant="contained"
                        onClick={() => dispatch(deleteIconDesign())}
                        startIcon={<DeleteIcon />}
                    >
                        Xóa
                    </Button>
                </div>

                <div>
                    <Button
                        onClick={() => dispatch(setPosition(POSITION_TOP))}
                        variant="outlined"
                        startIcon={<KeyboardDoubleArrowUpIcon />}
                        size="small"
                    >
                        Lên trước
                    </Button>
                </div>
                <div>
                    <Button
                        onClick={() => dispatch(setPosition(POSITION_BACK))}
                        variant="outlined"
                        startIcon={<KeyboardDoubleArrowDownIcon />}
                        size="small"
                    >
                        Về sau
                    </Button>
                </div>
            </div>
        </div>
    );
}
export default ImageDesign;
