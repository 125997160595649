import classNames from 'classnames/bind';
import styles from './Card.module.scss';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { SP_DA_XEM } from '@/constants/product';
import images from '@/assets/images';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import { useState, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { pushToCart } from '@/store/cart/cartSlice';
import Divider from '@mui/material/Divider';

const cx = classNames.bind(styles);
function ProductCardV3({ data }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleAddToCart = () => {
        const productAdd = {
            id: data.id,
            quantity: Number(data.paczka),
        };
        dispatch(pushToCart(productAdd));
        if (window.innerWidth > 800) {
            toast(
                <Box
                    sx={{
                        borderRadius: '5px',
                    }}
                >
                    <Box sx={{ fontWeight: 'bold', color: 'black', mb: 2, mt: 1 }}>
                        Produkt dodany do koszyka!
                    </Box>
                    <Divider />
                    <Box sx={{ mt: 2 }} className="d-flex">
                        <Box
                            sx={{
                                width: '90px',
                                height: '120px',
                                border: '1px solid #CACFD2',
                                borderRadius: '5px',
                                background: '#f7f7f7',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'contain',
                                backgroundPosition: 'center',
                                backgroundImage: `url('${data.url_image}')`,
                            }}
                        ></Box>
                        <Box sx={{ flex: 1, ml: 1 }}>
                            <Box sx={{ fontWeight: 'bold', color: 'black' }}>{data.name}</Box>
                        </Box>
                    </Box>
                    <Box>
                        <Box
                            className="d-flex align-center justify-center"
                            sx={{
                                mt: 2,
                                width: '100%',
                                borderRadius: '5px',
                                border: '1px solid black',
                                height: '35px',
                                fontWeight: 'bold',
                                color: 'black',
                            }}
                            onClick={() => navigate('/gio-hang')}
                        >
                            Zobacz Koszyk
                        </Box>
                    </Box>
                </Box>,
                {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                }
            );
        } else {
            // toast(
            //     <Box
            //         sx={{
            //             borderRadius: '5px',
            //         }}
            //     >
            //         <Box sx={{ fontWeight: 'bold', color: 'black', mb: 2, mt: 1 }}>
            //             Produkt dodany do koszyka!
            //         </Box>
            //         <Divider />
            //         <Box sx={{ mt: 2 }} className="d-flex">
            //             <Box
            //                 sx={{
            //                     width: '112px',
            //                     height: '180px',
            //                     border: '1px solid #CACFD2',
            //                     borderRadius: '5px',
            //                     background: '#f7f7f7',
            //                     backgroundRepeat: 'no-repeat',
            //                     backgroundSize: 'contain',
            //                     backgroundPosition: 'center',
            //                     backgroundImage: `url('${data.url_image}')`,
            //                 }}
            //             ></Box>
            //             <Box sx={{ flex: 1, ml: 1 }}>
            //                 <Box sx={{ fontWeight: 'bold', color: 'black' }}>{data.name}</Box>
            //             </Box>
            //         </Box>
            //         <Box>
            //             <Box
            //                 className="d-flex align-center justify-center"
            //                 sx={{
            //                     mt: 2,
            //                     width: '100%',
            //                     borderRadius: '5px',
            //                     border: '1px solid black',
            //                     height: '35px',
            //                     fontWeight: 'bold',
            //                     color: 'black',
            //                 }}
            //                 onClick={() => navigate('/gio-hang')}
            //             >
            //                 Zobacz Koszyk
            //             </Box>
            //         </Box>
            //     </Box>,
            //     {
            //         position: 'bottom-right',
            //         autoClose: 5000,
            //         hideProgressBar: false,
            //         closeOnClick: true,
            //         pauseOnHover: true,
            //         draggable: true,
            //         progress: undefined,
            //         theme: 'light',
            //     }
            // );
        }
    };
    const statusProduct = (status) => {
        if (status === 'Nowy') {
            return <Button style={{ backgroundColor: "#040fda", color: "white", fontWeight: "bold", alignContent: "center", width: "fit-content", height: "19px", minWidth: "40px", padding: "0px 0px 0px 0px", fontSize: "10px" }} variant="contained">{data.status}</Button>
        }
        if (status === 'Warte kupna') {
            return <Button style={{ backgroundColor: "rgb(103 21 177)", color: "white", fontWeight: "bold", alignContent: "center", width: "fit-content", height: "19px", minWidth: "65px", padding: "0px 0px 0px 0px", fontSize: "10px" }} variant="contained">{data.status}</Button>
        }
        if (status === 'To prawie koniec') {
            return <Button style={{ backgroundColor: "#f37121", color: "black", fontWeight: "bold", alignContent: "center", width: "fit-content", height: "19px", minWidth: "65px", padding: "0px 0px 0px 0px", fontSize: "10px" }} variant="contained">{data.status}</Button>
        }
        if (status === 'Ratować') {
            return <Button style={{ backgroundColor: "#e08282", color: "black", fontWeight: "bold", alignContent: "center", width: "fit-content", height: "19px", minWidth: "65px", padding: "0px 0px 0px 0px", fontSize: "10px" }} variant="contained">{data.status}</Button>
        }
        if (status === 'Sale') {
            return <Button style={{ backgroundColor: "#ff3102", color: "white", fontWeight: "bold", alignContent: "center", width: "fit-content", height: "19px", minWidth: "30px", padding: "0px 0px 0px 0px", fontSize: "10px" }} variant="contained">{data.status}</Button>
        }
        if (status === 'Limited') {
            return <Button style={{ backgroundColor: "#000000", color: "#f5ea87", fontWeight: "bold", alignContent: "center", width: "fit-content", height: "19px", minWidth: "65px", padding: "0px 0px 0px 0px", fontSize: "10px" }} variant="contained">{data.status}</Button>
        }
        else {
            return <Button style={{ backgroundColor: "#2f5acf", color: "white", fontWeight: "bold", alignContent: "center", width: "fit-content", height: "19px", minWidth: "85px", padding: "0px 0px 0px 0px", fontSize: "10px" }} variant="contained">{data.status}</Button>
        }

    }
    const goProductPage = (productId, code) => {
        const spDaXem = Cookies.get(SP_DA_XEM) ? JSON.parse(Cookies.get(SP_DA_XEM)) : [];
        if (spDaXem && spDaXem.length >= 5) {
            spDaXem.pop();
        }
        if (!spDaXem.includes(productId)) {
            spDaXem.unshift(productId);
            Cookies.set(SP_DA_XEM, JSON.stringify(spDaXem), { expires: 2 });
        }
        navigate(`/san-pham/${code}`);
    };
    const link_product = 'http://dl-zabawek.pl/san-pham/' + data.code;

    var sum = 0;
    var rating = 3;
    for (var i = 0; i < data.ratings.length; i++) {
        sum += parseInt(data.ratings[i].rate, 10);
    }

    if (data.ratings.length > 0) {
        var avg = parseFloat((sum / data.ratings.length).toFixed(1));
        rating = avg;
    }



    return (
        <div className={cx('top-wrap-card-ui-v2')}>
            <a style={{ textDecoration: 'none' }}>
                <div className={cx('top-image-card-border-ui')}>
                    <div onClick={() => goProductPage(data.id, data.code)} className={cx('image-card-ui-product')}>
                        <img src={data.url_image} className={cx('image-card-ui-product-img')} alt='product' />
                        <div className={cx('d-flex image-card-ui-insite')} style={{ position: 'absolute', top: '3%', justifyContent: 'space-between', width: '100%', paddingLeft: '1em', paddingRight: '1em' }}>
                            <div className={cx('card-ui-insite-status')} style={{ flexGrow: "1", textAlign: "right" }}>
                                {statusProduct(data.status)}
                            </div>
                        </div>
                    </div>
                    <div className="c-flex">
                        <div className={cx('card-title-uiv2')}>{data.name}</div>
                        <Box className={cx('card-price-uiv2')}>
                            <Box className={cx('text-pricev2')} style={{ color: 'black', fontWeight: '300', fontSize: '15px' }} >PACZKA {data.paczka} SZT</Box>
                        </Box>
                        <Box className={cx('card-price-uiv2')}>
                            <Box className={cx('text-pricev2')} style={{ color: 'black', fontWeight: '300', fontSize: '15px' }} >KOD:</Box>
                            <Box className={cx('text-pricev2')} style={{ color: 'black', fontWeight: '700', fontSize: '15px' }} > {data.sku_code}</Box>
                        </Box>
                        <Box className={cx('card-price-uiv2')}>
                            <Box className={cx('text-pricev2')} style={{ color: '#5a84c1', fontWeight: '500', fontSize: '15px' }} > {(data.sale_price).toLocaleString('de-DE')} zł</Box>
                        </Box>
                        <Box className={cx('card-price-uiv2')}>
                            <Box className={cx('text-pricev2')} style={{ color: 'black', fontWeight: '300', fontSize: '15px' }} > {data.rozmiary}</Box>
                        </Box>
                        <Box>
                            <Button
                                style={{
                                    width: '100%',
                                    borderRadius: '5px',
                                    backgroundColor: '#e08282',
                                    border: '1px solid #e08282',
                                    color: 'black',
                                }}
                                primary
                                onClick={handleAddToCart}
                            >
                                <Box
                                    className="d-flex align-center justify-center"
                                    sx={{ height: '7px', width: '100%' }}
                                >
                                    <img
                                        src={images.cartIconBlack}
                                        style={{ width: '20px', color: 'black' }}
                                        alt="icon"
                                    />

                                    <Box
                                        className={cx('carticon-mobile')}
                                        sx={{ mt: 0, ml: 2, bgcolor: '#e08282', color: 'white', fontSize: '11px' }}
                                    >
                                        Dodaj do koszyka
                                    </Box>
                                </Box>
                            </Button>
                        </Box>
                    </div>
                </div>
            </a>
        </div >
    );
}
export default ProductCardV3;
