import { createSlice } from '@reduxjs/toolkit';

export const voucherAction = createSlice({
    
    name: 'voucher',
    initialState: {
        voucher: null
    },
    reducers: {
        pushTovoucher: (state, action) => {
        },
        updateVoucher: (state, action) => {
            const newVoucher = action.payload;
            state.voucher = newVoucher;
        }
    },
});

export const { pushTovoucher, updateVoucher } = voucherAction.actions;

export default voucherAction.reducer;
