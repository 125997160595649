import RadioColorDesign from '@/components/RadioColorDesign';
import { useSelector, useDispatch } from 'react-redux';
import { BORDER_SELECTED, BORDER_NO_SELECT } from '@/store/design/constant';
import { changePosition, changeProduct } from '@/store/design/targetDesignSlice';
import { ConfirmDialog } from '@/components/ConfirmDialog';
import Divider from '@mui/material/Divider';

const titelCss = {
    marginBottom: '16px',
    fontSize: '16px',
    fontWeight: '300',
    textAlign: 'center',
    marginTop: '20px',
};

function ProductColor() {
    const productSelected = useSelector(state => state.targetDesign.productSelected);
    const productList = useSelector(state => state.targetDesign.productList);
    const product = productList.find(el => el.id === productSelected.productId);
    const colors = product.colors.map(el => {
        return { value: el.id, color: el.color, label: el.name };
    });
    const iconDesign = useSelector(state => state.targetDesign.iconArray);
    var hasItem = false;
    iconDesign.forEach(item => {
        if (item.icons && item.icons.length > 0) {
            hasItem = true;
        }
    });
    const { images } = product.colors.find(el => el.id === productSelected.colorId);
    const dispatch = useDispatch();

    const handleChangeProduct = itemId => {
        if (productSelected.productId === itemId) return;
        if (hasItem) {
            ConfirmDialog({
                title: 'Chuyển sản phẩm',
                html: true,
                body: 'Lưu ý: Khi đổi sản phẩm dữ liệu đang thiết kế sẽ bị mất',
                action: onClose => {
                    onClose();
                    dispatch(changeProduct(itemId));
                },
            });
        } else dispatch(changeProduct(itemId));
    };
    return (
        <div>
            <div>
                <div style={titelCss}>Áo Polo ONYOU-2023</div>
                <Divider sx={{ mt: 3, mb: 3 }} />
                <div style={titelCss}>Màu Áo</div>
                <div style={{ width: '100%' }}>
                    <RadioColorDesign value={productSelected.colorId} data={colors} />
                </div>
            </div>
        </div>
    );
}

export default ProductColor;
