import classNames from 'classnames/bind';
import styles from './header.module.scss';
import images from '@/assets/images';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import { Avatar, Box, Divider, Menu, MenuItem } from '@mui/material';
import { useSelector } from 'react-redux';
import MenuIcon from '@mui/icons-material/Menu';
import { useCallback, useEffect, useState } from 'react';
import productApi from '@/api/productApi';
import navbarApi from '@/api/navbarApi';
import MuiAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { styled } from '@mui/material/styles';
import Popper from '@mui/material/Popper';
import OnyouAbout from './OnyouAbout';
import ProductSub from './ProductSub';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slider from 'react-slick';
import { toast } from 'react-toastify';
import { updateVoucher } from '@/store/voucher/voucherSlice';
import { useDispatch } from 'react-redux';
import SearchDialog from './SearchDialog';
const cx = classNames.bind(styles);

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(1),
}));

const Accordion = styled(props => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
    '&:before': {
        display: 'none',
    },
}));

function HomeHeader() {
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorProduct, setAnchorProduct] = useState(null);
    const [showZalo, setShowzalo] = useState(false);
    const [showZaloHotline, setShowzaloHotline] = useState(false);
    const [showZaloOpen, setShowzaloOpen] = useState(true);
    const [showZaloClose, setShowzaloClose] = useState(false);
    const [showZaloSub, setShowzaloSub] = useState(false);
    const [anchorOnyouUNU, setAnchorOnyouUNU] = useState(null);
    const [anchorOnyouearly, setAnchorOnyouearly] = useState(null);
    const [anchorProductearly, setAnchorProductearly] = useState(null);
    const [onyouearly, setOnyouearly] = useState(0);
    const [anchorOnyouearlyStatus, setAnchorOnyouearlyStatus] = useState(0);
    const [anchorSubOnyou, setAnchorSubOnyou] = useState(1);
    const [anchorSubProduct, setAnchorSubProduct] = useState(1);
    const [categories, setCategories] = useState([]);
    const [showBottomVouncher, setShowBottomVouncher] = useState(false);
    const cartData = useSelector(state => state.cart.cartData);
    const [styleNavbar, setStyleNavbar] = useState({})
    const handleClickBottomVouncherOpen = () => {
        setShowBottomVouncher(true);
    };

    const handleClickBottomVouncherClose = () => {
        setShowBottomVouncher(false);
    };

    const handleChangeStatusOnyou = (value1, value2) => {
        setAnchorSubOnyou(value1);
        if (value1 == 0 && onyouearly == 0) {
            setAnchorOnyouearly(null);
        }
    };
    const handleChangeStatusProduct = (value1, value2) => {
        setAnchorSubProduct(value1);
        if (value1 == 0 && onyouearly == 0) {
            setAnchorProductearly(null);
        }
    };

    const openMenu = Boolean(anchorEl);
    const handleClickMenu = event => {
        setAnchorEl(event.currentTarget);
    };
    const totalItemCart = useSelector(state => state.cart.quantity);
    const navigate = useNavigate();
    const goToLink = link => {
        if (link == '/gio-hang' && cartData.length == 0) {
        } else {
            setAnchorEl(null);
            navigate(link);
        }
    };
    const getData = useCallback(async () => {
        try {
            const response = await navbarApi.getNavbarShow();
            setValueNavbar(response.value);
            setButtonNavbar(response.button);
            setLinkNavbar(response.link);
            setStyleNavbar({ text_color: response.text_color, bg_color: response.bg_color })
            if (window.location.pathname === '/') {
                setShowBottomVouncher(true);
            }
        } catch (error) { }
    });
    const getCategories = useCallback(async () => {
        let response = await productApi.getCategories({ perPage: 10 });
        setCategories(response.data);
    }, []);
    const me = useSelector(state => state.auth.me);
    const goDashboard = () => {
        if (me && me.role_code !== 'customer') {
            goToLink('/dashboard');
        }
        if (!me) {
            goToLink('/login');
        }
    };
    useEffect(() => {
        getCategories();
    }, [getCategories]);
    useEffect(() => {
        getData();
    }, []);
    useEffect(() => {
        handleChangeStatusOnyou();
    }, [anchorOnyouearlyStatus]);
    useEffect(() => {
        handleChangeStatusOnyou();
    }, [anchorOnyouearlyStatus]);

    const handleMenu = (event, type) => {
        switch (type) {
            case 'productearly':
                setAnchorOnyouUNU(null);
                setAnchorOnyouearly(null);

                if (anchorProduct !== event.currentTarget) {
                    setAnchorProductearly(event.currentTarget);
                }
                break;

            case 'onyouearly':
                setAnchorSubOnyou(1);
                setOnyouearly(1);
                setAnchorProduct(null);
                setAnchorOnyouUNU(null);
                setAnchorProductearly(null);

                if (anchorOnyouearly !== event.currentTarget) {
                    setAnchorOnyouearly(event.currentTarget);
                }
                break;
            default:
        }
    };
    const handleCloseMenu = () => {
        setAnchorProduct(null);
        setAnchorOnyouUNU(null);
        setAnchorOnyouearly(null);
    };
    const handleCloseOnyou = () => {
        setOnyouearly(0);
        if (anchorSubOnyou == 0) {
            setAnchorOnyouearly(null);
        }
    };

    const moveDiff = () => {
        setAnchorOnyouearly(null);
        setAnchorProductearly(null);
    };

    const callNewZalo = () => {
        window.open('https://zalo.me/902157732682606107');
    };
    const openBlog = () => {
        window.open('http://blog.onyou.vn');
    };

    const openSubZalo = () => {
        setShowzaloOpen(false);
        setShowzaloClose(true);
        setShowzaloSub(true);
    };

    const closeSubZalo = () => {
        setShowzaloOpen(true);
        setShowzaloClose(false);
        setShowzaloSub(false);
        setShowzalo(false);
        setShowzaloHotline(false);
    };
    const clickZaloIcon = () => {
        setShowzaloSub(false);
        setShowzalo(true);
    };

    const clickHotlineIcon = () => {
        setShowzaloSub(false);
        setShowzaloHotline(true);
    };

    const clickMessFBIcon = () => {
        window.open('https://m.me/dawidleez');
    };
    const clickMessWhatsapIcon = () => {
        window.open('https://wa.me/48579185859');
    };
    const clickMessViberIcon = () => {
        window.open('viber://chat?number=48579185859');
    };
    const clickMessGmailIcon = () => {
        window.open('https://mail.google.com/mail/?view=cm&fs=1&to=zabawkasl@gmail.com');
    };

    const closeHotlineIcon = () => {
        setShowzaloHotline(false);
        setShowzaloOpen(true);
        setShowzaloClose(false);
    };

    const closeZaloIcon = () => {
        setShowzalo(false);
        setShowzaloOpen(true);
        setShowzaloClose(false);
    };
    const dispatch = useDispatch();

    const onClickOYGIFT100K = event => {
        switch (event.detail) {
            case 1: {
                break;
            }
            case 2: {
                navigator.clipboard.writeText('OYGIFT100K');
                toast.success('Đã lưu lại mã VOUCHER');
                dispatch(updateVoucher({ voucher: 'OYGIFT100K' }));
                break;
            }
            case 3: {
                break;
            }
            default: {
                break;
            }
        }
    };

    const onClickOYSPECIAL50K = event => {
        switch (event.detail) {
            case 1: {
                break;
            }
            case 2: {
                navigator.clipboard.writeText('OYSPECIAL50K');
                toast.success('Đã lưu lại mã VOUCHER');
                dispatch(updateVoucher({ voucher: 'OYSPECIAL50K' }));
                break;
            }
            case 3: {
                break;
            }
            default: {
                break;
            }
        }
    };

    const onClickOYGIFT799K = event => {
        switch (event.detail) {
            case 1: {
                break;
            }
            case 2: {
                navigator.clipboard.writeText('OYCANVAS');
                toast.success('Đã lưu lại mã VOUCHER');
                dispatch(updateVoucher({ voucher: 'OYCANVAS' }));
                break;
            }
            case 3: {
                break;
            }
            default: {
                break;
            }
        }
    };
    const onClickONYOUORVER = event => {
        switch (event.detail) {
            case 1: {
                break;
            }
            case 2: {
                navigator.clipboard.writeText('OYGIFT100K');
                toast.error('Phần quà này đã tạm hết!');
                break;
            }
            case 3: {
                break;
            }
            default: {
                break;
            }
        }
    };
    const [openSearch, setOpenSearch] = useState(false);

    window.addEventListener('scroll', moveDiff);

    const [ValueNavbar, setValueNavbar] = useState('');
    const [buttonNavbar, setButtonNavbar] = useState('');
    const [linkNavbar, setLinkNavbar] = useState('');

    var widthView;
    var classNameMenuMobile = 'menu-mobile';
    var classNameMenuHeader = 'menu-header';
    if (window.location.pathname === '/design') {
        widthView = '1315px';
        classNameMenuHeader = 'menu-header-no-viewport';
        classNameMenuMobile = 'menu-mobile-no-viewport';
    }
    const style = {
        widthContentMobile: {
            minWidth: '170px',
            marginTop: '1em',
            marginBottom: '1em',
            marginLeft: '1em',
        },
        widthContentMobile2: {
            minWidth: '170px',
            color: 'black',
            fontWeight: 'BOLD',
            margin: '1em -4em 1em 6em',
        },
    };
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 2,
        overFlow: 'hidden',
    };
    const settings2 = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1.2,
        slidesToScroll: 1,
        overFlow: 'hidden',
    };
    const settingAni = () => ({
        effect: 'Zoom',
        duration: 1400,
        delay: 0,
    });

    return (
        <div className={cx('home-header')} style={{ minWidth: widthView }}>
            <div className={cx('top-header')} style={{ color: styleNavbar.text_color }}>
                <div
                    className={cx('pc-card-value')}
                    style={{ opacity: '1 !important' }}
                >
                    <div style={{ display: 'flex', flexDirection: 'row', fontSize: '9px' }}>
                        <div>
                            <img src={images.iconPhone} style={{ width: '3%' }} alt="Search" />
                            <a style={{ fontSize: '12px', paddingLeft: '5px' }}>Telefon:</a><a style={{ fontSize: '12px', paddingLeft: '5px', color: '#e08282' }}>+48579185859</a>
                            <img src={images.iconMail} style={{ width: '8%', paddingLeft: '20px' }} alt="Search" />
                            <a style={{ fontSize: '12px', paddingLeft: '5px' }}>E-mail:</a><a style={{ fontSize: '12px', paddingLeft: '5px', color: '#e08282' }}>zabawkasl@gmail.com</a>
                        </div>
                    </div>
                </div>
                <div className={cx('pc-card-button')} style={{ opacity: '1 !important' }}>
                    <a style={{ color: 'black', fontSize: '12px' }}>
                        Witaj,
                    </a>
                    <a style={{ fontSize: '12px', paddingLeft: '5px', color: '#e08282' }}>Dawid Lee- Zabawki</a>
                </div>
                <div
                    className={cx('mobile-card')}
                    style={{
                        width: '100%',
                        justifyContent: 'center',
                        opacity: '1 !important',
                        paddingRight: '3px',
                        paddingLeft: '3px',
                        paddingTop: '5px',
                        fontSize: '12px !important',
                    }}
                >
                    <a style={{ fontSize: '12px' }}>{ValueNavbar} </a>{' '}
                    <a
                        style={{ fontWeight: 'bold', color: 'black', fontSize: '12px', paddingBottom: '3px' }}
                        href={linkNavbar}
                    >
                        {buttonNavbar}
                    </a>
                </div>
            </div>
            {showZaloOpen == true ? (
                <div>
                    <div className={cx('header-zalo-1')}>
                        <img
                            onClick={() => openSubZalo()}
                            src={images.iconHotlineSVG}
                            style={{ width: '70%', cursor: 'pointer' }}
                            alt="Zalo icon"
                        />
                    </div>
                    <div className={cx('header-zalo-1-mobile')}>
                        <img onClick={() => openSubZalo()} src={images.iconHotlineSVG} alt="Zalo icon" />
                    </div>
                </div>
            ) : null}

            {showZaloClose == true ? (
                <div>
                    <div className={cx('header-zalo-2')}>
                        <img
                            onClick={() => closeSubZalo()}
                            src={images.iconCloseSVG}
                            style={{ width: '70%', cursor: 'pointer' }}
                            alt="Zalo icon"
                        />
                    </div>
                    <div className={cx('header-zalo-2-mobile')}>
                        <img onClick={() => closeSubZalo()} src={images.iconCloseSVG} alt="Zalo icon" />
                    </div>
                </div>
            ) : null}

            {showZaloSub == true ? (
                <div>
                    <div className={cx('header-zalo-sub1')}>
                        <img
                            onClick={() => clickHotlineIcon()}
                            src={images.iconHolineCallSVG}
                            style={{ width: '70%', cursor: 'pointer' }}
                            alt="Zalo icon"
                        />
                    </div>
                    <div className={cx('header-zalo-sub1-mobile')}>
                        <img onClick={() => clickHotlineIcon()} src={images.iconHolineCallSVG} alt="Zalo icon" />
                    </div>
                    <div className={cx('header-zalo-sub2')}>
                        <img
                            onClick={() => clickMessGmailIcon()}
                            src={images.iconsGmail}
                            style={{ width: '70%', cursor: 'pointer' }}
                            alt="Zalo icon"
                        />
                    </div>
                    <div className={cx('header-zalo-sub2-mobile')}>
                        <img style={{ width: '70%' }} onClick={() => clickMessGmailIcon()} src={images.iconsGmail} alt="Zalo icon" />
                    </div>
                    <div className={cx('header-zalo-sub3')}>
                        <img
                            onClick={() => clickMessViberIcon()}
                            src={images.iconsViber}
                            style={{ width: '90%', cursor: 'pointer' }}
                            alt="Zalo icon"
                        />
                    </div>
                    <div className={cx('header-zalo-sub3-mobile')}>
                        <img style={{ width: '100%' }} onClick={() => clickMessViberIcon()} src={images.iconsViber} alt="Zalo icon" />
                    </div>

                    <div className={cx('header-zalo-sub4')}>
                        <img
                            onClick={() => clickMessWhatsapIcon()}
                            src={images.iconsWhatsapp}
                            style={{ width: '42%', cursor: 'pointer' }}
                            alt="Zalo icon"
                        />
                    </div>
                    <div className={cx('header-zalo-sub4-mobile')}>
                        <img onClick={() => clickMessWhatsapIcon()} src={images.iconsWhatsapp} alt="Zalo icon" />
                    </div>
                    <div className={cx('header-zalo-sub5')}>
                        <img
                            onClick={() => clickMessFBIcon()}
                            src={images.iconMessFBSVG}
                            style={{ width: '42%', cursor: 'pointer' }}
                            alt="Zalo icon"
                        />
                    </div>
                    <div className={cx('header-zalo-sub5-mobile')}>
                        <img onClick={() => clickMessFBIcon()} src={images.iconMessFBSVG} alt="Zalo icon" />
                    </div>
                </div>
            ) : null}

            {showZalo == true ? (
                <div
                    style={{ transition: 'visibility 0s linear 0.33s, opacity 0.33s linear' }}
                    className={cx('header-zalo-main')}
                >
                    <div className={cx('header-zalo-main01')}>
                        <img
                            src={images.shortLogo}
                            style={{ width: '20%', marginLeft: '10px', borderRadius: '50%' }}
                            alt="Zalo icon"
                        />
                        <b style={{ width: '50%', paddingLeft: '10px', fontSize: '18px' }}> Dawid official</b>
                        <MoreHorizIcon
                            style={{ width: '10%', paddingLeft: '5px', borderRadius: '50px' }}
                        ></MoreHorizIcon>

                        <ExpandMoreIcon
                            onClick={() => closeZaloIcon()}
                            style={{ width: '20%', paddingLeft: '0px', borderRadius: '50px', cursor: 'pointer' }}
                        ></ExpandMoreIcon>
                    </div>
                    <div className={cx('header-zalo-main02')}>
                        <b style={{ width: '100%', paddingLeft: '20px', paddingBottom: '5px', fontSize: '24px' }}>
                            {' '}
                            Cześć!
                        </b>
                        <a style={{ width: '100%', paddingLeft: '20px', fontSize: '14px' }}>
                            {' '}
                            Chętnie Ci pomogę
                        </a>
                    </div>
                    <div className={cx('header-zalo-main03')}>
                        <a
                            style={{
                                width: '100%',
                                paddingTop: '20%',
                                paddingBottom: '10px',
                                fontSize: '14px',
                                color: 'white',
                                textAlign: 'center',
                            }}
                        >
                            {' '}
                            Zacznij rozmawiać z urzędnikiem Dawid Lee
                        </a>
                        <button
                            onClick={() => callNewZalo()}
                            style={{
                                backgroundColor: '#e08282',
                                cursor: 'pointer',
                                padding: '10px 70px 10px 70px',
                                borderRadius: '10px',
                                fontSize: '16px',
                                fontWeight: 'bold',
                            }}
                        >
                            Chat bằng Zalo
                        </button>
                    </div>
                    <Divider sx={{ mt: 2, bgcolor: 'white' }} />
                    <div className={cx('header-zalo-main04')}>
                        <button
                            style={{
                                cursor: 'pointer',
                                backgroundColor: 'rgb(62 63 57)',
                                color: '#e08282',
                                padding: '5px 15px 5px 15px',
                                borderRadius: '5px',
                                fontSize: '13px',
                                marginRight: '20px',
                            }}
                        >
                            Tiếng Việt
                        </button>
                        <button
                            style={{
                                cursor: 'pointer',
                                backgroundColor: '#121212',
                                color: 'white',
                                padding: '5px 15px 5px 15px',
                                borderRadius: '5px',
                                fontSize: '13px',
                                marginRight: '10px',
                            }}
                        >
                            English
                        </button>
                    </div>
                </div>
            ) : null}

            {showZaloHotline == true ? (
                <div
                    style={{ transition: 'visibility 0s linear 0.33s, opacity 0.33s linear' }}
                    className={cx('header-zalo-main')}
                >
                    <div className={cx('header-zalo-main01')}>
                        <img
                            src={images.shortLogo}
                            style={{ width: '20%', marginLeft: '10px', borderRadius: '50%' }}
                            alt="Zalo icon"
                        />
                        <b style={{ width: '50%', paddingLeft: '10px', fontSize: '18px' }}> Dawid official</b>
                        <MoreHorizIcon
                            style={{ width: '10%', paddingLeft: '5px', borderRadius: '50px' }}
                        ></MoreHorizIcon>

                        <ExpandMoreIcon
                            onClick={() => closeHotlineIcon()}
                            style={{ width: '20%', paddingLeft: '0px', borderRadius: '50px', cursor: 'pointer' }}
                        ></ExpandMoreIcon>
                    </div>
                    <div className={cx('header-zalo-main02')}>
                        <b style={{ width: '100%', paddingLeft: '20px', paddingBottom: '5px', fontSize: '24px' }}>
                            {' '}
                            Cześć!
                        </b>
                        <a style={{ width: '100%', paddingLeft: '20px', fontSize: '14px' }}>
                            {' '}
                            Chętnie Ci pomogę
                        </a>
                    </div>
                    <div className={cx('header-zalo-main03')}>
                        <a
                            style={{
                                width: '100%',
                                paddingTop: '20%',
                                paddingBottom: '10px',
                                fontSize: '14px',
                                color: 'white',
                                textAlign: 'center',
                            }}
                        >
                            {' '}
                            Zacznij rozmawiać z urzędnikiem Dawid Lee
                        </a>
                        <a
                            href="tel:+48579185859"
                            style={{
                                backgroundColor: '#e08282',
                                cursor: 'pointer',
                                padding: '10px 70px 10px 70px',
                                borderRadius: '10px',
                                fontSize: '16px',
                                fontWeight: 'bold',
                            }}
                        >
                            +48579185859
                        </a>
                    </div>
                    <Divider sx={{ mt: 2, bgcolor: 'white' }} />
                    <div className={cx('header-zalo-main04')}>
                        <button
                            style={{
                                cursor: 'pointer',
                                backgroundColor: 'rgb(62 63 57)',
                                color: '#e08282',
                                padding: '5px 15px 5px 15px',
                                borderRadius: '5px',
                                fontSize: '13px',
                                marginRight: '20px',
                            }}
                        >
                            Tiếng Việt
                        </button>
                        <button
                            style={{
                                cursor: 'pointer',
                                backgroundColor: '#121212',
                                color: 'white',
                                padding: '5px 15px 5px 15px',
                                borderRadius: '5px',
                                fontSize: '13px',
                                marginRight: '10px',
                            }}
                        >
                            English
                        </button>
                    </div>
                </div>
            ) : null}

            <div className={cx('inner')}>
                <div className={cx(classNameMenuMobile)}>
                    <IconButton
                        color="black"
                        onClick={handleClickMenu}
                        aria-controls={openMenu ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={openMenu ? 'true' : undefined}
                    >
                        <MenuIcon sx={{ fontSize: '32px', color: 'white' }} />
                    </IconButton>
                    <Menu
                        id="basic-menu"
                        className={cx('sub-mobile')}
                        open={openMenu}
                        anchorEl={anchorEl}
                        onClose={() => setAnchorEl(null)}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem className={cx('sub-mobile-item')} onClick={() => goToLink('/category/polecamy')}>
                            <b>SKLEP</b>
                        </MenuItem>
                        <Divider sx={{ mt: 1, bgcolor: '#9b9191' }} />
                        <Accordion className={cx('sub-mobile-item')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                                aria-controls="panel1a-content"
                            >
                                <b>KATEGORIE</b>
                            </AccordionSummary>
                            {categories
                                ? categories.map(item => (
                                    <AccordionDetails
                                        key={item.id}
                                        sx={{ pl: 3 }}
                                        onClick={() => goToLink(`/category/${item.code}`)}
                                    >
                                        {item.name}
                                    </AccordionDetails>
                                ))
                                : null}
                        </Accordion>
                        <Divider sx={{ mt: 1, bgcolor: '#9b9191' }} />
                        <Accordion className={cx('sub-mobile-item')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                                aria-controls="panel1a-content"
                            >
                                <b>KOLEKCJA</b>
                            </AccordionSummary>
                            <AccordionDetails sx={{ pl: 3 }} onClick={() => goToLink(`/category/toichat`)}>
                                Zabawki sezonowe
                            </AccordionDetails>
                            <AccordionDetails sx={{ pl: 3 }} onClick={() => goToLink(`/category/neonyou`)}>
                                Zabawki według siatki wiekowej
                            </AccordionDetails>
                        </Accordion>
                        <Divider sx={{ mt: 1, bgcolor: '#9b9191' }} />
                        <MenuItem className={cx('sub-mobile-item')} onClick={() => goToLink('/shipment')}>
                            <b>WYSYŁKA</b>
                        </MenuItem>
                        <Divider sx={{ mt: 1, bgcolor: '#9b9191' }} />
                        <MenuItem className={cx('sub-mobile-item')} onClick={() => goToLink('/contact')}>
                            <b>KONTAKT</b>
                        </MenuItem>
                        <Divider sx={{ mt: 1, bgcolor: '#9b9191' }} />
                        <Accordion className={cx('sub-mobile-item')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                                aria-controls="panel1a-content"
                            >
                                <b>OBSŁUGA KLIENTA</b>
                            </AccordionSummary>
                            <AccordionDetails sx={{ pl: 3 }} onClick={() => goToLink(`/chinh-sach-bao-mat`)}>
                                Polityka bezpieczeństwa informacji
                            </AccordionDetails>
                            <AccordionDetails sx={{ pl: 3 }} onClick={() => goToLink(`/chinh-sach-dat-hang`)}>
                                Zasady składania zamówień i dostaw
                            </AccordionDetails>
                            {/* <AccordionDetails sx={{ pl: 3 }} onClick={() => goToLink(`/chinh-sach-thanh-toan`)}>
                                Zasady płatności
                            </AccordionDetails>
                            <AccordionDetails
                                sx={{ pl: 3 }}
                                onClick={() => goToLink(`/chinh-sach-doi-tra-va-hoan-tien`)}
                            >
                                30-dniowa polityka zwrotów i zwrotów pieniędzy
                            </AccordionDetails>
                            <AccordionDetails sx={{ pl: 3 }} onClick={() => goToLink(`/chinh-sach-kiem-hang`)}>
                                Polityka kontroli towarów
                            </AccordionDetails>
                            <AccordionDetails sx={{ pl: 3 }} onClick={() => goToLink(`/dieu-kien-giao-dich-chung`)}>
                                Ogólne warunki handlowe
                            </AccordionDetails> */}
                        </Accordion>
                        <Divider sx={{ mt: 1, bgcolor: '#9b9191' }} />
                        <MenuItem className={cx('sub-mobile-item')} onClick={() => goToLink('/ve-chung-toi')}>
                            <b>POLYTIKA</b>
                        </MenuItem>
                        <Divider sx={{ mt: 1, bgcolor: '#9b9191' }} />
                        {/* <MenuItem className={cx('sub-mobile-item-red')} onClick={() => goToLink('/khuyen-mai')}>
                            <b>GIẢM 50%</b>
                        </MenuItem> */}
                        <Divider sx={{ mt: 1, bgcolor: '#9b9191' }} />
                        <MenuItem className={cx('sub-mobile-item')} onClick={() => goToLink('/contact')}>
                            <b>KONTAKT</b>
                        </MenuItem>
                    </Menu>
                    <SearchDialog open={openSearch} onClose={() => setOpenSearch(false)} />
                    <IconButton
                        className={cx('button-header-search-mobile')}
                        color="black" sx={{ mr: 1 }}
                        onClick={() => setOpenSearch(true)}
                    >
                        <a style={{ textDecoration: 'none' }}>
                            <img
                                src={images.iconSearch}
                                style={{ width: '100%', marginLeft: '0px', marginBottom: '-6px' }}
                                alt="Search"
                            />
                        </a>
                    </IconButton>
                    <div className={cx('logo')} onClick={() => goToLink('/')}>
                        <img src={images.logo} alt="Logo" />
                    </div>
                </div>

                {/* danh cho pc */}

                <div className={cx(classNameMenuHeader)}>
                    <div className={cx('logo')} onClick={() => goToLink('/')}>
                        <a href="/">
                            <img src={images.logo} alt="Logo" />
                        </a>
                    </div>
                    <a style={{ textDecoration: 'none' }}>
                        <div
                            onMouseOver={moveDiff}
                            className={cx('menu-design-new')}
                            onClick={() => goToLink('/category/polecamy')}
                        >
                            SKLEP
                        </div>
                    </a>
                    <div className={cx('menu-design-new')} onMouseOver={e => handleMenu(e, 'productearly')}>
                        KATEGORIE
                        <img src={images.iconDownWhite} alt="Logo" style={{ width: '15%', paddingLeft: '3px' }} />
                    </div>
                    <a style={{ textDecoration: 'none' }}>
                        <div
                            onMouseOver={moveDiff}
                            className={cx('menu-design')}
                            onClick={() => goToLink('/shipment')}
                        >
                            WYSYŁKA
                        </div>
                    </a>

                    <div
                        className={cx('menu-design-new')}
                        onMouseLeave={handleCloseOnyou}
                        onMouseOver={e => handleMenu(e, 'onyouearly')}
                    >
                        POLYTIKA
                        <img src={images.iconDownWhite} alt="Logo" style={{ width: '15%', paddingLeft: '3px' }} />
                    </div>
                    <a style={{ textDecoration: 'none' }}>
                        <div className={cx('menu-design-new')} onClick={() => goToLink('/contact')}>
                            KONTAKT
                        </div>
                    </a>
                </div>
                <div className={cx('button-header')}>
                    <SearchDialog open={openSearch} onClose={() => setOpenSearch(false)} />
                    <IconButton
                        className={cx('button-header-search')}
                        color="black"
                        sx={{ mr: 1 }}
                        onClick={() => setOpenSearch(true)}
                    >
                        <img src={images.iconSearch} style={{ width: '100%', marginLeft: '10px' }} alt="Search" />
                    </IconButton>
                    <IconButton color="black" sx={{ mr: 1 }}>
                        {me ? (
                            <a style={{ textDecoration: 'none' }}>
                                <Avatar sx={{ bgcolor: '#5B2C6F' }} src={me.avatar_url} onClick={() => goDashboard()}>
                                    {!me.avatar_url && me.name ? me.name.charAt(0) : null}
                                </Avatar>
                            </a>
                        ) : (
                            // <AccountCircleOutlinedIcon
                            //     onClick={() => goDashboard()}
                            //     sx={{ fontSize: '30px', stroke: '#ffffff', strokeWidth: 1 }}
                            // />
                            <img
                                onClick={() => goDashboard()}
                                src={images.iconProfile}
                                style={{ width: '100%', marginLeft: '0px' }}
                                alt="Profile"
                            />
                        )}
                    </IconButton>
                    <IconButton color="black" onClick={() => goToLink('/gio-hang')}>
                        <Badge badgeContent={totalItemCart} color="primary">
                            <a style={{ textDecoration: 'none' }}>
                                <img src={images.iconCart} style={{ width: '100%', marginLeft: '0px' }} alt="Profile" />
                            </a>
                        </Badge>
                    </IconButton>
                </div>
            </div>
            {anchorOnyouearly ? (
                <Box className={cx('menu-header')} sx={{ width: '100%', zIndex: '990' }}>
                    <OnyouAbout onChangeChildStatus={handleChangeStatusOnyou} />
                </Box>
            ) : null}

            {anchorProductearly ? (
                <Box className={cx('menu-header')} sx={{ width: '100%', zIndex: '990' }}>
                    <ProductSub onChangeChildStatus={handleChangeStatusProduct} />
                </Box>
            ) : null}
        </div>
    );
}
export default HomeHeader;
