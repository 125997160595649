import { Box, Grid } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import ProductCard from '@/components/ProductCard';
import CircularProgress from '@mui/material/CircularProgress';
import images from '@/assets/images';
import { useLocation } from "react-router-dom";


function GridProduct({ loading = false, products = [], totalPage = 0, onChangePage, showPainate = true, imgElement = null }) {
    const handleChangePage = page => {
        onChangePage(page);
        window.scrollTo(0, 0);
    };
    const location = useLocation().pathname;

    if (location === '/category/ao-hoddie') {
        imgElement = <img src={images.HOODIE} style={{ width: "100%", maxWidth: '900px' }} alt='product' />
    }
    if (location === '/category/mu') {
        imgElement = <img src={images.MU} style={{ width: "100%", maxWidth: '900px' }} alt='product' />
    }
    if (location === '/category/quan') {
        imgElement = <img src={images.QUANDUI} style={{ width: "100%", maxWidth: '900px' }} alt='product' />
    }
    if (location === '/category/polecamy') {
        imgElement = <img src={images.QUANDUI} style={{ width: "100%", maxWidth: '900px' }} alt='product' />
    }
    if (location === '/category/tat') {
        imgElement = <img src={images.TAT} style={{ width: "100%", maxWidth: '900px' }} alt='product' />
    }
    if (location === '/category/tui-xach') {
        imgElement = <img src={images.TUIXACH} style={{ width: "100%", maxWidth: '900px' }} alt='product' />
    }
    if (location === '/category/sweatshirt') {
        imgElement = <img src={images.SWEATSHIRT} style={{ width: "100%", maxWidth: '900px' }} alt='product' />
    }

    return (
        <Box>
            <Box>
                {!loading ? (
                    <Box>
                        {products.length > 0 ? (
                            <Grid container spacing={1} >
                                {products.map((item, index) => (
                                    <Grid item xs={6} sm={6} md={4} lg={4} xl={3} key={index} className='d-flex justify-center'>
                                        <ProductCard data={item} />
                                    </Grid>
                                ))}
                            </Grid>
                        ) : (
                            <Box className="c-flex justify-center align-center" sx={{ mt: 2, pl: 1, pr: 1 }}>
                                {imgElement}
                                <Box sx={{ mt: 4, fontSize: '22px' }}>Coming soon</Box>
                            </Box>

                        )}
                    </Box>
                ) : (
                    <Box className="d-flex justify-center align-center" sx={{ width: '100%', mt: 4, height: '500px' }}>
                        <CircularProgress />
                    </Box>
                )}
            </Box>
            <Box sx={{ mt: 2 }} className="d-flex justify-center">
                {showPainate && totalPage && totalPage > 0 ? (
                    <Pagination
                        color="primary"
                        count={totalPage}
                        variant="outlined"
                        onChange={(e, page) => handleChangePage(page)}
                    />
                ) : null}
            </Box>
        </Box>
    );
}
export default GridProduct;
