import axiosClient from '@/utils/axiosClient'
class AuthApi {
    login = (data) => {
        const url = '/login';
        return axiosClient.post(url, data);
    };
    logout = (data) => {
        const url = '/logout';
        return axiosClient.post(url, data);
    };
    getMe = () => {
        const url = '/me';
        return axiosClient.get(url);
    };
    smsOtp = (data) => {
        const url = '/smsotp';
        return axiosClient.post(url, data);
    };
    checkOtp = (data) => {
        const url = '/checkotp';
        return axiosClient.post(url, data);
    };

}
const authApi = new AuthApi();
export default authApi;