import MTable from '@/components/Table';
import { Box, InputAdornment, TextField } from '@mui/material';
import { Fragment, useCallback, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CreateEdit from './CreateEdit';
import { ConfirmDialog } from '@/components/ConfirmDialog';
import { toast } from 'react-toastify';
import SearchIcon from '@mui/icons-material/Search';
import { debounce } from 'lodash';
import images from '@/assets/images';
import accessoryApi from '@/api/accessoryApi';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';

function Accessory() {
    const onClose = () => setDialogData({ ...dialogData, open: false });
    const onOpenAdd = () => setDialogData({ ...dialogData, open: true, data: {}, type: 'ADD' });
    const onOpenEdit = data =>
        setDialogData({ ...dialogData, open: true, type: 'EDIT', data, title: 'Cập nhật phụ kiện / hình ảnh' });
    const [param, setParam] = useState({});
    const [tabeleData, setTableData] = useState([]);
    const [totalPage, setTotalPage] = useState(0);
    const [loading, setLoading] = useState(false);

    const getData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await accessoryApi.getAccessories(param);
            setLoading(false);
            setTableData(response.data);
            setTotalPage(response.last_page || response?.meta?.last_page);
        } catch (error) {
            setLoading(false);
        }
    }, [param]);
    const [dialogData, setDialogData] = useState({
        open: false,
        title: 'Thêm mới phụ kiện / hình ảnh',
        onClose: onClose,
        onDone: getData,
    });

    const onChangePage = page => {
        setParam({ ...param, page });
    };
    const onDelete = item => {
        ConfirmDialog({
            title: 'Xoá phụ kiện / hình ảnh',
            html: true,
            body: `Bạn có chắc chắn muốn xóa phụ kiện <b>${item.name}</b> không ?`,
            action: async onClose => {
                await accessoryApi.deleteAccessory(item.id);
                onClose();
                toast.success('Thành công!');
                getData();
            },
        });
    };
    const handleSearch = value =>
        debounce(() => {
            setParam({ ...param, search: value });
        }, 600)();

    useEffect(() => {
        getData();
    }, [getData]);
    return (
        <Box sx={{ padding: '15px' }}>
            <CreateEdit {...dialogData} />
            <Box sx={{ mb: 2 }} className="d-flex justify-space-between align-center">
                <Box sx={{ fontSize: '24px' }}>Danh sách phụ kiện</Box>
            </Box>
            <Box sx={{ mb: 1 }} className="d-flex justify-space-between">
                <TextField
                    size="small"
                    placeholder="Tìm kiếm danh mục"
                    variant="outlined"
                    style={{ width: '500px' }}
                    onChange={e => handleSearch(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                <Box>
                    <Button onClick={onOpenAdd} variant="contained" disableElevation startIcon={<AddIcon />}>
                        Thêm mới
                    </Button>
                </Box>
            </Box>
            <MTable data={tabeleData} loading={loading} countPage={totalPage} onChangePage={onChangePage}>
                {item => (
                    <Fragment>
                        <div property="name" label="Phụ kiện / Hình ảnh">
                            <Box className="d-flex align-cener">
                                <Box sx={{ width: '80px' }}>
                                    <img
                                        alt="san-pham"
                                        src={item.image_url ? item.image_url : images.noPhoto}
                                        style={{ width: item.image_url ? '100%' : '90%' }}
                                    />
                                </Box>
                                {item.name === null|| item.height === null ||item.width === null ||item.sku_code === null ||item.price === null ||item.quantity === null ||item.sale_price === null ||item.cost_price === null? (
                                    <WarningIcon fontSize="large" sx={{ color: '#e65100' }} />
                                ) : (
                                    <InfoIcon fontSize="large" color="Blue" />
                                )}
                                <Box sx={{ ml: 1, pt: 2}}>{item.name}</Box> 
                            </Box>
                        </div>
                        <div label="Mã SKU" property="sku_code"></div>
                        <div label="Màu sắc" property="color_name"></div>
                        <div label="Phân loại" property="type.name"></div>
                        <div label="Xoay" property="type.name">
                            {item.rotate_able ? 'Có' : 'Không'}
                        </div>
                        <div label="Re-Size" property="type.name">
                            {item.resize_able ? 'Có' : 'Không'}
                        </div>
                        <div label="Hành động" sx={{ width: '150px' }}>
                            <IconButton aria-label="delete" color="secondary" onClick={() => onDelete(item)}>
                                <DeleteIcon />
                            </IconButton>
                            <IconButton aria-label="edit" color="primary" onClick={() => onOpenEdit(item)}>
                                <EditIcon />
                            </IconButton>
                        </div>
                    </Fragment>
                )}
            </MTable>
        </Box>
    );
}

export default Accessory;
