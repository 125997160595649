import { call, put, takeEvery } from 'redux-saga/effects';
import { setMe, setRedirectTo, setError, setForm } from './authSlice';
import authApi from '@/api/authApi';
import { ACTION_LOGIN, ACTION_LOGOUT, ACTION_GETME } from './constant';
import { setToken, removeToken } from '@/utils/token';

const loginAction = async (username, password) => {
    try {
        const data = await authApi.login({ username, password });
        const token = data.access_token;
        if (token) {
            setToken(token);
            const me = await getMeAction();
            return { me: me, errors: {} };
        }
    } catch (error) {
        const errors = error.response.data.errors;
        for (const key in errors) {
            errors[key] = errors[key].join('. ');
        }
        return { me: null, errors };
    }
};
const getMeAction = async () => {
    const me = await authApi.getMe();
    return me;
};

const logoutAction = async () => {
    try {
        await authApi.logout();
        removeToken();
    } catch (error) {
        return null;
    }
};

// ...

// Our worker Saga: will perform the async increment task

function* loginEffect(action) {
    const data = yield call(loginAction, action.payload.username, action.payload.password);
    if (data && data.me) {
        yield put(setMe(data.me));
        yield put(
            setForm({
                username: '',
                password: '',
                errors: {},
            })
        );
        yield put(setRedirectTo(action.payload.redirectTo));
    } else {
        // yield put(setMe(null));
        if (data && data.errors) {
            yield put(setError(data.errors));
        }
    }
}
function* logoutEffect() {
    yield call(logoutAction);
    yield put(setMe(null));
}
function* getMeEffect() {
    const data = yield call(getMeAction);
    yield put(setMe(data));
}

// Our watcher Saga: spawn a new incrementAsync task on each INCREMENT_ASYNC
export default function* authSaga() {
    yield takeEvery(ACTION_LOGIN, loginEffect);
    yield takeEvery(ACTION_LOGOUT, logoutEffect);
    yield takeEvery(ACTION_GETME, getMeEffect);
}
