import { Box } from '@mui/material';
import images from '@/assets/images';
import classNames from 'classnames/bind';
import styles from './IconPeople.scss';
import Slider from 'react-slick';
import navbarApi from '@/api/navbarApi';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { Grid } from '@mui/material';

const cx = classNames.bind(styles);
const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1.1,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1.1,
                slidesToScroll: 1
            }
        }
    ]
};

const style = {
    widthContent: {
        minWidth: '170px',
        width: '22%',
        fontSize: '14px',
        borderRadius: '9px',
        height: '50px',
        color: 'black',
        fontWeight: 'BOLD'
    },
};
function IconPeople() {
    const [pathPeople, setpathPeople] = useState([]);
    const getData = useCallback(async () => {
        const response = await navbarApi.getRatingHome();
        setpathPeople(response.data);
    });

    useEffect(() => {
        getData();
    }, []);

    return (
        <Box>
            <Box sx={{ paddingTop: '2em', paddingBottom: '2em' }} className={cx('pc-peo-card')}>
                <div className={cx('d-flex peowrap')}>
                    {pathPeople.length > 0 ? (
                        <Grid sx={{ display: 'flex' }}>
                            {
                                pathPeople.map((item, index) => (
                                    <Box key={index}>
                                        <div className={cx('d-flex peowrapdiv11')}>
                                            <img src={item.path} style={{ width: '23vw', height: 'auto' }} alt='product' />
                                        </div>
                                        <br></br>
                                        <div className={cx('d-flex peowrapdiv2')}>
                                            {item.info1}
                                        </div>
                                        <div className={cx('d-flex peowrapdiv3')}>
                                            {item.info2}
                                        </div>
                                        <div className={cx('d-flex peowrapdiv4')}>
                                            {item.info3}
                                        </div>
                                    </Box>
                                ))
                            }
                        </Grid>
                    ) : (
                        <Box className="c-flex justify-center align-center" sx={{ mt: 2, pl: 1, pr: 1 }}>

                            <Box sx={{ mt: 4, fontSize: '22px' }}>Coming soon</Box>
                        </Box>

                    )}
                </div>
            </Box>
            <Box className={cx('mobile-card-people')}>
                <Slider {...settings}>
                    {
                        pathPeople.map((item, index) => (
                            <div sx={{ display: 'flex' }} key={index}>
                                <div className={cx('d-flex peowrapdiv11')}>
                                    <img src={item.path} style={{ width: '100%', height: 'auto' }} alt='product' />
                                </div>
                                <br></br>
                                <div className={cx('d-flex peowrapdiv2')}>
                                    {item.info1}
                                </div>
                                <div className={cx('d-flex peowrapdiv3')}>
                                    {item.info2}
                                </div>
                                <div className={cx('d-flex peowrapdiv4')}>
                                    {item.info3}
                                </div>
                            </div>
                        ))
                    }
                </Slider>
            </Box>
        </Box>

    );
}
export default IconPeople;
