import images from '@/assets/images';
import MDialog from '@/components/Dialog';
import classNames from 'classnames/bind';
import styles from './product.scss';

const cx = classNames.bind(styles);
function SizeDialog({ onClose, open }) {
    return (
        <MDialog open={open} style={{ textAlign: 'center' }} title="Instrukcje dotyczące wyboru rozmiaru" onClose={onClose}>
            <div className={cx('input-card-product-size')} id="sizeCM">
                <img src={images.sizeHelp} alt="Size" style={{ width: '100%' }} />
            </div>
        </MDialog>

    );
}

export default SizeDialog;
