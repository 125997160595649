import images from '@/assets/images';
import { Box } from '@mui/material';
import classNames from 'classnames/bind';
import styles from './backup.scss';
import React from "react";

const cx = classNames.bind(styles);

function BackUp() {
    return (
        <div>
            <Box>
                <Box sx={{ backgroundImage: `url(${images.backCustome})` }} className={cx('back-up')} >

                </Box>
                <Box sx={{ backgroundImage: `url(${images.backCustomeMobile})` }} className={cx('back-up-mobile')} >

                </Box>
            </Box>
        </div>
    );
}
export default BackUp;
