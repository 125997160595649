import { Box, TextField, Autocomplete, MenuItem } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Payment from './Payment';
import Grid from '@mui/material/Grid';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import regionApi from '@/api/regionApi';
import orderApi from '@/api/orderApi';
import { toast } from 'react-toastify';
import styles from './OrderDialog.module.scss';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
const cx = classNames.bind(styles);

const sizeDesign = ['S', 'M', 'L', 'XL'];
function OrderDialog({ open, handleCloseOrder, icons }) {
    const dataDesign = useSelector(state => state.targetDesign.iconArray);
    const productSelected = useSelector(state => state.targetDesign.productSelected);

    const me = useSelector(state => state.auth.me);
    const [form, setForm] = useState(
        me
            ? {
                name: me.name,
                email: me.email,
                phone: me.phone,
            }
            : {}
    );

    const navigate = useNavigate();

    const [provinces, setProvinces] = useState([]);
    const [province, setProvince] = useState(null);
    const [districts, setDistricts] = useState([]);
    const [district, setDistrict] = useState(null);

    const [wards, setWards] = useState([]);
    const [ward, setWard] = useState(null);
    const [errorSubmit, setErrorSubmit] = useState({});
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const getProvinces = useCallback(async () => {
        let response = await regionApi.getProvince();
        setProvinces(response);
    }, []);
    useEffect(() => {
        const getDistricts = async () => {
            let response = [];
            if (province && province.id) {
                response = await regionApi.getDistrict({ province_id: province.id });
            }
            setDistricts(response);
        };
        setDistrict(null);
        setWard(null);
        getDistricts();
    }, [province]);

    const getWards = useCallback(async () => {
        let response = [];
        if (district && district.id) {
            response = await regionApi.getWard({ district_id: district.id });
        }
        setWard(null);
        setWards(response);
    }, [district]);

    useEffect(() => {
        getProvinces();
        getWards();
    }, [getProvinces, getWards]);

    const handleChangForm = (key, value) => {
        setForm({ ...form, [key]: value });
    };
    const handleSubmit = async payment => {
        try {
            setLoadingSubmit(true);
            const formSubmit = {
                ...form,
                payment: payment,
                data_design: dataDesign,
                icons: icons,
                product: productSelected
            };
            if (payment === 'onepay') {
                const url = await orderApi.onePayOrderDesign(formSubmit);
                window.location.assign(url);
            } else {
                var respon = await orderApi.createOrderDesign(formSubmit);
                toast.success('Bạn đã đặt hàng thành công!');
                navigate(`/tra-cuu-don/${respon.order_code}`);

                // setTimeout(() => {
                //     window.location.reload();
                // }, 1000);
            }
            setLoadingSubmit(false);
        } catch (error) {
            const errors = error.response.data.errors;
            for (const key in errors) {
                errors[key] = errors[key].join('. ');
            }
            setErrorSubmit(errors);
            setLoadingSubmit(false);
        }
    };
    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xl"
        >
            <DialogTitle style={{ textAlign: 'center', fontSize: '24px', backgroundColor: 'black', color: 'white' }}>Đặt hàng sản phẩm thiết kế</DialogTitle>
            <Box className="d-flex" sx={{ width: '1000px', padding: '30px', paddingTop: '0px', zIndex: "4000", backgroundColor: 'black', color: 'white' }}>
                <Box sx={{ width: '55%', pr: 3 }} className="c-flex">
                    <Box className="c-flex" sx={{ flex: 1, pb: 3 }}>
                        <Grid container spacing={4}>
                            <Grid item xs={6}>
                                <TextField
                                    size="small"
                                    placeholder="Họ tên"
                                    onChange={e => handleChangForm('name', e.target.value)}
                                    value={form.name}
                                    variant="outlined"
                                    className={cx('cart-input')}
                                    error={errorSubmit.name ? true : false}
                                    helperText={errorSubmit.name}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    size="small"
                                    placeholder="Số điện thoại"
                                    onChange={e => handleChangForm('phone', e.target.value)}
                                    variant="outlined"
                                    className={cx('cart-input')}
                                    error={errorSubmit.phone ? true : false}
                                    value={form.phone}
                                    helperText={errorSubmit.phone}
                                />
                            </Grid>
                        </Grid>
                        <TextField
                            placeholder="Email"
                            size="small"
                            onChange={e => handleChangForm('email', e.target.value)}
                            variant="outlined"
                            className={cx('cart-input')}
                            error={errorSubmit.email ? true : false}
                            helperText={errorSubmit.email}
                            value={form.email}
                            sx={{ mt: 3 }}
                        />
                        <TextField
                            size="small"
                            placeholder="Địa chỉ"
                            onChange={e => handleChangForm('address', e.target.value)}
                            variant="outlined"
                            className={cx('cart-input')}
                            error={errorSubmit.address ? true : false}
                            helperText={errorSubmit.address}
                            value={form.address}
                            sx={{ mt: 3 }}
                        />
                        <Grid container spacing={4} sx={{ mt: 0 }}>
                            <Grid item xs={12}>
                                <Autocomplete
                                    disablePortal
                                    size="small"
                                    label="Tỉnh thành"
                                    variant="outlined"
                                    getOptionLabel={option => option.name}
                                    options={provinces}
                                    value={province}
                                    onChange={(e, newValue) => {
                                        handleChangForm('province_code', newValue.code);
                                        setProvince(newValue && newValue.code ? newValue : null);
                                    }}
                                    renderInput={params => (
                                        <TextField
                                            size="small"
                                            {...params}
                                            label="Tỉnh thành"
                                            error={errorSubmit.province_code ? true : false}
                                            helperText={errorSubmit.province_code}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    disablePortal
                                    size="small"
                                    label="Quận huyện"
                                    variant="outlined"
                                    getOptionLabel={option => option.name}
                                    options={districts}
                                    value={district}
                                    onChange={(e, newValue) => {
                                        handleChangForm('district_code', newValue.code);
                                        setDistrict(newValue && newValue.code ? newValue : null);
                                    }}
                                    renderInput={params => (
                                        <TextField
                                            size="small"
                                            {...params}
                                            label="Quận huyện"
                                            error={errorSubmit.district_code ? true : false}
                                            helperText={errorSubmit.district_code}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    size="small"
                                    disablePortal
                                    label="Xã phường"
                                    variant="outlined"
                                    getOptionLabel={option => option.name}
                                    options={wards}
                                    value={ward}
                                    onChange={(e, newValue) => {
                                        handleChangForm('ward_code', newValue.code);
                                        setWard(newValue && newValue.code ? newValue : null);
                                    }}
                                    renderInput={params => <TextField {...params} size="small" label="Xã phường" />}
                                />
                            </Grid>
                        </Grid>
                        <TextField
                            placeholder="Ghi chú"
                            onChange={e => handleChangForm('note', e.target.value)}
                            variant="outlined"
                            className={cx('cart-input')}
                            sx={{ mt: 3, mb: 1 }}
                            value={form.note}
                            size="small"
                        />
                        <Grid container spacing={2} sx={{ mt: 0 }}>
                            <Grid item xs={6}>
                                <TextField
                                    placeholder="Số lượng"
                                    label="Số lượng"
                                    variant="outlined"
                                    type="number"
                                    sx={{ width: '100%' }}
                                    className={cx('cart-input')}
                                    size="small"
                                    value={form.quantity}
                                    onChange={e => handleChangForm('quantity', e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    variant="outlined"
                                    label="Size"
                                    size="small"
                                    sx={{ width: '100%' }}
                                    className={cx('cart-input')}
                                    placeholder="Size"
                                    value={form.size}
                                    select
                                    onChange={e => handleChangForm('size', e.target.value)}
                                >
                                    {sizeDesign.map(el => (
                                        <MenuItem value={el} key={el}>
                                            {el}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box>
                        <Button variant="outlined" onClick={handleCloseOrder}>
                            Zamknąć
                        </Button>
                    </Box>
                </Box>
                <Box sx={{ flex: 1, pl: 1 }}>
                    <Box sx={{ fontSize: '18px', fontWeight: 'bold', mb: 2 }}>Phương thức thanh toán</Box>
                    <Payment onSubmit={handleSubmit} loadingButton={loadingSubmit} />
                </Box>
            </Box>
        </Dialog>
    );
}
export default OrderDialog;
