import MDialog from '@/components/Dialog';
import { Box, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import accessoryApi from '@/api/accessoryApi';

function CreateEdit({ open, title, onClose, data, type, onDone }) {
    const onSubmit = async () => {
        try {
            if (type === 'ADD') {
                await accessoryApi.addType(formData);
            }
            if (type === 'EDIT') {
                await accessoryApi.updateType(formData, formData.id);
            }
            onClose();
            toast.success('Thành công!');
            setErrorSubmit({});
            onDone();
        } catch (error) {
            const errors = error.response.data.errors;
            for (const key in errors) {
                errors[key] = errors[key].join('. ');
            }
            setErrorSubmit(errors);
        }
    };
    const [formData, setFormData] = useState({});
    const [errorSubmit, setErrorSubmit] = useState({});

    useEffect(() => {
        setFormData({ rotate_able: false, name: '', resize_able: false, description: '' });
        if (type === 'EDIT') {
            setFormData(data);
        }
    }, [type, data]);

    return (
        <div>
            <MDialog open={open} title={title} onClose={onClose} onSubmit={onSubmit} type={type}>
                <Box sx={{ width: '400px' }}>
                    <TextField
                        sx={{ width: '100%' }}
                        label="Tên phân loại"
                        variant="outlined"
                        value={formData.name}
                        error={errorSubmit.name ? true : false}
                        onChange={e => setFormData({ ...formData, name: e.target.value })}
                        helperText={errorSubmit.name}
                    />
                    <Box sx={{ mt: 2 }} className="d-flex justify-space-between">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formData.rotate_able}
                                    onChange={e => setFormData({ ...formData, rotate_able: e.target.checked })}
                                />
                            }
                            label="Có thể xoay"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formData.resize_able}
                                    onChange={e => setFormData({ ...formData, resize_able: e.target.checked })}
                                />
                            }
                            label="Có thể thay đổi kích thước"
                        />
                    </Box>
                    <TextField
                        sx={{ width: '100%', mt: 2 }}
                        label="Mô tả"
                        variant="outlined"
                        value={formData.description}
                        onChange={e => setFormData({ ...formData, description: e.target.value })}
                    />
                </Box>
            </MDialog>
        </div>
    );
}
export default CreateEdit;
