import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import classNames from 'classnames/bind';
import styles from './Service.scss';
import images from '@/assets/images';
const cx = classNames.bind(styles);

const faq = [
    {
        ask: 'Sản phẩm đã mặc/đã giặt/cắt mác có được đổi trả không?',
        answer: 'KHÔNG. Chính sách đổi trả 30 ngày áp dụng cho sản phẩm còn nhãn mác và chưa qua sử dụng.',
    },
    {
        ask: 'Tôi được đổi sản phẩm trong bao nhiêu lần?',
        answer: 'Bạn có thể đổi sản phẩm nhiều hơn 1 lần nếu bạn cho rằng sản phẩm đó vẫn chưa phù hợp với bạn',
    },
    {
        ask: 'Có được đổi sang mẫu khác không?',
        answer: 'CÓ. Bạn được đổi sang mẫu khác có giá bán bằng hoặc lớn hơn giá của sản phẩm cũ. Trường hợp bạn đổi sang sản phẩm có giá cao hơn, thì ONYOU sẽ tính giá chênh lệch của 2 sản phẩm và bạn vui lòng thanh toán cho bưu vận số tiền chênh lệch',
    },
    {
        ask: 'Hàng Sale có được đổi/trả không?',
        answer: 'KHÔNG. Hiện tại ONYOU không áp dụng đổi trả với sản phẩm thuộc danh mục Outlet, hay sản phẩm Sale. ',
    },
    {
        ask: 'Tôi có mất thêm chi phí gì khi đổi/trả không?',
        answer: 'Bạn sẽ không mất thêm bất kỳ 1 chỉ phí phát sinh nào liên quan đến việc đổi trả. Trừ trường hợp bạn đổi sang sản phẩm có giá trị cao hơn thì bạn vui lòng thanh toán cho ONYOU số tiền chênh lệch của 2 sản phẩm đó.',
    },
    {
        ask: 'Tại sao lại có chính sách như thế?',
        answer: 'ONYOU áp dụng chính sách này như 1 lời cam kết về chất lượng cũng như uy tín về sản phẩm của mình.',
    }
];
function Return() {
    const goForm = () => {
        window.open('https://my.forms.app/trungtd293/form-doi-tra-hang-30-ngay', '_blank');
    }

    return (
        <div className="d-flex justify-center bg-gray">
            <div className={cx('container-service', 'width-container')}>
                <div style={{ width: '100%', textAlign: 'center', fontSize: '32px', fontWeight: 'bold' }}>
                    Chính sách đổi trả và hoàn tiền
                </div>
                <Divider sx={{ mb: 3, mt: 1 }} />
                <div
                    style={{
                        marginBottom: '10px',
                        marginTop: '20px',
                        fontSize: '26px',
                    }}
                >
                    ONYOU hiểu được nỗi đau cũng như sự bất an của bạn khi mua sắm online như:
                </div>
                <div>
                    <Box sx={{ mb: 1, fontSize: '18px' }}>
                        <FiberManualRecordIcon sx={{ fontSize: '12px', mr: 2 }} />
                        Không thể xem và cảm nhận trực tiếp sản phẩm trước khi mua, dẫn đến việc không chắc chắn về chất
                        lượng và kích thước của sản phẩm.
                    </Box>
                    <Box sx={{ mb: 1, fontSize: '18px' }}>
                        <FiberManualRecordIcon sx={{ fontSize: '12px', mr: 2 }} />
                        Sản phẩm nhận được không giống như mô tả hoặc hình ảnh trên trang web không phản ánh đầy đủ về
                        sản phẩm.
                    </Box>
                    <Box sx={{ mb: 1, fontSize: '18px' }}>
                        <FiberManualRecordIcon sx={{ fontSize: '12px', mr: 2 }} />
                        Khó khăn trong việc đổi trả lại sản phẩm nếu không hài lòng.
                    </Box>
                </div>
                <Box className="c-flex align-center" sx={{ mt: 4 }}>
                    <Box sx={{ mb: 1 }}>
                        <h3>VÀ TRONG SỐ ĐÓ THÌ</h3>
                    </Box>
                    <Box sx={{ mb: 1 }}>
                        <h3> TRÊN 86% NỖI ĐAU ĐẾN TỪ CHẤT LIỆU SẢN PHẨM</h3>
                    </Box>
                    <Box sx={{ mb: 1 }}>
                        <h3> TRÊN 80% NỖI ĐAU ĐẾN TỪ VIỆC BỊ NHẦM SIZE SỐ</h3>
                    </Box>
                    <Box sx={{ fontSize: '20px', mb: 3, mt: 3 }}>
                        Không ai muốn mặc trên người những chiếc áo không thoải mái về chất liệu và rộng hoặc chật hơn
                        so với cơ thể mình
                    </Box>
                    <Box sx={{ fontSize: '20px', mb: 3, mt: 1 }}>
                        Chính vì vậy, ONYOU mong muốn tất cả những khách hàng của mình có được trải nghiệm tốt nhất với
                        thương hiệu. Do đó ONYOU cung cấp cho bạn chính sách đổi trả sản phẩm trong:
                    </Box>
                    <Box sx={{ mb: 1, fontWeight: 'bold', fontSize: '30px', color: 'black' }}>
                        <div> 30 ngày với 3 KHÔNG</div>
                    </Box>
                    <Box sx={{ fontSize: '18px', fontWeight: 'bold', mt: 1 }}>
                        <Box sx={{ mb: 1 }}>
                            <FiberManualRecordIcon sx={{ fontSize: '14px', mr: 2 }} />
                            KHÔNG CHI PHÍ PHÁT SINH
                        </Box>
                        <Box sx={{ mb: 1 }}>
                            <FiberManualRecordIcon sx={{ fontSize: '14px', mr: 2 }} />
                            KHÔNG THỦ TỤC RƯỜM RÀ
                        </Box>
                        <Box sx={{ mb: 1 }}>
                            <FiberManualRecordIcon sx={{ fontSize: '14px', mr: 2 }} />
                            KHÔNG PHẢI MANG HÀNG ĐI ĐỔI TRẢ
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ fontSize: '20px', mt: 4 }}>
                    <Box sx={{ mb: 2 }}>
                        ONYOU chỉ áp dụng chính sách đổi trả 30 ngày đối với
                        <b> sản phẩm được thiết kế sẵn do Stylist của ONYOU thiết kế.</b>
                    </Box>
                    <Box>
                        Thời gian 30 ngày được tính kể từ khi bạn nhận sản phẩm thì bạn sẽ được đổi hàng và trả lại hàng với điều kiện sản phẩm còn nhãn mác và chưa qua sử dụng.
                    </Box>
                </Box>
                <Box sx={{ mt: 4, fontSize: '20px', mb: 6 }}>
                    <Box sx={{ mb: 2, fontWeight: 'bold' }}>LƯU Ý: <i>"ONYOU chỉ áp dụng chính sách hoàn tiền với sản phẩm được thiết kế sẵn do Stylist của ONYOU thiết kế"</i></Box>
                    <Box sx={{ mb: 1 }}>
                        <FiberManualRecordIcon sx={{ fontSize: '13px', mr: 2 }} />
                        Chính sách không áp dụng cho sản phẩm bạn tự thiết kế
                    </Box>
                    <Box sx={{ mb: 1 }}>
                        <FiberManualRecordIcon sx={{ fontSize: '13px', mr: 2 }} />
                        Chính sách không áp dụng cho sản phẩm Outlet, Sale
                    </Box>
                </Box>
                <Box>
                    <img alt='doitra' onClick={goForm} src={images.doiTra3Buoc} style={{ width: '100%' }} />
                </Box>
                <Box>
                    <Divider sx={{ mb: 3, mt: 1 }} />
                    <div
                        style={{
                            marginBottom: '10px',
                            marginTop: '20px',
                            fontSize: '26px',
                        }}
                    >
                        OnYou sẽ thực hiện hoàn tiền cho khách hàng trong những trường hợp sau:
                    </div>
                    <div>
                        <Box sx={{ mb: 1, fontSize: '18px' }}>
                            <FiberManualRecordIcon sx={{ fontSize: '12px', mr: 2 }} />
                            Hoàn tiền khi đặt hàng thành công nhưng chưa nhận được sản phẩm. Trường hợp này xảy ra khi OnYou hết sản phẩm khách hàng muốn đặt nhưng chưa cập nhật kịp thời lên website hoặc khách hàng đặt nhầm
                        </Box>
                        <Box sx={{ mb: 1, fontSize: '18px' }}>
                            <FiberManualRecordIcon sx={{ fontSize: '12px', mr: 2 }} />
                            Hoàn tiền khi khách hàng nhận được sản phẩm những sản phẩm lỗi từ phía OnYou. Trường hợp này khách hàng có thể áp dụng chính sách đổi sang sản phẩm khác hoặc trả hàng hoàn tiền.
                        </Box>
                        <Box sx={{ mb: 1, fontSize: '18px' }}>
                            <FiberManualRecordIcon sx={{ fontSize: '12px', mr: 2 }} />
                            Hoàn tiền khi khách hàng không muốn nhận sản phẩm do nhu cầu cá nhân thay đổi.
                        </Box>
                    </div>
                    <Divider sx={{ mb: 3, mt: 1 }} />
                    <div
                        style={{
                            marginBottom: '10px',
                            marginTop: '20px',
                            fontSize: '26px',
                        }}
                    >
                        Chi phí chuyển đổi, hoàn tiền trả sản phẩm:
                    </div>
                    <div>
                        <Box sx={{ mb: 1, fontSize: '18px' }}>
                            <FiberManualRecordIcon sx={{ fontSize: '12px', mr: 2 }} />
                            Trong trường hợp lỗi từ phía OnYou: Mọi chi phí vận chuyển đổi trả sản phẩm sẽ do OnYou chịu 100%
                        </Box>
                        <Box sx={{ mb: 1, fontSize: '18px' }}>
                            <FiberManualRecordIcon sx={{ fontSize: '12px', mr: 2 }} />
                            Trong trường hợp do nhu cầu cá nhân khách hàng muốn trả sản phẩm và hoàn tiền thì toàn bộ chi phí vận chuyển trả sản phẩm khách hàng chịu 100%
                        </Box>
                    </div>
                    <Divider sx={{ mb: 3, mt: 1 }} />
                    <div
                        style={{
                            marginBottom: '10px',
                            marginTop: '20px',
                            fontSize: '26px',
                        }}
                    >
                        Thời gian hoàn tiền
                    </div>
                    <div>
                        <Box sx={{ mb: 1, fontSize: '18px' }}>
                            <FiberManualRecordIcon sx={{ fontSize: '12px', mr: 2 }} />
                            Thời gian trong vòng 7 ngày kể từ khi OnYou nhận được sản phẩm khách hàng trả.
                        </Box>
                        <Box sx={{ mb: 1, fontSize: '18px' }}>
                            <FiberManualRecordIcon sx={{ fontSize: '12px', mr: 2 }} />
                            Điều kiện về sản phẩm:
                            Sản phẩm còn nguyên vẹn, nhãn mác đầy đủ, kèm theo các sản phẩm tặng kèm (nếu có)
                            Sản phẩm tuyệt đối chưa được qua sử dụng

                        </Box>
                    </div>
                    <Divider sx={{ mb: 3, mt: 1 }} />
                    <Box sx={{ mb: 2, fontWeight: 'bold' }}>LƯU Ý: <i>"OnYou có quyền từ chối hoàn tiền khi sản phẩm khách hàng trả không thỏa mãn 2 điều kiện trên."</i></Box>

                </Box>
                <Divider sx={{ mb: 3, mt: 3 }} />
                <Box className="d-flex justify-center" sx={{ fontSize: '26px', fontWeight: 'bold', mt: 4 }}>
                    FAQs
                </Box>
                <Divider sx={{ mb: 3, mt: 3 }} />
                <div>
                    {faq.map((item, index) => (
                        <Box key={index} sx={{ mb: 2 }}>
                            <Box sx={{ fontWeight: 'bold', fontSize: '20px', mb: 1 }}>
                                {index + 1}. {item.ask}
                            </Box>
                            <Box sx={{ ml: 3, fontSize: '20px' }}>{item.answer}</Box>
                        </Box>
                    ))}
                </div>
            </div>
        </div>
    );
}
export default Return;
