import MTable from '@/components/Table';
import { Box, InputAdornment, TextField } from '@mui/material';
import { Fragment, useCallback, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import HideIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CreateEdit from './CreateEdit';
import { ConfirmDialog } from '@/components/ConfirmDialog';
import { toast } from 'react-toastify';
import SearchIcon from '@mui/icons-material/Search';
import { debounce } from 'lodash';
import promotionApi from '@/api/promotionApi';
import Chip from '@mui/material/Chip';

function Promotion() {
    const onClose = () => setDialogData({ ...dialogData, open: false });
    const onOpenAdd = () => setDialogData({ ...dialogData, open: true, data: {}, type: 'ADD' });
    const clickEDIT = useCallback(async (value) => {
        try {
            const response = await promotionApi.getPromotionDetail(value.id);
            setLoading(false);
            onOpenEdit(response.data[0])

        } catch (error) {
            setLoading(false);
        }
    });
    const onOpenEdit = data =>
        setDialogData({ ...dialogData, open: true, type: 'EDIT', data, title: 'Cập nhật chương trình khuyến mãi' });
    const [param, setParam] = useState({});
    const [tabeleData, setTableData] = useState([]);
    const [totalPage, setTotalPage] = useState(0);
    const [loading, setLoading] = useState(false);

    const getData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await promotionApi.getPromotion(param);
            setLoading(false);
            setTableData(response.data);
            setTotalPage(response.last_page || response?.meta?.last_page);
        } catch (error) {
            setLoading(false);
        }
    }, [param]);

    const [dialogData, setDialogData] = useState({
        open: false,
        title: 'Thêm mới chương trình khuyến mãi',
        onClose: onClose,
        onDone: getData,
    });

    const onChangePage = page => {
        setParam({ ...param, page });
    };
    const onDelete = item => {
        ConfirmDialog({
            title: 'Xoá khuyến mãi',
            html: true,
            body: `Bạn có chắc chắn muốn xóa khuyến mãi <b>${item.name}</b> không ?`,
            action: async onClose => {
                await promotionApi.deletePromotion(item.id);
                onClose();
                toast.success('Thành công!');
                getData();
            },
        });
    };


    const hidePromotion = item => {
        ConfirmDialog({
            title: 'Ẩn khuyến mãi trên TRANG CHỦ',
            html: true,
            body: `Bạn có chắc chắn muốn ẩn chương trình khuyến mãi <b>${item.name}</b> trên trang web không ?`,
            action: async onClose => {
                await promotionApi.hidePromotion(item.id);
                onClose();
                toast.success('Ẩn thành công sản phẩm!');
                getData()
            },
        });
    };
    const clickShowPromotion = item => {
        ConfirmDialog({
            title: 'Hiển thị khuyến mãi trên TRANG CHỦ',
            html: true,
            body: `Bạn có chắc chắn muốn hiển thị khuyến mãi <b>${item.name}</b> lên trang web không ?`,
            action: async onClose => {
                await promotionApi.showPromotion(item.id);
                onClose();
                toast.success('Hiển thị thành công!');
                getData()
            },
        });
    };

    const handleSearch = value =>
        debounce(() => {
            setParam({ ...param, search: value });
        }, 600)();

    useEffect(() => {
        getData();
    }, [getData]);

    const statusPromotion = (status) => {
        if (status === false) {
            return <Chip label="Đã sử dụng hết" color="warning" />
        } else {
            return <Chip label="Đang hiệu lực" color="success" />
        }
    }
    const showPromotion = (status) => {
        if (status === false) {
            return <Chip label="Không hiển thị" color="warning" />
        } else {
            return <Chip label="Được phép hiển thị" color="success" />
        }
    }
    return (
        <Box sx={{ padding: '15px' }}>
            <CreateEdit {...dialogData} />
            <Box sx={{ mb: 2 }} className="d-flex justify-space-between align-center">
                <Box sx={{ fontSize: '24px', textAlign: 'center' }}>Danh sách chương trình khuyến mãi</Box>
            </Box>
            <Box sx={{ mb: 1 }} className="d-flex justify-space-between">
                <TextField
                    size="small"
                    placeholder="Tìm kiếm"
                    variant="outlined"
                    style={{ width: '500px' }}
                    onChange={e => handleSearch(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                <Box>
                    <Button onClick={onOpenAdd} variant="contained" disableElevation startIcon={<AddIcon />}>
                        Thêm mới
                    </Button>
                </Box>
            </Box>
            <MTable data={tabeleData} loading={loading} countPage={totalPage} onChangePage={onChangePage}>
                {item => (
                    <Fragment>
                        <div property="name" label="Tên"></div>
                        <div property="code" label="Mã Chương trình khuyến mãi"></div>
                        <div label="Thời gian" property="time">
                        </div>
                        <div label="Đã dùng / Số lượng">
                            {item.used} / {item.quantity}
                        </div>
                        <div label="Trạng thái">
                            {statusPromotion(item.status)}
                        </div>
                        <div label="Hiển thị trên Website">
                            {showPromotion(item.show)}
                        </div>
                        <div label="Hành động" sx={{ width: '150px' }}>
                            {
                                item.show === false ?
                                    <IconButton aria-label="hide" color="warning" onClick={() => clickShowPromotion(item)}>
                                        <VisibilityIcon />
                                    </IconButton> :
                                    <IconButton aria-label="hide" color="warning" onClick={() => hidePromotion(item)}>
                                        <HideIcon />
                                    </IconButton>
                            }
                            <IconButton aria-label="delete" color="secondary" onClick={() => onDelete(item)}>
                                <DeleteIcon />
                            </IconButton>
                            <IconButton aria-label="edit" color="primary" onClick={() => clickEDIT(item)}>
                                <EditIcon />
                            </IconButton>
                        </div>
                    </Fragment>
                )}
            </MTable>
        </Box>
    );
}
export default Promotion;
