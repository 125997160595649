import MTable from '@/components/Table';
import { Box, InputAdornment, TextField } from '@mui/material';
import { Fragment, useCallback, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import LockIcon from '@mui/icons-material/Lock';
import EditIcon from '@mui/icons-material/Edit';
import CreateEdit from './CreateEdit';
import { ConfirmDialog } from '@/components/ConfirmDialog';
import { toast } from 'react-toastify';
import SearchIcon from '@mui/icons-material/Search';
import { debounce } from 'lodash';
import flashsaleApi from '@/api/flashsaleApi';
import Chip from '@mui/material/Chip';

function Flashsale() {
    const onClose = () => setDialogData({ ...dialogData, open: false });
    const onOpenAdd = () => setDialogData({ ...dialogData, open: true, data: {}, type: 'ADD' });
    const onOpenEdit = data =>
        setDialogData({ ...dialogData, open: true, type: 'EDIT', data, title: 'Cập nhật Flash Sale' });
    const [param, setParam] = useState({});
    const [tabeleData, setTableData] = useState([]);
    const [totalPage, setTotalPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const clickEDIT = useCallback(async (value) => {
        try {
            const response = await flashsaleApi.getFlashsaleDetail(value.id);
            setLoading(false);
            onOpenEdit(response.data[0])
        } catch (error) {
            setLoading(false);
        }
    });
    const getData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await flashsaleApi.getFlashsale(param);
            setLoading(false);
            setTableData(response.data);
            setTotalPage(response.last_page || response?.meta?.last_page);
        } catch (error) {
            setLoading(false);
        }
    }, [param]);
    const [dialogData, setDialogData] = useState({
        open: false,
        title: 'Thêm mới chương trình Flash sale',
        onClose: onClose,
        onDone: getData,
    });

    const onChangePage = page => {
        setParam({ ...param, page });
    };
    const onDelete = item => {
        ConfirmDialog({
            title: 'Xoá phân loại phụ kiện',
            html: true,
            body: `Bạn có chắc chắn muốn xóa phân loại <b>${item.name}</b> không ?`,
            action: async onClose => {
                await flashsaleApi.deleteFlashsale(item.id);
                onClose();
                toast.success('Thành công!');
                getData();
            },
        });
    };
    const hideProduct = item => {
        ConfirmDialog({
            title: 'Thay đổi trạng thái Flash sale',
            html: true,
            body: `Bạn có chắc chắn muốn cập nhật trạng thái của <b>${item.name}</b> không ?`,
            action: async onClose => {
                await flashsaleApi.updateStatusFlashsale(item.id);
                onClose();
                toast.success('Cập nhật thành công!');
                getData()
            },
        });
    };
    const handleSearch = value =>
        debounce(() => {
            setParam({ ...param, search: value });
        }, 600)();

    useEffect(() => {
        getData();
    }, [getData]);
    const statusFlashsale = (status) => {
        if(status === true){
            return <Chip label="Đang kích hoạt" color="success"/>
        }
        if(status === false){
            return <Chip label="Đã ngừng hoạt động" color="error">Đã ngừng hoạt động</Chip>
        }
    }
    const statusLockFlashsale = (status, item) => {
        if(status === true){
            return <IconButton aria-label="hide" color="secondary" onClick={() => hideProduct(item)}>
                            <VpnKeyIcon />
                    </IconButton>
        }
        if(status === false){
            return <IconButton aria-label="hide" color="secondary" onClick={() => hideProduct(item)}>
                            <LockIcon />
                    </IconButton>
        }
    }
    return (
        <Box sx={{ padding: '15px' }}>
            <CreateEdit {...dialogData} />
            <Box sx={{ mb: 2 }} className="d-flex justify-space-between align-center">
                <Box sx={{ fontSize: '24px' }}>Danh sách chương trình Flash Sale</Box>
                <Box style={{ color: 'red', fontWeight: 'bold' }} sx={{ fontSize: '14px' }}>Tại mỗi thời điểm chỉ kích hoạt 1 chương trình flashSale</Box>
            </Box>
            <Box sx={{ mb: 1 }} className="d-flex justify-space-between">
                <TextField
                    size="small"
                    placeholder="Tìm kiếm"
                    variant="outlined"
                    style={{ width: '500px' }}
                    onChange={e => handleSearch(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                <Box>
                    <Button onClick={onOpenAdd} variant="contained" disableElevation startIcon={<AddIcon />}>
                        Thêm mới
                    </Button>
                </Box>
            </Box>
            <MTable data={tabeleData} loading={loading} countPage={totalPage} onChangePage={onChangePage}>
                {item => (
                    <Fragment>
                        <div property="name" label="Tên chương trình"></div>
                        <div property="code" label="Mã Flash Sale"></div>
                        <div label="Thời gian bắt đầu" property="start_at">
                        </div>
                        <div label="Thời gian kết thúc" property="end_at">
                        </div>
                        <div label="Đã dùng / Số lượng">
                            {item.used} / {item.quantity}
                        </div>
                        <div label="Trạng thái">
                            {statusFlashsale(item.active)}
                        </div>
                        <div label="Hành động" sx={{ width: '120px' }}>
                            {statusLockFlashsale(item.active, item)}
                            <IconButton aria-label="edit" color="primary" onClick={() => clickEDIT(item)}>
                                <EditIcon />
                            </IconButton>
                        </div>
                    </Fragment>
                )}
            </MTable>
        </Box>
    );
}
export default Flashsale;
