import axiosClient from '@/utils/axiosClient'
class DashboardApi {
    getIndex = params => {
        const url = '/index-dashboard';
        return axiosClient.get(url, {params});
    };
    getPieData= params => {
        const url = '/pie-dashboard';
        return axiosClient.get(url, {params});
    };
}
const dashboardApi = new DashboardApi();
export default dashboardApi;