import productApi from '@/api/productApi';
import MDialog from '@/components/Dialog';
import { Box, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ImageUpload from '@/components/UploadImage/UploadSingle';

function CreateEdit({ open, title, onClose, data, type, onDone }) {
    const onSubmit = async () => {
        try {
            if (type === 'ADD') {
                await productApi.addCategory(formData);
            }
            if (type === 'EDIT') {
                await productApi.updateCategory(formData,formData.id);
            }
            onClose();
            toast.success('Thành công!');
            setErrorSubmit({});
            onDone()
        } catch (error) {
            const errors = error.response.data.errors;
            for (const key in errors) {
                errors[key] = errors[key].join('. ');
            }
            setErrorSubmit(errors);
        }
    };
    const [formData, setFormData] = useState({});
    const [errorSubmit, setErrorSubmit] = useState({});

    useEffect(() => {
        setFormData({ url_image: null, name: '', code: '' });
        if (type === 'EDIT') {
            setFormData(data);
        }
    }, [type, data]);

    const handleUpload = urlImage => {
        setFormData({ ...formData, url_image: urlImage });
    };

    return (
        <div>
            <MDialog open={open} title={title} onClose={onClose} onSubmit={onSubmit} type={type}>
                <Box sx={{ width: '400px' }}>
                    <ImageUpload onDone={handleUpload} url={formData.url_image}/>
                    <TextField
                        sx={{ width: '100%', mt: 3 }}
                        label="Tên danh mục"
                        variant="outlined"
                        value={formData.name}
                        error={errorSubmit.name ? true : false}
                        onChange={e => setFormData({ ...formData, name: e.target.value })}
                        helperText={errorSubmit.name}
                    />
                </Box>
            </MDialog>
        </div>
    );
}
export default CreateEdit;
