import classNames from 'classnames/bind';
import styles from './HomeSearch.scss';
import images from '@/assets/images';
import { InputAdornment, Box, TextField, Button } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import styled from "styled-components";

const cx = classNames.bind(styles);

const style = {
    widthContent: {
        minWidth: '170px',
        width: '22%',
        fontSize: '14px',
        borderRadius: '9px',
        height: '50px',
        color: 'black',
        fontWeight: 'BOLD'
    },
};

function HomeSearch({ height }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();
    const goToLink = link => {
        setAnchorEl(null);
        navigate(link);
    };

    const [keyWord, setKeyWord] = useState('');

    const handleKeyDown = e => {
        if (e.key === 'Enter') {
            window.location.href = encodeURI('/search?search=' + keyWord);

        }
    };

    return (
        <Box>
            <Box sx={{ paddingLeft: '160px', paddingRight: '160px', fontFamily: 'MyFont' }} className={cx('homedesign-card-pc')} >
                <div className={cx('d-flex homedesign2')}>
                    <div className={cx('homedesign-top1')}>
                        Bạn tìm gì hôm nay?
                    </div>
                    <br></br>
                    <div className={cx('home-search-card')}>
                        <TextField
                            size="medium"
                            variant="outlined"
                            id='abcde'
                            onKeyDown={handleKeyDown}
                            value={keyWord}
                            placeholder="Nhập nội dung bạn muốn tìm kiếm"
                            style={{ width: '600px', backgroundColor: 'white', borderRadius: '20px' }}
                            onChange={e => setKeyWord(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <div sx={{ fontSize: '10px !important' }}>
                        được tìm kiếm nhiều nhất
                    </div>
                    <div sx={{ fontSize: '10px !important' }}>
                        {/* <Box>
                                <Button backgroundColor='white' size="small" color="error">Small</Button>
                                </Box> */}
                    </div>
                </div>
            </Box>
            <Box className={cx('homedesign-card-mobile')}>
                <div className={cx('d-flex homedesign2')}>
                    <div className={cx('homedesign-top1')}>
                        Bạn tìm gì hôm nay?
                    </div>
                    <br></br>
                    <div className={cx('home-search-card')}>
                        <TextField
                            size="small"
                            placeholder="Nhập nội dung bạn muốn tìm kiếm"
                            variant="outlined"
                            style={{ width: '100%', backgroundColor: 'white', borderRadius: '20px' }}
                            onChange={e => setKeyWord(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <div sx={{ fontSize: '10px !important' }}>
                        được tìm kiếm nhiều nhất
                    </div>
                    <div sx={{ fontSize: '10px !important' }}>
                        {/* <Box>
                            <Button backgroundColor='white' size="small" color="error">Small</Button>
                            </Box> */}
                    </div>
                </div>
                <div className={cx('d-flex homedesign3')}>

                </div>
            </Box>
        </Box>

    );
}
export default HomeSearch;
