import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import classNames from 'classnames/bind';
import styles from './login-signup.scss';
import images from '@/assets/images';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ACTION_LOGIN } from '@/store/auth/constant';
import { setForm } from '@/store/auth/authSlice';

const cx = classNames.bind(styles);

function Login() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [style, setStyle] = useState({
        widthContent: {
            fontSize: '14px',
            borderRadius: '9px',
            color: 'black',
            fontWeight: 'BOLD',
            backgroundColor: '#f6fded',
            display: 'none',
            maxWidth: '450px',
            width: '100%'
        },
    });
    const [style1, setStyle1] = useState({
        widthContent: {
            fontSize: '14px',
            borderRadius: '9px',
            color: 'black',
            fontWeight: 'BOLD',
            display: 'block',
            maxWidth: '450px',
            width: '100%'
        },
    });

    const [style2, setStyle2] = useState({
        widthContent: {
            fontSize: '14px',
            borderRadius: '9px',
            color: 'black',
            fontWeight: 'BOLD',
            display: 'none',
            maxWidth: '450px',
            width: '100%'
        },
    });


    const goHome = () => {
        navigate('/');
    };
    const me = useSelector(state => state.auth.me);

    const handleClickOpen = () => {
        setOpen(true);
        setStyle({
            widthContent: {
                fontSize: '14px',
                borderRadius: '9px',
                color: 'black',
                fontWeight: 'BOLD',
                backgroundColor: '#f6fded',
                display: 'block',
                maxWidth: '450px',
                width: '100%'
            },
        });
        setStyle1({
            widthContent: {
                fontSize: '14px',
                borderRadius: '9px',
                color: 'black',
                fontWeight: 'BOLD',
                backgroundColor: 'gray',
                display: 'none',
                maxWidth: '450px',
                width: '100%'
            },
        });
    };

    const handleClose = () => {
        setOpen(false);
    };

    const form = useSelector(state => state.auth.form);
    const handleInputForm = (label, value) => {
        dispatch(setForm({ [label]: value, errors: { ...form.errors, [label]: null } }));
    };

    const loginSubmit2 = () => {
        setStyle2({
            widthContent: {
                fontSize: '14px',
                borderRadius: '9px',
                color: 'black',
                fontWeight: 'BOLD',
                backgroundColor: '#f6fded',
                display: 'block',
                maxWidth: '450px',
                width: '100%'
            },
        });
        setStyle({
            widthContent: {
                fontSize: '14px',
                borderRadius: '9px',
                color: 'black',
                fontWeight: 'BOLD',
                backgroundColor: 'gray',
                display: 'none',
                maxWidth: '450px',
                width: '100%'
            },
        });

    };

    const loginSubmit = async () => {
        const data = { ...form, redirectTo: '/' };
        dispatch({ type: ACTION_LOGIN, payload: data });
    };
    const redirect = useSelector(state => state.auth.redirectTo);

    useEffect(() => {
        if (me && !redirect) {
            navigate('/');
        }
        if (redirect) {
            navigate(redirect);
        }
    }, [me, redirect, navigate]);

    useEffect(() => {
    }, [open]);

    return (
        <div sx={{ backgroundImage: 'images.backgroundLogin !important' }} className={cx('wrapper-login')}>
            <div className={cx('logo-box')}>
                <div className={cx('logo')} onClick={goHome}>
                    <img src={images.logoWidth} alt="Logo" />
                </div>
                <div className={cx('login-text')}>Đăng nhập tài khoản của bạn</div>
            </div>
            <Card style={style1.widthContent} variant="outlined">
                <div className={cx('card-login')}>
                    <Box className={cx('login-label')}>E-Mail/SĐT</Box>
                    <TextField
                        size="small"
                        sx={{
                            width: '100%',
                        }}
                        variant="outlined"
                        mb={3}
                        value={form.email}
                        onChange={e => handleInputForm('username', e.target.value)}
                        helperText={form.errors.username}
                        error={form.errors.username}
                    />

                    <Box className={cx('login-label')} mt={3}>
                        Mật khẩu
                    </Box>
                    <TextField
                        size="small"
                        sx={{
                            width: '100%',
                        }}
                        variant="outlined"
                        value={form.password}
                        type="password"
                        onChange={e => handleInputForm('password', e.target.value)}
                        helperText={form.errors.password}
                        error={form.errors.password}
                    />

                    <Button
                        variant="contained"
                        onClick={loginSubmit}
                        sx={{
                            width: '100%',
                            backgroundColor: '#F37121',
                            marginTop: '35px',
                            borderRadius: '5px',
                        }}
                        disableElevation
                    >
                        ĐĂNG NHẬP
                    </Button>
                    <Box mt={4}>
                        <Divider />
                    </Box>
                    <div className="c-flex align-center">
                        <Box mt={3} className={cx('login-label')}>
                            Bạn chưa có tài khoản ?
                            Vui lòng <span onClick={handleClickOpen} style={{ color: '#F37121' }}>đăng ký</span>
                        </Box>
                    </div>
                </div>
            </Card>
            <Card style={style.widthContent} variant="outlined">
                <div className={cx('card-login')}>
                    <Box className={cx('login-label')}>Nhập số điện thoại</Box>
                    <TextField
                        size="small"
                        sx={{
                            width: '100%',
                        }}
                        variant="outlined"
                        mb={3}
                    />
                    <Button
                        variant="contained"
                        onClick={loginSubmit2}
                        sx={{
                            width: '100%',
                            backgroundColor: '#F37121',
                            marginTop: '35px',
                            borderRadius: '5px',
                        }}
                        disableElevation
                    >
                        XÁC NHẬN
                    </Button>
                    <Box mt={4}>
                        <Divider />
                    </Box>
                    <div className="c-flex align-center">
                        <Box mt={3} className={cx('login-label')}>
                            Bạn quên mật khẩu ?
                            Vui lòng click <span onClick={handleClickOpen} style={{ color: '#F37121' }}>Quên mật khẩu</span>
                        </Box>
                    </div>
                </div>
            </Card>
            <Card style={style2.widthContent} variant="outlined">
                <div className={cx('card-login')}>
                    <Box className={cx('login-label')}>Nhập mã xác thực</Box>
                    <TextField
                        size="small"
                        sx={{
                            width: '100%',
                        }}
                        variant="outlined"
                        mb={3}
                    />
                    <Button
                        variant="contained"
                        onClick={loginSubmit2}
                        sx={{
                            width: '100%',
                            backgroundColor: '#F37121',
                            marginTop: '35px',
                            borderRadius: '5px',
                        }}
                        disableElevation
                    >
                        XÁC THỰC
                    </Button>
                    <Box mt={4}>
                        <Divider />
                    </Box>
                    <div className="c-flex align-center">
                        <Box mt={3} className={cx('login-label')}>
                            Bạn không nhận được mã xác thực ?
                            Vui lòng click <span style={{ color: '#F37121' }}>Gửi lại mã</span>
                        </Box>
                    </div>
                </div>
            </Card>
        </div>
    );
}

export default Login;
