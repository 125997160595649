import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import classNames from 'classnames/bind';
import styles from './Service.scss';
const cx = classNames.bind(styles);

const textData = [
    {
        title: 'Cel i zakres gromadzenia informacji',
        text1: '',
        line: [
            'Posiadając blisko piętnastoletnie doświadczenie w działalności jako hurtownia obuwie, prowadząc sklep online zapewniamy naszym klientom dostęp do najwyższej jakości odzieży damskiej i dziecięcej. Przez wiele lat zbieraliśmy obserwacje i dokładnie poznawaliśmy rynek, dzięki czemu uruchamiając hurt tureckiej odzieży damskiej możemy Państwu zaoferować produkty odpowiadające panującym trendom, fasonom oraz w atrakcyjnej kolorystyce.',
            'Działając jako nowoczesna i tania hurtownia odzieży online, proponujemy szeroki wybór ubrań zarówno w klasycznych czerniach i szarościach, jak również w mocnych i zdecydowanych barwach, które z całą pewnością przypadną do gustu nawet najbardziej wymagającym klientkom. Jako producent dokładamy wszelkich starań, aby oferowana przez nas odzież hurt odznaczała się wysoką jakością i doskonałym wykończeniem. To co posiada w swoim asortymencie nasza hurtownia odzieżowa, to przede wszystkim damska moda, czyli sukienki, spódnice, bluzki, narzutki, spodnie oraz wiele innych ubrań, dostępnych w szerokiej gamie rozmiarów.',
            'Nie działamy jak stacjonarne hurtownie odzieżowe, ale prowadzimy sprzedaż za pośrednictwem sieci, nastawioną na profesjonalną obsługę klientów oraz automatyzację zamówień. Jako internetowa hurtownia odzieży damskiej i dziecięcej, zamówione towary wysyłamy maksymalnie w ciągu 24 godzin od momentu otrzymania wpłaty lub zamówienia paczki za pobraniem. Klienci, którzy wybiorą nasz sklep online hurtowo oferujący ubrania, mogą również zdecydować się na opcję odbioru osobistego w siedzibie naszej firmy, po wcześniejszym dokonaniu płatności na rachunek bankowy.',
            'Hurtownia sukienek, spodni i bluzek zabawkahurtownia.pl to szeroki asortyment produktów i indywidualne podejście do każdego klienta. Jako doświadczony producent odzieży damskiej dokładamy wszelkich starań, aby zakupy u nas były przyjemne, komfortowe i w pełni satysfakcjonujące.'
        ]
    }
];
function Security() {
    return (
        <div className="d-flex justify-center bg-gray">
            <div className={cx('container-service', 'width-container')}>
                <div style={{ width: '100%', textAlign: 'center', fontSize: '24px', fontWeight: 'bold' }}>
                    INFORMACJE O SKLEPIE
                </div>
                <Divider sx={{ mb: 3, mt: 1 }} />
                <Box>
                    <Box sx={{ mt: 4, fontSize: '16px' }}>
                        {textData.map((item, index) => (
                            <div key={index}>
                                <Box sx={{ fontSize: '18px', fontWeight: 'bold', mt: 3 }}>
                                    {index + 1}. {item.title}
                                </Box>
                                <Box sx={{ mt: 2, mb: 2 }}>{item.text1}</Box>
                                {item.line.map((line, id) => (
                                    <Box key={id} sx={{ pl: 3 }}>
                                        <FiberManualRecordIcon sx={{ fontSize: '13px', mr: 2 }} />
                                        {line}
                                    </Box>
                                ))}
                                <Box sx={{ mt: 2 }}>{item.text2}</Box>
                            </div>
                        ))}
                    </Box>
                </Box>
            </div>
        </div>
    );
}
export default Security;
