import { Box } from '@mui/material';
import classNames from 'classnames/bind';
import styles from './about.module.scss';
const cx = classNames.bind(styles);

function UNuStory() {
    return (
        <Box className="d-flex justify-center bg-gray">
            <div className={cx('container-about', 'width-container')} style={{ fontSize: '18px' }}>
                <Box>
                    Lấy cảm hứng từ chú chó Corgi Hoàng Gia đầy trách nhiệm, trung thành và đặc biệt yêu thích sự tự do
                    với những chuyến phiêu lưu mạo hiểm, chúng tôi mong muốn tạo ra một thế giới mới trải nghiệm thời
                    trang độc nhất và hấp dẫn nhất
                </Box>
                <Box sx={{ mt: 1 }}>
                    <b>OnYou</b> được xây dựng dựa trên tôn chỉ/ phương châm tôn trọng sự sáng tạo của khách hàng và
                    những sản phẩm độc đáo mang dấu ấn cá nhân hóa, tại đây bạn được tự do thể hiện tính cách và bản sắc
                    cá nhân bằng những sản phẩm độc đáo và độc nhất.
                </Box>
                <Box sx={{ mt: 1 }}>
                    Với hình ảnh chú chó Corgi - được coi là loài chó bậc nhất của Hoàng Gia Anh, OnYou luôn nỗ lực đem
                    đến cho khách hàng sản phẩm chất lượng nhất, những trải nghiệm đáng giá nhất, hướng đến trở thành
                    thương hiệu thời trang sáng tạo đứng đầu và đẳng cấp
                </Box>
                <Box sx={{ mt: 2, fontSize: '24px', fontWeight: 'bold', color: '#CB4335' }}>
                    <i>Câu chuyện về Unu</i>
                </Box>
                <Box sx={{ mt: 1 }}>
                    Câu chuyện kể về chú chó corgi tên là Unu. Unu là thú cưng của một gia đình hoàng gia Anh với cuộc
                    sống đầy mơ ước đối với hàng ngàn chú chó khác: Gia đình nhà chủ giàu có, được chủ nhân yêu thương
                    cưng chiều. Unu luôn được chăm sóc tỉ mỉ từ thực đơn ăn uống đến lịch spa chăm sóc lông móng hàng
                    ngày.
                </Box>
                <Box sx={{ mt: 1 }}>
                    Mỗi ngày, sau khi ăn uống đầy đủ, cậu được rong chơi trong khu vườn rộng rãi và sạch sẽ tại biệt thự
                    gia đình. Cậu được khoác trên mình những bộ đồ xinh xắn và có khu vui chơi riêng với những món đồ
                    chơi đắt đỏ. Dường như cuộc sống của cậu đã quá hoàn hảo đến mức chẳng cần phải nghĩ ngợi gì. Ấy vậy
                    nhưng mọi thứ mà cậu có đều chỉ khiến cậu vui vẻ trong chốc lát, Unu vẫn luôn thấy trống vắng và cần
                    điều gì đó mới mẻ hơn. Những ngày tháng cứ thế trôi qua, Unu càng cảm thấy bồn chồn và khao khát
                    khám phá thế giới mới - một thế giới không bị giới hạn bởi những bức tường và chiếc cổng sắt lạnh
                    lẽo kia…
                </Box>
                <Box sx={{ mt: 1 }}>
                    Một ngày nọ, Unu quyết định trốn khỏi hoàng gia của mình và khám phá thế giới xung quanh. Với tâm
                    trạng vô cùng hào hứng, Unu lên đường bước vào rừng và khám phá những điều mới mẻ mà cậu chưa từng
                    thấy trước đây. Cậu như chìm đắm vào mọi thứ xung quanh: những bông hoa thơm ngát, những cánh đồng
                    xanh tươi và những con đường vắng vẻ…
                </Box>
                <Box sx={{ mt: 1 }}>
                    Tuy nhiên, càng đi, Unu càng lạc và không biết đường về nhà. Đói, khát và mệt mỏi, cậu dần thấy nhớ
                    chiếc nệm êm ái với những bữa ăn ngon và rồi thiếp đi trong tuyệt vọng…
                </Box>
                <Box sx={{ mt: 1 }}>
                    Trong giấc ngủ, cậu chợt nghe có tiếng bước chân lạ và những âm thanh nghe như tiếng nói chuyện nho
                    nhỏ đầy bí ẩn và hấp dẫn. Giật mình tỉnh giấc và vểnh đôi tai lên nghe ngóng, cậu nhón từng bước
                    chân bước chân thận trọng. Nhẹ nhàng bước tiếp rồi bước tiếp như bị ai đó thôi miên, rồi bỗng nhiên
                    khựng lại, trước mắt Unu là những luồng ánh sáng huyền diệu lấp lánh đầy mê hoặc mà cậu chưa từng
                    thấy trước đây
                </Box>
                <Box sx={{ mt: 1 }}>
                    Unu nheo mắt rồi từ từ căng tròn đôi mắt để nhìn ngắm những gì đang hiện ra trước mắt: Một thảo
                    nguyên mênh mông rộng lớn chạy dài về phía khu rừng, những con sông yên bình với dòng nước chảy
                    quanh đồng cỏ, xa xa là ngọn núi phủ đầy tuyết trắng. Mọi thứ như một giấc mơ mà cậu chưa bao giờ
                    nghĩ đến
                </Box>
                <Box sx={{ mt: 1 }}>“Gì thế này? Mình nằm mơ ư?”</Box>
                <Box sx={{ mt: 1 }}>
                    Unu đã đang tự hỏi chính mình. Rồi cậu chợt giật mình bởi một giọng nói: “Cậu muốn đi cùng chúng tớ
                    chứ?” - Một bạn Cừu Trắng biết đứng thẳng chỉ bằng 2 chân với bộ quần áo thật xinh đẹp. Bên cạnh là
                    bạn Voi Xanh có thêm đôi cánh thật kỳ lạ, bạn Thỏ Xám đeo một chiếc khiên nhỏ xíu và bạn Mèo Vàng có
                    chiếc “mũ hải tặc" cùng thanh kiếm cực ngầu.
                </Box>
                <Box sx={{ mt: 1 }}>
                    Unu nhón chân bước từng bước chậm rãi về phía họ, dưới chân cậu là sự êm ái của đất, sự tươi mát của
                    cỏ non với vài giọt sương mai lấp lánh. Quá thích thú, cậu chạy nhảy tung tăng trên cánh đồng mênh
                    mông ấy, lăn mình trên thảm cỏ, hít hà sự trong lành của đất trời và cười hạnh phúc như một đứa trẻ
                    lạc đã thực sự được trở về nhà.
                </Box>
                <Box sx={{ mt: 1 }}>
                    Lần đầu tiên trong cuộc đời, Unu được chạy mà không bị giới hạn bởi những bức tường thành lớn, cũng
                    không có sợi dây nào níu cậu lại, càng không có tiếng gọi hay quát mắng rằng cậu không được đi chỗ
                    này, không được chạy chỗ kia. Đó cũng là lần đầu tiên cậu cảm nhận được cảm giác của sự tự do…
                </Box>
                <Box sx={{ mt: 1 }}>
                    Với lời mời của Cừu Trắng, Voi Xanh, Thỏ Xám và Mèo Vàng, Unu quyết định cùng họ tham gia chuyến
                    phiêu lưu khám phá những vùng đất mới. Họ cùng nhau băng rừng, lội suối, cùng nhau tìm thức ăn, dạy
                    cho nhau những “món nghề" của riêng mình và giúp nhau trốn khỏi những hiểm nguy.
                </Box>
                <Box sx={{ mt: 1 }}>
                    Đó cũng là khi Unu hiểu nhiều hơn về chính mình: Thay vì chờ đợi được tắm trong bể nước đầy bọt xà
                    bông ở Hoàng Gia, cậu biết mình có thể bơi và vui đùa với Cá dưới làn nước trong xanh;
                </Box>
                <Box sx={{ mt: 1 }}>
                    Thay vì chờ đợi những bữa ăn thịnh soạn bày biện sẵn mỗi ngày, giờ đây cậu đã biết cách tìm thức ăn
                    cho mình;
                </Box>
                <Box sx={{ mt: 1 }}>
                    Thay vì mặc những bộ quần áo rườm rà và cầu kỳ, Unu thấy thoải mái hơn khi chỉ mang trên mình bộ
                    lông mềm nhẹ, và đôi khi cậu cũng tự tạo cho mình những bộ đồ thật thoải mái với phong cách bụi bặm
                    đầy năng động
                </Box>
                <Box sx={{ mt: 1 }}>
                    Unu còn khám phá ra mình có “món nghề" cực ngầu là khả năng tấn công nhanh và chính xác vào chân kẻ
                    thù mà vẫn tránh được những đòn đá hậu nguy hiểm. Cậu biết mình có thể chạy nhanh và khỏe đến mức
                    nào. Quan trọng hơn cả, Unu biết rằng cậu vô cùng yêu thích cuộc sống này, cậu thích được khám phá
                    thế giới, thích những chuyến phiêu lưu tự do và thích chạy nhảy trên những miền đất mới.
                </Box>
                <Box sx={{ mt: 1 }}>
                    Unu biết mình đang hạnh phúc, mọi cảm giác trống trải bấy lâu nay trong cậu cũng dần tan biến hết.
                    Cậu được là chính mình - một chú chó thuộc dòng Corgi năng động, hoạt bát, thân thiện, tinh nghịch
                    và thích chạy nhảy. Bên cạnh cậu còn có những người bạn đồng hành vô cùng đáng yêu và đầy thân thiện
                    giúp cậu hiểu hơn về chính mình. Qua mỗi vùng đất mới, đôi chân cậu lại nhảy múa đầy phấn khích, Unu
                    háo hức được khám phá thật nhiều, khám phá vùng trời mới cũng chính là khám phá con người cậu - chú
                    chó Corgi tự do và thích phiêu lưu mang tên Unu.
                </Box>
                <Box sx={{ mt: 1 }}>
                    <i>
                        Hình tượng Unu được OnYou lựa chọn thể hiện một phong cách sống trẻ trung, năng động và sáng
                        tạo. Unu là một chú chó nghịch ngợm, đầy năng lượng, thích khám phá và tìm hiểu những điều mới
                        mẻ. Đó cũng chính là phong cách thời trang mà thương hiệu muốn hướng đến: một cuộc đời tự do với
                        nhiều trải nghiệm.
                    </i>
                </Box>
                <Box sx={{ mt: 1 }}>
                    <i>
                        Mỗi người trong chúng ta nên học hỏi từ Unu, bước ra khỏi những rào cản thông thường để khám phá
                        thế giới xung quanh, trải nghiệm những điều mới mẻ và tìm kiếm con người thật sự của chính mình.
                        Hãy sống theo cách của Unu, sống thật với bản thân, tự do với những điều mình yêu thích và tìm
                        ra cách để thể hiện bản thân một cách độc đáo và sáng tạo. Với thông điệp này, thương hiệu hy
                        vọng sẽ giúp mọi người trở thành phiên bản tốt nhất của chính mình và sống một cuộc đời đầy ý
                        nghĩa và hạnh phúc.
                    </i>
                </Box>
            </div>
        </Box>
    );
}
export default UNuStory;
