import { createSlice } from '@reduxjs/toolkit';
import { CART_PRODUCT } from '@/constants/product';
import Cookies from 'js-cookie';

export const cartAction = createSlice({
    name: 'smax',
    initialState: {
        products: []
    },
    reducers: {
        pushToSmax: (state, action) => {
            const productAdd = action.payload;
            const productInSmax = state.products;
            
            const checkHasproduct = productInSmax.find(item => item.size_id === productAdd.size_id);
            var newProductCart = [];
            if (checkHasproduct) {
                newProductCart = productInSmax.map(item => {
                    if (item.id === productAdd.id) {
                        return { ...item, products: item };
                    }
                    return item;
                });
            } else {
                newProductCart = [...productInSmax, productAdd];
            }
            
            state.email = productAdd.email;
            state.name = productAdd.name;
            state.address = productAdd.address;
            state.payment = productAdd.payment;
            state.phone = productAdd.phone;
            state.products = newProductCart;
            state.province_code = productAdd.province_code;
            state.district_code = productAdd.district_code;
            state.voucher = productAdd.voucher;
            state.ward_code = productAdd.ward_code;
            
        },
        updateSmax: (state, action) => {
            const product = action.payload;
            const old_products = state.products;
            var newProductCart = [];
            const checkHasproduct = old_products.find(item => item.size_id === product.size_id);
            if (checkHasproduct) {
                newProductCart = old_products.map(item => {
                    if (item.size_id === product.size_id) {
                        return { ...item, quantity: Number(product.quantity) };
                    }
                    return item;
                });
                state.products = newProductCart;
            } else {
                if(product.size_id){
                    product['product_id'] = product.id
                    newProductCart = [...old_products, product];
                    state.products = newProductCart;
                }
            }
        },
        removeSmax: (state, action) => {
            
        },
        removeAllItem: state => {
            Cookies.remove(CART_PRODUCT);
            state.quantity = 0;
            state.cartData = [];
            state.provisionalMoney = 0;
        },
    },
});

export const { pushToSmax, updateSmax, removeSmax,removeAllSmax } = cartAction.actions;

export default cartAction.reducer;
