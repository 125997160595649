import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { publicRoutes, privateRoutes, publicRoutesNoFooter } from '@/routes';
import { HomeLayout, AdminLayout, HomeLayoutNoFooter } from '@/components/Layout';
import { Fragment, useCallback, useEffect, useState } from 'react';
import Error from './pages/Error';
import { getToken } from './utils/token';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import authApi from './api/authApi';
import { setMe } from './store/auth/authSlice';
import { removeToken } from './utils/token';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useLocation } from 'react-router';

const ScrollToTop = props => {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }, [location]);

    return <>{props.children}</>;
};

function App() {
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const token = getToken();
    const me = useSelector(state => state.auth.me);
    const userRoleCode = me?.role_code;

    const getMe = useCallback(async () => {
        try {
            if (token && !me) {
                setLoading(true);
                const meInfo = await authApi.getMe();
                dispatch(setMe(meInfo));
                setLoading(false);
            }
            setLoading(false);
        } catch (error) {
            removeToken();
            dispatch(setMe(null));
        }
    }, [token, me, dispatch]);

    useEffect(() => {
        getMe();
    }, [getMe]);

    const privateRoute = (roles, children, path) => {
        if (!userRoleCode) {
            return <Navigate to={`/login?redirect=${path}`} replace />;
        }
        if (!roles || roles.includes(userRoleCode)) {
            return children;
        }
        if (roles && !roles.includes(userRoleCode)) {
            return <Error />;
        }
    };
    if (loading) {
        return (
            <Box
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100vh' }}
            >
                <CircularProgress />
            </Box>
        );
    }
    return (
        <BrowserRouter>
            <ScrollToTop>
                <Routes>
                    {publicRoutes.map((route, index) => {
                        const Page = route.component;
                        var Layout = Fragment;
                        if (route.layout) {
                            Layout = route.layout;
                        }
                        if (route.layout !== null) {
                            Layout = HomeLayout;
                        }
                        return (
                            <Route
                                key={index}
                                path={route.path}
                                element={
                                    <Layout>
                                        <Page />
                                    </Layout>
                                }
                            />
                        );
                    })}

                    {publicRoutesNoFooter.map((route, index) => {
                        const Page = route.component;
                        var Layout = Fragment;
                        if (route.layout) {
                            Layout = route.layout;
                        }
                        if (route.layout !== null) {
                            Layout = HomeLayoutNoFooter;
                        }
                        return (
                            <Route
                                key={index}
                                path={route.path}
                                element={
                                    <Layout>
                                        <Page />
                                    </Layout>
                                }
                            />
                        );
                    })}

                    {privateRoutes.map((route, index) => {
                        var Layout = AdminLayout;
                        var Page = route.component;
                        if (route.layout) {
                            Layout = route.layout;
                        }
                        if (route.children && route.children.length > 0) {
                            return (
                                <Route
                                    key={index}
                                    path={route.path}
                                    // element={privateRoute(route.role, <Layout />, route.path)}
                                    element={<Layout />}
                                >
                                    {route.children.map((child, childIndex) => {
                                        Page = child.component;
                                        return (
                                            <Route
                                                key={childIndex}
                                                path={child.path}
                                                element={privateRoute(
                                                    child.role,
                                                    <Page />,
                                                    `${route.path}/${child.path}`
                                                )}
                                            />
                                        );
                                    })}
                                </Route>
                            );
                        }
                        return (
                            <Route
                                key={index}
                                path={route.path}
                                element={privateRoute(
                                    route.role,
                                    <Layout>
                                        <Page />
                                    </Layout>,
                                    route.path
                                )}
                            />
                        );
                    })}
                    <Route path="*" element={<Error />} />
                </Routes>
            </ScrollToTop>
        </BrowserRouter>
    );
}

export default App;
