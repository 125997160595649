import styles from './design.scss';
import classNames from 'classnames/bind';
import { useState, useMemo, useEffect } from 'react';
import DesignView from '@/components/DesignView';
import DesignBoxNew from './components/DesignBoxNew';
import Card from '@mui/material/Card';
import IconImageOnly from './components/IconImageOnly';
import IconImageText from './components/IconImageText';
import ProductDesign from './components/Product';
import ProductColor from './components/ProductColor';
import ProductDesignBottom from './components/ProductBottom';
import { toast } from 'react-toastify';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import Box from '@mui/material/Box';
import TabList from '@mui/lab/TabList';
import AppsIcon from '@mui/icons-material/Apps';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { getToken } from '@/utils/token';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import authApi from '@/api/authApi';
import Button from '@/components/Button';
import LoadingOverlay from 'react-loading-overlay';
import { useSelector, useDispatch } from 'react-redux';
import { setElementTarget } from '@/store/design/targetDesignSlice';
import PriceChangeOutlinedIcon from '@mui/icons-material/PriceChangeOutlined';
import OrderBox from './components/OrderBox/OrderBox';
import OrderDialog from './components/OrderDialog/OrderDialog';
import MovingText from 'react-moving-text';
import Divider from '@mui/material/Divider';
import images from '@/assets/images';
import { InputAdornment, TextField } from '@mui/material';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';

const cx = classNames.bind(styles);
const SHIP_COST = 40;
const PRODUCT_COST = 300000;
function Design() {
    const [tabValue, setTabValue] = useState('1');
    const [totalPay, setTotalPay] = useState(300000);
    const iconSelected = useSelector(state => state.targetDesign.iconSelected);
    const dispatch = useDispatch();
    const [openOrder, setOpenOrder] = useState(false);
    const [openPhoiAo, setPhoiAo] = useState(false);
    const [openView, setOpenView] = useState(false);
    const [openSigUp, setOpenSigUp] = useState(false);
    const [openCheckOtp, setOpenCheckOtp] = useState(false);
    const [responseOtp, setResponseOtp] = useState(false);
    const [openPriceDetails, setOpenPriceDetails] = useState(false);
    const [openThemTep, setThemTep] = useState(true);
    const [otpPhone, setOtpPhone] = useState('');
    const [otpCheck, setOtpCheck] = useState(0);
    const [openLeftbar, setopenLeftbar] = useState(true);
    const [loadingOtp, setLoadingOtp] = useState(false);

    const backgroundphoi = useSelector(state => state.targetDesign.background);
    const token = getToken();

    const clickOpenPhoiao = () => {
        setThemTep(false);
        setPhoiAo(true);
    }
    const clickClosePhoiao = () => {
        setThemTep(true);
        setPhoiAo(false);
    }
    const clickopenLeftbar = () => {
        setopenLeftbar(true);
    }
    const clickCloseLeftBar = () => {
        setopenLeftbar(false);
    }
    const clickBuyNow = () => {
        setOpenOrder(true);
    }
    const handleClosePreview = () => {
        setOpenView(false);
    }
    const clickOpenPreview = () => {
        setOpenView(true);
    }
    const handleClosePrice = () => {
        setOpenPriceDetails(false);
    }
    const clickOpenPrice = () => {
        setOpenPriceDetails(true);
    }
    const checkAccount = () => {
        if (token == null) {
            setOpenSigUp(true);
        } else {

        }

    }
    const handleCloseSignUp = () => {
        setOpenSigUp(false);
    }
    const handleCloseCheckOTP = () => {
        setOpenCheckOtp(false);
    }
    const submitOTP = () => {
        try {

            let response = authApi.smsOtp({ 'phone': otpPhone });
            setOpenSigUp(false);
            setOpenCheckOtp(true);

        } catch (error) {

        }

    }

    const checkOTP = async () => {
        try {
            let response = await authApi.checkOtp({ 'phone': otpPhone, 'otp': otpCheck });
            if (response.code == 200) {
                setResponseOtp(true);
                setOpenCheckOtp(false);
                handleCheckOTPTrue();
            } else {
                setResponseOtp(false);
                handleCheckOTPFalse();
            }

        } catch (error) {

        }

    }
    const handleCheckOTPTrue = () => {
        toast(
            <Box sx={{
                borderRadius: '12px'
            }}>
                <Box sx={{ fontWeight: 'bold', color: 'black', mb: 2, mt: 1 }}>Đã xác thực OTP</Box>
            </Box>, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        }
        );
    };
    const handleCheckOTPFalse = () => {
        toast(
            <Box sx={{
                borderRadius: '12px'
            }}>
                <Box sx={{ fontWeight: 'bold', color: 'black', mb: 2, mt: 1 }}>Mã OTP không đúng!</Box>
            </Box>, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        }
        );
    };

    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };
    const handleClickOutsite = () => {
        dispatch(setElementTarget({ target: null, iconSelected: { type: null, data: null } }));
    };
    const dataIcons = useSelector(state => state.targetDesign.iconArray);
    const [listOrder, setListOrder] = useState({
        icons: [],
        itemMoney: 0,
        productCost: PRODUCT_COST,
        shipCost: SHIP_COST,
    });

    const buttons = [
        <Button key="one">Phôi áo</Button>,
        <Button key="two">Thêm phụ kiện</Button>
    ];
    const handleSearch = value => {
        return 12;
    };
    const openNewDesign = () => {
        window.open("http://dl-zabawek.pl/design2");
    }
    useMemo(() => {
        if (iconSelected && iconSelected.type) {
            setTabValue('4');
        }
    }, [iconSelected]);

    useMemo(() => {
        let icons = [];
        dataIcons.forEach(item => {
            item.icons.forEach(it => {
                icons = icons.map(x => {
                    if (x.db_id === it.db_id) {
                        return { ...x, quantityBuy: +x.quantityBuy + 1, cost_price: 'private' };
                    } else return x;
                });

                const check = icons.find(i => i.db_id === it.db_id);
                if (!check) {
                    icons.push({ ...it, quantityBuy: 1, cost_price: 'private' });
                }
            });
        });
        const itemMoney = icons.reduce((acc, current) => {
            const price = current.sale_price ? current.sale_price : current.price;
            return (acc = acc + Number(price * current.quantityBuy));
        }, 0);
        setListOrder({ productCost: PRODUCT_COST, shipCost: SHIP_COST, icons, itemMoney });
        setTotalPay(PRODUCT_COST + SHIP_COST + itemMoney);
    }, [dataIcons]);

    useEffect(() => {
        const meta = document.querySelector('meta[name="viewport"]');
        if (meta) {
            meta.remove();
        }
    }, []);
    return (
        <div className="d-flex justify-center">
            <OrderDialog open={openOrder} handleCloseOrder={() => setOpenOrder(false)} icons={listOrder.icons} />
            <Dialog
                open={openView}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="xl"
            >
                <Box className="d-flex" sx={{ width: 'auto', height: '100vh', padding: '30px', paddingTop: '0px', zIndex: "4000" }}>
                    <Box>
                        <div style={{ pointerEvents: 'none' }}>
                            <DesignView />
                        </div>
                        <div style={{ display: 'block', position: 'fixed', left: '48vw', bottom: '10vh', zIndex: '950' }}>
                            <Button variant="outlined" onClick={handleClosePreview}>
                                Zamknąć
                            </Button>
                        </div>

                    </Box>
                </Box>
            </Dialog>
            <Dialog
                open={openSigUp}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="xl"
            >
                <Box className="d-flex" sx={{ height: 'auto' }}>
                    <Box>
                        <div>
                            <Card sx={{ padding: '30px 20px 10px 20px' }} variant="outlined">
                                <div>
                                    <div>
                                        <Box style={{ textAlign: 'center', fontSize: '18px', marginBottom: '10px' }}>Nhập số điện thoại của bạn</Box>
                                        <TextField
                                            size="small"
                                            sx={{
                                                width: '100%',
                                            }}
                                            variant="outlined"
                                            placeholder='VD: 0988998899'
                                            onChange={e => {
                                                setOtpPhone(e.target.value);
                                            }}
                                        />
                                    </div>

                                    <div style={{ textAlign: 'center', marginTop: '40px' }}>
                                        <Button
                                            size="small"
                                            disableElevation
                                            onClick={handleCloseSignUp}
                                        >
                                            Zamknąć
                                        </Button>
                                        <Button
                                            size="small"
                                            backgroundColor='red'
                                            variant="contained"
                                            sx={{
                                                width: '100%',
                                                backgroundColor: '#F37121',
                                                borderRadius: '5px',
                                            }}
                                            disableElevation
                                            onClick={submitOTP}
                                        >
                                            XÁC NHẬN
                                        </Button>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </Box>
                </Box>
            </Dialog>
            <Dialog
                open={openCheckOtp}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="xl"
            >
                <Box className="d-flex" sx={{ height: 'auto' }}>
                    <Box>
                        <div>
                            <Card sx={{ padding: '30px 20px 10px 20px' }} variant="outlined">
                                <div>
                                    <div style={{ textAlign: 'center' }}>
                                        <Box style={{ textAlign: 'center', fontSize: '18px', marginBottom: '10px' }}>Nhập mã OTP</Box>
                                        <TextField
                                            size="small"
                                            sx={{
                                                width: '50%',
                                                textAlign: 'center'
                                            }}
                                            variant="outlined"
                                            placeholder='-----------------------------------------'
                                            onChange={e => {
                                                setOtpCheck(e.target.value);
                                            }}
                                        />
                                    </div>

                                    <div style={{ textAlign: 'center', marginTop: '40px' }}>
                                        <Button
                                            size="small"
                                            disableElevation
                                            onClick={handleCloseCheckOTP}
                                        >
                                            BỎ QUA
                                        </Button>
                                        <Button
                                            size="small"
                                            backgroundColor='red'
                                            variant="contained"
                                            sx={{
                                                width: '100%',
                                                backgroundColor: '#F37121',
                                                borderRadius: '5px',
                                            }}
                                            disableElevation
                                            onClick={checkOTP}
                                        >
                                            XÁC NHẬN
                                        </Button>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </Box>
                </Box>
            </Dialog>
            <Dialog
                open={openPriceDetails}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="xl"
            >
                <Box className="d-flex" sx={{ width: 'auto', height: '30vh', padding: '30px', paddingTop: '0px', zIndex: "4000" }}>
                    <Box>
                        <div style={{ pointerEvents: 'none', paddingTop: '20px' }}>
                            <OrderBox orders={listOrder} />
                        </div>
                        <div style={{ display: 'block', position: 'fixed', left: '48vw', bottom: '35vh', zIndex: '950' }}>
                            <Button variant="outlined" onClick={handleClosePrice}>
                                Zamknąć
                            </Button>
                        </div>

                    </Box>
                </Box>
            </Dialog>

            <div className={cx('mobile-card')}>
                <MovingText
                    type="fadeInFromRight"
                    duration="10000ms"
                    delay="0s"
                    direction="normal"
                    timing="linear"
                    iteration="infinite"
                    fillMode="none"
                    style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', opacity: '1 !important' }}
                >
                    CHỨC NĂNG CUSTOM CHỈ HOẠT ĐỘNG TRÊN PC & TABLET
                </MovingText>
            </div>
            <div className={cx('pc-container-total')}>

                <div style={{ height: '70px', width: '100%', display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', backgroundColor: '#e1e1e6', width: '100%' }}>
                        <div className={cx('d-flex bnt1')}>
                            <div style={{ paddingRight: '20px' }}>
                                <img src={images.backIcon} style={{ width: "30px", textAlign: 'center', justifyContent: 'center' }} alt='product' />
                            </div>
                            <div>
                                <img src={images.forwardIcon} style={{ width: "30px", textAlign: 'center', justifyContent: 'center' }} alt='product' />
                            </div>

                            <Divider orientation="vertical" variant="middle" flexItem style={{ paddingLeft: '10px' }} />
                            <img onClick={() => clickOpenPreview()} src={images.eyeIcon} style={{ width: "45px", cursor: 'pointer', textAlign: 'center', justifyContent: 'center', paddingLeft: '10px', paddingRight: '10px' }} alt='product' />
                            <a style={{ cursor: 'pointer' }} onClick={() => clickOpenPreview()} > Xem Thử</a>
                            <Button onClick={() => openNewDesign()} style={{ marginLeft: '10px', color: '#2587cc', borderColor: '#2587cc' }} variant="outlined">Thêm mới + </Button>

                        </div>
                        <div className={cx('d-flex bnt2')}>

                        </div>
                        <div className={cx('d-flex bnt3')}>
                            <div>
                                <div className={cx('d-flex')}>
                                    <p onClick={() => clickOpenPrice()} style={{ cursor: 'pointer', color: 'black', fontSize: '22px', fontWeight: 'bold' }}>Tổng cộng:</p> <p onClick={() => clickOpenPrice()} style={{ color: 'red', fontSize: '22px', paddingLeft: '10px', fontWeight: 'bold' }}>{parseInt(totalPay).toLocaleString('de-DE')} zł</p>
                                </div>
                            </div>
                            <div>
                                <Button onClick={() => checkAccount()} style={{ marginLeft: '10px', color: 'black', borderColor: 'black' }} variant="outlined">Lưu mẫu </Button>
                                <Button onClick={() => clickBuyNow()} style={{ marginLeft: '10px', color: 'black', borderColor: 'black' }} variant="contained">Mua ngay </Button>
                            </div>


                        </div>
                    </div>
                </div>
                <div style={{ backgroundColor: 'black', height: '5px', width: '100%' }}>
                    {/* black line */}
                </div>
                <div className={cx('pc-container-designer')}>
                    <div style={{ backgroundColor: '#e1e1e6', height: '100%', width: '20px' }}>
                        {/* gray line */}
                    </div>
                    <div style={{ backgroundColor: 'white', height: '100%', width: '100px', zIndex: '800', boxShadow: 'rgb(208 216 208 / 30%) 9px 0em 1em inset, white 2px 2px 2px 0px, rgb(80 68 68 / 60%) 0.3em 0em 1em' }}>
                        <div style={{ borderRadius: '10px', fontFamily: 'MyFont', fontSize: '15px', width: '120%', height: '100px', backgroundColor: '#111111', boxShadow: '2px 2px #cdff00', marginLeft: '-10px', marginTop: '30px' }}>
                            <div style={{ width: '100%', textAlign: 'center', paddingTop: '20px' }}>
                                <img src={images.bagIcon} style={{ width: "45px", textAlign: 'center', justifyContent: 'center', paddingLeft: '10px', paddingRight: '10px' }} alt='product' />
                            </div>
                            <div style={{ width: '100%', textAlign: 'center', paddingTop: '5px', color: 'white' }}>
                                Thiết kế
                            </div>
                        </div>
                        <div style={{ borderRadius: '10px', fontSize: '15px', width: '100%', height: '100px', backgroundColor: 'white', marginTop: '30px' }}>
                            <div style={{ width: '100%', textAlign: 'center', paddingTop: '20px' }}>
                                <img src={images.bocucIcon} style={{ width: "45px", textAlign: 'center', justifyContent: 'center', paddingLeft: '10px', paddingRight: '10px' }} alt='product' />
                            </div>
                            <div style={{ width: '100%', textAlign: 'center', paddingTop: '5px', color: 'gray' }}>
                                Bố cục
                            </div>
                        </div>
                    </div>
                    {openLeftbar == true ? (
                        <div style={{ zIndex: '100', height: '100%', width: '25%', boxShadow: 'rgb(208 216 208 / 30%) 9px 0em 1em inset, white 2px 2px 2px 0px, rgb(80 68 68 / 60%) 0.3em 0em 1em' }}>
                            <div style={{ height: '10%', width: '100%' }}>
                                <div style={{ borderRadius: '10px', fontFamily: 'MyFont', fontSize: '15px', width: '80%', height: '68px', backgroundColor: 'white', border: '2px solid #236DFF', marginTop: '15px', marginLeft: '9%' }}>
                                    <div className={cx('d-flex bnt2345')} style={{ width: '100%', textAlign: 'center', paddingTop: '5px', color: '#236DFF', justifyContent: 'center' }}>
                                        <div>
                                            <img src={images.guideIcon} style={{ width: "45px", textAlign: 'center', justifyContent: 'center', paddingLeft: '10px', paddingRight: '10px', marginTop: '10px' }} alt='product' />
                                        </div>
                                        <div style={{ textAlign: 'center', justifyContent: 'center', paddingTop: '18px', fontWeight: '400' }}>
                                            Hướng dẫn sử dụng
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ backgroundColor: '#f7f7f6', height: '90%', width: '100%', display: 'flex' }}>
                                <div style={{ height: '100%', width: '50%', borderTop: '1px solid #bbbbbb' }}>
                                    <div style={{ marginTop: '20px' }}>
                                        {openPhoiAo == true ? (
                                            <Stack spacing={2} direction="row">
                                                <Button style={{ marginLeft: '8%', border: '1px solid gray', backgroundColor: '#236DFF', color: 'white', fontWeight: '300' }} variant="contained">Phôi áo</Button>
                                                <Button onClick={() => clickClosePhoiao()} style={{ marginLeft: '0px', border: '0px solid white', backgroundColor: 'white', color: '#D9D9D9', boxShadow: '0px 1px 1px #888888', fontWeight: '300' }} variant="outlined">Thêm phụ kiện</Button>
                                            </Stack>
                                        ) : (
                                            <Stack spacing={2} direction="row">
                                                <Button onClick={() => clickOpenPhoiao()} style={{ marginLeft: '8%', border: '0px solid white', backgroundColor: 'white', color: '#D9D9D9', boxShadow: '0px 1px 1px #888888', fontWeight: '300' }} variant="contained">Phôi áo</Button>
                                                <Button style={{ marginLeft: '0px', border: '1px solid gray', backgroundColor: '#236DFF', color: 'white', fontWeight: '300' }} variant="outlined">Thêm phụ kiện</Button>
                                            </Stack>
                                        )
                                        }
                                    </div>
                                    <div style={{ marginTop: '20px' }}>
                                        {openPhoiAo == true ? (
                                            <div
                                                id='designviewphoi'
                                                style={{
                                                    width: '100%',
                                                    height: '350px',
                                                    backgroundImage: `url('${backgroundphoi.link}')`,
                                                    ...backgroundphoi.css,
                                                }}
                                            >
                                            </div>
                                        ) : (
                                            <IconImageText />
                                        )
                                        }
                                    </div>
                                    <div>
                                        {/* div 2 */}
                                    </div>
                                </div>
                                <div style={{ backgroundColor: '#f7f7f6', height: '100%', width: '50%', borderLeft: '1px solid #bbbbbb', borderTop: '1px solid #bbbbbb' }}>
                                    {openPhoiAo == true ? (
                                        <ProductColor />
                                    ) : (
                                        <IconImageOnly />
                                    )
                                    }
                                </div>
                            </div>
                        </div>
                    ) : (
                        null
                    )
                    }
                    <div style={{ zIndex: '90', textAlign: 'center', backgroundColor: 'black', height: '70px', marginTop: '45vh', width: '1%', borderTopRightRadius: '10px', borderBottomRightRadius: '10px' }}>
                        {openLeftbar == true ? (
                            <img onClick={() => clickCloseLeftBar()} src={images.iconLeftWhite} style={{ cursor: 'pointer', height: '15px', marginTop: '28px' }} />
                        ) : (
                            <img onClick={() => clickopenLeftbar()} src={images.iconRightWhite} style={{ cursor: 'pointer', height: '15px', marginTop: '28px' }} />
                        )
                        }
                    </div>
                    <div className='newclass' style={{ flex: 1, justifyContent: 'center', textAlign: 'center', display: 'flex' }} onClick={handleClickOutsite}>
                        <DesignView />
                    </div>
                    <div style={{ backgroundColor: '#F5F5F5', height: '100%', width: 'auto' }}>
                        <DesignBoxNew />
                    </div>
                    <div style={{ display: 'block', position: 'fixed', left: '45vw', bottom: '1vh', zIndex: '950' }}>
                        <ProductDesignBottom />
                        {/* <OrderBox orders={listOrder} /> */}
                    </div>
                </div>

            </div>

        </div>
    );
}

export default Design;
