import classNames from 'classnames/bind';
import styles from './IconLine.scss';
import images from '@/assets/images';
import { Box, Grid, Button as MuiButton } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
const cx = classNames.bind(styles);

const style = {
    widthContent: {
        minWidth: '170px',
        width: '22%',
        fontSize: '14px',
        borderRadius: '9px',
        height: '50px',
        color: 'black',
        fontWeight: 'BOLD'
    },
};
function IconLine({ height }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();
    const goToLink = link => {
        setAnchorEl(null);
        navigate(link);
    };

    return (
        <Box sx={{ paddingTop: '0px', paddingLeft: '0px', paddingRight: '0px' }}>
            <Box className={cx('line-card')} >

            </Box>
            <Box className={cx('mobile-card')}>
                <Box className="d-flex justify-center">

                </Box>
            </Box>
        </Box>

    );
}
export default IconLine;
