import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Divider } from '@mui/material';
import classNames from 'classnames/bind';
import styles from './header.module.scss';
import MiniInput from '@/components/Input/MiniInput';
import productApi from '@/api/productApi';
import { useCallback, useEffect, useState } from 'react';
import GridProduct from '@/components/GridProduct/GridProductV3';
import CheckroomIcon from '@mui/icons-material/Checkroom';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import { Routes, Route, Navigate, NavLink, useLocation } from 'react-router-dom';

const cx = classNames.bind(styles);
export default function SearchDialog({ open, onClose }) {
    const [products, setProudcts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [isShowMore, setIsShowMore] = useState(false);
    const [keyWord, setKeyWord] = useState('');
    const perPage = 50;
    const handleClose = () => {
        onClose();
    };
    const location = useLocation();
    useEffect(() => {
        handleClose();
    }, [location]);

    const searchProduct = async keyword => {
        setKeyWord(keyword);
        setLoading(true);
        setIsShowMore(false);
        let data = await productApi.getProduct({ search: keyword, perPage });
        setLoading(false);
        if (data.meta.total > perPage) {
            setIsShowMore(true);
        } else {
            setIsShowMore(false);
        }
        setTotal(data.meta.total);
        setProudcts(data.data);
    };
    const handleShowMore = () => {
        window.location.href = encodeURI('/search?search=' + keyWord);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll="paper"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="1000px"
            sx={{ margin: '0px' }}
        >
            <DialogTitle className={cx('all-item-search')}>Wyszukaj produkt</DialogTitle>
            <DialogContent className={cx('all-body-search')}>
                <DialogContentText>
                    <Box>
                        <MiniInput
                            onChange={e => searchProduct(e.target.value)}
                            placeholder="Wpisz nazwę produktu, który chcesz wyszukać"
                            className={cx('all-body-search-input')}
                            sx={{ mt: 2 }}
                        />
                        <Divider sx={{ mt: 2, mb: 3 }} />
                        {!keyWord ? (
                            <Box sx={{ mt: 8 }} className={cx('c-flex justify-center align-center')}>
                                <CheckroomIcon sx={{ fontSize: '130px' }} />
                                <Box sx={{ mt: 4, fontSize: '20px' }}>Wprowadź słowa kluczowe do wyszukiwania</Box>
                            </Box>
                        ) : null}
                        {total > 0 ? (
                            <Box>
                                <Box sx={{ fontSize: '20px', fontWeight: 'bold', mb: 2 }}>Wyniki wyszukiwania</Box>
                                <GridProduct products={products} loading={loading} />
                            </Box>
                        ) : null}
                        {keyWord && total < 1 ? (
                            <Box sx={{ mt: 8 }} className={cx('c-flex justify-center align-center')}>
                                <SearchOffIcon sx={{ fontSize: '130px' }} />
                                <Box sx={{ mt: 4, fontSize: '20px' }}>Nie znaleziono pasujących wyników!</Box>
                            </Box>
                        ) : null}
                    </Box>
                    {isShowMore ? (
                        <Box className={cx('d-flex justify-center')} sx={{ mt: 3 }}>
                            <Button size="large" variant="contained" onClick={handleShowMore}>
                                Zobacz więcej {total} produkt
                            </Button>
                        </Box>
                    ) : null}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleClose}>
                    Zamknąć
                </Button>
            </DialogActions>
        </Dialog>
    );
}
