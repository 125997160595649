import axiosClient from '@/utils/axiosClient';

class RatingApi {
    getRating = params => {
        const url = '/rating';
        return axiosClient.get(url, { params });
    };
    createRating = data => {
        const url = '/rating';
        return axiosClient.post(url, data);
    };
    updateRating = (id, data) => {
        const url = `/rating/${id}`;
        return axiosClient.put(url, data);
    };
    deleteRating = (id) => {
        const url = `/rating/${id}`;
        return axiosClient.delete(url);
    };
}
const ratingApi = new RatingApi();
export default ratingApi;
