import MDialog from '@/components/Dialog';
import { Box, Divider, TextField, IconButton } from '@mui/material';
import { useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import flashsaleApi from '@/api/flashsaleApi';
import productApi from '@/api/productApi';
import Autocomplete from '@mui/material/Autocomplete';
import { debounce } from 'lodash';
import classNames from 'classnames/bind';
import styles from './CartSmax.scss';
import Grid from '@mui/material/Grid';
import MInput from '@/components/Input/MiniInput';
import { useSelector } from 'react-redux';
import CartPayment from './Payment';
import orderApi from '@/api/orderApi';
import { useDispatch } from 'react-redux';
import { removeAllItem, removeCartPromotionItem } from '@/store/cart/cartSlice';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import ProductItem from './ProductItemSmax';
import regionApi from '@/api/regionApi';

const cx = classNames.bind(styles);

function CartSmax() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [disableFeild, setDisableFeild] = useState(false)
    const [typeFlashsale, setTypeFlashsale] = useState(null)
    const [province, setProvince] = useState(null);
    const [districts, setDistricts] = useState([]);
    const [district, setDistrict] = useState(null);
    const cartData = useSelector(state => state.cart.cartData);
    const [provisionalMoney, setProvisionalMoney] = useState(0);
    const [costMoney, setCostMoney] = useState(40);
    const [provinces, setProvinces] = useState([]);
    const [voucher, setVoucher] = useState('');
    const [voucherMessage, setVoucherMessage] = useState('');
    const [discount, setDiscount] = useState(0);
    const [wards, setWards] = useState([]);
    const [ward, setWard] = useState(null);
    const [uID, setUID] = useState(null);
    const [totalMoney, setTotalMoney] = useState(0);
    const [arrayProduct, setArrayProduct] = useState([]);
    const smax_products = useSelector(state => state.smax);

    const getProvinces = useCallback(async () => {
        let response = await regionApi.getProvince();
        setProvinces(response);
    }, []);
    useEffect(() => {
        setDistrict(null);
        setWard(null);
        getDistricts();
    }, [province]);

    const getDistricts = async () => {
        let response = [];
        if (province && province.id) {
            response = await regionApi.getDistrict({ province_id: province.id });
        }
        setDistricts(response);
    };

    const getWards = useCallback(async () => {
        let response = [];
        if (district && district.id) {
            response = await regionApi.getWard({ district_id: district.id });
        }
        setWard(null);
        setWards(response);
    }, [district]);

    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [form, setForm] = useState(
        []
    );
    const me = useSelector(state => state.auth.me);
    const handleChangForm = (key, value) => {
        setForm({ ...form, [key]: value });
    };
    const getVoucher = async () => {
        const data = {
            products: smax_products.products.map(item => ({
                product_id: item.product_id,
                color: item.color,
                size: item.size,
                quantity: Number(item.quantity),
            })),
            voucher: voucher,
        };
        let response = await orderApi.applyVoucher(data);
        if (response.hasOwnProperty('promotion')) {
            const arrayColor = productSelected;
            arrayColor.push(response.promotion[0]);
            setProductSelected(arrayColor);
        };
        setTotalMoney(totalMoney - response.discount);
        setDiscount(response.discount);
        setVoucherMessage(response.message);
    };
    const handleSubmit = async payment => {

        try {
            setLoadingSubmit(true);
            const formSubmit = {
                ...form,
                payment: payment,
                voucher: voucher,
                UID: uID,
                ward_code: ward ? ward.code : null,
                district_code: district ? district.code : null,
                province_code: province ? province.code : null,
                products: smax_products.products
            };

            if (payment === 'onepay') {
                const url = await orderApi.onePayOrder(formSubmit);
                window.location.assign(url);
            } else {

                const respon = await orderApi.createOrder(formSubmit);
                toast.success('Thành công!');
                dispatch(removeAllItem());
                setForm({});
                setProvince(null);
                setDistrict(null);
                setWard(null);

                let elementSmaxname = document.querySelector('#SMAXname')
                let elementSmaxphone = document.querySelector('#SMAXphone')
                let elementSmaxaddress = document.querySelector('#SMAXaddress')
                let elementSmaxnote = document.querySelector('#SMAXnote')

                if (elementSmaxname) {
                    elementSmaxname.value = null;
                }
                if (elementSmaxphone) {
                    elementSmaxphone.value = null;
                }
                if (elementSmaxaddress) {
                    elementSmaxaddress.value = null;
                }
                if (elementSmaxnote) {
                    elementSmaxnote.value = null;
                }
                window.location.reload(false);
            }

            setLoadingSubmit(false);

        } catch (error) {
            const errors = error.response.data.errors;
            for (const key in errors) {
                errors[key] = errors[key].join('. ');
            }
            setErrorSubmit(errors);
            setLoadingSubmit(false);
        }
    };
    const [errorSubmit, setErrorSubmit] = useState({});
    const [products, setProducts] = useState([]);
    const [productSelected, setProductSelected] = useState([]);
    const [param, setParam] = useState({});
    const [loading, setLoading] = useState(false);

    const handleSearch = value =>
        debounce(() => {
            setParam({ ...param, search: value });
        }, 600)();

    const handleSelectProcduct = newProductArray => {
        const arrayColor = newProductArray.map(item => {
            const selected = productSelected.find(it => it.id === item.id);
            if (selected) {
                return selected;
            }
            return { ...item, priceFlash: 0 };

        });
        setProductSelected(arrayColor);
    };

    const handleChangeTotal = (id, type, value, price) => {
        const isFound = arrayProduct.some(element => {
            if (element.id === id) {
                return true;
            }

            return false;
        });
        if (isFound) {
            const newState = arrayProduct.map(obj => {
                if (obj.id === id) {
                    return { ...obj, value: value };
                }
                return obj;
            });

            setArrayProduct(newState);
        } else {
            setArrayProduct(oldArrayProduct => [...oldArrayProduct,
            {
                'id': id,
                'type': type,
                'value': value,
                'price': price
            }
            ]);
        }
    }

    window.addEventListener('message', function (event) {
        if (typeof event.data == 'object' && event.data.name === '__SM_FORM_CUSTOMER') {
            var user_fb = event.data?.data?.customer;

            setUID(user_fb.id);
            if (user_fb.name !== null) {
                handleChangForm('name', user_fb.name)
            }

        }
    }, false);

    const getData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await productApi.getProduct(param);
            setLoading(false);
            setProducts(response.data);
        } catch (error) {
            setLoading(false);
        }
    }, [param]);

    useEffect(() => {
        setDisableFeild(false);
        setTypeFlashsale(null);
    });

    useEffect(() => {
        getData();
    }, [getData]);
    useEffect(() => {
        getProvinces();
        getWards();
    }, [getProvinces, getWards]);

    useEffect(() => {
        getData();
    }, [getData]);


    useEffect(() => {
        var TOTAL_temp = 0;
        smax_products.products.forEach((product) => {
            TOTAL_temp = TOTAL_temp + Number(product.quantity) * Number(product.sale_price)
        })
        setProvisionalMoney(TOTAL_temp);
        if (TOTAL_temp > 1500) {
            setCostMoney(0);
            setTotalMoney(TOTAL_temp);
        } else {
            setTotalMoney(TOTAL_temp + costMoney);
        }
        if (TOTAL_temp < 500000) {
            dispatch(removeCartPromotionItem());
        }

    }, [arrayProduct]);
    return (
        <div>
            <div className="d-flex">
                <Grid item xs={12} sm={12} md={6} lg={7} xl={7} className={cx('order-customer-smax')} sx={{ pb: 4 }}>
                    <Box className="c-flex">
                        <Grid container spacing={4}>
                            <Grid item xs={6}>
                                <MInput
                                    placeholder="Họ tên"
                                    onChange={e => handleChangForm('name', e.target.value)}
                                    value={form.name}
                                    variant="outlined"
                                    size="small"
                                    className={cx('cart-input-smax')}
                                    error={errorSubmit.name ? true : false}
                                    helperText={errorSubmit.name}
                                    id='SMAXname'
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <MInput
                                    placeholder="Số điện thoại"
                                    size="small"
                                    onChange={e => handleChangForm('phone', e.target.value)}
                                    variant="outlined"
                                    className={cx('cart-input-smax')}
                                    error={errorSubmit.phone ? true : false}
                                    value={form.phone}
                                    helperText={errorSubmit.phone}
                                    id='SMAXphone'
                                />
                            </Grid>
                        </Grid>
                        <MInput
                            placeholder="Địa chỉ"
                            onChange={e => handleChangForm('address', e.target.value)}
                            variant="outlined"
                            size="small"
                            className={cx('cart-input-smax')}
                            id='SMAXaddress'
                            error={errorSubmit.address ? true : false}
                            helperText={errorSubmit.address}
                            value={form.address}
                            sx={{ mt: 3 }}
                        />
                        <Grid container spacing={4} sx={{ mt: 0 }}>
                            <Grid className={cx('cart-input-mobile-smax')} item xs={12} sm={6} md={6} lg={4} xl={4}>
                                <Autocomplete
                                    disablePortal
                                    label="Tỉnh thành"
                                    variant="outlined"
                                    size="small"
                                    getOptionLabel={option => option.name}
                                    options={provinces}
                                    value={province}
                                    onChange={(e, newValue) => {
                                        handleChangForm('province_code', newValue.code);
                                        setProvince(newValue);
                                        setDistrict(null);
                                        setWard(null);
                                    }}
                                    renderInput={params => (
                                        <MInput
                                            {...params}
                                            label="Tỉnh thành"
                                            error={errorSubmit.province_code ? true : false}
                                            helperText={errorSubmit.province_code}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid className={cx('cart-input-mobile-smax')} item xs={12} sm={6} md={6} lg={4} xl={4}>
                                <Autocomplete
                                    disablePortal
                                    label="Quận huyện"
                                    size="small"
                                    variant="outlined"
                                    getOptionLabel={option => option.name}
                                    options={districts}
                                    value={district}
                                    onChange={(e, newValue) => {
                                        handleChangForm('district_code', newValue.code);
                                        setDistrict(newValue);
                                        setWard(null);
                                    }}
                                    renderInput={params => (
                                        <MInput
                                            {...params}
                                            label="Quận huyện"
                                            error={errorSubmit.district_code ? true : false}
                                            helperText={errorSubmit.district_code}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid className={cx('cart-input-mobile-smax')} item xs={12} sm={6} md={6} lg={4} xl={4}>
                                <Autocomplete
                                    disablePortal
                                    label="Xã phường"
                                    size="small"
                                    variant="outlined"
                                    getOptionLabel={option => option.name}
                                    options={wards}
                                    value={ward}
                                    onChange={(e, newValue) => {
                                        handleChangForm('ward_code', newValue.code);
                                        setWard(newValue);
                                    }}
                                    renderInput={params => <MInput {...params} label="Xã phường" />}
                                />
                            </Grid>
                        </Grid>
                        <MInput
                            placeholder="Ghi chú"
                            size="small"
                            onChange={e => handleChangForm('note', e.target.value)}
                            variant="outlined"
                            className={cx('cart-input-smax')}
                            id='SMAXnote'
                            sx={{ mt: 3, mb: 2 }}
                            value={form.note}
                        />
                        <Autocomplete
                            disablePortal
                            multiple
                            sx={{ width: '100%', mr: 1, maxWidth: '600px' }}
                            label="Chọn sản phẩm"
                            variant="outlined"
                            size="meidum"
                            getOptionLabel={option => option.product_by ? option.product_by.name : option.name}
                            renderOption={(props, option) => (
                                <Box className="d-flex align-cener" {...props}>
                                    <Box sx={{ ml: 1 }}>{option.product_by ? option.product_by.name : option.name}</Box>
                                </Box>
                            )}
                            options={products}
                            value={productSelected}
                            onChange={(e, newValue) => handleSelectProcduct(newValue)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Search input"
                                    onChange={e => handleSearch(e.target.value)}
                                />
                            )}
                        />
                        <Box className="d-flex flex-column" sx={{ mt: 2 }}>
                            <Box sx={{ flex: 1 }}>

                                {productSelected.length > 0 ? (

                                    productSelected.map(item => (
                                        <ProductItem
                                            onChangeChild={handleChangeTotal}
                                            key={item.id} product={item}
                                        />
                                    ))

                                ) : (
                                    <Box>Vui lòng chọn sản phẩm</Box>
                                )}
                            </Box>
                        </Box>
                        <Divider sx={{ mt: 2 }} />
                        <Box className="d-flex" sx={{ mt: 2 }}>
                            <Box sx={{ flex: 1 }}>
                                <MInput
                                    helperText={voucherMessage}
                                    variant="outlined"
                                    placeholder="Mã giảm giá"
                                    size="small"
                                    sx={{ width: '100%' }}
                                    value={voucher}
                                    onChange={e => setVoucher(e.target.value)}
                                />
                            </Box>
                            <Button
                                variant="contained"
                                sx={{ ml: 1, borderRadius: '10px', maxHeight: '55px' }}
                                onClick={getVoucher}
                                style={{ borderRadius: '10px', backgroundColor: '#F37121', border: '#F37121' }}
                            >
                                Áp dụng
                            </Button>
                        </Box>
                        <Divider sx={{ mt: 2 }} />
                        <Box sx={{ mt: 2 }}>
                            <Box className="d-flex justify-space-between">
                                <Box className={cx('title-price')}>Tạm tính</Box>
                                <Box className={cx('title-price')}>{Number(provisionalMoney).toLocaleString('de-DE')} zł</Box>
                            </Box>
                            <Box sx={{ mt: 1 }} className="d-flex justify-space-between">
                                <Box className={cx('title-price')}>Giảm giá</Box>
                                <Box className={cx('title-price')}>{Number(discount).toLocaleString('de-DE')} zł</Box>
                            </Box>
                            <Box sx={{ mt: 1 }} className="d-flex justify-space-between">
                                <Box className={cx('title-price')}>Phí vận chuyển</Box>
                                <Box className={cx('title-price')}>{Number(costMoney).toLocaleString('de-DE')} zł</Box>
                            </Box>
                            <Divider sx={{ mt: 2 }} />
                            <Box className="d-flex justify-space-between" sx={{ mt: 2 }}>
                                <Box className={cx('title-price')}>Tổng tiền</Box>
                                <Box sx={{ fontSize: '24px', fontWeight: 'bold', color: '#CB4335' }}>
                                    {Number(totalMoney).toLocaleString('de-DE')} zł
                                </Box>
                            </Box>
                        </Box>
                        <Box>
                            <CartPayment onSubmit={handleSubmit} loadingButton={loadingSubmit} />
                        </Box>
                    </Box>
                </Grid>
            </div>
        </div>
    );
}
export default CartSmax;
