import CloseIcon from '@mui/icons-material/Close';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import Tooltip from '@mui/material/Tooltip';

const style = {
    rectangle: {
        position: 'relative',
        width: '65px',
        height: '36px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '9px',
        backgroundColor: 'black',
        border: '1px solid white',
    },
};
function OutSize({ prop, children }) {
    return (
        <div>
            <Tooltip title="Size này đã hết hàng!">
                <div style={style.rectangle}>
                    <DoDisturbIcon
                        style={{
                            opacity: '0.9',
                            position: 'absolute',
                            height: '100%',
                            width: '100%',
                            color: '#D0D3D4',
                        }}
                    />
                    <div style={{ fontSize: '20px', fontWeight: 'lighter' }}>{children}</div>
                </div>
            </Tooltip>
        </div>
    );
}
export default OutSize;
