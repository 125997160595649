import images from '@/assets/images';
import classNames from 'classnames/bind';
import styles from './Banner.module.scss';
import navbarApi from '@/api/navbarApi';
import { Fragment, useCallback, useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Divider } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useNavigate } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

const cx = classNames.bind(styles);

function HomeBanner() {
    const [pathBannerPC, setPathBannerPC] = useState([]);
    const [pathBannerMobile, setPathBannerMobile] = useState([]);
    const [loadingBanner, setLoadingBanner] = useState(false);
    const navigate = useNavigate();

    const getData = useCallback(async () => {
        setLoadingBanner(true);
        const response = await navbarApi.getBanner();
        setLoadingBanner(false);
        let imagePcOb = response.data.find(item => item.info1 === 'PC');
        let imageMobileOb = response.data.find(item => item.info1 === 'mobile');

        try {
            var imagePc = JSON.parse(imagePcOb.path);
        } catch (e) {
            var imagePc = [imagePcOb.path];
        }
        try {
            var imageMobile = JSON.parse(imageMobileOb.path);
        } catch (e) {
            var imageMobile = [imageMobileOb.path];
        }
        setPathBannerPC(imagePc);
        setPathBannerMobile(imageMobile);
    });

    useEffect(() => {
        getData();
    }, []);
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    return (
        <div>
            <div className={cx('pc-card')}>
                {/* <div><img src={pathBannerPC} style={{ width: '100%', height: 'auto' }} alt="bannerPC" /></div> */}
                <Slider {...settings}>
                    {pathBannerPC.map((item, index) => (
                        <img key={index} src={item} sx={{ width: '100%', height: 'auto' }} />
                    ))}
                </Slider>
            </div>
            <div className={cx('mobile-card')}>
                {/* <div>
                    <img src={pathBannerMobile} style={{ width: '100%', height: 'auto' }} alt="bannerMobile" />
                </div> */}

                <Slider {...settings}>
                    {pathBannerMobile.map((item, index) => (
                        <img key={index} src={item} sx={{ width: '100%', height: 'auto' }} />
                    ))}
                </Slider>
            </div>
        </div>
    );
}
export default HomeBanner;
