import { Box, Divider } from '@mui/material';
import productApi from '@/api/productApi';
import { useCallback, useEffect, useState } from 'react';
import GridProduct from '@/components/GridProduct/GridProduct';
import GridProductV3 from '@/components/GridProduct/GridProductV3';
import { useParams } from 'react-router-dom';
import images from '@/assets/images';
import classNames from 'classnames/bind';
import styles from './Carto.scss';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const cx = classNames.bind(styles);
function ProductCategory() {
    const [productLoading, setProductLoading] = useState(true);
    const [products, setProducts] = useState([]);
    const [totalPage, setTotalPage] = useState(0);
    const { categoryCode } = useParams();
    const [param, setParam] = useState({ perPage: 50 });
    const [category, setCategory] = useState({});
    const [totalItem, setTotalItem] = useState(0);
    const getProduct = useCallback(async () => {
        try {
            setProductLoading(true);
            let response = await productApi.getProductInCategory({ ...param, categoryCode });
            setProducts(response.data);
            setCategory(response.category);
            setTotalItem(response.datapage.total || response?.data?.meta?.total || 0);
            setTotalPage(response.datapage.last_page || response?.data?.meta?.last_page);
            setProductLoading(false);
        } catch (error) { }
    }, [param, categoryCode]);
    const onChangePage = page => {
        setParam({ ...param, page });
    };

    const [checkedMau, setCheckedMau] = useState([true, false]);
    const [checkedKichCo, setCheckedKichCo] = useState([true, false]);
    const [checked, setChecked] = useState([true, false]);


    const chonTatCaAo = (event) => {
        setChecked([event.target.checked, event.target.checked]);
    };
    const chonTatCaMau = (event) => {
        setCheckedMau([event.target.checked, event.target.checked]);
    };
    const chonTatCaKichThuoc = (event) => {
        setCheckedKichCo([event.target.checked, event.target.checked]);
    };

    const handleChange2Mau = (event) => {
        setCheckedMau([event.target.checked, checked[1]]);
    };
    const handleChange2KichCo = (event) => {
        setChecked([event.target.checked, checked[1]]);
    };
    const handleChange2 = (event) => {
        setChecked([event.target.checked, checked[1]]);
    };

    const handleChange3 = (event) => {
        setChecked([checked[0], event.target.checked]);
    };
    const handleChange4 = (event) => {
        setChecked([checked[0], event.target.checked]);
    };

    const children = (
        <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3, color: 'black' }}>
            <FormControlLabel
                label="Áo T-shirt"
                control={<Checkbox sx={{ color: 'black' }} checked={checked[0]} onChange={handleChange2} />}
            />
            <FormControlLabel
                label="Áo Sweatshirt"

                control={<Checkbox sx={{ color: 'black' }} checked={checked[1]} onChange={handleChange3} />}
            />
            <FormControlLabel
                label="Áo Hoodie"
                control={<Checkbox sx={{ color: 'black' }} checked={checked[2]} onChange={handleChange4} />}
            />
        </Box>
    );
    const kichco = (
        <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3, color: 'black' }}>
            <FormControlLabel
                label="S"
                control={<Checkbox sx={{ color: 'black' }} checked={checkedKichCo[0]} onChange={handleChange2} />}
            />
            <FormControlLabel
                label="M"
                control={<Checkbox sx={{ color: 'black' }} checked={checkedKichCo[1]} onChange={handleChange3} />}
            />
            <FormControlLabel
                label="L"
                control={<Checkbox sx={{ color: 'black' }} checked={checkedKichCo[2]} onChange={handleChange3} />}
            />
            <FormControlLabel
                label="XL"
                control={<Checkbox sx={{ color: 'black' }} checked={checkedKichCo[3]} onChange={handleChange3} />}
            />
        </Box>
    );

    const mausac = (
        <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3, color: 'black' }}>
            <FormControlLabel
                label="Đen"
                control={<Checkbox sx={{ color: 'black' }} checked={checkedMau[0]} onChange={handleChange2} />}
            />
            <FormControlLabel
                label="Xám"
                control={<Checkbox sx={{ color: 'black' }} checked={checkedMau[1]} onChange={handleChange3} />}
            />
            <FormControlLabel
                label="Trắng"
                control={<Checkbox sx={{ color: 'black' }} checked={checkedMau[1]} onChange={handleChange3} />}
            />
            <FormControlLabel
                label="Tím"
                control={<Checkbox sx={{ color: 'black' }} checked={checkedMau[1]} onChange={handleChange3} />}
            />
            <FormControlLabel
                label="Xanh nhạt"
                control={<Checkbox sx={{ color: 'black' }} checked={checkedMau[1]} onChange={handleChange3} />}
            />
            <FormControlLabel
                label="Xanh Đậm"
                control={<Checkbox sx={{ color: 'black' }} checked={checkedMau[1]} onChange={handleChange3} />}
            />
            <FormControlLabel
                label="Rêu Bụi"
                control={<Checkbox sx={{ color: 'black' }} checked={checkedMau[1]} onChange={handleChange3} />}
            />
            <FormControlLabel
                label="Nâu"
                control={<Checkbox sx={{ color: 'black' }} checked={checkedMau[1]} onChange={handleChange3} />}
            />
            <FormControlLabel
                label="Vàng"
                control={<Checkbox sx={{ color: 'black' }} checked={checkedMau[1]} onChange={handleChange3} />}
            />
            <FormControlLabel
                label="Beige"
                control={<Checkbox sx={{ color: 'black' }} checked={checkedMau[1]} onChange={handleChange3} />}
            />
        </Box>
    );
    useEffect(() => {
        getProduct();
    }, [getProduct]);
    return (
        <div>
            <Box className={cx('lichnt-pc')} sx={{ pb: 4, paddingBottom: '0px', paddingTop: '0px !important' }}>
                <img src={images.iconMydesignG2} style={{ width: '100%', height: 'auto' }} alt='product' />
                <Box sx={{ backgroundColor: 'white' }} className={cx('carto-card-img')}>
                    <Box sx={{ paddingTop: '0px' }} className={cx('carto-card-all')}>
                        {/* <div className={cx('d-flex carto-left')}>
                            <div className={cx('carto-l1')}>
                                <div>
                                    <b> <a style={{ fontSize: '28px', color: 'black' }}>{category.name}</a></b>
                                </div>
                                <div>
                                    <a style={{ fontSize: '16px', color: 'black' }}>Strona główna / </a><a> {category.name} </a>
                                </div>

                            </div>
                            <div className={cx('carto-l2')}>
                                <div><b style={{ fontSize: '22px', color: 'black' }}>Bộ lọc</b></div>

                                <br></br>
                                <div>DANH MỤC</div>
                                <br></br>
                                <div>
                                    <FormControlLabel
                                        label="Áo"
                                        control={
                                            <Checkbox
                                                checked={checked[0] && checked[1]}
                                                indeterminate={checked[0] !== checked[1]}
                                                onChange={chonTatCaAo}
                                            />
                                        }
                                    />
                                    {children}

                                </div>
                            </div>
                            <div className={cx('carto-l3')}>
                                <div>MỨC GIÁ</div>
                                <br></br>
                                <div>MÀU SẮC</div>
                                <div>
                                    <FormControlLabel
                                        label="Tất cả"
                                        control={
                                            <Checkbox
                                                checked={checkedMau[0] && checkedMau[1]}
                                                indeterminate={checkedMau[0] !== checkedMau[1]}
                                                onChange={chonTatCaMau}
                                            />
                                        }
                                    />
                                    {mausac}

                                </div>

                            </div>
                            <div className={cx('carto-l4')}>
                                <div>KÍCH CỠ</div>
                                <div>
                                    <FormControlLabel
                                        label="Tất cả"
                                        control={
                                            <Checkbox
                                                checked={checkedKichCo[0] && checkedKichCo[1]}
                                                indeterminate={checkedKichCo[0] !== checkedKichCo[1]}
                                                onChange={chonTatCaKichThuoc}
                                            />
                                        }
                                    />
                                    {kichco}

                                </div>

                            </div>
                        </div> */}

                        <div className={cx('d-flex carto-right')}>
                            {/* ben phai */}

                            <div className="width-container">
                                <Box
                                    className="d-flex justify-space-between align-end"
                                    sx={{ paddingTop: '50px', fontWeight: 'bold', paddingBottom: '20px', pl: 1, pr: 2 }}
                                >
                                    <div>
                                        <b> <a style={{ fontSize: '28px', color: 'black' }}>{category.name}</a></b>
                                    </div>
                                    <div>
                                        <a style={{ fontSize: '16px', color: 'black' }}>Strona główna / </a><a> {category.name} </a>
                                    </div>

                                    <div style={{ fontSize: '24px', color: 'black' }}>{totalItem} produkt</div>
                                </Box>
                                <Divider sx={{ mb: 3 }} />
                                <GridProductV3
                                    products={products}
                                    loading={productLoading}
                                    showPainate={totalItem > 0 ? true : false}
                                    totalPage={totalPage}
                                    onChangePage={onChangePage}
                                />
                            </div>

                        </div>
                    </Box>
                </Box>
            </Box>
            <Box className={cx('lichnt-mobile')} sx={{ pb: 4, paddingBottom: '0px', paddingTop: '0px !important' }}>
                <Box className={cx('carto-card-img')}>
                    <Box sx={{ paddingTop: '0px' }} className={cx('carto-card-all')}>
                        <div className={cx('d-flex carto-right')}>
                            {/* ben phai */}
                            <div className="width-container-home-product">
                                <Box
                                    className="d-flex justify-space-between align-end"
                                    sx={{ paddingTop: '10px', fontWeight: 'bold', paddingBottom: '5px', pl: 1, pr: 2 }}
                                >
                                    <div style={{ fontSize: '24px', color: 'black' }}>{totalItem} produkt</div>
                                </Box>
                                {/* <Divider sx={{ mb: 1 }} /> */}
                                <GridProductV3
                                    products={products}
                                    loading={productLoading}
                                    showPainate={totalItem > 0 ? true : false}
                                    totalPage={totalPage}
                                    onChangePage={onChangePage}
                                />
                            </div>

                        </div>
                    </Box>


                </Box>
            </Box>
        </div>
    );
}
export default ProductCategory;
