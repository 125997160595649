import axiosClient from '@/utils/axiosClient';
class AccessoryApi {
    getType = params => {
        const url = '/category-accessory';
        return axiosClient.get(url, { params });
    };
    updateType = (data, id) => {
        const url = `/category-accessory/${id}`;
        return axiosClient.put(url, data);
    };
    addType = (data) => {
        const url = `/category-accessory`;
        return axiosClient.post(url, data);
    };
    deleteType = (id) => {
        const url = `/category-accessory/${id}`;
        return axiosClient.delete(url);
    };

    getAccessories = (params) => {
        const url = '/accessories';
        return axiosClient.get(url, { params });
    };
    addAccessory = (data) => {
        const url = '/accessories';
        return axiosClient.post(url, data);
    };
    updateAccessory = (data, id) => {
        const url = `/accessories/${id}`;
        return axiosClient.put(url, data);
    };
    deleteAccessory = (id) => {
        const url = `/accessories/${id}`;
        return axiosClient.delete(url);
    };
    getItemDesign = () => {
        const url = `/item-design`;
        return axiosClient.get(url);
    }
}
const accessoryApi = new AccessoryApi();
export default accessoryApi;
