import { Badge, Box, IconButton, TextField } from '@mui/material';
import styles from './cart.scss';
import classNames from 'classnames/bind';
import CloseIcon from '@mui/icons-material/Close';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import { updateCartItem, removeCartItem, updateCartColorItem } from '@/store/cart/cartSlice';
import { useDispatch } from 'react-redux';
import MInput from '@/components/Input/MInput';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';

const cx = classNames.bind(styles);

function ProductItem(props) {
    const product = props.product;
    const sx = props.sx;
    const onChangeChildCart = props.onChangeChildCart;
    console.log('product', product)
    const [quantity, setQuantity] = useState(product.quantity);
    const [defaultQuantity, setDefaultQuantity] = useState(product.quantity);

    const dispatch = useDispatch();
    var filtered = product.url_image;

    const handleUpdateItem = (key, value) => {
        switch (key) {
            case 'quantity':
                const quantityValue = Number(value) < 0 ? 0 : Number(value);
                const calculate = Math.ceil(quantityValue / defaultQuantity);
                setQuantity(calculate * defaultQuantity);
                dispatch(updateCartItem({ ...product, quantity: calculate * defaultQuantity, type: 'quantity' }));
                break;
            default:
                break;
        }
    };
    return (
        <Box className="d-flex" sx={{ ...sx, marginBottom: '20px' }}>
            <Badge badgeContent={quantity} color="primary">
                <Box
                    className={cx('item-image-product')}
                    sx={{
                        backgroundImage: `url('${filtered}')`,
                        borderBottomLeftRadius: '30',
                        borderBottomRightRadius: '30',
                    }}
                ></Box>
            </Badge>
            <Box sx={{ ml: 3, width: '100%' }} className="c-flex justify-space-between">
                <Box>
                    <Box className="d-flex justify-space-between align-center">
                        <Box
                            className={cx('cardname-text')}
                            sx={{ fontWeight: '500', color: 'black', fontSize: '16px' }}
                        >
                            {product.name} (#{product.sku_code})
                        </Box>
                        <Box sx={{ ml: 2 }}>
                            <IconButton onClick={() => dispatch(removeCartItem(product))}>
                                <CloseIcon sx={{ color: 'black' }} />
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
                <Box className="d-flex flex-wrap">
                    <Box sx={{ pb: 1, width: '150px' }}>
                        <Box sx={{ textAlign: 'start' }}>
                            {product.sale_price} zł
                        </Box>
                        <Box className="d-flex" sx={{ mt: 2 }}>
                            {product.promotion == true ? (
                                <Box style={{ color: 'rgb(205, 255, 0)' }} className="d-flex align-center">
                                    <CardGiftcardIcon fontSize="small" color="primary"></CardGiftcardIcon>
                                    <div style={{ fontSize: '13px', paddingLeft: '3px', fontWeight: '200' }}>
                                        Quà tặng từ ONYOU!
                                    </div>
                                </Box>
                            ) : (
                                <MInput
                                    className={cx('cart-input-select-quantity')}
                                    variant="outlined"
                                    value={quantity}
                                    onChange={e => handleUpdateItem('quantity', e.target.value)}
                                    size="small"
                                    type="number"
                                    min="1"
                                />
                            )}

                        </Box>
                    </Box>
                    <Box sx={{ flex: 1 }} className="c-flex align-end">
                        <Box sx={{ fontWeight: 'bold', color: 'black', fontSize: '16px', paddingBottom: '10px' }}>
                            {Number(quantity * product.sale_price).toLocaleString('de-DE')} zł
                        </Box>
                    </Box>
                    <br></br>
                </Box>
            </Box>
        </Box>
    );
}
export default ProductItem;
