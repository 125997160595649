import { createSlice } from '@reduxjs/toolkit';

export const auth = createSlice({
    name: 'auth',
    initialState: {
        me: null,
        redirectTo: null,
        form: {
            username: '',
            password: '',
            errors: {},
        },
    },
    reducers: {
        setMe: (state, action) => {
            state.me = action.payload;
        },
        setForm: (state, action) => {
            state.form = {
                ...state.form,
                ...action.payload,
            };
        },
        setError: (state, action) => {
            state.form = {
                ...state.form,
                errors: { ...state.form.errors, ...action.payload },
            };
        },
        setRedirectTo: (state, action) => {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            var redirect = action.payload;
            if (urlParams.has('redirect')) {
                redirect = urlParams.get('redirect');
            }
            state.redirectTo = redirect;
        },
    },
});

export const { setMe, setRedirectTo, setForm, setError } = auth.actions;

export default auth.reducer;
