import styles from './Blog.scss';
import classNames from 'classnames/bind';
import { Box } from '@mui/material';

const cx = classNames.bind(styles);

function Blog() {
    // return <div className={cx('iframe-container')}>
    //     <iframe
    //         className={cx('responsive-iframe')}
    //         id="inlineFrameExample"
    //         title="Inline Frame Example"
    //         src="https://blog.onyou.vn"
    //     >
    //     </iframe>
    // </div>
    return (
        <div className={cx('d-flex blog-all')}>
            <div className={cx('d-flex blog-link')}>
                <a style={{ fontSize: '16px', color: '#8e8e8e', fontFamily: 'MyFont' }}>Strona główna / </a><a style={{ fontSize: '16px', color: 'gray' }}> Blog /</a><a>Phối đồ</a>
            </div>
            <Box>
                <div className={cx('d-flex blog-title')}>
                    Bí kíp phối đồ tập gym nam cực chất lại thoải mái cho chàng
                </div>
                <div className={cx('d-flex blog-opening')}>
                    Tìm hiểu bí kíp phối đồ tập gym nam cực chất để mang lại sự thoải mái và phong cách cho các chàng trai. Xem các gợi ý và nguyên tắc phối đồ tốt nhất để tạo nên một phong cách tập luyện ấn tượng.
                </div>
                <div className={cx('d-flex blog-date')}>
                    Ngày đăng: 21.05.2023
                </div>
            </Box>
            <Box sx={{ paddingTop: '0px' }} className={cx('blog-card-pc')} >
                <div className={cx('d-flex blog-left')}>
                    Trang phục tập gym nam là một tiêu chí quan trọng được nhiều anh em quan tâm vì nó ảnh hưởng trực tiếp đến hiệu quả tập luyện của bạn. Phối đồ đúng cách không chỉ là việc chọn những bộ quần áo hợp thời trang, mà còn là việc kết hợp các loại vải, kiểu dáng và màu sắc sao cho phù hợp với mọi bài tập gym.

                    Trong bài viết này, ONYOU sẽ giúp bạn khám phá những tips quan trọng để phối đồ tập gym nam cực chất và thoải mái, giúp bạn trở thành tâm điểm trong phòng tập.

                    Những tiêu chí gì để chọn đồ tập gym cho các chàng
                    1. Chất liệu
                    Yếu tố quan trọng và cơ bản nhất ảnh hưởng đến chất lượng của trang phục và hiệu quả của việc luyện tập là chất liệu. Khi bạn đầu tư một cách hợp lý vào quần áo tập gym, bạn sẽ tận hưởng sự thoải mái và dễ chịu trong quá trình luyện tập.

                    Khi mua quần áo tập gym cho nam giới, hãy chú ý xem liệu chất liệu vải có khả năng thấm hút mồ hôi tốt và thoáng khí hay không. Khi tập luyện, cơ thể sẽ sản sinh mồ hôi và cần một luồng khí tươi để lưu thông tốt hơn.

                    Dù việc tập thể dục có ích cho sức khỏe, nhưng nếu mặc những bộ quần áo không cho phép mồ hôi thoát ra, cơ thể sẽ bị ướt và dễ bị cảm lạnh.

                    Hiện nay, có ba chất liệu phổ biến được sử dụng trong sản xuất quần áo tập gym: cotton, polyester và spandex. Hãy cùng xem những đặc điểm của từng loại vải này:

                    Cotton: Chất liệu này có khả năng thấm hút tuyệt vời và giới hạn mùi cơ thể. Tuy nhiên, trong những buổi tập với cường độ cao như tập gym, bạn có thể cảm thấy cơ thể ẩm như vừa tắm.
                    Polyester: Chất liệu này có độ bền cao, ít nhăn nhúm sau khi giặt, nhẹ và rất thoáng khí. Đặc biệt, vải polyester còn có khả năng chống tia cực tím (UV) ngay cả khi bị ướt.
                    Spandex: Chất liệu này có tính co giãn xuất sắc, có thể kéo giãn nhiều lần mà vẫn giữ được hình dạng ban đầu. Ngoài ra, vải spandex còn mềm mại, không gây tĩnh điện và không tạo xơ trên bề mặt.
                    bi-kip-phoi-do-tap-gym-nam-cuc-chat-lai-thoai-mai-cho-chang-1

                    Nên lựa chọn chất liệu vải thấm hút mồ hôi tốt khi tập gym. Nguồn: Internet

                    2. Độ co giãn
                    Không chỉ về chất liệu, mà độ co giãn cũng đóng góp một phần quan trọng trong việc mang lại sự thoải mái và tự tin cho người mặc. Đối với một bộ đồ tập gym, độ co giãn cao là điều cần thiết để đảm bảo phạm vi hoạt động rộng khi thực hiện các động tác. Ngoài ra, lựa chọn đai quần có tính đàn hồi, ôm vừa eo cũng rất quan trọng. Tránh những chiếc quần quá chặt vì chúng có thể gây ra vết hằn và đau rát trên vùng bụng.

                    3. Size phù hợp
                    Một bộ quần áo thể thao lý tưởng nên có kích cỡ vừa vặn, không quá rộng hoặc quá chật. Khi quần áo quá rộng, nó có thể bị vướng vào các thiết bị tập luyện hoặc gây cản trở khi thực hiện các động tác. Nếu quần áo quá chật, nó có thể hạn chế sự lưu thông máu và ảnh hưởng đến chất lượng buổi tập.

                    bi-kip-phoi-do-tap-gym-nam-cuc-chat-lai-thoai-mai-cho-chang-2

                    Chọn size đồ tập thoải mái sẽ giúp nâng cao hiệu suất luyện tập. Nguồn: Internet

                    4. Thời tiết
                    Dưới đây là một số gợi ý về trang phục tập gym cho nam giới, tùy thuộc vào mùa:

                    Trang phục mùa hè:Khi mùa hè đến, chất liệu thoáng khí và thấm hút là ưu tiên hàng đầu. Vì khí hậu nóng có thể làm bạn ra mồ hôi nhiều hơn, việc chọn sai chất liệu sẽ khiến bạn cảm thấy nóng bức và không thoải mái khi tập luyện. Đồng thời, nếu mồ hôi không được thấm hút ngay, cơ thể có thể bị lạnh. Đây là một số item phù hợp cho mùa hè:

                    Áo ba lỗ, áo tank top: Những loại áo này thoáng mát và giúp bạn cảm thấy dễ chịu trong nắng nóng.
                    Áo phông: Một chiếc áo phông có chất liệu thấm hút tốt cũng là một lựa chọn tốt cho mùa hè.
                    Quần short: Để tạo sự thoải mái và linh hoạt khi tập luyện, quần short là một item phù hợp.
                    Trang phục mùa đông: Mùa đông đòi hỏi trang phục thoải mái, thấm hút và giữ ấm cơ thể. Dưới đây là một số gợi ý cho mùa đông:

                    Áo phông kết hợp áo ba lỗ: Lớp áo phông bên trong có thể giữ ấm cơ thể, trong khi áo ba lỗ bên ngoài có tính thoáng khí và linh hoạt hơn cho việc vận động.
                    Quần jogger: Một chiếc quần jogger thoải mái và ấm áp là lựa chọn phù hợp trong mùa đông.
                    Áo thun hoặc quần legging giữ nhiệt: Có sẵn những chiếc áo thun hoặc quần legging giữ nhiệt, được ưa chuộng bởi nhiều người tập gym. Đây là những item đáng xem xét để sở hữu trong những buổi tập luyện vào mùa đông.
                    Gợi ý cách phối đồ tập gym cực chất cho từng buổi tập
                    Việc lựa chọn trang phục phù hợp khi tập gym không chỉ giúp bạn thoải mái và tự tin hơn khi tập, mà còn giúp tăng cường hiệu quả của bài tập. Hãy tham khảo cách phối đồ tập gym nam cực chất cho từng buổi tập dưới đây:

                    1. Buổi tập vai (áo ba lỗ + quần short + giày thể thao)
                    Cách phối đồ cho nam tập gym trong buổi tập vai phổ biến nhất là kết hợp áo ba lỗ với quần short và giày thể thao. Áo ba lỗ sẽ giúp bạn thoải mái vận động cánh tay và khoe trọng phần vai săn chắc của mình. Trong khi, quần short nam là món đồ phổ biến mà chàng trai nào cũng sở hữu vài chiếc.

                    Quần có đặc điểm vừa mát mẻ vừa dễ dàng di chuyển qua lại. Hãy nhớ lựa chọn thêm một đôi giày thể thao êm ái để mọi vận động của bạn trở nên thoải mái. Hiện nay ONYOU đưa ra thị trường rất nhiều mẫu áo ba lỗ và những mẫu quần short vô cùng chất lượng, hợp thời trang. Sẽ không khó để bạn tìm mua các sản phẩm này với mức giá hấp dẫn kèm theo vô vàn khuyến mãi tại các gian hàng thương mại điện tử chính thức của hãng. Đừng bỏ lỡ cơ hội sở hữu cho mình những item này vào tủ đồ của mình các chàng nhé.
                </div>
                <div className={cx('d-flex blog-right')}>
                    <div className={cx('d-flex blog-right-top')}>
                        Mục lục
                        Những tiêu chí gì để chọn đồ tập gym cho các chàng
                        1. Chất liệu
                        2. Độ co giãn
                        3. Size phù hợp
                        4. Thời tiết
                        Gợi ý cách phối đồ tập gym cực chất cho từng buổi tập
                        1. Buổi tập vai (áo ba lỗ + quần short + giày thể thao)
                        2. Buổi tập ngực (áo ba lỗ/áo thun + quần short + giày sneaker)
                        3. Buổi tập lưng (áo thun + quần tập lưng cao + giày thể thao)
                        4. Buổi tập tay (áo tank top + quần short + giày sneaker)
                        5. Buổi tập mông (áo tank top + quần jogger/legging + giày tập gym chuyên dụng)
                        6. Buổi tập chân (áo phông + quần legging + giày thể thao chuyên dụng)
                        7. Buổi tập cardio (áo thun + quần short + giày thể thao)
                    </div>
                    <div className={cx('d-flex blog-right-bottom')}>

                    </div>

                </div>
            </Box>
        </div>
    )
}

export default Blog;
