import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
const CssTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        borderRadius: '14px',
        color: 'white',
        border: '0px solid white !important',
        fontSize: '19px'
    },
});
function MInputProduct(prop) {
    return <CssTextField {...prop} />;
}
export default MInputProduct;
