import { Badge, Box, IconButton, TextField } from '@mui/material';
import styles from './cart.scss';
import classNames from 'classnames/bind';
import CloseIcon from '@mui/icons-material/Close';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import { updateCartItem, removeCartItem } from '@/store/cart/cartSlice';
import { useDispatch } from 'react-redux';
import MInput from '@/components/Input/MInput';

const cx = classNames.bind(styles);

function ProductOrderItem(props) {
    const product = props.product;
    const sx = props.sx;

    const onChangeChild = props.onChangeChild;
    const [quantity, setQuantity] = useState(1);
    const [hotPrice, sethotPrice] = useState(product.sale_price);

    const dispatch = useDispatch();
    const handleUpdateItem = (key, value) => {
        switch (key) {
            case 'quantity':
                const quantityValue = Number(value) < 0 ? 0 : Number(value);
                if (quantityValue === 0) {
                    setQuantity(1);
                    dispatch(updateCartItem({ ...product, quantity: 1 }));
                } else {
                    setQuantity(quantityValue);
                    dispatch(updateCartItem({ ...product, quantity: quantityValue }));
                }
                onChangeChild(product.id, key, value, product.sale_price);
                break;
            case 'hotPrice':
                sethotPrice(value);

                break;
            default:
                break;
        }
    };
    return (
        <Box className="d-flex" sx={{ ...sx }}>
            <Badge badgeContent={quantity} color="primary">
                <Box className={cx('item-image')} sx={{ backgroundImage: `url('${product.url_image}')` }}></Box>
            </Badge>
            <Box sx={{ ml: 3, width: '100%' }} className="c-flex justify-space-between">
                <Box>
                    <Box className="d-flex justify-space-between align-center">
                        <Box className={cx('cardname-text')} sx={{ fontWeight: 'bold' }}>{product.name}</Box>
                        <Box sx={{ ml: 2 }}>
                            <IconButton onClick={() => dispatch(removeCartItem(product))}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
                <Box className="d-flex flex-wrap">
                    <Box sx={{ pb: 1, width: '200px' }}>
                        <Box className="d-flex" sx={{ mt: 2 }}>
                            <MInput
                                variant="outlined"
                                value={hotPrice}
                                onChange={e => handleUpdateItem('hotPrice', e.target.value)}
                                size="small"
                                type="number"
                                min="0"
                            />
                        </Box>
                        <Box className="d-flex" sx={{ mt: 2 }}>
                            <MInput
                                variant="outlined"
                                value={quantity}
                                onChange={e => handleUpdateItem('quantity', e.target.value)}
                                size="small"
                                type="number"
                                min="0"
                            />
                        </Box>
                    </Box>
                    <Box sx={{ flex: 1 }} className="c-flex align-end">
                        <Box sx={{ fontWeight: 'bold' }}>{Number(quantity * product.sale_price).toLocaleString('de-DE')} zł</Box>
                    </Box>
                    <br></br>
                </Box>
            </Box>
        </Box>
    );
}
export default ProductOrderItem;
