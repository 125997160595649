import Divider from '@mui/material/Divider';
import classNames from 'classnames/bind';
import styles from './Service.scss';
import FileViewer from "react-file-viewer";
import images from '@/assets/images';

const cx = classNames.bind(styles);

const onError = e => {

};

function Checking() {

    return (
        <div className="d-flex justify-center bg-gray">
            <div className={cx('container-service', 'width-container')}>
                <div style={{ width: '100%', textAlign: 'center', fontSize: '24px', fontWeight: 'bold' }}>
                    CHÍNH SÁCH KIỂM HÀNG
                </div>
                <Divider sx={{ mb: 1, mt: 1 }} />
                <div className={cx('file-service')}>
                    <FileViewer style={{ width: '200 !important', height: '200 !important' }} className={cx('content-service')} fileType="pdf" filePath={images.fileChecking} onError={onError} />
                </div>

            </div>
        </div>
    );
}
export default Checking;
