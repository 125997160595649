import { privateRoutes } from '@/routes';
import MuiDrawer from '@mui/material/Drawer';

import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import images from '@/assets/images';
import { useSelector } from 'react-redux';

import { useState } from 'react';
const drawerWidth = 300;

const closedMixin = theme => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});
const openedMixin = theme => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: prop => prop !== 'open' })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));
const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}));

function Sidebar() {
    const openSidebar = useSelector(state => state.sidebar.openSidebar);

    const sidbarMenu = [];
    privateRoutes.forEach(item => {
        if (!item.hidden) {
            var subMenus = item.children;
            if (subMenus && subMenus.length > 0) {
                subMenus = subMenus.filter(el => !el.hidden);
            }
            sidbarMenu.push({ ...item, children: subMenus });
        }
    });
    const [submenuOpen, setSubmenuOpen] = useState({});

    const handleOpenSubmenu = key => {
        var newState = submenuOpen[key] ? false : true;
        setSubmenuOpen({ ...submenuOpen, [key]: newState });
    };
    const navigate = useNavigate();
    const goToPage = path => {
        navigate(path);
    };
    return (
        <Drawer variant="permanent" open={openSidebar}>
            <DrawerHeader>
                <div className="d-flex justify-center align-center" style={{ width: '100%', height: '100%' }}>
                    <img
                        loading="lazy"
                        alt="logo"
                        src={openSidebar ? images.logo : images.shortLogo}
                        style={{ width: '100%', height: 'auto', cursor: 'pointer' }}
                        onClick={() => goToPage('/')}
                    />
                </div>
            </DrawerHeader>

            <List sx={{ width: drawerWidth, bgcolor: 'background.paper' }}>
                {sidbarMenu.map((item, index) => {
                    if (item.children && item.children.length > 0) {
                        return (
                            <Fragment key={index}>
                                <ListItemButton onClick={() => handleOpenSubmenu(item.path)}>
                                    <ListItemIcon>{item.icon ? <item.icon /> : null}</ListItemIcon>
                                    <ListItemText disableTypography={true} sx={{ fontSize: 16 }} primary={item.name} />
                                    {submenuOpen[item.path] ? <ExpandLess /> : <ExpandMore />}
                                </ListItemButton>
                                <Collapse in={submenuOpen[item.path]} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {item.children.map((child, indexChild) => (
                                            <ListItemButton
                                                sx={{ pl: openSidebar ? 4 : 3 }}
                                                key={indexChild}
                                                onClick={() => goToPage(`${item.path}/${child.path}`)}
                                            >
                                                <ListItemIcon>
                                                    {child.icon ? (
                                                        <child.icon sx={{ fontSize: openSidebar ? 22 : 17 }} />
                                                    ) : null}
                                                </ListItemIcon>
                                                <ListItemText disableTypography={true} primary={child.name} sx={{ fontSize: 15 }} />
                                            </ListItemButton>
                                        ))}
                                    </List>
                                </Collapse>
                            </Fragment>
                        );
                    }
                    return (
                        <ListItemButton key={index} onClick={() => goToPage(`${item.path}`)}>
                            <ListItemIcon>{item.icon ? <item.icon /> : null}</ListItemIcon>
                            <ListItemText disableTypography={true} sx={{ fontSize: 16 }} primary={item.name} />
                        </ListItemButton>
                    );
                })}
            </List>
        </Drawer>
    );
}
export default Sidebar;
