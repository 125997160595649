import ratingApi from '@/api/ratingApi';
import productApi from '@/api/productApi';
import MDialog from '@/components/Dialog';
import { Box, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Autocomplete from '@mui/material/Autocomplete';
import { useCallback } from 'react';
import ImageUpload from '@/components/UploadImage/UploadSingle';
import { debounce } from 'lodash';

function CreateEdit({ open, title, onClose, data, type, onDone }) {
    const onSubmit = async () => {
        try {
            if (type === 'ADD') {
                await ratingApi.createRating(formData);
            }
            if (type === 'EDIT') {
                await ratingApi.updateRating(formData.id, formData);
            }
            onClose();
            toast.success('Thành công!');
            setErrorSubmit({});
            onDone()
        } catch (error) {
            const errors = error.response.data.errors;
            for (const key in errors) {
                errors[key] = errors[key].join('. ');
            }
            setErrorSubmit(errors);
        }
    };
    const [formData, setFormData] = useState({});
    const [errorSubmit, setErrorSubmit] = useState({});
    const [sanphams, setSanphams] = useState([]);
    const [searchProduct, setSearchProduct] = useState(null);
    const [products, setProducts] = useState(null);
    const [param, setParam] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setFormData({ url_image: null, name: '', code: '' });
        if (type === 'EDIT') {
            setFormData(data);
            setProducts(data.product_id);
        }
    }, [type, data]);
    

    const handleUpload = urlImage => {
        setFormData({ ...formData, url_image: urlImage });
    };
    const getSanphams = useCallback(async () => {
        let dataSanphams = await productApi.getProduct({ perPage: 9 });
        setSanphams(dataSanphams.data);
    }, []);
    const getData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await productApi.getProduct(param);
            setLoading(false);
            setSanphams(response.data);
        } catch (error) {
            setLoading(false);
        }
    }, [param]);

    const handleSearch = value =>
        debounce(() => {
            setParam({ ...param, search: value });
        }, 600)();

    useEffect(() => {
            getData();
    }, [getData]);

    useEffect(() => {
        getSanphams();
    }, [getSanphams]);

    return (
        <div>
            <MDialog open={open} title={title} onClose={onClose} onSubmit={onSubmit} type={type}>
                <Box sx={{ width: '400px' }}>
                    {/* <ImageUpload onDone={handleUpload} url={formData.url_image}/> */}
                    <Autocomplete
                                freeSolo
                                disableClearable
                                disablePortal
                                sx={{ width: '100%', mt: 3 }}
                                label="Lựa chọn sản phẩm"
                                variant="outlined"
                                size="small"
                                getOptionLabel={option => option.name}
                                options={sanphams}
                                value={products}
                                onChange={(e, newValue) => {
                                    setFormData({ ...formData, product_id: newValue.id });
                                    setProducts(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Search input"
                                      onChange={e => handleSearch(e.target.value)}
                                    />
                                )}
                    />
                    <TextField
                        sx={{ width: '100%', mt: 3 }}
                        label="Tên người rating"
                        variant="outlined"
                        value={formData.username}
                        error={errorSubmit.username ? true : false}
                        onChange={e => setFormData({ ...formData, username: e.target.value })}
                        helperText={errorSubmit.username}
                    />
                    <TextField
                        sx={{ width: '100%', mt: 3 }}
                        label="Nội dung rating"
                        variant="outlined"
                        value={formData.content}
                        error={errorSubmit.content ? true : false}
                        onChange={e => setFormData({ ...formData, content: e.target.value })}
                        helperText={errorSubmit.content}
                    />
                    <TextField
                        sx={{ width: '100%', mt: 3 }}
                        label="Star"
                        placeholder="Star"
                        value={formData.rate}
                        onChange={e => setFormData({ ...formData, rate: e.target.value })}
                        variant="outlined"
                        type="number"
                        InputProps={{ inputProps: { min: 1, max: 5 } }}
                        size="small"
                    />
                </Box>
            </MDialog>
        </div>
    );
}
export default CreateEdit;
