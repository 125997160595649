import images from '@/assets/images';
import MDialog from '@/components/Dialog';


function SizeDialog({ onClose, open }) {

    // const handleSelectType = () => {
    //     const rev = document.getElementById('sizeCM');
    //     rev.setAttribute("style","display:none;")
    //     const rev2 = document.getElementById('sizeINCHE');
    //     rev2.setAttribute("style","display:flex; width: 500px")

    //     const revbtn = document.getElementById('sizeINCHEbtn');
    //     revbtn.setAttribute("style","display:flex;")
    //     const revbtn2 = document.getElementById('sizeCMbtn');
    //     revbtn2.setAttribute("style","display:none;")

    // };
    // const handleSelectTypeCm = () => {
    //     const rev = document.getElementById('sizeINCHE');
    //     rev.setAttribute("style","display:none;")
    //     const rev2 = document.getElementById('sizeCM');
    //     rev2.setAttribute("style","display:flex; width: 500px")

    //     const revbtn = document.getElementById('sizeCMbtn');
    //     revbtn.setAttribute("style","display:flex;")
    //     const revbtn2 = document.getElementById('sizeINCHEbtn');
    //     revbtn2.setAttribute("style","display:none;")
    // };

    return (
        <MDialog open={open} style={{ textAlign: 'center' }} title="Instrukcje dotyczące wyboru rozmiaru" onClose={onClose}>
            <div id="sizeCM" style={{ width: '35vw' }}>
                <img src={images.sizeHelp} alt="Size" style={{ width: '100%' }} />
            </div>

            {/* <div id="sizeINCHE" style={{ width: '500px', display:'none' }}>
                <img src={images.sizeHelpInches} alt="Size" style={{ width: '100%' }} />
            </div>
            <Button id="sizeCMbtn" variant="outlined" onClick={() => handleSelectType()}>
                Chọn size theo Inches
            </Button>
            <Button id="sizeINCHEbtn" style={{ display:'none' }} variant="outlined" onClick={() => handleSelectTypeCm()}>
                Chọn size theo Cm
            </Button> */}
        </MDialog>

    );
}

export default SizeDialog;
