import { useDispatch } from 'react-redux';
import { pushIconDesign } from '@/store/design/targetDesignSlice';
import { v4 as uuidv4 } from 'uuid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState, useEffect } from 'react';
import accessoryApi from '@/api/accessoryApi';
import LazyLoad from 'react-lazyload';
import { TextField } from '@mui/material';
import styles from './cart.scss';
import classNames from 'classnames/bind';


const cx = classNames.bind(styles);

function IconImageOnly() {
    const [expanded, setExpanded] = useState('panel1');
    const [expandAll, setExpandAll] = useState(false);
    const [accessories, setAccessories] = useState([]);
    const [accessoriesFilter, setAccessoriesFilter] = useState([]);
    const dispatch = useDispatch();
    const handlePushIcon = item => {
        var newItem = { ...item, text: null };
        var cRong = 55
        if (item.width != null) {
            cRong = item.width / 6.5 * 55
        }
        var strRong = cRong + 'px'

        newItem.style = { width: strRong, position: 'absolute' };
        newItem.db_id = item.id;
        newItem.id = uuidv4();
        return dispatch(pushIconDesign(newItem));
    };
    const handleChangeExpand = panel => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    useEffect(() => {
        const getItemDesign = async () => {
            const response = await accessoryApi.getItemDesign();
            setAccessories(response);
            setAccessoriesFilter(response);
        };
        getItemDesign();
    }, []);

    return (
        <div>
            <div style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                {accessoriesFilter.map((item, index) => (
                    <Accordion
                        key={index}
                        expanded={expanded === `panel${index + 1}` || expandAll}
                        onChange={handleChangeExpand(`panel${index + 1}`)}
                    >
                        <AccordionSummary
                            className={cx('title-item')}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <b>{item.name}</b>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div
                                className="d-flex"
                                style={{
                                    flexWrap: 'wrap',
                                    maxHeight: '40vh',
                                    overflowY: 'auto',
                                    position: 'relative',
                                }}
                            >
                                {item.accessories.map(image => (
                                    <LazyLoad
                                        overflow
                                        key={image.image_url}
                                        height={70}
                                        width={80}
                                        style={{
                                            border: '1px solid #D7DBDD',
                                            padding: '1px',
                                            marginRight: '6px',
                                            marginBottom: '6px',
                                        }}
                                    >
                                        <img
                                            style={{
                                                maxHeight: '80px',
                                                minWidth: '60px',
                                                width: '75px',
                                                position: 'relative',
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => handlePushIcon(image)}
                                            src={'http://dl-zabawek.pl/' + image.image_url}
                                            alt="icon"
                                            loading="lazy"
                                        />
                                    </LazyLoad>
                                ))}
                            </div>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </div>
        </div>
    );
}
export default IconImageOnly;
