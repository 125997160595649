import { Box } from '@mui/material';
import Radio from '@mui/material/Radio';
import styles from './cart.scss';
import classNames from 'classnames/bind';
import { useState } from 'react';
import { PAYMENTS } from '@/constants/order';
import LoadingButton from '@mui/lab/LoadingButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
const cx = classNames.bind(styles);

function CartPayment({ onSubmit, loadingButton = true }) {
    const [paymentMethod, setPaymentMethod] = useState('cod');
    const handleSelectPayment = paymentID => {
        setPaymentMethod(paymentID);
    };
    const [checkAgree, setCheckAgree] = useState(false);
    const handleSubmit = () => onSubmit(paymentMethod);
    return (
        <div>
            {PAYMENTS.map((item, index) => (
                <Box
                    className={cx('payment-method', 'd-flex align-center')}
                    key={index}
                    onClick={() => handleSelectPayment(item.id)}
                    sx={{
                        border: item.id === paymentMethod ? '1px solid #e08282' : '',
                        opacity: item.id === paymentMethod ? 1 : null,
                    }}
                >
                    <Radio
                        checked={item.id === paymentMethod}
                        sx={{
                            '& .MuiSvgIcon-root': {
                                fontSize: 28,
                            },
                            mr: 2,
                            color: 'black'
                        }}
                    />
                    <Box sx={{ mr: 3 }}>
                        <img src={item.image} alt="cod" style={{ width: '60px', marginRight: '10px' }} />
                    </Box>
                    <Box>
                        <Box sx={{ fontSize: '14px', mb: 1, color: 'black', fontWeight: '300' }}>{item.title}</Box>
                        <Box sx={{ fontSize: '12px', mb: 1, color: 'black', fontWeight: '100' }}>{item.body}</Box>
                    </Box>
                </Box>
            ))}
            {paymentMethod === 'onepay' ? (
                <Box className={cx('box-dieu-khoan')} sx={{ pl: 3, pt: 2, pb: 1 }}>
                    <Box sx={{ fontSize: '17px', fontWeight: '300', color: 'black' }}>
                        Vui lòng đọc và đồng ý với các điều khoản sau trước khi thanh toán
                    </Box>
                    <Box sx={{ mt: 2, fontSize: '16px', color: 'black' }}>
                        <Box className="d-flex align-center">
                            <FiberManualRecordIcon sx={{ fontSize: '14px', mr: 1, color: 'black' }} />
                            <a style={{ color: 'black', fontWeight: '200' }} href="/chinh-sach-doi-tra-va-hoan-tien" target="_blank">
                                Chính sách 30 ngày đổi trả
                            </a>
                        </Box>
                        <Box sx={{ mt: 1 }} className="d-flex align-center">
                            <FiberManualRecordIcon sx={{ fontSize: '14px', mr: 1 }} />
                            <a style={{ color: 'black', fontWeight: '200' }} href="/chinh-sach-bao-mat" target="_blank">
                                Chính sách bảo mật
                            </a>
                        </Box>
                    </Box>
                    <Box sx={{ textAlign: 'center', fontWeight: '300', color: 'black', paddingTop: '30px' }}>
                        <FormControlLabel
                            control={<Checkbox sx={{ color: 'black' }} checked={checkAgree} onChange={e => setCheckAgree(e.target.checked)} />}
                            label="Tôi đã đọc, đồng ý với điều kiện giao dịch chung và các chính sách"
                        />
                    </Box>
                </Box>
            ) : null}

            <LoadingButton
                disabled={!checkAgree && paymentMethod === 'onepay'}
                loading={loadingButton}
                sx={{ width: '100%', mt: 4, height: '60px', borderRadius: '15px' }}
                size="large"
                variant="contained"
                onClick={() => handleSubmit()}
            >
                Wyróżniające się cechy
            </LoadingButton>
            {/* <div className={cx('box-dieu-khoan')}>
                <LoadingButton
                    disabled={!checkAgree && paymentMethod === 'onepay'}
                    loading={loadingButton}
                    sx={{ width: '100%', mt: 4, height: '60px', borderRadius: '15px' }}
                    size="large"
                    variant="contained"
                    onClick={() => handleSubmit()}
                >
                    Wyróżniające się cechy
                </LoadingButton>
            </div> */}

        </div>
    );
}
export default CartPayment;
