import MDialog from '@/components/Dialog';
import { Box, InputAdornment, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import voucherApi from '@/api/voucherApi';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

function CreateEdit({ open, title, onClose, data, type, onDone }) {
    const [disableFeild, setDisableFeild] = useState(false)
    const [typeVoucher, setTypeVoucher] = useState(null)
    const onSubmit = async () => {
        try {
            if (type === 'ADD') {
                await voucherApi.createVoucher(formData);
            }
            if (type === 'EDIT') {
                await voucherApi.updateVoucher(formData.id, formData);
            }
            onClose();
            toast.success('Thành công!');
            setErrorSubmit({});
            onDone();
        } catch (error) {
            const errors = error.response.data.errors;
            for (const key in errors) {
                errors[key] = errors[key].join('. ');
            }
            setErrorSubmit(errors);
        }
    };
    const [formData, setFormData] = useState({});
    const [errorSubmit, setErrorSubmit] = useState({});

    useEffect(() => {
        setFormData({});
        setDisableFeild(false)
        setTypeVoucher(null)
        if (type === 'EDIT') {
            setFormData({...data});
            setDisableFeild(true);
            setTypeVoucher(data.type)
        }
    }, [type, data]);

    return (
        <div>
            <MDialog open={open} title={title} onClose={onClose} onSubmit={onSubmit} type={type}>
                <Box sx={{ width: '800px' }}>
                    <TextField
                        sx={{ width: '100%' }}
                        label="Tên mã giảm giá"
                        variant="outlined"
                        value={formData.name}
                        error={errorSubmit.name ? true : false}
                        onChange={e => setFormData({ ...formData, name: e.target.value })}
                        helperText={errorSubmit.name}
                    />
                    <Box className="d-flex" sx={{ mt: 2 }}>
                        <TextField
                            sx={{ width: '100%', mr: 1 }}
                            label="Mã giảm giá"
                            variant="outlined"
                            value={formData.code}
                            error={errorSubmit.code ? true : false}
                            onChange={e => setFormData({ ...formData, code: e.target.value })}
                            helperText={errorSubmit.code}
                            disabled={disableFeild}
                        />
                        <TextField
                            sx={{ width: '100%', ml: 1 }}
                            variant="outlined"
                            type="number"
                            label="Số lượng"
                            value={formData.quantity}
                            error={errorSubmit.quantity ? true : false}
                            onChange={e => setFormData({ ...formData, quantity: e.target.value })}
                            helperText={errorSubmit.quantity}
                        />
                    </Box>
                    <Box className="d-flex" sx={{ mt: 2 }}>
                        <Box sx={{ width: '100%', mr: 1 }}>
                            <Box sx={{ mb: 1 }}>Loại mã giảm giá</Box>
                            <Select
                                sx={{ width: '100%', mr: 1 }}
                                value={typeVoucher}
                                placeholder="Loại mã giảm giá"
                                onChange={e => {setTypeVoucher(e.target.value); setFormData({ ...formData, type: e.target.value })}}
                                error={errorSubmit.type ? true : false}
                                helperText={errorSubmit.type}
                            >
                                <MenuItem value={'phan_tram'}>Phần trăm</MenuItem>
                                <MenuItem value={'money'}>Giá tiền</MenuItem>
                            </Select>
                        </Box>
                        <Box sx={{ width: '100%', ml: 1 }}>
                            <Box sx={{ mb: 1 }}>Giá trị {formData.type === 'phan_tram' ? '(%)' : (formData.type === 'money' ? '(Vnđ)' : '')}</Box>
                            <TextField
                                sx={{ width: '100%' }}
                                variant="outlined"
                                type="number"
                                value={formData.value}
                                error={errorSubmit.value ? true : false}
                                onChange={e => setFormData({ ...formData, value: e.target.value })}
                                helperText={errorSubmit.value}
                                disabled={!formData.type}
                                InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        {formData.type === 'phan_tram' ? '%' : (formData.type === 'money' ? 'Vnđ' : '')}
                                      </InputAdornment>
                                    ),
                                  }}
                            />
                        </Box>
                    </Box>

                    <Box className="d-flex" sx={{ mt: 2 }}>
                        <Box sx={{ width: '100%', mr: 1 }}>
                            <Box sx={{ mb: 1 }}>Giá trị từ ngày</Box>
                            <TextField
                                sx={{ width: '100%' }}
                                variant="outlined"
                                type="datetime-local"
                                value={formData.start_at}
                                error={errorSubmit.start_at ? true : false}
                                onChange={e => setFormData({ ...formData, start_at: e.target.value })}
                                helperText={errorSubmit.start_at}
                            />
                        </Box>
                        <Box sx={{ width: '100%', ml: 1 }}>
                            <Box sx={{ mb: 1 }}>Giá trị đến ngày</Box>
                            <TextField
                                sx={{ width: '100%' }}
                                variant="outlined"
                                type="datetime-local"
                                value={formData.end_at}
                                error={errorSubmit.end_at ? true : false}
                                onChange={e => setFormData({ ...formData, end_at: e.target.value })}
                                helperText={errorSubmit.end_at}
                            />
                        </Box>
                    </Box>
                    <Box className="d-flex" sx={{ mt: 2 }}>
                        <Box sx={{ width: '100%', mr: 1 }}>
                            <Box sx={{ mb: 1 }}>Giảm giá tối đa</Box>
                            <TextField
                                sx={{ width: '100%' }}
                                variant="outlined"
                                type="number"
                                value={formData.max_reduce}
                                error={errorSubmit.max_reduce ? true : false}
                                onChange={e => setFormData({ ...formData, max_reduce: e.target.value })}
                                helperText={errorSubmit.max_reduce}
                            />
                        </Box>
                        <Box sx={{ width: '100%', ml: 1 }}>
                            <Box sx={{ mb: 1 }}>Đơn hàng tối thiểu</Box>
                            <TextField
                                sx={{ width: '100%' }}
                                variant="outlined"
                                type="number"
                                value={formData.min_order}
                                error={errorSubmit.min_order ? true : false}
                                onChange={e => setFormData({ ...formData, min_order: e.target.value })}
                                helperText={errorSubmit.min_order}
                            />
                        </Box>
                    </Box>
                    <TextField
                        sx={{ width: '100%', mt: 2 }}
                        label="Mô tả"
                        variant="outlined"
                        value={formData.description}
                        onChange={e => setFormData({ ...formData, description: e.target.value })}
                    />
                </Box>
            </MDialog>
        </div>
    );
}
export default CreateEdit;
