import { createSlice } from '@reduxjs/toolkit';

export const sidebarAction = createSlice({
    name: 'sidebar',
    initialState: {
       openSidebar: true
    },
    reducers: {
        toggleSidbar: (state) => {
            state.openSidebar = !state.openSidebar
        }   
    },
});

export const {
    toggleSidbar
} = sidebarAction.actions;

export default sidebarAction.reducer;
