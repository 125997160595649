import { Badge, Box, IconButton, TextField } from '@mui/material';
import styles from './CartSmax.scss';
import classNames from 'classnames/bind';
import CloseIcon from '@mui/icons-material/Close';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useState, useEffect } from 'react';
import { updateCartItem, removeCartItem } from '@/store/cart/cartSlice';
import { updateSmax, removeSmax } from '@/store/smax/smaxSlice';
import { useDispatch } from 'react-redux';
import MInput from '@/components/Input/MInput';
import { useSelector } from 'react-redux';

const cx = classNames.bind(styles);

function ProductItemSmax(props) {
    const product = props.product;
    const sx = props.sx;
    const onChangeChild = props.onChangeChild;

    const colors = product.colors;
    const [size, setSize] = useState(null);
    const [sizes, setSizes] = useState([]);
    const [color, setColor] = useState(null);
    const [quantity, setQuantity] = useState(0);
    const dispatch = useDispatch();
    const abc = useSelector(state => state.smax);
    const handleUpdateItem = (key, value) => {
        switch (key) {
            case 'size':
                setSize(value);
                var color_conv = null;
                colors.map(item => {
                    if (item.id === color) {
                        color_conv = item.name;
                    }
                });
                //
                var size_conv = null;
                sizes.map(item => {
                    if (item.id === value) {
                        size_conv = item.name;
                    }
                });

                dispatch(updateSmax({ ...product, size_id: value, size: size_conv, quantity: quantity, color: color_conv }));
                break;
            case 'color':
                // cap nhat size cua moi mau

                setColor(value);
                var color_conv = null;
                colors.map(item => {
                    if (item.id === value) {
                        color_conv = item.name;
                        setSizes(item.sizes)
                    }
                });
                // 
                var size_conv = null;
                sizes.map(item => {
                    if (item.id === value) {
                        size_conv = item.name;
                    }
                });

                dispatch(updateSmax({ ...product, color: color_conv, size_id: size, size: size_conv, quantity: quantity }));

                break;
            case 'quantity':
                const quantityValue = Number(value) < 0 ? 0 : Number(value);
                if (quantityValue === 0) {
                    setQuantity(1);
                    var color_conv = null;
                    colors.map(item => {
                        if (item.id === color) {
                            color_conv = item.name;
                        }
                    });
                    var size_conv = null;
                    sizes.map(item => {
                        if (item.id === value) {
                            size_conv = item.name;
                        }
                    });

                    dispatch(updateSmax({ ...product, quantity: 1, color: color_conv, size_id: size, size: size_conv }));
                } else {
                    setQuantity(quantityValue);
                    var color_conv = null;
                    colors.map(item => {
                        if (item.id === color) {
                            color_conv = item.name;
                        }
                    });
                    //
                    var size_conv = null;
                    sizes.map(item => {
                        if (item.id === value) {
                            size_conv = item.name;
                        }
                    });
                    dispatch(updateSmax({ ...product, quantity: quantityValue, color: color_conv, size_id: size, size: size_conv }));
                }
                onChangeChild(product.id, key, value, product.sale_price);
                break;
            default:
                break;
        }
    };
    useEffect(() => {

    }, [abc]);
    return (
        <Box className="d-flex" sx={{ ...sx }}>
            <Badge badgeContent={quantity} color="primary">
                <Box className={cx('item-image-smax')} sx={{ backgroundImage: `url('${product.url_image}')` }}></Box>
            </Badge>
            <Box sx={{ ml: 3, width: '50%' }} className="c-flex justify-space-between">
                <Box>
                    <Box className="d-flex justify-space-between align-center">
                        <Box className={cx('cardname-text-smax')} sx={{ fontWeight: 'bold' }}>{product.name}</Box>
                        <Box sx={{ ml: 2 }}>
                            <IconButton onClick={() => dispatch(removeCartItem(product))}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    {/* <Box sx={{ mt: 1 }}>
                        {product.color}/{product.size}
                    </Box> */}
                </Box>
                <Box className="d-flex flex-wrap" sx={{ paddingLeft: '1px !important' }}>
                    <Box className="lichntSmax" sx={{ pb: 1, paddingLeft: '1px' }}>
                        <Box className="d-flex">
                            <Select
                                value={color}
                                onChange={e => handleUpdateItem('color', e.target.value)}
                                size="small"
                                sx={{ width: '50%', borderRadius: '10px' }}
                            >
                                {colors.map(item => (
                                    <MenuItem value={item.id} key={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            <Select
                                value={size}
                                onChange={e => handleUpdateItem('size', e.target.value)}
                                size="small"
                                sx={{ ml: 1, width: '100%', borderRadius: '10px' }}
                            >
                                {sizes.map(item => (
                                    <MenuItem value={item.id} key={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                        <Box className="d-flex" sx={{ mt: 2 }}>
                            <MInput
                                variant="outlined"
                                value={quantity}
                                onChange={e => handleUpdateItem('quantity', e.target.value)}
                                size="small"
                                type="number"
                                min="0"
                            />
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ flex: 1 }} className="c-flex align-end">
                    <Box sx={{ fontWeight: 'bold' }}>{Number(quantity * product.sale_price).toLocaleString('de-DE')} zł</Box>
                    <Box style={{ textDecoration: 'line-through', marginLeft: '6px', color: '#A6ACAF' }}>
                        {Number(quantity * product.price).toLocaleString('de-DE')} zł
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
export default ProductItemSmax;
