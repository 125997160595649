import classNames from 'classnames/bind';
import styles from './IconSpec.scss';
import { Box, Grid, Button as MuiButton } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect } from 'react';
import navbarApi from '@/api/navbarApi';
const cx = classNames.bind(styles);

const style = {
    widthContent: {
        minWidth: '170px',
        width: '22%',
        fontSize: '14px',
        borderRadius: '9px',
        height: '50px',
        color: 'black',
        fontWeight: 'BOLD'
    },
};

const goToLinkCartegory = (link) => {
    window.open("http://dl-zabawek.pl/category" + link);
};

function IconSpec({ height }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();
    const goToLink = link => {
        setAnchorEl(null);
        navigate(link);
    };
    const [pathBannerPC, setPathBannerPC] = useState(null);
    const [pathBannerMobile, setPathBannerMobile] = useState(null);
    const getData = useCallback(async () => {
        const response = await navbarApi.getVoucher();
        setPathBannerPC(response.data[0].path);
        setPathBannerMobile(response.data[1].path);
    },);
    useEffect(() => {
        getData();
    }, []);

    return (
        <Box>
            <Box sx={{ paddingTop: '15px' }} className={cx('spec-card')}>
                <img src={pathBannerPC} style={{ width: '100%', height: 'auto' }} alt="bannerPC" />
            </Box>
            <Box className={cx('mobile-card-spec')}>
                <img src={pathBannerMobile} style={{ width: '100%', height: 'auto' }} alt="bannerMobile" />
            </Box>
        </Box>

    );
}
export default IconSpec;
