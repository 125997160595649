const images = {
    logo: require('@/assets/images/logog.png'), 
    // logo: require('@/assets/images/logo.svg').default, 
    homeImage: require('@/assets/images/home-banner.png'),
    shortLogo: require('@/assets/images/short-logo.png'),
    sizeHelp: require('@/assets/images/size-help.png'),
    sizeHelpInches: require('@/assets/images/size-help-inches.png'),

    freeship: require('@/assets/images/freeship.svg').default,
    freeshiptoanquoc: require('@/assets/images/freeshiptoanquoc.svg').default,
    trahang: require('@/assets/images/trahang.svg').default,
    tuvan: require('@/assets/images/tuvan.svg').default,
    doitra: require('@/assets/images/doitra.svg').default,
    hoantien: require('@/assets/images/hoantien.svg').default,

    kinhlup: require('@/assets/images/kinhlup.png'),
    homeBanner1: require('@/assets/images/home-banner1.jpg'),
    homeBannerMobile: require('@/assets/images/home-banner-mobile.jpg'),
    
    thanhToan: require('@/assets/images/thanh-toan.png'),
    uploadImageIcon: require('@/assets/images/uploadIcon.svg').default,
    noPhoto: require('@/assets/images/no-photo.png'),
    // logoWidth: require('@/assets/images/logo.svg').default,
    logoWidth: require('@/assets/images/logogblack.png'),
    logoBoCongThuong: require('@/assets/images/bocongthuong.png'),
    defaultProduct: require('@/assets/images/shirt.png'),
    
    codIcon: require('@/assets/images/cod-icon.svg').default,
    atmCard: require('@/assets/images/atm-card.svg').default,
    banking: require('@/assets/images/banking.svg').default,

    thankiu: require('@/assets/images/thankiu.png'),
    thongTinThanhToan: require('@/assets/images/thongtinthanhtoan.jpg'),

    noProduct: require('@/assets/images/no-product.svg').default,

    cartIcon: require('@/assets/images/cart-white.png'),
    cartIconBlack: require('@/assets/images/cartIcon.png'),
    doiTra3Buoc: require('@/assets/images/doitra3buoc.png'),
    facebook: require('@/assets/images/facebook.png'),
    instagram: require('@/assets/images/instagram.png'),
    shopee: require('@/assets/images/shopee.png'),

    bePhai: require('@/assets/images/mockup/1be_phai.jpg'),
    beTrai: require('@/assets/images/mockup/1be_trai.jpg'),
    beTruoc: require('@/assets/images/mockup/1be_truoc.jpg'),
    beSau: require('@/assets/images/mockup/1be_sau.jpg'),

    denPhai: require('@/assets/images/mockup/1den_phai.jpg'),
    denTrai: require('@/assets/images/mockup/1den_trai.jpg'),
    denTruoc: require('@/assets/images/mockup/1den_truoc.jpg'),
    denSau: require('@/assets/images/mockup/1den_sau.jpg'),

    trangPhai: require('@/assets/images/mockup/1trang_phai.jpg'),
    trangTrai: require('@/assets/images/mockup/1trang_trai.jpg'),
    trangTruoc: require('@/assets/images/mockup/1trang_truoc.jpg'),
    trangSau: require('@/assets/images/mockup/1trang_sau.jpg'),

    nauPhai: require('@/assets/images/mockup/1nau_phai.jpg'),
    nauTrai: require('@/assets/images/mockup/1nau_trai.jpg'),
    nauTruoc: require('@/assets/images/mockup/1nau_truoc.jpg'),
    nauSau: require('@/assets/images/mockup/1nau_sau.jpg'),

    HOODIE: require('@/assets/images/comming/HOODIE.png'),
    MU: require('@/assets/images/comming/MU.png'),
    QUANDUI: require('@/assets/images/comming/QUANDUI.png'),
    TAT: require('@/assets/images/comming/TAT.png'),
    TUIXACH: require('@/assets/images/comming/TUIXACH.png'),
    SWEATSHIRT: require('@/assets/images/comming/SWEATSHIRT.png'),

    THANHTOANTHANHCONG: require('@/assets/images/thankiu.gif'),
    dashBoard: require('@/assets/images/dashboard.png'),

    iconSetting: require('@/assets/images/icon/setting.png'),
    iconCopy: require('@/assets/images/icon/copy.png'),
    
    iconVoucher: require('@/assets/images/icon/iconVoucher.png'),
    miniVouncher: require('@/assets/images/icon/miniVouncher.png'),
    iconMydesign: require('@/assets/images/icon/iconMydesign.png'),
    iconMydesignG: require('@/assets/images/icon/iconMydesignG.png'),
    iconMydesignG2: require('@/assets/images/icon/iconMydesignG2.png'),
    iconSpec: require('@/assets/images/icon/iconSpec.png'),
    iconSpecMobile: require('@/assets/images/icon/iconSpecMobile.png'),

    iconDown2: require('@/assets/images/icon/downicon.png'),
    iconUp2: require('@/assets/images/icon/upicon.png'),
    iconVoucher2: require('@/assets/images/icon/vounchericon.png'),
    
    iconCato1: require('@/assets/images/icon/iconCato1.png'),
    iconCato2: require('@/assets/images/icon/iconCato2.png'),
    iconCato3: require('@/assets/images/icon/iconCato3.png'),
    iconCato4: require('@/assets/images/icon/iconCato4.png'),

    VouncherCam: require('@/assets/images/icon/VouncherCam.svg').default,
    VouncherHong: require('@/assets/images/icon/VouncherHong.svg').default,
    VouncherRed: require('@/assets/images/icon/VouncherRed.svg').default,
    VouncherTrang: require('@/assets/images/icon/VouncherTranghong.svg').default,
    VouncherVang: require('@/assets/images/icon/VouncherVang.svg').default,
    VouncherXam: require('@/assets/images/icon/VouncherXam.svg').default,

    CanvasBlack: require('@/assets/images/icon/canvasblack.svg').default,
    iconCrown: require('@/assets/images/icon/iconCrown.png'),

    iconPartner: require('@/assets/images/icon/doitacxemthem.svg').default,
    iconKenh: require('@/assets/images/icon/doitac1.svg').default,
    iconStarChanel: require('@/assets/images/icon/doitac2.svg').default,
    iconYeah: require('@/assets/images/icon/doitac3.svg').default,
    iconYan: require('@/assets/images/icon/doitac4.svg').default,
    iconZing: require('@/assets/images/icon/doitac5.svg').default,

    iconPeo1: require('@/assets/images/icon/peo1.png'),
    iconPeo2: require('@/assets/images/icon/peo2.png'),
    iconPeo3: require('@/assets/images/icon/peo3.png'),
    iconPeo4: require('@/assets/images/icon/peo4.png'),

    iconCloseSVG: require('@/assets/images/zalo/iconClose.svg').default,
    iconHolineCallSVG: require('@/assets/images/zalo/iconHolineCall.svg').default,
    iconHotlineSVG: require('@/assets/images/zalo/iconHotline.svg').default,
    iconOnyouSVG: require('@/assets/images/zalo/iconOnyou.svg').default,
    iconZaloSVG: require('@/assets/images/zalo/iconZalo.svg').default,
    iconMessFB: require('@/assets/images/zalo/messenger.png'),
    iconsGmail: require('@/assets/images/zalo/iconsGmail.png'),
    iconsViber: require('@/assets/images/zalo/iconsViber.png'),
    iconsWhatsapp: require('@/assets/images/zalo/iconsWhatsapp.png'),
    iconMessFBSVG: require('@/assets/images/zalo/iconMess.svg').default,

    iconMakeVN: require('@/assets/images/icon/makeVN.png'),
    iconlove1000: require('@/assets/images/icon/love1000.png'),
    icon247Mobile: require('@/assets/images/icon/247mobile.png'),
    icon30Days: require('@/assets/images/icon/30days.png'),

    mobileChinhSach: require('@/assets/images/icon/mobileChinhSach.png'),
    mobileGioiTre: require('@/assets/images/icon/mobileGioiTre.png'),
    mobileKH: require('@/assets/images/icon/mobileKH.png'),
    mobileMadeVn: require('@/assets/images/icon/mobileMadeVn.png'),
    pricing: require('@/assets/images/chinhsach/pricing.png'),

    fblogo: require('@/assets/images/icon/fblogo.svg').default,
    tiktok: require('@/assets/images/icon/tiktok.svg').default,
    instalogo: require('@/assets/images/icon/instalogo.svg').default,
    shopee: require('@/assets/images/icon/shopee.svg').default,

    iconsDown: require('@/assets/images/icon/iconsDown.png'),
    iconDownBlack: require('@/assets/images/icon/iconDownBlack.png'),
    iconDownWhite: require('@/assets/images/icon/iconDownWhite.png'),
    iconUpWhite: require('@/assets/images/icon/iconUpWhite.png'),
    iconLeftWhite: require('@/assets/images/icon/iconLeftWhite.png'),
    iconRightWhite: require('@/assets/images/icon/iconRightWhite.png'),
    iconOne: require('@/assets/images/icon/iconOne.svg').default,
    iconFour: require('@/assets/images/icon/iconFour.svg').default,
    iconThree: require('@/assets/images/icon/iconThree.svg').default,
    iconTwo: require('@/assets/images/icon/iconTwo.svg').default,

    iconStar: require('@/assets/images/icon/iconStar.png'),
    iconStarNew: require('@/assets/images/icon/iconStarNew.png'),

    backIcon: require('@/assets/images/icon/back.png'),
    forwardIcon: require('@/assets/images/icon/unback.png'),
    eyeIcon: require('@/assets/images/icon/eye.png'),
    bagIcon: require('@/assets/images/icon/bag.png'),
    bocucIcon: require('@/assets/images/icon/bocuc.png'),
    guideIcon: require('@/assets/images/icon/guide.png'),

    iconPhone: require('@/assets/images/icon/iconPhone.png'),
    iconMail: require('@/assets/images/icon/iconMail.png'),

    backCarto: require('@/assets/images/background/carto.png'),
    backCarto1: require('@/assets/images/background/backCarto1.png'),
    backCarto1Mobile: require('@/assets/images/background/backCarto1Mobile.png'),

    iconSearch: require('@/assets/images/icon/iconSearch.svg').default,
    iconCart: require('@/assets/images/icon/iconCart.svg').default,
    iconProfile: require('@/assets/images/icon/iconProfile.svg').default,

    myDesignMobile: require('@/assets/images/icon/myDesignMobile.png'),
    myDesignMobileG: require('@/assets/images/icon/myDesignMobileG1.png'),

    backgroundRangCua: require('@/assets/images/background/backgroundRangCua.png'),
    backgroundDut1: require('@/assets/images/background/backgroundDut.png'),
    backgroundLogin: require('@/assets/images/icon/background_login.png'),
    backgroundHome: require('@/assets/images/background/homepage_background.svg').default,
    backgroundHomePng: require('@/assets/images/background/homepage_background.png'),

    backCustome: require('@/assets/images/background/backCustome.png'),
    backCustomeMobile: require('@/assets/images/background/backCustomeMobile.png'),
    
    iconZalo: require('@/assets/images/icon/zalo.png'),

    fileOrder: require('@/assets/images/files/order.pdf'),
    fileChecking: require('@/assets/images/files/checking.pdf'),
    filePayment: require('@/assets/images/files/payment.pdf'),
    fileReturn: require('@/assets/images/files/return.pdf'),
    fileTrans: require('@/assets/images/files/trans.pdf'),
};

export default images;
