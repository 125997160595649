
import { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import styles from '../home.scss';
import productApi from '@/api/productApi';
import GridProductV3 from '@/components/GridProduct/GridProductV3';
import { useNavigate } from 'react-router-dom';

const cx = classNames.bind(styles);

function HomeProduct() {
    const [products, setProducts] = useState([]);
    const [productLoading, setProductLoading] = useState(false);
    const [collectionName, setCollectionName] = useState('');
    const navigate = useNavigate();
    const goToLink = link => {
        navigate(link);
    };
    const getTopData = useCallback(async () => {
        setProductLoading(true);
        const response = await productApi.getProductwithImage({
            perPage: 8,
        });
        setProductLoading(false);
        setProducts(response.data);
        setCollectionName(response.name)
    }, []);

    useEffect(() => {
        getTopData();
    }, [getTopData]);
    return (
        <div>
            <div onClick={() => goToLink('/category/nowe-produkty')} style={{ marginTop: '1em', textDecoration: 'underline', cursor: 'pointer' }} className={cx('title-home-cator')}>
                {/* {collectionName} */}
                NOWE PRODUKTY
            </div>
            <GridProductV3 products={products} loading={productLoading} />
        </div>
    );
}
export default HomeProduct;
