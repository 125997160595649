import MTable from '@/components/Table';
import { Box, InputAdornment, TextField } from '@mui/material';
import { Fragment, useCallback, useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import { ConfirmDialog } from '@/components/ConfirmDialog';
import { toast } from 'react-toastify';
import SearchIcon from '@mui/icons-material/Search';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';
import orderApi from '@/api/orderApi';
import { Avatar } from '@mui/material';
import MailIcon from '@mui/icons-material/Mail';
import PhoneIcon from '@mui/icons-material/Phone';
import { PAYMENTS, ODER_STATUS } from '@/constants/order';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
function OrderDesign() {
    const onClose = () => setDialogData({ ...dialogData, open: false });
    const [param, setParam] = useState({});
    const [tabeleData, setTableData] = useState([]);
    const [totalPage, setTotalPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const getData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await orderApi.getBuyOrder({ param, type: 'design' });
            setLoading(false);
            setTableData(response.data);
            setTotalPage(response.last_page || response?.meta?.last_page);
        } catch (error) {
            setLoading(false);
        }
    }, [param]);
    const [dialogData, setDialogData] = useState({
        open: false,
        title: 'Thêm mới danh mục',
        onClose: onClose,
        onDone: getData,
    });
    const onDelete = item => {
        ConfirmDialog({
            title: 'Xoá đơn hàng',
            html: true,
            body: `Bạn có chắc chắn muốn xóa đơn hàng <b>${item.code}</b> không ?`,
            action: async onClose => {
                await orderApi.deleteOrder(item.id);
                onClose();
                toast.success('Thành công!');
                getData();
            },
        });
    };
    const onChangePage = page => {
        setParam({ ...param, page });
    };

    const handleSearch = value =>
        debounce(() => {
            setParam({ ...param, search: value });
        }, 600)();

    const PaymentMethod = (code, payed) => {
        try {
            const payment = PAYMENTS.find(item => item.id === code);
            return (
                <Box className="d-flex align-center">
                    <Box sx={{ mr: 1 }}>
                        <img src={payment.image} alt="cod" style={{ width: '30px' }} />
                    </Box>
                    <Box>
                        {payed ? (
                            <Box sx={{ fontWeight: 'bold', color: '#1E8449' }}>Đã thanh toán</Box>
                        ) : (
                            <Box sx={{ fontWeight: 'bold', color: '#943126' }}>Chưa thanh toán</Box>
                        )}
                        <Box></Box>
                        <Box sx={{ fontSize: '14px', mb: 1 }}>{payment.title}</Box>
                    </Box>
                </Box>
            );
        } catch (error) {
            return <Box></Box>;
        }
    };
    const OrderStatus = code => {
        try {
            const status = ODER_STATUS[code];
            return <Box sx={{ color: status.color, fontWeight: 'bold' }}>{status.name}</Box>;
        } catch (error) {
            return '';
        }
    };
    useEffect(() => {
        getData();
    }, [getData]);
    return (
        <Box sx={{ padding: '15px' }}>
            <Box sx={{ mb: 2 }} className="d-flex justify-space-between align-center">
                <Box sx={{ fontSize: '24px' }}>Danh sách đơn hàng thiết kế</Box>
            </Box>
            <Box sx={{ mb: 1 }} className="d-flex justify-space-between">
                <TextField
                    size="small"
                    placeholder="Tìm kiếm đơn hàng"
                    variant="outlined"
                    style={{ width: '500px' }}
                    onChange={e => handleSearch(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
            <MTable data={tabeleData} loading={loading} countPage={totalPage} onChangePage={onChangePage}>
                {item => (
                    <Fragment>
                        <div property="code" label="Đơn hàng">
                            <Box>
                                <Box>
                                    Mã đơn: <b>{item.code}</b>
                                </Box>
                                <Box>Tạo lúc: {item.created_at}</Box>
                            </Box>
                        </div>
                        <div property="name" label="Khách hàng">
                            <Box className="d-flex align-cener">
                                <Avatar sx={{ bgcolor: '#5B2C6F', width: 55, height: 55, mt: 1 }} src={item.avatar_url}>
                                    {!item.avatar_url && item.name ? item.name.charAt(0) : null}
                                </Avatar>
                                <Box sx={{ ml: 1 }}>
                                    <Box sx={{ fontSize: 16 }}>{item.name}</Box>
                                    <Box sx={{ color: 'gray' }} className="d-flex align-cener">
                                        <MailIcon sx={{ fontSize: 18, mr: 1 }} />
                                        <div>{item.email}</div>
                                    </Box>
                                    <Box className="d-flex align-cener" sx={{ color: 'gray' }}>
                                        <PhoneIcon sx={{ fontSize: 18, mr: 1 }} />
                                        <div>{item.phone}</div>
                                    </Box>
                                </Box>
                            </Box>
                        </div>
                        <div label="Địa chỉ">
                            <Box>
                                <Box>{item.address}</Box>
                                <Box sx={{ color: 'gray' }}>
                                    <Box>{item.province}</Box>
                                    <Box>{item.district}</Box>
                                    <Box>{item.ward}</Box>
                                </Box>
                            </Box>
                        </div>
                        <div label="Giá trị đơn hàng">
                            <Box>
                                <Box>Tổng tiền: {parseFloat(item.total_money).toLocaleString('de-DE')} zł</Box>
                                <Box>Giảm giá: {parseFloat(item.discount_money).toLocaleString('de-DE')} zł</Box>
                                <Box sx={{ fontWeight: 'bold', mt: 1 }}>
                                    Thanh toán: {parseFloat(item.total_pay).toLocaleString('de-DE')} zł
                                </Box>
                            </Box>
                        </div>
                        <div label="Trạng thái đơn">
                            <Box>{OrderStatus(item.status)}</Box>
                        </div>
                        <div label="Thanh toán">
                            <Box>{PaymentMethod(item.payment, item.payed)}</Box>
                        </div>
                        <div label="Xem chi tiết" sx={{ width: '120px' }}>
                            <IconButton
                                aria-label="edit"
                                color="primary"
                                onClick={() => navigate(`/don-hang/don-thiet-ke/${item.id}`)}
                            >
                                <RemoveRedEyeIcon />
                            </IconButton>
                            {/* <IconButton aria-label="delete" color="secondary" onClick={() => onDelete(item)}>
                                <DeleteIcon />
                            </IconButton> */}
                        </div>
                    </Fragment>
                )}
            </MTable>
        </Box>
    );
}
export default OrderDesign;
