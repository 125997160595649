import orderApi from '@/api/orderApi';
import { Box, Button, Divider } from '@mui/material';
import { useEffect, useState, Fragment, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { PAYMENTS, ODER_STATUS } from '@/constants/order';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { ConfirmDialog } from '@/components/ConfirmDialog';
import styles from './order-design.scss';
import classNames from 'classnames/bind';
import { toast } from 'react-toastify';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import DesignView from '@/components/DesignView';
import { useSelector, useDispatch } from 'react-redux';
import { changePosition, changeProduct, changeColor } from '@/store/design/targetDesignSlice';
import { BORDER_SELECTED, BORDER_NO_SELECT } from '@/store/design/constant';

const cx = classNames.bind(styles);

function DetailOrderDesign() {
    const dispatch = useDispatch();
    const [order, setOrder] = useState({});
    const { id: orderId } = useParams();
    const [paymentMethod, setPaymentMethod] = useState({});
    const [dataDesign, setDataDesign] = useState([]);
    const [images, setImages] = useState([]);
    const productList = useSelector(state => state.targetDesign.productList);
    const productSelected = useSelector(state => state.targetDesign.productSelected);
    const [productInfo, setProductInfo] = useState({});
    const [loadingView, setLoadingView] = useState(true);
    const getOrder = useCallback(async () => {
        setLoadingView(true);
        const response = await orderApi.getDetailOrder(orderId);
        setOrder(response.data ? response.data : {});
        setDataDesign(response.data.designs[0]?.design_data);
        const productStore = response.data.designs[0]?.product;
        if (productSelected.productId !== productStore.productId) {
            dispatch(changeProduct(productStore.productId));
        }
        if (productSelected.colorId !== productStore.colorId) {
            dispatch(changeColor(productStore.colorId));
        }
        setProductInfo(productStore);

        const product = productList.find(el => el.id === productStore.productId);

        const payment = PAYMENTS.find(item => item.id === response.data.payment);
        setPaymentMethod(payment);
        const color = product.colors.find(el => el.id === productStore.colorId);
        setImages(color.images);
        setLoadingView(false);
    }, [orderId, productList, dispatch]);

    useEffect(() => {
        getOrder();
    }, [getOrder]);
    const OrderStatus = code => {
        try {
            const status = ODER_STATUS[code];
            return <Box sx={{ color: status.color, fontWeight: 'bold' }}>{status.name}</Box>;
        } catch (error) {
            return '';
        }
    };

    const handleOrder = () => {
        const cancel = (
            <Button
                onClick={() => onClickActionButton('cancel')}
                color="error"
                startIcon={<CloseIcon />}
                variant="contained"
            >
                Huỷ đơn
            </Button>
        );
        const approve = (
            <Button
                onClick={() => onClickActionButton('approve')}
                color="primary"
                startIcon={<DoneIcon />}
                variant="contained"
            >
                Xác nhận đơn
            </Button>
        );
        const shiping = (
            <Button
                onClick={() => onClickActionButton('shiping')}
                color="primary"
                startIcon={<LocalShippingIcon />}
                variant="contained"
            >
                Bàn giao vận chuyển
            </Button>
        );
        const done = (
            <Button
                onClick={() => onClickActionButton('done')}
                color="success"
                startIcon={<DoneAllIcon />}
                variant="contained"
            >
                Hoàn thành đơn
            </Button>
        );
        const fail = (
            <Button
                onClick={() => onClickActionButton('fail')}
                color="error"
                startIcon={<WarningAmberIcon />}
                variant="contained"
            >
                Khách không nhận hàng
            </Button>
        );
        if (order.status === 'SHOP_CANCEL') {
            return <Box>{approve}</Box>;
        }
        if (order.status === 'PENDING') {
            return (
                <Box>
                    {cancel} {approve}
                </Box>
            );
        }
        if (order.status === 'APPROVED') {
            return (
                <Box>
                    {cancel} {shiping}
                </Box>
            );
        }
        if (order.status === 'SHIPPING') {
            return (
                <Box>
                    {done} {fail}
                </Box>
            );
        }
        return '';
    };
    const onClickActionButton = actionName => {
        let title = '';
        let body = '';
        let action = null;
        if (actionName === 'cancel') {
            title = 'Xác nhận huỷ đơn';
            action = orderApi.cancelOrder;
            body = 'Bạn có chắc chắn muốn huỷ đơn hàng này không ? Hành động này không thể hoàn tác';
        } else if (actionName === 'approve') {
            title = 'Xác nhận đơn hàng';
            action = orderApi.approveOrder;
            body = 'Bạn có chắc chắn muốn xác nhận đơn hàng này không ?';
        } else if (actionName === 'shiping') {
            action = orderApi.shipOrder;
            title = 'Xác nhận bàn giao vận chuyển';
            body = 'Đơn hàng của bạn đã bàn giao cho đơn vị vận chuyển ?';
        } else if (actionName === 'done') {
            action = orderApi.finishOrder;
            title = 'Hoàn thành đơn';
            body = 'Đơn hàng của bạn giao đến khách hàng?';
        } else if (actionName === 'fail') {
            action = orderApi.failOrder;
            title = 'Đơn hàng không hoàn thành';
            body = 'Đơn hàng của bạn không hoàn thành do khách không nhận hàng?';
        } else return;
        ConfirmDialog({
            title,
            html: true,
            body,
            action: async onClose => {
                await action(orderId);
                onClose();
                toast.success('Thành công!');
                getOrder();
            },
        });
    };

    return (
        <Box sx={{ padding: '20px' }}>
            <Box sx={{ mb: 4 }} className="d-flex justify-space-between align-center">
                <Box sx={{ fontSize: '24px' }}>Thông tin đơn hàng</Box>
            </Box>
            <Box className="d-flex justify-space-between" sx={{ fontSize: '20px', mt: 2 }}>
                <Box>
                    Mã đơn hàng: <b>#{order.code}</b>
                </Box>
                <Box>
                    Thời gian: <b>{order.created_at}</b>
                </Box>
            </Box>
            <Box className="d-flex justify-space-between" sx={{ fontSize: '20px', mt: 2 }}>
                <Box className="d-flex">
                    <Box sx={{ mr: 1 }}>Trạng thái đơn hàng:</Box> {OrderStatus(order.status)}
                </Box>
                <Box>{handleOrder()}</Box>
            </Box>
            <Box sx={{ mt: 3 }} className={cx('box-content-order')}>
                <Box className="d-flex justify-space-between">
                    <Box sx={{ fontSize: '20px', mb: 1, fontWeight: 'bold' }}>Sản phẩm đặt mua</Box>
                    <Box sx={{ fontWeight: 'bold' }}>
                        {order.payed ? (
                            <Box sx={{ color: '#196F3D' }}>ĐÃ THANH TOÁN</Box>
                        ) : (
                            <Box sx={{ color: '#DC192E' }}>CHƯA THANH TOÁN</Box>
                        )}
                    </Box>
                </Box>
                <Box className="d-flex">
                    <Box sx={{ flex: 1 }}>
                        {!loadingView ? (
                            <Fragment>
                                <DesignView dataDesign={dataDesign} productSelected={productInfo} />
                                <Box sx={{ ml: 3 }}>
                                    <Box sx={{ fontWeight: 'bold', mb: 1, fontSize: '18px' }}>Vị trí</Box>
                                    <Box className="d-flex">
                                        {images.map((item, index) => (
                                            <div
                                                key={index}
                                                onClick={() => dispatch(changePosition(item.id))}
                                                style={{
                                                    border:
                                                        item.id === productSelected.imageId
                                                            ? BORDER_SELECTED
                                                            : BORDER_NO_SELECT,
                                                    marginRight: '10px',
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <img src={item.link} alt="Vi tri" style={{ height: '70px' }} />
                                            </div>
                                        ))}
                                    </Box>
                                </Box>
                            </Fragment>
                        ) : null}
                    </Box>
                    <Box sx={{ width: '500px' }}>
                        <Box sx={{ fontSize: '20px', fontWeight: 'bold', mb: 1 }}>Hóa đơn</Box>
                        <Divider />
                        <Box className="d-flex justify-space-between" sx={{ fontSize: '20px', mt: 2 }}>
                            <Box>Tổng tiền:</Box>
                            <Box sx={{ fontWeight: 'bold' }}>
                                {parseFloat(order.total_money).toLocaleString('de-DE')} zł
                            </Box>
                        </Box>
                        <Box className="d-flex justify-space-between" sx={{ fontSize: '20px', mt: 1 }}>
                            <Box>Giảm giá:</Box>
                            <Box sx={{ fontWeight: 'bold' }}>
                                {parseFloat(order.discount_money).toLocaleString('de-DE')} zł
                            </Box>
                        </Box>
                        <Box className="d-flex justify-space-between" sx={{ fontSize: '20px', mb: 1, mt: 1 }}>
                            <Box>Tổng thanh toán:</Box>
                            <Box sx={{ fontWeight: 'bold', fontSize: '18px', color: '#6C3483' }}>
                                {parseFloat(order.total_pay).toLocaleString('de-DE')} zł
                            </Box>
                        </Box>
                        <Divider />

                        <Box sx={{ mt: 2, fontWeight: 'bold', fontStyle: 'italic' }}>Chi tiết</Box>
                        <Box className="d-flex justify-space-between" sx={{ fontSize: '20px', mt: 1, mb: 1 }}>
                            <Box>Phí vận chuyển:</Box>
                            <Box sx={{ fontWeight: 'bold' }}>{parseFloat(order.fee_ship).toLocaleString('de-DE')} zł</Box>
                        </Box>
                        {order.designs
                            ? order.designs.map(item => (
                                <Box key={item.id}>
                                    <Box className="d-flex justify-space-between" sx={{ fontSize: '20px' }}>
                                        <Box>Giá sản phẩm:</Box>
                                        <Box sx={{ fontWeight: 'bold' }}>
                                            {parseFloat(350000).toLocaleString('de-DE')} zł
                                        </Box>
                                    </Box>
                                    <Box
                                        className="d-flex justify-space-between"
                                        sx={{ fontSize: '20px', mt: 1, mb: 1 }}
                                    >
                                        <Box>Số lượng:</Box>
                                        <Box sx={{ fontWeight: 'bold' }}>{item.quantity} (sản phẩm)</Box>
                                    </Box>
                                    <Box
                                        className="d-flex justify-space-between"
                                        sx={{ fontSize: '20px', mt: 1, mb: 1 }}
                                    >
                                        <Box>Size:</Box>
                                        <Box sx={{ fontWeight: 'bold' }}>{item.size}</Box>
                                    </Box>
                                    <Box sx={{ mt: 1, mb: 1, fontWeight: 'bold' }}>Phụ kiện</Box>

                                    <Box>
                                        {item.icons.map(el => {
                                            const price = el.sale_price ? el.sale_price : el.price;
                                            return (
                                                <Box sx={{ mt: 1, mb: 2 }} className="d-flex justify-space-between">
                                                    <Box className="d-flex">
                                                        <img
                                                            src={el.image_url}
                                                            style={{ width: '60px', height: 'auto' }}
                                                            alt="icon"
                                                        />
                                                        <Box sx={{ ml: 2 }}>
                                                            <Box sx={{ fontSize: '20px' }}>{el.name}</Box>
                                                            <Box sx={{ mt: 1, color: '#979A9A' }}>
                                                                {parseFloat(price).toLocaleString('de-DE')} zł x
                                                                {el.quantityBuy}
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            color: '#6C3483',
                                                            fontWeight: 'bold',
                                                            pr: 1,
                                                        }}
                                                    >
                                                        {parseFloat(el.quantityBuy * price).toLocaleString('de-DE')} zł
                                                    </Box>
                                                </Box>
                                            );
                                        })}
                                    </Box>
                                </Box>
                            ))
                            : null}
                    </Box>
                </Box>
            </Box>
            <Box sx={{ fontSize: '20px', mb: 1, fontWeight: 'bold', mt: 4 }}>Thông tin nhận hàng</Box>
            <Box sx={{ fontSize: '18px' }} className={cx('box-content-order')}>
                <Box sx={{ mb: 1 }}>Tên người nhận: {order.name}</Box>
                <Box sx={{ mb: 1 }}>Email: {order.email}</Box>
                <Box sx={{ mb: 1 }}>Số điện thoại: {order.phone}</Box>
                <Box sx={{ mb: 1 }}>
                    Hình thức thanh toán: {paymentMethod.title} - {paymentMethod.body}
                </Box>
                <Box>Địa chỉ nhận hàng: {order.address}</Box>
                <Box>
                    {order?.province}, {order?.district}, {order?.ward}
                </Box>
            </Box>
        </Box>
    );
}
export default DetailOrderDesign;
