import { Box } from '@mui/material';
import images from '@/assets/images';
import classNames from 'classnames/bind';
import styles from './IconVoucherBar.scss';
import Slider from 'react-slick';

const cx = classNames.bind(styles);

const style = {
    widthContent: {
        minWidth: '170px',
        width: '100%',
        height:'140px',
        fontWeight:'BOLD',
        paddingLeft: '10px'
    },
};
const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1.5,
    slidesToScroll: 1
};
function IconVoucherBar({ height }) {
    return (
        <Box>
            <Box sx={{ paddingTop: '40px', paddingLeft: '150px', paddingRight: '150px'}} className={cx('pc-card-vouncher')}>
                <Box className="d-flex">
                    <div className={cx('d-flex width-container wrap-cator')}>
                        <img src={images.iconVoucher} style={{width: "100%", maxWidth: '550px'}} alt='product' />
                    </div>
                    <div className={cx('d-flex width-container wrap-cator')}>
                        <img src={images.iconVoucher} style={{width: "100%", maxWidth: '550px'}} alt='product' />
                    </div>
                    <div className={cx('d-flex width-container wrap-cator')}>
                        <img src={images.iconVoucher} style={{width: "100%", maxWidth: '550px'}} alt='product' />
                    </div>
                    <div className={cx('d-flex width-container wrap-cator')}>
                        <img src={images.iconVoucher} style={{width: "100%", maxWidth: '550px'}} alt='product' />
                    </div>
                    <div className={cx('d-flex width-container wrap-cator')}>
                        <img src={images.iconVoucher} style={{width: "100%", maxWidth: '550px'}} alt='product' />
                    </div>
                </Box>
            </Box>
            <Box className={cx('mobile-card-voucher')}>
                <div sx={{paddingTop: '10px !important', paddingLeft:'10px', paddingRight: '10px' }}>
                    <Slider {...settings}>
                    <div>
                        <img src={images.iconVoucher} style={{width: "80%"}} alt='product' />
                    </div>
                    <div>
                        <img src={images.iconVoucher} style={{width: "80%"}} alt='product' />
                    </div>
                    <div>
                        <img src={images.iconVoucher} style={{width: "80%"}} alt='product' />
                    </div>
                    <div>
                        <img src={images.iconVoucher} style={{width: "80%"}} alt='product' />
                    </div>
                    </Slider>
                </div>
            </Box>
        </Box>
        
    );
}
export default IconVoucherBar;
