import { useParams } from 'react-router-dom';
import images from '@/assets/images';
import { Box, Divider } from '@mui/material';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import styles from './view-order.scss';
import { useEffect, useState, Fragment } from 'react';
import orderApi from '@/api/orderApi';
import MTable from '@/components/Table';
import { PAYMENTS, ODER_STATUS } from '@/constants/order';
import { Button as MuiButton } from '@mui/material';
import Popup from 'reactjs-popup';
import React from "react";
import { toast } from 'react-toastify';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const cx = classNames.bind(styles);


function ViewOrder() {
    const { code: orderCode } = useParams();
    const navigate = useNavigate();
    const [order, setOrder] = useState({});
    const [urlQrcode, setUrlQrcode] = useState({});
    const [products, setProducts] = useState([]);
    const [paymentMethod, setPaymentMethod] = useState({});
    const [loading, setLoading] = useState(false);

    const goToLink = link => {
        navigate(link);
    };

    useEffect(() => {
        const getOrder = async ({ loadTable }) => {
            try {
                setLoading(loadTable);
                const data = await orderApi.viewOrder(orderCode);
                const payment = PAYMENTS.find(item => item.id === data.payment);
                setPaymentMethod(payment);
                setOrder(data);
                let temp_url = 'https://img.vietqr.io/image/MB-5853686868-compact2.jpg?amount=' + data.total_pay + '&addInfo=' + 'OnYou%20' + data.code + '&accountName=THOI%20TRANG%20SANG%20TAO%20ONYOU';
                setUrlQrcode(temp_url);
                setProducts(data.products);
                setLoading(false);
            } catch (error) {
                navigate('/');
            }
        };
        const realTime = setInterval(() => {
            getOrder({ loadTable: false });
        }, 3000);
        getOrder({ loadTable: true });
        return () => clearInterval(realTime);
    }, [orderCode, navigate]);

    const OrderStatus = code => {
        try {
            const status = ODER_STATUS[code];
            return <Box sx={{ color: status.color, fontWeight: 'bold' }}>{status.name}</Box>;
        } catch (error) {
            return '';
        }
    };
    const onClickSTK = event => {
        navigator.clipboard.writeText('5853686868');
        toast.success('Đã copy STK');
    };
    const onClickCK = event => {
        navigator.clipboard.writeText('ONYOU_' + order.code);
        toast.success('Đã copy nội dung CK');
    };

    return (
        <div className="d-flex justify-center bg-gray" style={{ paddingBottom: '30px' }}>
            <Box className={cx('main-container')}>
                <Box
                    className="width-container c-flex align-center"
                    sx={{
                        bgcolor: 'white',
                        paddingTop: '0px',
                        border: '1px solid #A6ACAF',
                        boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
                    }}
                >
                    <Box className={cx('pc-card')}>
                        <Divider sx={{ mt: 2, mb: 2 }} />
                        <Box className="d-flex justify-space-between" sx={{ fontSize: '17px' }}>
                            <Box>
                                <img src={images.thankiu} style={{ height: '250px' }} alt="thank" />
                            </Box>
                            <Box>
                                <Box sx={{ mt: 2 }} className={cx('title-content')}>
                                    ZAMÓW SUKCES
                                </Box>
                                <Box sx={{ mt: 2 }} className="c-flex align-center">
                                    <Box className={cx('text-box')}>
                                        Dziękujemy za zaufanie i wybranie produktów firmy <b style={{ fontWeight: "800", fontSize: "16px" }}>DAWID-LEE!</b>! Zawsze staramy się rozwijać każdego dnia, aby zapewnić klientom więcej wspaniałych doświadczeń.
                                    </Box>
                                    <Box className={cx('text-box')} sx={{ mt: 3 }}>
                                        Twoje zamówienie zostało UDOSTĘPNIONE i przesłane do systemu <b style={{ fontWeight: "800", fontSize: "16px" }}>DAWID-LEE</b>. Jeśli podczas realizacji zamówienia pojawi się problem, OnYou wkrótce się z Tobą skontaktuje. Dodatkowo informacje o wysyłce zostaną przesłane do Ciebie e-mailem i wiadomością!
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={cx('mobile-card')}>
                        <img src={images.thankiu} style={{ height: '200px' }} alt="thank" />
                    </Box>
                    <Box sx={{ mt: 2 }} className={cx('mobile-title-content')}>
                        ZAMÓW SUKCES
                    </Box>
                    <Box sx={{ mt: 2 }} className="c-flex align-center">
                        <Box className={cx('mobile-text-box')}>
                            Dziękujemy za zaufanie i wybranie produktów firmy DAWID-LEE!
                            Zawsze staramy się rozwijać każdego dnia, aby zapewnić klientom więcej wspaniałych doświadczeń.
                        </Box>
                        <Box className={cx('mobile-text-box')} sx={{ mt: 3 }}>
                            Twoje zamówienie zostało UDOSTĘPNIONE i przesłane do systemu DAWID-LEE. Jeśli podczas realizacji zamówienia pojawi się problem, OnYou wkrótce się z Tobą skontaktuje. Dodatkowo informacje o wysyłce zostaną przesłane do Ciebie e-mailem i wiadomością!
                        </Box>
                    </Box>
                    <br></br>
                    <br></br>
                    <Box>
                        <MuiButton onClick={() => goToLink('/category/polecamy')} style={{ borderRadius: '5px', fontFamily: 'MyFont', fontSize: '12px', width: '100%', height: '55px', backgroundColor: '#FFEE58', color: 'black', fontWeight: 'bold' }} sx={{ mb: 2 }} disableElevation variant="contained">
                            Odkryj więcej produktów tutaj
                        </MuiButton>
                    </Box>
                    {!order.payed && order.payment === 'banking' ? (
                        <Box>
                            <div style={{ fontSize: '24px', textAlign: 'center', justifyContent: 'center', fontWeight: '500', paddingTop: '20px' }}>
                                THÔNG TIN THANH TOÁN
                            </div>
                            <div className={cx('qr-pc')} style={{ width: '60vw', height: 'auto', textAlign: 'center', justifyContent: 'center' }}>
                                <img
                                    src={urlQrcode}
                                    style={{ width: '40%' }}
                                />
                            </div>
                            <div className={cx('qr-mobile')} style={{ width: '90vw', height: 'auto', textAlign: 'center', justifyContent: 'center' }}>
                                <img
                                    src={urlQrcode}
                                    style={{ width: '100%' }}
                                />
                            </div>

                        </Box>
                    ) : null}
                    {!order.payed && order.payment === 'banking' ? (
                        <Box className={cx('box-contenttop')} sx={{ mt: 4, fontSize: '22px' }}>
                            <Box sx={{ mb: 2 }}>
                                Thanh toán đơn hàng: <b>OnYou_{order.code}</b>
                            </Box>
                            <Box sx={{ mb: 2 }}>
                                <b className={cx('money-content')}>{parseFloat(order.total_pay).toLocaleString('de-DE')} VND</b>
                                <br></br>
                                <b className={cx('line-content')}>-----------------------------------</b>
                            </Box>
                            <Box className="d-flex justify-space-between" sx={{ mb: 2 }}>
                                <table>
                                    <tr>
                                        <td>Giá trị đơn hàng:</td>
                                        <td>{parseFloat(order.total_pay).toLocaleString('de-DE')} VND</td>
                                    </tr>
                                    <tr>
                                        <td>Phí giao dịch: </td>
                                        <td>0 VND</td>
                                    </tr>
                                    <tr>
                                        <td>STK: </td>
                                        <td onClick={onClickSTK} className={cx('stk-content')}>5853686868 <img src={images.iconCopy} alt="copy" style={{ height: '20px', cursor: 'pointer' }} /> </td>
                                    </tr>
                                    <tr>
                                        <td>Ngân hàng:</td>
                                        <td>MB BANK</td>
                                    </tr>
                                    <tr>
                                        <td>Nhà cung cấp: </td>
                                        <td>CONG TY TNHH THOI TRANG SANG TAO ONYOU</td>
                                    </tr>
                                </table>
                            </Box>
                            <Box onClick={onClickCK} className={cx('content-copy')} sx={{ mb: 1, cursor: 'pointer' }}>
                                Nội dung bắt buộc: <b>OnYou_{order.code}</b> <img src={images.iconCopy} alt="copy" style={{ height: '20px' }} />
                            </Box>
                        </Box>
                    ) : null}

                    <Box sx={{ mt: 4 }} className={cx('title-content')}>
                        Linia informacyjna
                    </Box>
                    <Box className={cx('box-content')}>
                        <Box sx={{ fontWeight: 'bold' }} className="d-flex justify-space-between">
                            {order.payment !== 'cod' ? (
                                order.payed ? (
                                    <Box sx={{ color: '#196F3D' }}>ĐÃ THANH TOÁN
                                        <Popup
                                            open
                                            modal
                                            nested
                                        >
                                            {open => (
                                                <div className={cx('modal')}>
                                                    <div className={cx('header')}> Bạn đã thanh toán thành công! </div>
                                                    <div className={cx('content')}>
                                                        <img src={images.THANHTOANTHANHCONG} style={{ height: '400px' }} alt="thank" />
                                                    </div>
                                                </div>
                                            )}
                                        </Popup>
                                    </Box>
                                ) : (
                                    <Box sx={{ color: '#DC192E' }}>CHƯA THANH TOÁN</Box>
                                )
                            ) : null}

                            {order.payment !== 'cod' ? <Box>{OrderStatus(order.status)}</Box> : null}
                        </Box>
                        <Box className="d-flex justify-space-between" sx={{ mt: 2, mb: 1 }}>
                            <Box>
                                ZAMÓWIENIE: <span style={{ fontWeight: 'bold' }}>#{order.code}</span>
                            </Box>
                            <Box>{order.created_at}</Box>
                        </Box>
                        <Box>
                            <div className={cx('view-pc-order')}>
                                <MTable data={products} loading={loading} showPaginate={false} countPage={1} onChangePage={() => { }}>
                                    {item => (
                                        <Fragment>
                                            <div property="product_name" label="Nazwa produktu"></div>
                                            <div label="Ilość" property="quantity"></div>
                                            <div label="cena" property="price">
                                                {parseFloat(item.price).toLocaleString('de-DE')} zł
                                            </div>
                                            <div label="całkowita kwota">
                                                {parseFloat(item.price * item.quantity).toLocaleString('de-DE')} zł
                                            </div>
                                        </Fragment>
                                    )}
                                </MTable>
                            </div>
                            <div className={cx('view-mobile-order')}>
                                <Table sx={{ maxWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Nazwa produktu</TableCell>
                                            <TableCell align="left">Ilość</TableCell>
                                            <TableCell align="left">Całkowita płatność</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {products.map((row) => (
                                            <TableRow
                                                key={row.name}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {row.product_name}
                                                </TableCell>
                                                <TableCell align="left">{row.quantity}</TableCell>
                                                <TableCell align="left">{parseFloat(row.price * row.quantity).toLocaleString('de-DE')} zł</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </div>

                            <Box className="d-flex justify-space-between" sx={{ mt: 2, fontSize: '17px' }}>
                                <Box>Kod rabatowy</Box>
                                <Box></Box>
                            </Box>
                            <Divider sx={{ mt: 2, mb: 2 }} />
                            <Box className="d-flex justify-space-between" sx={{ fontSize: '17px' }}>
                                <Box>Całkowita wartość produktu (netto)</Box>
                                <Box>{parseFloat(order.total_money / 1.23).toLocaleString('de-DE')} zł</Box>
                            </Box>
                            <Box className="d-flex justify-space-between" sx={{ fontSize: '17px' }}>
                                <Box>Całkowita wartość produktu (brutto)</Box>
                                <Box>{parseFloat(order.total_money).toLocaleString('de-DE')} zł</Box>
                            </Box>
                            <Divider sx={{ mt: 2, mb: 2 }} />

                            <Box className="d-flex justify-space-between" sx={{ fontSize: '17px' }}>
                                <Box>Totalna promocja</Box>
                                <Box>{parseFloat(order.discount_money).toLocaleString('de-DE')} zł</Box>
                            </Box>
                            <Divider sx={{ mt: 2, mb: 2 }} />
                            <Box className="d-flex justify-space-between" sx={{ fontSize: '17px' }}>
                                <Box>Koszty dostawy</Box>
                                <Box>{parseFloat(order.fee_ship).toLocaleString('de-DE')} zł</Box>
                            </Box>
                            <Divider sx={{ mt: 2, mb: 2 }} />
                            <Box className="d-flex justify-space-between" sx={{ fontSize: '17px', fontWeight: 'bold' }}>
                                <Box>Całkowita płatność</Box>
                                <Box>{parseFloat(order.total_pay).toLocaleString('de-DE')} zł</Box>
                            </Box>

                        </Box>
                    </Box>
                    <Box sx={{ mt: 4 }} className={cx('title-content')}>
                        Informacje o dostawie
                    </Box>
                    <Box className={cx('box-content')} sx={{ fontSize: '18px' }}>
                        <Box sx={{ mb: 2 }}>Nazwisko odbiorcy: {order.name}</Box>
                        <Box sx={{ mb: 2 }}>Email: {order.email}</Box>
                        <Box sx={{ mb: 2 }}>Numer telefonu: {order.phone}</Box>
                        <Box sx={{ mb: 2 }}>
                            Płatności: {paymentMethod.title} - {paymentMethod.body}
                        </Box>
                        <Box>Adres: {order.address}</Box>
                        <Box>
                            {order?.province?.name} {order?.district?.name} {order?.ward?.name}
                        </Box>
                    </Box>
                    <Button variant="contained" sx={{ mt: 2, mb: 4 }} onClick={() => navigate('/')}>
                        Strona główna
                    </Button>
                </Box>
            </Box>
        </div>
    );
}
export default ViewOrder;
