import MTable from '@/components/Table';
import { Box, InputAdornment, TextField } from '@mui/material';
import { Fragment, useCallback, useEffect, useState } from 'react';
import productApi from '@/api/productApi';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import HideIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import { ConfirmDialog } from '@/components/ConfirmDialog';
import { toast } from 'react-toastify';
import SearchIcon from '@mui/icons-material/Search';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';
import styles from './product.scss';
import classNames from 'classnames/bind';
import images from '@/assets/images';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import LoadingButton from '@mui/lab/LoadingButton';
import BeenhereIcon from '@mui/icons-material/Beenhere';

const cx = classNames.bind(styles);

function ProductManagement() {
    const onClose = () => setDialogData({ ...dialogData, open: false });
    const [param, setParam] = useState({});
    const [tabeleData, setTableData] = useState([]);
    const [updateData, setUpdateData] = useState([]);
    const [updateNameData, setUpdateNameData] = useState([]);
    const [totalPage, setTotalPage] = useState(0);
    const [oldPage, setOldPage] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);
    const [loading, setLoading] = useState(false);
    const [loadingExport, setLoadingExport] = useState(false);
    const [formData, setFormData] = useState({});
    const [errorSubmit, setErrorSubmit] = useState({});

    const navigate = useNavigate();

    const getData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await productApi.getProduct(param);
            setLoading(false);
            setTableData(response.data);
            setTotalPage(response.last_page || response?.meta?.last_page);
        } catch (error) {
            setLoading(false);
        }
    }, [param]);

    const handleSubmitUpdateName = (idValue) => {
        const data = updateNameData.filter(it => it.id === idValue);
        try {
            productApi.updateTenVaKho(data[0], idValue);
            toast.success('Thành công!');
        } catch (error) {
            const errors = error.response.data.errors;
            for (const key in errors) {
                errors[key] = errors[key].join('. ');
            }
            setErrorSubmit(errors);
        }
    };

    const handleSubmitUpdateKho = (idValue) => {
        const data = updateData.filter(it => it.id === idValue);
        try {
            productApi.updateTenVaKho(data[0], idValue);
            toast.success('Thành công!');
        } catch (error) {
            const errors = error.response.data.errors;
            for (const key in errors) {
                errors[key] = errors[key].join('. ');
            }
            setErrorSubmit(errors);
        }
    };

    const handleSelectUpdateProcduct = (data, idValue) => {
        var array_value = updateData.map(item => {
            if (item.id === idValue) {
                return data
            }
            return item;
        });
        array_value.push(data)
        setUpdateData(array_value);
    };
    const handleSelectUpdateNameProcduct = (data, idValue) => {
        var array_value = updateNameData.map(item => {
            if (item.id === idValue) {
                return data
            }
            return item;
        });
        array_value.push(data)
        setUpdateNameData(array_value);
    };

    const getDataFirst = useCallback(async () => {
        const queryString = window.location.search;
        setLoading(true);
        try {
            if (queryString) {
                const urlParams = new URLSearchParams(queryString);
                const response = await productApi.getProduct({ page: urlParams.get('perPage') });
                setLoading(false);
                setTableData(response.data);
                setTotalPage(response.last_page || response?.meta?.last_page);
            } else {
                const response = await productApi.getProduct({ page: pageNumber });
                setLoading(false);
                setTableData(response.data);
                setTotalPage(response.last_page || response?.meta?.last_page);
            }

        } catch (error) {
            setLoading(false);
        }
    }, []);

    const [dialogData, setDialogData] = useState({
        open: false,
        title: 'Thêm mới danh mục',
        onClose: onClose,
        onDone: getData,
    });
    const onDelete = item => {
        ConfirmDialog({
            title: 'Xoá sản phẩm',
            html: true,
            body: `Bạn có chắc chắn muốn xóa sản phẩm <b>${item.name}</b> không ?`,
            action: async onClose => {
                await productApi.deleteProduct(item.id);
                onClose();
                toast.success('Thành công!');
                getData();
            },
        });
    };
    const hideProduct = item => {
        ConfirmDialog({
            title: 'Ẩn sản phẩm',
            html: true,
            body: `Bạn có chắc chắn muốn ẩn sản phẩm <b>${item.name}</b> không ?`,
            action: async onClose => {
                await productApi.hideProduct(item.id);
                onClose();
                toast.success('Ẩn thành công sản phẩm!');
                getData();
            },
        });
    };
    const showProduct = item => {
        ConfirmDialog({
            title: 'Hiển thị sản phẩm',
            html: true,
            body: `Bạn có chắc chắn muốn hiển thị sản phẩm <b>${item.name}</b> không ?`,
            action: async onClose => {
                await productApi.showProduct(item.id);
                onClose();
                toast.success('Hiển thị thành công!');
                getData();
            },
        });
    };
    const onChangePage = page => {
        setOldPage(page)
        setParam({ ...param, page });
    };

    const handleSearch = value =>
        debounce(() => {
            setParam({ ...param, search: value });
        }, 600)();
    const handleExport = async () => {
        try {
            setLoadingExport(true);
            const response = await productApi.exportProduct();
            const filename = 'san-pham.xlsx';

            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(response);
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setLoadingExport(false);
        } catch (err) {
            setLoadingExport(false);
        }
    };
    useEffect(() => {
        getData();
    }, [param]);
    useEffect(() => {
        getDataFirst();
    }, [getDataFirst]);


    return (
        <Box sx={{ padding: '15px' }}>
            <Box sx={{ mb: 2 }} className="d-flex justify-start align-center">
                <Box sx={{ fontSize: '24px' }}>Danh sách sản phẩm</Box>
                <Box className={cx('product-list-bnt-plus')}>
                    <Button
                        onClick={() => navigate('them-san-pham')}
                        variant="contained"
                        disableElevation
                        startIcon={<AddIcon />}
                    >
                    </Button>
                </Box>
            </Box>
            <Box sx={{ mb: 1 }} className="d-flex justify-space-between">
                <TextField
                    size="small"
                    placeholder="Wyszukaj produkt"
                    variant="outlined"
                    className={cx('product-list-search')}
                    onChange={e => handleSearch(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                <Box className={cx('product-list-bnt-pc')}>
                    <LoadingButton
                        onClick={handleExport}
                        loading={loadingExport}
                        loadingPosition="start"
                        variant="contained"
                        disableElevation
                        startIcon={<FileDownloadIcon />}
                        sx={{ mr: 3 }}
                    >
                        Xuất dữ liệu
                    </LoadingButton>
                    <Button
                        onClick={() => navigate('them-san-pham')}
                        variant="contained"
                        disableElevation
                        startIcon={<AddIcon />}
                    >
                        Thêm mới
                    </Button>
                </Box>
            </Box>
            <Box className={cx('product-list-content-pc')}>
                <MTable data={tabeleData} loading={loading} countPage={totalPage} onChangePage={onChangePage}>
                    {item => (
                        <Fragment>
                            <div property="name" label="Tên sản phẩm">
                                <Box className="d-flex align-cener">
                                    <Box sx={{ width: '80px' }}>
                                        <img
                                            alt="san-pham"
                                            src={item.url_image ? item.url_image : images.noPhoto}
                                            style={{ width: item.url_image ? '100%' : '90%' }}
                                        />
                                    </Box>
                                    <Box>{item.name}
                                        <TextField
                                            sx={{ flex: 1 }}
                                            variant="outlined"
                                            size="small"
                                            placeholder={item.name}
                                            onChange={e => handleSelectUpdateNameProcduct({ name: e.target.value, id: item.id }, item.id)}
                                            helperText={errorSubmit.name}
                                        />
                                        <IconButton
                                            aria-label="edit"
                                            color="success"
                                            onClick={() => handleSubmitUpdateName(item.id)}
                                        >
                                            <BeenhereIcon />
                                        </IconButton>
                                    </Box>
                                </Box>
                            </div>
                            <div label="Danh mục" prop="category"></div>
                            <div label="Thời gian tạo" prop="created_at"></div>
                            <div label="Kho lưu trữ" prop="kho_van_chuyen">
                                <TextField
                                    sx={{ flex: 1 }}
                                    variant="outlined"
                                    size="small"
                                    placeholder={item.kho_van_chuyen}

                                    onChange={e => handleSelectUpdateProcduct({ kho_van_chuyen: e.target.value, id: item.id }, item.id)}
                                    helperText={errorSubmit.kho_van_chuyen}
                                />
                                <IconButton
                                    aria-label="edit"
                                    color="success"
                                    onClick={() => handleSubmitUpdateKho(item.id)}
                                >
                                    <BeenhereIcon />
                                </IconButton>

                            </div>
                            <div label="Hành động" sx={{ width: '150px' }}>
                                {item.status == 'hide' ? (
                                    <IconButton aria-label="hide" color="warning" onClick={() => showProduct(item)}>
                                        <HideIcon />
                                    </IconButton>
                                ) : (
                                    <IconButton aria-label="hide" color="warning" onClick={() => hideProduct(item)}>
                                        <VisibilityIcon />
                                    </IconButton>
                                )}
                                <IconButton aria-label="delete" color="danger" onClick={() => onDelete(item)}>
                                    <DeleteIcon />
                                </IconButton>
                                <IconButton
                                    aria-label="edit"
                                    color="primary"
                                    onClick={() => navigate(`cap-nhat-san-pham/${item.id}/${oldPage}`)}

                                >
                                    <EditIcon />
                                </IconButton>
                            </div>
                        </Fragment>
                    )}
                </MTable>
            </Box>
            <Box className={cx('product-list-content-mobile')}>
                <MTable data={tabeleData} loading={loading} countPage={totalPage} onChangePage={onChangePage}>
                    {item => (
                        <Fragment>
                            <div property="name" label="Tên sản phẩm">
                                <Box sx={{ width: '45vw' }} className="d-flex">
                                    <Box>
                                        <img
                                            alt="san-pham"
                                            src={item.url_image ? item.url_image : images.noPhoto}
                                            style={{ width: item.url_image ? '100%' : '90%', maxWidth: '200px' }}
                                        />
                                    </Box>
                                    <Box>{item.name}
                                        <TextField
                                            sx={{ flex: 1 }}
                                            variant="outlined"
                                            size="small"
                                            placeholder={item.name}
                                            onChange={e => handleSelectUpdateNameProcduct({ name: e.target.value, id: item.id }, item.id)}
                                            helperText={errorSubmit.name}
                                        />
                                        <IconButton
                                            aria-label="edit"
                                            color="success"
                                            onClick={() => handleSubmitUpdateName(item.id)}
                                        >
                                            <BeenhereIcon />
                                        </IconButton>
                                    </Box>

                                </Box>
                            </div>
                            <div sx={{ width: '35vw' }} label="Kho lưu trữ" prop="kho_van_chuyen">
                                <TextField
                                    sx={{ flex: 1, minWidth: '200px' }}
                                    variant="outlined"
                                    size="small"
                                    placeholder={item.kho_van_chuyen}

                                    onChange={e => handleSelectUpdateProcduct({ kho_van_chuyen: e.target.value, id: item.id }, item.id)}
                                    helperText={errorSubmit.kho_van_chuyen}
                                />
                                <IconButton
                                    aria-label="edit"
                                    color="success"
                                    onClick={() => handleSubmitUpdateKho(item.id)}
                                >
                                    <BeenhereIcon />
                                </IconButton>

                            </div>
                            <div label="Hành động" sx={{ width: '15vw' }}>
                                {item.status == 'hide' ? (
                                    <IconButton aria-label="hide" color="warning" onClick={() => showProduct(item)}>
                                        <HideIcon />
                                    </IconButton>
                                ) : (
                                    <IconButton aria-label="hide" color="warning" onClick={() => hideProduct(item)}>
                                        <VisibilityIcon />
                                    </IconButton>
                                )}
                                <IconButton aria-label="delete" color="danger" onClick={() => onDelete(item)}>
                                    <DeleteIcon />
                                </IconButton>
                                <IconButton
                                    aria-label="edit"
                                    color="primary"
                                    onClick={() => navigate(`cap-nhat-san-pham/${item.id}/${oldPage}`)}
                                >
                                    <EditIcon />
                                </IconButton>
                            </div>
                        </Fragment>
                    )}
                </MTable>
            </Box>

        </Box>
    );
}
export default ProductManagement;
