import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import classNames from 'classnames/bind';
import styles from './Service.scss';

const cx = classNames.bind(styles);

const traiNghiem = [
    {
        ask: 'Tôi có thể mua hàng như thế nào?',
        answer: {
            text: 'Hiện tại bạn có thể mua sản phẩm của OnYou thông qua website của công ty. Trong website của công ty có 2 loại hình sản phẩm bạn có thể mua',
            line: [
                'Sản phẩm do bạn thiết kế: Đây là loại hình đầu tiên và duy nhất ở Việt Nam cho phép bạn tự tay thiết kế hình ảnh in ấn, thêu may, gắn nhãn dán và hình 3D trên sản phẩm.',
                'Sản phẩm được thiết kế sẵn do Stylist của OnYou thiết kế',
            ],
        },
    },
    {
        ask: 'Các phương thức thanh toán khi mua hàng trên web của OnYou',
        answer: {
            text: 'Công ty chúng tôi đang áp dụng 2 hình thức thanh toán khi bạn mua sản phẩm của công ty. Đó là:',
            line: [
                'Thanh toán bằng hình thức trả trước thông qua chuyển khoản qua số tài khoản của công ty hoặc thanh toán qua ví tiền điện tử của bên thứ 3 nhứ Momo, ZaloPay, ShopeePay',
                'Thanh toán bằng hình thức COD: Bạn nhận hàng và kiểm tra hàng, sau đó thanh toán tiền cho đơn vị vận chuyển',
            ],
        },
    },
];

const giaoHang = [
    {
        ask: 'Bao nhiêu thì tôi nhận được hàng kể từ lúc đặt',
        answer: {
            text: 'Nếu ở tỉnh khác, khách hàng sẽ nhận được hàng trong 2-4 ngày. Các trường hợp đơn hàng đặt trước (Pre-oder) sẽ được thông báo về ngày giao hàng ở mỗi đợt bán trước',
            line: [
                'Đối với sp được thiết kế sẵn: Nếu ở nội thành Hà Nội và Hồ Chí Minh, khách hàng sẽ được nhận hàng trong vòng 1 ngày kể từ khi có cuộc gọi hoặc tin nhắn xác nhận trừ trường hợp đơn chưa đủ tồn kho OnYou sẽ gửi đi từ kho vận hành khác tỉnh thì thời gian sẽ là 2-3 ngày',
                'Đối với sản phẩm do bạn thiết kế: Nếu ở nội thành Hà Nội và Hồ Chí Minh, khách hàng sẽ được nhận hàng trong vòng 3 ngày kể từ khi có cuộc gọi hoặc tin nhắn xác nhận trừ trường hợp đơn chưa đủ tồn kho OnYou sẽ gửi đi từ kho vận hành khác tỉnh thì thời gian sẽ là 3-4 ngày.',
            ],
        },
    },
    {
        ask: 'Làm thế nào để tôi có thể theo dõi đơn hàng của mình?',
        answer: {
            text: ' Bạn có thể inbox vào Fanpage OnYou hoặc email chúng tôi với thông tin mã đơn hàng hoặc số điện thoại đặt hàng. Bộ phận Chăm sóc khách hàng sẽ gửi đến thông tin về mã vận đơn sản phẩm của bạn.',
            line: [],
        },
    },
];
const vanDe = [
    {
        ask: 'Tôi muốn thay đổi thông tin về đơn hàng đã đặt (địa chỉ nhận hàng, số điện thoại nhận hàng ….)',
        answer: 'Bạn vui lòng inbox vào fanpage OnYou hoặc gọi lên tổng đài +48579185859 để bộ phận chăm sóc khách hàng hỗ trợ bạn thay đổi thông tin đơn hàng.',
    },
    {
        ask: 'Tôi muốn thay đổi hình thức thanh toán từ COD sang trả trước',
        answer: 'Bạn vui lòng gọi lên tổng đài +48579185859 để bộ phận chăm sóc khách hàng hỗ trợ bạn chuyển khoản thanh toán đơn hàng của mình',
    },
    {
        ask: 'Tôi muốn hủy đơn hàng đã thanh toán trước',
        answer: 'Bạn vui lòng gọi lên tổng đài +48579185859 để bộ phận chăm sóc khách hàng hỗ trợ bạn hủy đơn hàng của mình và xác nhận thông tin hoàn tiền. OnYou sẽ hoàn tiền lại cho bạn trong vòng 24h kể từ lúc nhận được thông tin',
    },
];

const askAnswerStyle = {
    width: '50px',
    fontWeight: '600',
    fontSize: '18px',
};
const askStyle = {
    fontWeight: '600',
    fontSize: '18px',
};
function FAQ() {
    return (
        <div className="d-flex justify-center bg-gray">
            <div className={cx('container-service', 'width-container')}>
                <Box sx={{ mt: 1 }}>
                    <h1>FAQs</h1>
                </Box>
                <div style={{ width: '100%', textAlign: 'center', fontSize: '24px', fontWeight: 'bold' }}>
                    Câu hỏi thường gặp khi mua sắm tại OnYou
                </div>
                <Box sx={{ mt: 6 }}>
                    <h2>Trải nghiệm mua hàng</h2>
                    <Divider sx={{ mb: 3, mt: 1 }} />
                    {traiNghiem.map((item, index) => (
                        <div key={index}>
                            <div className="d-flex" style={{ marginBottom: '15px' }}>
                                <div style={askAnswerStyle}>Hỏi:</div>
                                <div style={askStyle}>{item.ask}</div>
                            </div>

                            <div className="d-flex" style={{ paddingBottom: '25px' }}>
                                <div style={askAnswerStyle}>Đáp:</div>
                                <div style={{ fontSize: '18px', flex: 1 }}>
                                    <div style={{ paddingBottom: '10px' }}>{item.answer.text}</div>
                                    <div>
                                        {item.answer.line.map((line, index) => (
                                            <div key={index} style={{ paddingBottom: '8px' }}>
                                                <FiberManualRecordIcon sx={{ fontSize: '10px' }} /> {line}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    <div>
                        <h3 style={{ paddingBottom: '10px' }}>Lưu ý: Hình thức thanh toán</h3>
                        <div style={{ fontSize: '18px' }}>
                            <div style={{ paddingBottom: '8px' }}>
                                <FiberManualRecordIcon sx={{ fontSize: '10px' }} /> Sản phẩm do bạn thiết kế: Chỉ chấp
                                nhận hình thức thanh toán trả trước
                            </div>
                            <div style={{ paddingBottom: '8px' }}>
                                <FiberManualRecordIcon sx={{ fontSize: '10px' }} /> Sản phẩm được thiết kế sẵn do
                                Stylist: Chấp nhận hình thức thanh toán trả trước và COD
                            </div>
                        </div>
                    </div>
                </Box>

                <Box sx={{ mt: 4 }}>
                    <h2>Thời gian giao hàng</h2>
                    <Divider sx={{ mb: 3, mt: 1 }} />
                    {giaoHang.map((item, index) => (
                        <div key={index}>
                            <div className="d-flex" style={{ marginBottom: '15px' }}>
                                <div style={askAnswerStyle}>Hỏi:</div>
                                <div style={askStyle}>{item.ask}</div>
                            </div>

                            <div className="d-flex" style={{ paddingBottom: '25px' }}>
                                <div style={askAnswerStyle}>Đáp:</div>
                                <div style={{ fontSize: '18px', flex: 1 }}>
                                    <div style={{ paddingBottom: '10px' }}>{item.answer.text}</div>
                                    <div>
                                        {item.answer.line.map((line, index) => (
                                            <div key={index} style={{ paddingBottom: '8px' }}>
                                                <FiberManualRecordIcon sx={{ fontSize: '10px' }} /> {line}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Box>

                <Box sx={{ mt: 4 }}>
                    <h2>Những vấn đề liên quan đến đơn hàng</h2>
                    <Divider sx={{ mb: 3, mt: 1 }} />
                    {vanDe.map((item, index) => (
                        <div key={index}>
                            <div className="d-flex" style={{ marginBottom: '15px' }}>
                                <div style={askAnswerStyle}>Hỏi:</div>
                                <div style={askStyle}>{item.ask}</div>
                            </div>

                            <div className="d-flex" style={{ paddingBottom: '25px' }}>
                                <div style={askAnswerStyle}>Đáp:</div>
                                <div style={{ fontSize: '18px', flex: 1 }}>
                                    <div>{item.answer}</div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Box>
            </div>
        </div>
    );
}
export default FAQ;
