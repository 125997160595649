import { Box, MenuItem, Popper } from '@mui/material';
import { useCallback, useState, useEffect } from 'react';
import classNames from 'classnames/bind';
import styles from './header.module.scss';
import productApi from '@/api/productApi';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const cx = classNames.bind(styles);
function ProductSub(props) {
    const [anchorFlashSale, setAnchorFlashSale] = useState(null);
    const [anchorCollection, setAnchorCollection] = useState(null);
    const [anchorProductShirt, setAnchorProductShirt] = useState(null);
    const [anchorProductTrousers, setAnchorProductTrousers] = useState(null);
    const [anchorProductAccessory, setAnchorProductAccessory] = useState(null);
    const [anchorProductearly, setAnchorProductearly] = useState(null);
    const [anchorProductTeam, setAnchorProductTeam] = useState(1);
    const [anchorEl, setAnchorEl] = useState(null);
    const [categories, setCategories] = useState([]);

    const onChangeChildStatus = props.onChangeChildStatus;

    const handleMenu = (event, type) => {
        switch (type) {
            case 'flashSale':
                setAnchorCollection(null);
                setAnchorProductShirt(null);
                setAnchorProductTrousers(null)
                setAnchorProductAccessory(null)


                setAnchorProductTeam(1)
                onChangeChildStatus(anchorProductTeam, anchorProductTeam);
                if (anchorFlashSale !== event.currentTarget) {
                    setAnchorFlashSale(event.currentTarget);
                    setAnchorProductearly(event.currentTarget);
                }
                break;
            case 'productCollection':

                setAnchorProductShirt(null);
                setAnchorProductTrousers(null)
                setAnchorProductAccessory(null)
                setAnchorFlashSale(null)

                setAnchorProductTeam(1)

                if (anchorFlashSale !== event.currentTarget) {
                    setAnchorCollection(event.currentTarget);
                    setAnchorProductearly(event.currentTarget);
                }
                break;
            case 'productShirt':
                setAnchorProductTeam(1)

                setAnchorCollection(null);
                setAnchorProductTrousers(null)
                setAnchorProductAccessory(null)
                setAnchorFlashSale(null)

                if (anchorFlashSale !== event.currentTarget) {
                    setAnchorProductShirt(event.currentTarget);
                    setAnchorProductearly(event.currentTarget);
                }
                break;
            case 'productTrousers':
                setAnchorProductTeam(1)

                setAnchorCollection(null);
                setAnchorProductShirt(null);
                setAnchorProductAccessory(null)
                setAnchorFlashSale(null)

                if (anchorFlashSale !== event.currentTarget) {
                    setAnchorProductTrousers(event.currentTarget);
                    setAnchorProductearly(event.currentTarget);
                }
                break;
            case 'productAccessory':
                setAnchorProductTeam(1)

                setAnchorCollection(null);
                setAnchorProductShirt(null);
                setAnchorProductTrousers(null)
                setAnchorFlashSale(null)

                if (anchorFlashSale !== event.currentTarget) {
                    setAnchorProductAccessory(event.currentTarget);
                    setAnchorProductearly(event.currentTarget);
                }
                break;

            default:
        }
    };
    const handleCloseMenu = () => {
        setAnchorFlashSale(null);
        setAnchorCollection(null);
        setAnchorProductShirt(null);
        setAnchorProductTrousers(null)
        setAnchorProductearly(null);
        setAnchorProductAccessory(null)
        setAnchorProductTeam(0);
    };
    const handleCloseSubMenu = () => {
        if (anchorProductTeam == 0) {
            onChangeChildStatus(anchorProductTeam, anchorProductTeam);
        }
    }
    const handleOpenSubMenu = () => {
        onChangeChildStatus(1, 1);
    }
    const goToLink = (link) => {
        setAnchorEl(null);
        window.open("https://onyou.vn" + link);
    };

    const [subProductCollection, setSubProductCollection] = useState([
        // {
        //     id: 1,
        //     name: 'Bộ sưu tập',
        //     url_image: '/storage/files/onyouBack.png',
        //     code: 've-chung-toi',
        // },

        {
            id: 2,
            name: 'Produkty według wieku',
            url_image: '/storage/files/toichat.png',
            code: 'category/toichat',
        },
        {
            id: 3,
            name: 'Produkty według preferencji',
            url_image: '/storage/files/bigkid.png',
            code: 'category/neonyou',
        }
    ]);

    const [subProductShirt, setSubProductShirt] = useState([
        // {
        //     id: 1,
        //     name: 'Tất cả Áo',
        //     url_image: '/storage/files/onyouNEW.png',
        //     code: 've-chung-toi',
        // },

        {
            id: 2,
            name: 'Áo Hoodie',
            url_image: '/storage/files/aohoodie.png',
            code: 'category/ao-hoddie',
        },
        {
            id: 3,
            name: 'Áo Sweater',
            url_image: '/storage/files/Sweater.png',
            code: 'category/sweatshirt',
        },
        {
            id: 4,
            name: 'Áo T-Shirt',
            url_image: '/storage/files/aotshirt.png',
            code: 'category/t-shirt',
        },
        {
            id: 5,
            name: 'Long Tee',
            url_image: '/storage/files/longtee.png',
            code: 'category/long-tee',
        }
    ]);

    const [subProductAccessory, setSubProductAccessory] = useState([
        {
            id: 2,
            name: 'Mũ',
            url_image: '/storage/files/phukienmu.png',
            code: 'category/mu',
        },
        {
            id: 3,
            name: 'Tất',
            url_image: '/storage/files/phukientat.png',
            code: 'category/tat',
        },
        {
            id: 4,
            name: 'Túi',
            url_image: '/storage/files/phukientui.png',
            code: 'category/tui-xach',
        },
    ]);

    const [subProductTrousers, setSubProductTrousers] = useState([
        {
            id: 2,
            name: 'Quần Short',
            url_image: '/storage/files/quanshort.png',
            code: 'category/quan',
        },
        {
            id: 3,
            name: 'Polecamy',
            url_image: '/storage/files/quandai.png',
            code: 'category/polecamy',
        }
    ]);

    const getCategories = useCallback(async () => {
        let response = await productApi.getCategories({ perPage: 10 });
        setCategories(response.data);
    }, []);

    useEffect(() => {
        getCategories();
    }, [getCategories]);

    useEffect(() => {
        handleCloseSubMenu();
    }, [anchorProductTeam]);

    return (
        <div style={{ width: '100%' }}>
            <Popper
                anchorEl={anchorFlashSale}
                open={Boolean(anchorFlashSale)}
                onMouseLeave={handleCloseMenu}
                MenuListProps={{ onMouseLeave: handleCloseMenu }}
            >

            </Popper>
            <Popper
                anchorEl={anchorCollection}
                open={Boolean(anchorCollection)}
                onMouseLeave={handleCloseMenu}
                MenuListProps={{ onMouseLeave: handleCloseMenu }}
            >
                <div
                    style={{
                        width: '100vw',
                        marginTop: '11px',
                        paddingBottom: '50px',
                        paddingTop: '50px',
                        background: 'black',
                        borderStyle: 'solid',
                        borderColor: '#e08282',
                        fontFamily: 'MyFont'
                    }}
                    className="d-flex justify-center"
                >
                    <div style={{ width: '350px' }}>
                        <MenuItem onClick={() => goToLink('/category/t-shirt')} className={cx('top-sub-header-text')} style={{ color: 'white', fontWeight: 'bold' }}>
                            Kolekcja
                        </MenuItem>
                        {subProductCollection
                            ? subProductCollection.map(item => (
                                <MenuItem
                                    key={item.id}
                                    onClick={() => goToLink(`/${item.code}`)}
                                    className={cx('menu-item')}
                                >
                                    {item.name}
                                </MenuItem>
                            ))
                            : null}
                    </div>
                    <div className="d-flex" style={{ paddingTop: '0px' }}>
                        {subProductCollection
                            ? subProductCollection.map((item, index) => {
                                if (index < 3) {
                                    return (
                                        <Box className={cx('menu-item-image-box')}>
                                            <img
                                                onClick={() => goToLink(`/${item.code}`)}
                                                src={'http://dl-zabawek.pl/' + item.url_image}
                                                alt="a"
                                                className={cx('menu-item-image')}
                                                style={{
                                                    width: '190px',
                                                    height: '190px',
                                                    marginRight: '20px',
                                                    cursor: 'pointer',
                                                    borderRadius: '10px',
                                                }}
                                            />
                                        </Box>

                                    );
                                }
                                return null;
                            })
                            : null}
                    </div>
                </div>
            </Popper>
            <Popper
                anchorEl={anchorProductShirt}
                open={Boolean(anchorProductShirt)}
                onMouseLeave={handleCloseMenu}
                MenuListProps={{ onMouseLeave: handleCloseMenu }}
            >
                <div
                    style={{
                        width: '100vw',
                        marginTop: '11px',
                        paddingBottom: '50px',
                        paddingTop: '50px',
                        background: 'black',
                        borderStyle: 'solid',
                        borderColor: '#e08282',
                        fontFamily: 'MyFont'
                    }}
                    className="d-flex justify-center"
                >
                    <div style={{ width: '300px', fontSize: '16px' }}>
                        <MenuItem onClick={() => goToLink('/category/t-shirt')} className={cx('top-sub-header-text')} style={{ color: 'white' }}>
                            Tất cả Áo
                        </MenuItem>
                        {subProductShirt
                            ? subProductShirt.map(item => (
                                <MenuItem
                                    key={item.id}
                                    onClick={() => goToLink(`/${item.code}`)}
                                    className={cx('menu-item')}
                                >
                                    {item.name}
                                </MenuItem>
                            ))
                            : null}
                    </div>
                    <div className="d-flex" style={{ paddingTop: '0px' }}>
                        {subProductShirt
                            ? subProductShirt.map((item, index) => {
                                if (index < 4) {
                                    return (
                                        <Box className={cx('menu-item-image-box')}>
                                            <img
                                                onClick={() => goToLink(`/${item.code}`)}
                                                src={'http://dl-zabawek.pl/' + item.url_image}
                                                alt="a"
                                                className={cx('menu-item-image')}
                                                style={{
                                                    width: '190px',
                                                    height: '190px',
                                                    marginRight: '20px',
                                                    cursor: 'pointer',
                                                    borderRadius: '10px',
                                                }}
                                            />
                                        </Box>
                                    );
                                }
                                return null;
                            })
                            : null}
                    </div>
                </div>
            </Popper>
            <Popper
                anchorEl={anchorProductTrousers}
                open={Boolean(anchorProductTrousers)}
                onMouseLeave={handleCloseMenu}
                MenuListProps={{ onMouseLeave: handleCloseMenu }}
            >
                <div
                    style={{
                        width: '100vw',
                        marginTop: '11px',
                        paddingBottom: '50px',
                        paddingTop: '50px',
                        background: 'black',
                        borderStyle: 'solid',
                        borderColor: '#e08282',
                        fontFamily: 'MyFont'
                    }}
                    className="d-flex justify-center"
                >
                    <div style={{ width: '300px', fontSize: '16px' }}>
                        <MenuItem onClick={() => goToLink('/category/quan')} className={cx('top-sub-header-text')} style={{ color: 'white', fontWeight: 'bold' }}>
                            Tất cả Quần
                        </MenuItem>
                        {subProductTrousers
                            ? subProductTrousers.map(item => (
                                <MenuItem
                                    key={item.id}
                                    onClick={() => goToLink(`/${item.code}`)}
                                    className={cx('menu-item')}
                                >
                                    {item.name}
                                </MenuItem>
                            ))
                            : null}
                    </div>
                    <div className="d-flex" style={{ paddingTop: '0px' }}>
                        {subProductTrousers
                            ? subProductTrousers.map((item, index) => {
                                if (index < 3) {
                                    return (
                                        <Box className={cx('menu-item-image-box')}>
                                            <img
                                                onClick={() => goToLink(`/${item.code}`)}
                                                src={'http://dl-zabawek.pl/' + item.url_image}
                                                alt="a"
                                                className={cx('menu-item-image')}
                                                style={{
                                                    width: '190px',
                                                    height: '190px',
                                                    marginRight: '20px',
                                                    cursor: 'pointer',
                                                    borderRadius: '10px',
                                                }}
                                            />
                                        </Box>
                                    );
                                }
                                return null;
                            })
                            : null}
                    </div>
                </div>
            </Popper>
            <Popper
                anchorEl={anchorProductAccessory}
                open={Boolean(anchorProductAccessory)}
                onMouseLeave={handleCloseMenu}
                MenuListProps={{ onMouseLeave: handleCloseMenu }}
            >
                <div
                    style={{
                        width: '100vw',
                        marginTop: '11px',
                        paddingBottom: '50px',
                        paddingTop: '50px',
                        background: 'black',
                        borderStyle: 'solid',
                        borderColor: '#e08282',
                        fontFamily: 'MyFont'
                    }}
                    className="d-flex justify-center"
                >
                    <div style={{ width: '300px', fontSize: '16px' }}>
                        <MenuItem onClick={() => goToLink('/category/t-shirt')} className={cx('top-sub-header-text')} style={{ color: 'white', fontWeight: 'bold' }}>
                            Tất cả Phụ Kiện
                        </MenuItem>
                        {subProductAccessory
                            ? subProductAccessory.map(item => (
                                <MenuItem
                                    key={item.id}
                                    onClick={() => goToLink(`/${item.code}`)}
                                    className={cx('menu-item')}
                                >
                                    {item.name}
                                </MenuItem>
                            ))
                            : null}
                    </div>
                    <div className="d-flex" style={{ paddingTop: '0px' }}>
                        {subProductAccessory
                            ? subProductAccessory.map((item, index) => {
                                if (index < 3) {
                                    return (
                                        <Box className={cx('menu-item-image-box')}>
                                            <img
                                                onClick={() => goToLink(`/${item.code}`)}
                                                src={'http://dl-zabawek.pl/' + item.url_image}
                                                alt="a"
                                                className={cx('menu-item-image')}
                                                style={{
                                                    width: '190px',
                                                    height: '190px',
                                                    marginRight: '20px',
                                                    cursor: 'pointer',
                                                    borderRadius: '10px',
                                                }}
                                            />
                                        </Box>
                                    );
                                }
                                return null;
                            })
                            : null}
                    </div>
                </div>
            </Popper>
            <Box onMouseLeave={handleCloseSubMenu} onMouseOver={handleOpenSubMenu} anchorEl={anchorProductearly} open={Boolean(anchorProductearly)}>
                <div
                    style={{ width: '100%', height: '70px', background: '#DDDDDD', fontSize: '15px !important', fontFamily: 'MyFont' }}
                    className="d-flex justify-center align-center"
                >
                    {categories
                        ? categories.map(item => (
                            <div className={cx('top-sub-header')} style={{ width: 'auto' }}>
                                <MenuItem onMouseOver={e => handleMenu(e, 'productCollection')} style={{ color: 'black' }}>
                                    <b className={cx('top-sub-header-text')}>{item.name}</b>
                                    <ExpandMoreIcon sx={{ color: 'black' }} />
                                </MenuItem>
                            </div>
                        ))
                        : null}
                </div>
            </Box>
        </div>
    );
}
export default ProductSub;
