import MDialog from '@/components/Dialog';
import { Box, Divider, TextField, IconButton } from '@mui/material';
import { useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import flashsaleApi from '@/api/flashsaleApi';
import productApi from '@/api/productApi';
import Autocomplete from '@mui/material/Autocomplete';
import { debounce } from 'lodash';
import classNames from 'classnames/bind';
import styles from './flash.scss';

const cx = classNames.bind(styles);

function CreateEdit({ open, title, onClose, data, type, onDone }) {
    const [disableFeild, setDisableFeild] = useState(false)
    const [typeFlashsale, setTypeFlashsale] = useState(null)
    const onSubmit = async () => {
        // check thong tin da dien
        if (formData.products === null || formData.code === null || formData.name === null || formData.start_at === null || formData.end_at === null) {
            toast.error('Kiểm tra thông tin đầu vào!');
        }
        try {
            if (type === 'ADD') {
                formData.products = [...productSelected];
                await flashsaleApi.createFlashsale(formData);
            }
            if (type === 'EDIT') {
                formData.products = [...productSelected];
                await flashsaleApi.updateFlashsale(formData.id, formData);
            }
            onClose();
            toast.success('Thành công!');
            setErrorSubmit({});
            onDone();
        } catch (error) {
            const errors = error.response.data.errors;
            for (const key in errors) {
                errors[key] = errors[key].join('. ');
            }
            setErrorSubmit(errors);
        }
    };
    const [formData, setFormData] = useState({});
    const [errorSubmit, setErrorSubmit] = useState({});
    const [products, setProducts] = useState([]);
    const [productSelected, setProductSelected] = useState([]);
    const [param, setParam] = useState({});
    const [loading, setLoading] = useState(false);
    const [colorSelected, setColorSelected] = useState([]);

    const handleSearch = value =>
        debounce(() => {
            setParam({ ...param, search: value });
    }, 600)();

    const handleSelectProcduct = newProductArray => {
        const arrayColor = newProductArray.map(item => {
            const selected = productSelected.find(it => it.id === item.id);
            if (selected) {
                return selected;
            }
            return { ...item, priceFlash: 0 };
        });
        setProductSelected(arrayColor);
    };

    const handleChangePrice = (key, value) => {
        productSelected[key].priceFlash = value;
        productSelected[key].flash_price = value;
        setColorSelected([...productSelected]);
    };
    
    const getData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await productApi.getProduct(param);
            setLoading(false);
            setProducts(response.data);
        } catch (error) {
            setLoading(false);
        }
    }, [param]);

    useEffect(() => {
        setDisableFeild(false)
        setTypeFlashsale(null)
        
        if (type === 'EDIT') {
            setFormData({...data});
            setDisableFeild(true);
            setTypeFlashsale(data.type)
            const arrayColor = data.product_array.map(item => {
                return { ...item, priceFlash: item.flash_price, id: item.product_id };
            });
            setProductSelected(arrayColor);
        }else{
            setFormData({...data});
            setProductSelected([]);
        }
    }, [type, data]);

    useEffect(() => {
        getData();
    }, [getData]);

    return (
        <div>
            <MDialog open={open} title={title} onClose={onClose} onSubmit={onSubmit} type={type}>
                <Box sx={{ width: '800px' }}>
                    <TextField
                        sx={{ width: '100%' }}
                        label="Tên chương trình"
                        variant="outlined"
                        value={formData.name}
                        error={errorSubmit.name ? true : false}
                        onChange={e => setFormData({ ...formData, name: e.target.value })}
                        helperText={errorSubmit.name}
                    />
                    <Box className="d-flex" sx={{ mt: 2 }}>
                        <TextField
                            sx={{ width: '100%', mr: 1 }}
                            label="Mã flash sale"
                            variant="outlined"
                            value={formData.code}
                            error={errorSubmit.code ? true : false}
                            onChange={e => setFormData({ ...formData, code: e.target.value })}
                            helperText={errorSubmit.code}
                            disabled={disableFeild}
                        />
                        <TextField
                            sx={{ width: '100%', ml: 1 }}
                            variant="outlined"
                            label="Số lượng"
                            value={formData.quantity}
                            error={errorSubmit.quantity ? true : false}
                            onChange={e => setFormData({ ...formData, quantity: e.target.value })}
                            helperText={errorSubmit.quantity}
                            type="number"
                            InputProps={{ inputProps: { min: 1, max: 5000 } }}
                        />
                    </Box>
                    <Box className="d-flex" sx={{ mt: 2 }}>
                        <Box sx={{ width: '100%', mr: 1 }}>
                            <Box sx={{ mb: 1 }}>Khởi động từ ngày</Box>
                            <TextField
                                sx={{ width: '100%' }}
                                variant="outlined"
                                type="datetime-local"
                                value={formData.start_at}
                                error={errorSubmit.start_at ? true : false}
                                onChange={e => setFormData({ ...formData, start_at: e.target.value })}
                                helperText={errorSubmit.start_at}
                            />
                        </Box>
                        <Box sx={{ width: '100%', ml: 1 }}>
                            <Box sx={{ mb: 1 }}>Kết thúc vào ngày</Box>
                            <TextField
                                sx={{ width: '100%' }}
                                variant="outlined"
                                type="datetime-local"
                                value={formData.end_at}
                                error={errorSubmit.end_at ? true : false}
                                onChange={e => setFormData({ ...formData, end_at: e.target.value })}
                                helperText={errorSubmit.end_at}
                            />
                        </Box>
                    </Box>
                    <br></br>
                    <Autocomplete
                            disablePortal
                            multiple
                            sx={{ width: '100%', mr: 1 }}
                            label="Chọn sản phẩm áp dụng Flash sale"
                            variant="outlined"
                            size="meidum"
                            getOptionLabel={option => option.product_by ? option.product_by.name : option.name}
                            renderOption={(props, option) => (
                                <Box className="d-flex align-cener" {...props}>
                                    <Box sx={{ ml: 1 }}>{option.product_by ? option.product_by.name : option.name}</Box>
                                </Box>
                            )}
                            options={products}
                            value={productSelected}
                            onChange={(e, newValue) => handleSelectProcduct(newValue)}
                            renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Search input"
                                  onChange={e => handleSearch(e.target.value)}
                                />
                            )}
                    />
                    <Box className="d-flex flex-column" sx={{ mt: 2 }}>
                            <Box sx={{ flex: 1 }}>
                                {productSelected.length > 0 ? (
                                    productSelected.map((item, index) => (
                                        <Box key={index} sx={{ width: '100%', mt: 4 }}>
                                            <Box className="d-flex align-center">
                                                <Box sx={{ ml: 2, fontWeight: 'bold' }}>{index+1}.</Box>
                                                <Box sx={{ ml: 2, fontWeight: 'bold' }}>{item.product_by ? item.product_by.name : item.name}</Box>
                                                <TextField
                                                    sx={{ width: '150px', ml: 1 }}
                                                    variant="outlined"
                                                    label="Giá Flash"
                                                    value={item.product_array ? item.product_array[index].flash_price : item.flash_price}
                                                    onChange={e =>
                                                        handleChangePrice(
                                                            index,
                                                            e.target.value
                                                        )
                                                    }
                                                    type="number"
                                                    
                                                />

                                            </Box>
                                            <Box sx={{ mt: 1 }}>

                                            </Box>
                                            <Divider sx={{ mt: 1 }} />
                                        </Box>
                                    ))
                                ) : (
                                    <Box>Vui lòng chọn sản phẩm</Box>
                                )}
                            </Box>
                    </Box>
                </Box>
            </MDialog>
        </div>
    );
}
export default CreateEdit;
