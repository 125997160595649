import classNames from 'classnames/bind';
import styles from './home.scss';
import HomeBanner from './components/Banner';
import HomeProduct from './components/Products';
import FlashSale from './components/FlashSale';
import IconBar from './components/IconBar'
import NewProduct from './components/NewProducts';
import IconVoucherBar from './components/IconVoucher'
import MyDesign from './components/MyDesign'
import IconCator from './components/IconCator'
import HomeSearch from './components/HomeSearch'
import IconSpec from './components/IconSpec'
import IconPartner from './components/IconPartner'
import IconTitle from './components/IconTitle'
import IconTalk from './components/IconTalk'
import IconPeople from './components/IconPeople'
import IconLine from './components/IconLine'
import images from '@/assets/images';
import { Box } from '@mui/material';

const cx = classNames.bind(styles);
function Home() {
    return (
        <div className={cx('home-wrapper')}>
            <HomeBanner />

            <Box className={cx('home-wrapper-g1')} sx={{ backgroundColor: 'white' }}>
                {/* <IconBar /> */}
                <Box className="d-flex justify-center align-center" sx={{ mb: 1 }}>
                    <div className="width-container-home-product">
                        {/* <HomeProduct /> */}
                        <NewProduct />
                    </div>
                </Box>
                <FlashSale />
                <MyDesign />
            </Box>

            <Box className={cx('home-wrapper-g1')} sx={{ backgroundColor: 'white' }}>
                <Box className="d-flex justify-center align-center" sx={{ mb: 1 }}>
                    <div className="width-container-home-product">

                        <HomeProduct />
                    </div>
                </Box>
            </Box>
            <IconTitle />

            {/* <Box className={cx('home-wrapper-g2')} sx={{ backgroundColor: 'white' }}>
                <IconTalk />
                <IconPeople />
            </Box> */}
            <IconLine />
        </div>
    );
}
export default Home;
