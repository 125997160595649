import MDialog from '@/components/Dialog';
import { Box, Divider, TextField, IconButton } from '@mui/material';
import { useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import orderApi from '@/api/orderApi';
import productApi from '@/api/productApi';
import Autocomplete from '@mui/material/Autocomplete';
import { debounce } from 'lodash';
import classNames from 'classnames/bind';
import styles from './cart.scss';
import Grid from '@mui/material/Grid';
import MInput from '@/components/Input/MInput';

const cx = classNames.bind(styles);

function CreateEditMore({ open, title, onClose, data, type, onDone, total }) {
    const [formData, setFormData] = useState({});
    const [errorSubmit, setErrorSubmit] = useState({});
    const [products, setProducts] = useState([]);
    const [productSelected, setProductSelected] = useState();
    const [param, setParam] = useState({});
    const [loading, setLoading] = useState(false);

    const onSubmit = async () => {
        // check thong tin da dien
        if (formData.products === null || formData.quanlity === null) {
            toast.error('Kiểm tra thông tin đầu vào!');
        }
        try {
            if (type === 'ADD') {
                console.log('formData')
                console.log(formData)
                await orderApi.updateOrderMore(formData.id, formData);
            }
            onClose();
            toast.success('Powodzenie!');
            setErrorSubmit({});
            onDone();
        } catch (error) {
            console.log(error)
            // const errors = error.response.data.errors;
            // for (const key in errors) {
            //     errors[key] = errors[key].join('. ');
            // }
            // setErrorSubmit(errors);
        }
    };

    const handleSearch = value =>
        debounce(() => {
            setParam({ ...param, search: value });
        }, 600)();

    const getData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await productApi.getProduct(param);
            setLoading(false);
            setProducts(response.data);
        } catch (error) {
            setLoading(false);
        }
    }, [param]);

    useEffect(() => {
        if (type === 'EDIT') {
            setFormData({ ...data });
        } else {
            setFormData({ ...data });
            setProductSelected([]);
        }
    }, [type, data]);

    useEffect(() => {
        getData();
    }, [getData]);

    return (
        <div>
            <MDialog open={open} title={title} onClose={onClose} onSubmit={onSubmit} type={type}>
                <div className="d-flex">
                    <Grid item xs={12} sm={12} md={6} lg={5} xl={5} className={cx('bac-cart')}>
                        <Autocomplete
                            disablePortal
                            sx={{ width: '30vw', minWidth: '300px', paddingBottom: '2em' }}
                            label="Lựa chọn sản phẩm"
                            variant="outlined"
                            size="small"
                            getOptionLabel={option => option.name}
                            options={products}
                            value={productSelected}
                            onChange={(e, newValue) => {
                                setFormData({ ...formData, product_id: newValue.id });
                                setProductSelected(newValue);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Search input"
                                    onChange={e => handleSearch(e.target.value)}
                                />
                            )}
                        />
                        <div label="giá thương lượng" sx={{ width: '30vw', minWidth: '300px' }}>
                            <MInput
                                variant="outlined"
                                onChange={e => setFormData({ ...formData, quanlity: e.target.value })}
                                type="number"
                                sx={{ width: '30vw', minWidth: '300px' }}
                                min="0"
                            />
                        </div>
                    </Grid>
                </div>
            </MDialog >
        </div >
    );
}
export default CreateEditMore;
