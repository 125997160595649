import { useParams } from 'react-router-dom';
import RadioColor from '@/components/RadioColor';
import RadioColorMobile from '@/components/RadioColorMobile';
import Divider from '@mui/material/Divider';
import { Box } from '@mui/material';
import Button from '@/components/Button';
import { useState, useEffect, useCallback, Fragment } from 'react';
import { Button as MuiButton } from '@mui/material';
import Cookies from 'js-cookie';
import { SP_DA_XEM } from '@/constants/product';
import Grid from '@mui/material/Grid';
import Rating from '@mui/material/Rating';
import SizeDialog from './SizeDialog';
import images from '@/assets/images';
import productApi from '@/api/productApi';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { useDispatch } from 'react-redux';
import { pushToCart } from '@/store/cart/cartSlice';
import { toast } from 'react-toastify';
import GridProduct from '@/components/GridProduct/GridProduct';
import GridProductMini from '@/components/GridProduct/GridProductMini';
import GridProductV3 from '@/components/GridProduct/GridProductV3';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { randomProductReview } from './reviews';
import MInput from '@/components/Input/MInputProduct';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import styles from './product.scss';
import StarIcon from '@mui/icons-material/Star';
import IconLine from '@/pages/Home/components/IconLine';
import IconPartner from '@/pages/Home/components/IconPartner';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormControl from '@mui/material/FormControl';
import FormCombo from '@/components/Input/FormCombo';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import NativeSelect from '@mui/material/NativeSelect';

import MenuItem from '@mui/material/MenuItem';
import chroma from 'chroma-js';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import { Markup } from 'interweave';
import Typography from '@mui/material/Typography';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { all } from 'redux-saga/effects';
import OutSize from '@/components/OutSize';

const cx = classNames.bind(styles);
const iconInfo = [
    { name: 'Darmowa wysyłka przy zamówieniach powyżej 1.200 zł netto', icon: images.freeship },
    { name: 'Niezwykle łatwy zwrot, wystarczy numer telefonu', icon: images.doitra },
    { name: 'Szybka dostawa na terenie całego kraju', icon: images.freeshiptoanquoc },
    { name: '30 dni na zwrot bez opłat', icon: images.trahang },
    { name: 'Infolinia obsługi klienta czynna codziennie od 8:00 do 22:00', icon: images.tuvan },
    { name: 'Przyjedź do siebie, aby dokonać zwrotu i zwrotu pieniędzy', icon: images.hoantien },
];

const Accordion = styled(props => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
    '&:before': {
        display: 'none',
    },
}));
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(1),
}));

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1.5,
    slidesToScroll: 1,
};
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 8,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#F37121' : '#F37121',
    },
}));

function ProductDetail() {
    const topReview = randomProductReview[Math.floor(Math.random() * randomProductReview.length)];
    const allReview = null;
    const navigate = useNavigate();
    const [danhGia, setDanhGia] = useState(0);
    const [coAnh, setCoAnh] = useState(0);
    const [phanHoi, setPhanHoi] = useState(0);
    const [styleInfoChooseSize, setStyleInfoChooseSize] = useState(null);
    const { productCode } = useParams();
    const [product, setProduct] = useState({});
    const [imageProduct, setImageProduct] = useState([]);
    const [colors, setColors] = useState([]);
    const [sizes, setSizes] = useState([]);
    const [colorSelect, setColorSelect] = useState(null);
    const [pathProductSelect, setPathProductSelect] = useState(null);
    const [seenProductData, setSeenProductData] = useState([]);
    const [quantity, setQuantity] = useState(1);
    const [defaultQuantity, setDefaultQuantity] = useState(1);
    const [sizeSelected, setSizeSelected] = useState();
    const [favoriteProducts, setFavoriteProduct] = useState([]);
    const [openSizeDialog, setOpenSizeDialog] = useState(false);

    const [countRating, setCountRating] = useState(6);
    const [ratings, setRatings] = useState([]);
    const [heightContent, setHeightContent] = useState('300px');
    const dispatch = useDispatch();
    const getDetail = useCallback(async () => {
        const response = await productApi.getDetailProductByCode(productCode);
        const colorData = response.colors
            ? response.colors.map(el => {
                return { value: el.id, color: el.code, label: el.name, sizes: el.sizes, images: el.images };
            })
            : [];
        setColorSelect(colorData[0]);
        setColors(colorData);
        const sizeData =
            colorData[0] && colorData[0].sizes
                ? colorData[0].sizes.map(el => {
                    return {
                        name: el.name,
                        id: el.id,
                        quantity: el.quantity,
                    };
                })
                : [];
        const imageList =
            colorData[0] && colorData[0].images
                ? colorData[0].images.map(el => ({ original: el.path, thumbnail: el.path }))
                : [];
        imageList.unshift({ original: response.url_image, thumbnail: response.url_image });
        setImageProduct(imageList);
        setSizeSelected(null);
        setSizes(sizeData);
        setProduct(response);
        setQuantity(Number(response.paczka));
        setDefaultQuantity(Number(response.paczka));
        const spDaXem = Cookies.get(SP_DA_XEM) ? JSON.parse(Cookies.get(SP_DA_XEM)) : [];
        const responseSeen = await productApi.getProductShow({
            listID: spDaXem.filter(item => Number(item) !== response.id),
        });
        setSeenProductData(responseSeen.data);
    }, [productCode]);

    const StyledRating = styled(Rating)({
        '& .MuiRating-iconFilled': {
            color: '#e08282',
            fontSize: '20px',
            justifyContent: 'left !important',
            textAlign: 'left',
        },
        '& .MuiRating-iconHover': {
            color: '#e08282',
            fontSize: '20px',
        },
    });

    const handleUpdateQuantity = (value) => {

        const quantityValue = Number(value) < 0 ? 0 : Number(value);
        const calculate = Math.ceil(quantityValue / defaultQuantity);
        setQuantity(calculate * defaultQuantity);
    };

    const formatDate = date => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [day, month, year].join('-');
    };
    const goReview = () => {
        const rev = document.getElementById('product-reviews');
        rev.scrollIntoView({ behavior: 'smooth' });
    };

    const getTopData = useCallback(async () => {
        const response = await productApi.getProductShow({
            perPage: 3,
            remove: true,
        });
        setFavoriteProduct(response.data);
    }, []);

    const colourStyles = {
        control: styles => ({ ...styles, backgroundColor: 'white' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            const color = chroma(data.color);
            return {
                ...styles,
                backgroundColor: isDisabled
                    ? undefined
                    : isSelected
                        ? data.color
                        : isFocused
                            ? color.alpha(0.1).css()
                            : undefined,
                color: isDisabled
                    ? '#ccc'
                    : isSelected
                        ? chroma.contrast(color, 'white') > 2
                            ? 'white'
                            : 'black'
                        : data.color,
                cursor: isDisabled ? 'not-allowed' : 'default',

                ':active': {
                    ...styles[':active'],
                    backgroundColor: !isDisabled ? (isSelected ? data.color : color.alpha(0.3).css()) : undefined,
                },
            };
        },
        multiValue: (styles, { data }) => {
            const color = chroma(data.color);
            return {
                ...styles,
                backgroundColor: color.alpha(0.1).css(),
            };
        },
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: data.color,
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: data.color,
            ':hover': {
                backgroundColor: data.color,
                color: 'white',
            },
        }),
    };

    const handleAddToCart = () => {
        const productAdd = {
            id: product.id,
            quantity: quantity,
        };
        dispatch(pushToCart(productAdd));
        if (window.innerWidth > 800) {
            toast(
                <Box
                    sx={{
                        borderRadius: '12px',
                    }}
                >
                    <Box sx={{ fontWeight: 'bold', color: 'black', mb: 2, mt: 1 }}>
                        Produkt dodany do koszyka!
                    </Box>
                    <Divider />
                    <Box sx={{ mt: 2 }} className="d-flex">
                        <Box
                            sx={{
                                width: '90px',
                                height: '120px',
                                border: '1px solid #CACFD2',
                                borderRadius: '12px',
                                background: '#f7f7f7',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'contain',
                                backgroundPosition: 'center',
                                backgroundImage: `url('${product.url_image}')`,
                            }}
                        ></Box>
                        <Box sx={{ flex: 1, ml: 1 }}>
                            <Box sx={{ fontWeight: 'bold', color: 'black' }}>{product.name}</Box>
                            {colorSelect && sizeSelected ? (
                                <Box sx={{ mt: 2 }}>
                                    {colorSelect.label} / {sizeSelected.name}
                                </Box>
                            ) : null}
                        </Box>
                    </Box>
                    <Box>
                        <Box
                            className="d-flex align-center justify-center"
                            sx={{
                                mt: 2,
                                width: '100%',
                                borderRadius: '18px',
                                border: '1px solid black',
                                height: '35px',
                                fontWeight: 'bold',
                                color: 'black',
                            }}
                            onClick={() => navigate('/gio-hang')}
                        >
                            Zobacz Koszyk
                        </Box>
                    </Box>
                </Box>,
                {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                }
            );
        } else {
            toast(
                <Box
                    sx={{
                        borderRadius: '12px',
                    }}
                >
                    <Box sx={{ fontWeight: 'bold', color: 'black', mb: 2, mt: 1 }}>
                        Produkt dodany do koszyka!
                    </Box>
                    <Divider />
                    <Box sx={{ mt: 2 }} className="d-flex">
                        <Box
                            sx={{
                                width: '112px',
                                height: '180px',
                                border: '1px solid #CACFD2',
                                borderRadius: '12px',
                                background: '#f7f7f7',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'contain',
                                backgroundPosition: 'center',
                                backgroundImage: `url('${product.url_image}')`,
                            }}
                        ></Box>
                        <Box sx={{ flex: 1, ml: 1 }}>
                            <Box sx={{ fontWeight: 'bold', color: 'black' }}>{product.name}</Box>
                            {colorSelect && sizeSelected ? (
                                <Box sx={{ mt: 2 }}>
                                    {colorSelect.label} / {sizeSelected.name}
                                </Box>
                            ) : null}
                        </Box>
                    </Box>
                    <Box>
                        <Box
                            className="d-flex align-center justify-center"
                            sx={{
                                mt: 2,
                                width: '100%',
                                borderRadius: '18px',
                                border: '1px solid black',
                                height: '35px',
                                fontWeight: 'bold',
                                color: 'black',
                            }}
                            onClick={() => navigate('/gio-hang')}
                        >
                            Zobacz Koszyk
                        </Box>
                    </Box>
                </Box>,
                {
                    position: 'bottom-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                }
            );
        }
    };

    useEffect(() => {
        getDetail();
        getTopData();
    }, [getDetail, getTopData]);

    const someValue = Number(product.id) > 0 ? 1 : 0;
    const id_fix_random = Number(product.id) * 10 + Number(product.id);
    return (
        <div>
            <Box sx={{ backgroundColor: 'white' }} className={cx('product-card-img')}>
                <Box className="c-flex align-center">
                    <Box className="width-container-product-detail">
                        <Box className={cx('prod-container')}>
                            <div className={cx('prod-container-name')}>
                                <a
                                    href="/"
                                    style={{
                                        fontSize: '16px',
                                        color: 'black',
                                        paddingBottom: '2em',
                                        textDecoration: 'none',
                                    }}
                                >
                                    Strona główna /
                                </a>
                                {product.category ? (
                                    <a href={'/category/' + product.category.code} style={{ fontSize: '16px', color: 'black', textDecoration: 'none' }}> {product.category.name} /</a>
                                ) : null}

                                <a style={{ fontSize: '16px', color: '#e08282' }}> {product.name} </a>
                                {someValue > 0}
                            </div>
                            <SizeDialog open={openSizeDialog} onClose={() => setOpenSizeDialog(false)} />
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12} md={7} lg={7} xl={7} sx={{ minHeight: '425px', mt: 3 }}>
                                    <Box className={cx('slide-small')} sx={{ pl: 2, pr: 1 }}>
                                        <ImageGallery
                                            showFullscreenButton={false}
                                            showPlayButton={false}
                                            disableThumbnailScroll
                                            items={imageProduct}
                                            thumbnailPosition="left"
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={5} lg={5} xl={5} sx={{ paddingTop: '20px' }}>
                                    <Box sx={{ pl: 2, pr: 2 }}>
                                        <Box
                                            className={cx('name-card')}
                                            sx={{ fontWeight: '400', mt: 1, color: 'black', paddingBottom: '5px' }}
                                        >
                                            {product.name}
                                        </Box>
                                        {product.note_free_ship ? (
                                            <Box sx={{ mb: 2 }} className="d-flex flex-wrap">
                                                <Box className={cx('name-card-freeship')} sx={{ ml: 1 }}>
                                                    <a style={{ fontSize: '16px', color: 'black', fontWeight: '300' }}>
                                                        {product.note_free_ship}
                                                    </a>
                                                </Box>
                                            </Box>
                                        ) : null}

                                        <Box
                                            sx={{ mt: 2, mb: 1, fontSize: '24px' }}
                                            className="d-flex"
                                        >
                                            <Box className={cx('font-size-card')} sx={{ fontWeight: '400' }}>
                                                {Number(product.sale_price).toLocaleString('de-DE')} zł

                                            </Box>
                                            <Box sx={{ fontSize: '16px', mb: 2, color: '#5a84c1', fontWeight: '400' }}>
                                                (Netto)
                                            </Box>
                                        </Box>
                                        <Box
                                            sx={{ mt: 2, mb: 1, fontSize: '24px' }}
                                            className="d-flex"
                                        >
                                            <Box sx={{ fontWeight: '400', fontSize: '16px', mb: 2, color: 'black', }}>
                                                {Number(product.sale_price * 1.23).toLocaleString('de-DE')} zł

                                            </Box>
                                            <Box sx={{ fontSize: '16px', mb: 2, color: '#5a84c1', fontWeight: '400' }}>
                                                (brutto)
                                            </Box>
                                        </Box>
                                        <Box className="d-flex" sx={{ mt: 3 }}>
                                            <Box sx={{ width: '25%', mr: 4, color: 'black' }}>
                                                <MInput
                                                    type="number"
                                                    size="small"
                                                    sx={{
                                                        color: 'black !important',
                                                        borderRadius: '15px',
                                                        fontSize: '18px',
                                                        border: '1px solid black',
                                                    }}
                                                    value={quantity}
                                                    onChange={e => handleUpdateQuantity(e.target.value)}
                                                />
                                            </Box>
                                            <Button
                                                style={{
                                                    width: '100%',
                                                    borderRadius: '18px',
                                                    backgroundColor: '#e08282',
                                                    border: '1px solid #e08282',
                                                    color: 'black',
                                                }}
                                                primary
                                                onClick={handleAddToCart}
                                            >
                                                <Box
                                                    className="d-flex align-center justify-center"
                                                    sx={{ height: '7px', width: '100%' }}
                                                >
                                                    <img
                                                        src={images.cartIconBlack}
                                                        style={{ width: '25px', color: 'black' }}
                                                        alt="icon"
                                                    />

                                                    <Box
                                                        className={cx('carticon-mobile')}
                                                        sx={{ mt: 0, ml: 2, bgcolor: '#e08282', color: 'white' }}
                                                    >
                                                        Dodaj do koszyka
                                                    </Box>
                                                </Box>
                                            </Button>
                                        </Box>
                                        <Divider sx={{ mt: 2, bgcolor: 'black' }} />
                                        <Box sx={{ padding: '15px', fontSize: '17px', color: 'black' }}>
                                            <Box
                                                sx={{
                                                    fontSize: '18px',
                                                    fontWeight: '200',
                                                    paddingBottom: '10px',
                                                    color: 'black',
                                                }}
                                            >
                                                Produkty, które mogą Ci się spodobać
                                            </Box>
                                            <GridProductMini products={favoriteProducts} loading={false} />
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box sx={{ pl: 1, pr: 1 }}>
                            <Divider sx={{ mt: 3, mb: 3, bgcolor: 'black' }} />
                            <Box
                                className="d-flex align-center"
                                sx={{
                                    fontWeight: 'bold',
                                    mr: 1,
                                    fontSize: '24px',
                                    color: 'black',
                                    textAlign: 'center',
                                }}
                            >
                            </Box>
                            <Box sx={{ mt: 2 }} id="product-reviews">
                                <Grid container spacing={3}>
                                    {ratings.map(item => (
                                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} sm={5} md={4} lg={2} xl={2}>
                                                    <StyledRating
                                                        name="customized-color"
                                                        defaultValue={item.rate}
                                                        getLabelText={value =>
                                                            `${value} Heart${value !== 1 ? 's' : ''}`
                                                        }
                                                        precision={0.5}
                                                        icon={<StarIcon fontSize="inherit" />}
                                                        emptyIcon={<StarIcon fontSize="inherit" />}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={7} md={8} lg={10} xl={10}>
                                                    <Box sx={{ fontWeight: '100', fontSize: '16px', color: 'black' }}>
                                                        {item.username}
                                                    </Box>
                                                    <Box sx={{ mt: 1, mb: 2, color: 'gray' }}>Size: M</Box>
                                                    {/* <Box sx={{ mt: 3, padding: '10px 10px 10px 20px', minHeight: '60px', lineHeight: '26px', fontSize: '14px', borderRadius: '18px', color: 'black', bgcolor: "#E4FD80" }}>
                                                        <a>{item.content}</a>
                                                    </Box> */}
                                                    <Box
                                                        sx={{
                                                            mt: 3,
                                                            minHeight: '60px',
                                                            lineHeight: '26px',
                                                            fontSize: '14px',
                                                            borderRadius: '18px',
                                                            color: 'black',
                                                            fontWeight: '100',
                                                            marginTop: '0px',
                                                        }}
                                                    >
                                                        <a>{item.content}</a>
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            mt: 3,
                                                            fontSize: '14px',
                                                            color: 'gray',
                                                            fontWeight: '100',
                                                            marginTop: '0px',
                                                        }}
                                                    >
                                                        {formatDate(item.created_at)}
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Divider sx={{ mt: 3, bgcolor: 'black', borderRadius: '18px' }} />
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        </Box>
                        <br></br>

                        {seenProductData && seenProductData.length > 0 ? (
                            <Box className="d-flex justify-center">
                                <Box sx={{ mt: 4 }} className="width-container">
                                    <Box
                                        sx={{
                                            fontWeight: '300',
                                            fontSize: '18px',
                                            mb: 2,
                                            textAlign: 'center',
                                            color: 'black',
                                        }}
                                    >
                                        OSTATNIO OGLĄDANE PRODUKTY
                                    </Box>
                                    <GridProductV3 products={seenProductData} loading={false} />
                                </Box>
                            </Box>
                        ) : null}
                    </Box>
                </Box>
            </Box>
            <Box sx={{ backgroundColor: '#121212' }}>
                <IconLine />
                {/* <IconPartner /> */}
            </Box>
        </div>
    );
}
export default ProductDetail;
