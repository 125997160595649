import Home from '@/pages/Home';
import Product from '@/pages/Products';
import Login from '@/pages/Login/login';
import Design from '@/pages/Design';
import Blog from '@/pages/Blog';
import Dashboard from '@/pages/Admin/Dashboard';
import ProductManagement from '@/pages/Admin/Product';
import CreateEditProduct from '@/pages/Admin/Product/create-edit';
import UserManagement from '@/pages/Admin/Users/management';
import CustomerManagement from '@/pages/Admin/CRM/Customer';
import UserProfile from '@/pages/Admin/Users/profile';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import WidgetsIcon from '@mui/icons-material/Widgets';
import EngineeringIcon from '@mui/icons-material/Engineering';

import FAQ from '@/pages/Services/FAQ';
import Return from '@/pages/Services/Return';
import Security from '@/pages/Services/Security';
import Shipment from '@/pages/Services/Shipment';
import Contact from '@/pages/Services/Contact';
import Order from '@/pages/Services/Order';
import Trans from '@/pages/Services/Trans';
import Checking from '@/pages/Services/Checking';
import Payment from '@/pages/Services/Payment';

import ProductDetail from '@/pages/Products/detail';
import ProductFlashDetail from '@/pages/ProductFlashs/detail';

import Categories from '@/pages/Admin/Product/Category/Categories';
import RatingProduct from '@/pages/Admin/Product/Rating/Rating';
import Cart from '@/pages/Cart';
import CartSmax from '@/pages/CartSmax';
import CategoryIcon from '@mui/icons-material/Category';
import CategoryAccessory from '@/pages/Admin/Accessory/CategoryAccessory/CategoryAccessory';
import Accessory from '@/pages/Admin/Accessory/Accessory/Accessory';
import PaymentResult from '@/pages/PaymentResult';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import settingIcon from '@mui/icons-material/Settings';
import textFieldIcon from '@mui/icons-material/TextFields';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import AirplaneTicketIcon from '@mui/icons-material/AirplaneTicket';
import SellIcon from '@mui/icons-material/Sell';
import SuperscriptIcon from '@mui/icons-material/Superscript';
import ContentCutOutlinedIcon from '@mui/icons-material/ContentCutOutlined';
import AddShoppingCartOutlinedIcon from '@mui/icons-material/AddShoppingCartOutlined';
import HistoryEduOutlinedIcon from '@mui/icons-material/HistoryEduOutlined';
import NotesOutlinedIcon from '@mui/icons-material/NotesOutlined';
import SpellcheckOutlinedIcon from '@mui/icons-material/SpellcheckOutlined';
import ViewCarouselOutlinedIcon from '@mui/icons-material/ViewCarouselOutlined';
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';


import OrderBuy from '@/pages/Admin/Order/OrderBuy/OrderBuy';
import OrderDesign from '@/pages/Admin/Order/OrderDesign/OrderDesign';
import DetailOrder from '@/pages/Admin/Order/OrderBuy/DetailOrder';
import ViewOrder from '@/pages/Order/ViewOrder';
import ProductCategory from '@/pages/ProductCategory';
import AboutUs from '@/pages/About/AboutUs';
import UNuStory from '@/pages/About/UNuStory';
import OnYouStory from '@/pages/About/OnYouStory';
import Voucher from '@/pages/Admin/Voucher';
import Promotion from '@/pages/Admin/Promotion';
import Upsell from '@/pages/Admin/Upsell';
import Gmail from '@/pages/Admin/Gmail';
import Flashsale from '@/pages/Admin/Flashsale';
import BackUp from '@/pages/BackUp/BackUp';
import HomeCollection from '@/pages/Admin/HomeCollection';

import NavbarCode from '@/pages/Admin/Setting/Navbar';
import VoucherCode from '@/pages/Admin/Setting/Voucher';
import RatingCode from '@/pages/Admin/Setting/Rating';
import BannerCode from '@/pages/Admin/Setting/Banner';
import DetailOrderDesign from '@/pages/Admin/Order/OrderDesign/DetailOrder';
import Search from '@/pages/Search';


const publicRoutes = [
    { path: '/', component: Home },
    { path: '/products', component: Product },
    { path: '/category/:categoryCode', component: ProductCategory },
    { path: '/login', component: Login, layout: null },
    { path: '/design', component: Design },
    { path: '/blog', component: Blog },
    { path: '/faq', component: FAQ },

    { path: '/chinh-sach-doi-tra-va-hoan-tien', component: Return },
    { path: '/chinh-sach-bao-mat', component: Security },
    { path: '/shipment', component: Shipment },
    { path: '/contact', component: Contact },
    { path: '/dieu-kien-giao-dich-chung', component: Trans },
    { path: '/chinh-sach-kiem-hang', component: Checking },
    { path: '/chinh-sach-thanh-toan', component: Payment },
    { path: '/chinh-sach-dat-hang', component: Order },

    { path: '/san-pham/:productCode', component: ProductDetail },
    { path: '/san-pham-flash/:productCode', component: ProductFlashDetail },
    { path: '/gio-hang', component: Cart },
    { path: '/gio-hang-smax-onyou', component: CartSmax },

    { path: '/ket-qua-thanh-toan', component: PaymentResult },
    { path: '/tra-cuu-don/:code', component: ViewOrder },
    { path: '/tu-do-thiet-ke', component: BackUp },
    { path: '/ve-chung-toi', component: AboutUs },
    { path: '/cau-chuyen-ve-unu', component: UNuStory },
    { path: '/cau-chuyen-ve-onyou', component: OnYouStory },
    { path: '/don-hangs', component: OrderBuy },
    { path: '/search', component: Search }

];
const publicRoutesNoFooter = [
    { path: '/design2', component: Design }
];
const privateRoutes = [
    { path: '/dashboard', icon: WidgetsIcon, name: 'Tổng quan', component: Dashboard, hidden: false },
    {
        path: '/nguoi-dung',
        name: 'Người dùng',
        icon: PeopleAltIcon,
        hidden: false,
        children: [
            { path: 'ho-so', name: 'Hồ sơ', icon: AccountCircleIcon, hidden: false, component: UserProfile },
            {
                path: 'quan-ly',
                name: 'Quản lý người dùng',
                icon: SupervisorAccountIcon,
                hidden: false,
                component: UserManagement,
            },
        ],
    },
    {
        path: '/san-pham',
        name: 'Sản phẩm',
        icon: LocalMallIcon,
        children: [
            {
                path: 'danh-muc',
                name: 'Danh mục sản phẩm',
                icon: CategoryIcon,
                hidden: false,
                component: Categories,
            },
            {
                path: '',
                name: 'Sản phẩm',
                icon: LocalMallIcon,
                hidden: false,
                component: ProductManagement,
            },
            {
                path: 'san-pham-rating',
                name: 'Rating sản phẩm',
                icon: StarOutlinedIcon,
                hidden: false,
                component: RatingProduct,
            },
            {
                path: 'them-san-pham',
                name: 'Thêm sản phẩm',
                icon: '',
                hidden: true,
                role: ['admin'],
                component: CreateEditProduct,
            },
            {
                path: 'cap-nhat-san-pham/:id/:oldPage',
                name: 'Thêm sản phẩm',
                icon: '',
                hidden: true,
                role: ['admin'],
                component: CreateEditProduct,
            },
        ],
    },
    {
        path: '/phu-kien',
        name: 'Phụ kiện',
        icon: ContentCutOutlinedIcon,
        children: [
            {
                path: 'danh-muc',
                name: 'Phân loại phụ kiện',
                icon: CategoryIcon,
                hidden: false,
                component: CategoryAccessory,
            },
            {
                path: '',
                name: 'Phụ kiện',
                icon: ContentCutOutlinedIcon,
                hidden: false,
                component: Accessory,
            },
        ]
    },
    {
        path: '/don-hang',
        name: 'Đơn hàng',
        icon: AddShoppingCartOutlinedIcon,
        children: [
            {
                path: 'don-mua',
                name: 'Đơn mua hàng',
                icon: ShoppingCartIcon,
                hidden: false,
                component: OrderBuy,
            },
            // {
            //     path: 'don-thiet-ke',
            //     name: 'Đơn hàng thiết kế',
            //     icon: DesignServicesIcon,
            //     hidden: false,
            //     component: OrderDesign,
            // },
            {
                path: 'don-thiet-ke/:id',
                name: 'Đơn hàng thiết kế',
                icon: DesignServicesIcon,
                hidden: true,
                component: DetailOrderDesign,
            },
            {
                path: 'chi-tiet/:id',
                name: 'Chi tiết đơn hàng',
                icon: DesignServicesIcon,
                hidden: true,
                component: DetailOrder,
            },
        ]
    },
    {
        path: '/giam-gia-khuyen-mai',
        name: 'Giảm giá - Khuyến mãi',
        icon: SellIcon,
        children: [
            {
                path: 'voucher',
                name: 'voucher',
                icon: ConfirmationNumberIcon,
                hidden: false,
                component: Voucher,
            },
            {
                path: 'coupon',
                name: 'Coupon',
                icon: LocalActivityIcon,
                hidden: false,
                component: Voucher,
            },
            {
                path: 'chuong-trinh-khuyen-mai',
                name: 'Chương trình khuyến mãi',
                icon: SellIcon,
                hidden: false,
                component: Promotion,
            },
            {
                path: 'chuong-trinh-flash-sale',
                name: 'Flash sale',
                icon: AirplaneTicketIcon,
                hidden: false,
                component: Flashsale,
            },
            {
                path: 'upsell',
                name: 'Upsell',
                icon: SuperscriptIcon,
                hidden: false,
                component: Upsell,
            }
        ]
    },
    {
        path: '/cai-dat-chung',
        name: 'Cài đặt chung',
        icon: settingIcon,
        children: [
            {
                path: 'setting-navbar',
                name: 'Cài đặt Navbar',
                icon: textFieldIcon,
                hidden: false,
                component: NavbarCode,
            },
            {
                path: 'setting-banner',
                name: 'Cài đặt Banner',
                icon: ViewCarouselOutlinedIcon,
                hidden: false,
                component: BannerCode,
            },
            {
                path: 'setting-voucher',
                name: 'Cài đặt Ảnh Home Section',
                icon: ViewCarouselOutlinedIcon,
                hidden: false,
                component: VoucherCode,
            },
            {
                path: 'setting-rating',
                name: 'Cài đặt Home Review',
                icon: ViewCarouselOutlinedIcon,
                hidden: false,
                component: RatingCode,
            },
            {
                path: 'setting-home-collection',
                name: 'Cài đặt SP-HOME collection',
                icon: ViewCarouselOutlinedIcon,
                hidden: false,
                component: HomeCollection,
            },
        ]
    },
    {
        path: '/crm',
        name: 'Dawid CRM',
        icon: SupportAgentIcon,
        children: [
            {
                path: 'thong-tin-khach-hang',
                name: 'Thông tin khách hàng',
                icon: EngineeringIcon,
                hidden: false,
                component: CustomerManagement,
            },
            {
                path: 'gmail-marketing',
                name: 'Gmail marketing',
                icon: AttachEmailIcon,
                hidden: false,
                component: Gmail,
            },
            {
                path: 'abandoned-cart',
                name: 'Bỏ rơi giỏ hàng',
                icon: MarkEmailUnreadIcon,
                hidden: false,
                component: NavbarCode,
            },
            {
                path: 'winback',
                name: 'Winback',
                icon: ContactMailIcon,
                hidden: false,
                component: NavbarCode,
            },
        ]
    },
    {
        path: '/blog-onyou',
        name: 'Blog',
        icon: HistoryEduOutlinedIcon,
        children: [
            {
                path: 'setting-blog',
                name: 'Cài đặt blog',
                icon: settingIcon,
                hidden: false,
                component: NavbarCode,
            },
            {
                path: 'tat-ca-bai-viet',
                name: 'Tất cả bài viết',
                icon: textFieldIcon,
                hidden: false,
                component: NavbarCode,
            },
            {
                path: 'them-moi-bai-viet',
                name: 'Thêm mới bài viết',
                icon: HistoryEduOutlinedIcon,
                hidden: false,
                component: NavbarCode,
            },
            {
                path: 'danh-muc-bai-viet',
                name: 'Danh mục bài viết',
                icon: NotesOutlinedIcon,
                hidden: false,
                component: NavbarCode,
            },
            {
                path: 'quan-ly-tag-bai-viet',
                name: 'Tags bài viết',
                icon: SpellcheckOutlinedIcon,
                hidden: false,
                component: NavbarCode,
            }

        ]
    },
];
export { publicRoutes, privateRoutes ,publicRoutesNoFooter };
