import ImageDesign from './ImageDesign';
import TextDesign from './TextDesign';
import { TYPE_IMAGE, TYPE_TEXT } from '@/store/design/constant';
import { useSelector, useDispatch } from 'react-redux';
import images from '@/assets/images';
import { useState, useMemo, useEffect } from 'react';
import styles from './designBox.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

function DesignBoxNew() {
    const iconSelected = useSelector(state => state.targetDesign.iconSelected);
    const isShowBorder = useSelector(state => state.targetDesign.showBorder);
    const isShowGrid = useSelector(state => state.targetDesign.showGrid);
    const [openRightbar, setOpenRightbar] = useState(true);
    const clickOpenRightBar = () => {
        setOpenRightbar(true);
    }
    const clickCloseRightBar = () => {
        setOpenRightbar(false);
    }
    const styletext = {
        paddingTop: '20px',
    };
    const dispatch = useDispatch();
    return (
        <div style={{ display: 'flex' }}>
            <div style={{ backgroundColor: '#f7f7f6', height: '100vh', width: '2vh' }}>
                <div style={{ zIndex: '90', textAlign: 'center', marginTop: '45vh', backgroundColor: 'black', height: '70px', width: '100%', borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px' }}>

                    {openRightbar == true ? (
                        <img onClick={() => clickCloseRightBar()} src={images.iconRightWhite} style={{ cursor: 'pointer', height: '15px', marginTop: '28px' }} />
                    ) : (
                        <img onClick={() => clickOpenRightBar()} src={images.iconLeftWhite} style={{ cursor: 'pointer', height: '15px', marginTop: '28px' }} />
                    )
                    }
                </div>
            </div>
            {openRightbar == true ? (
                <div style={{ zIndex: '100', width: '25vw', boxShadow: 'rgb(208 216 208 / 30%) 9px 0em 1em inset, white 2px 2px 2px 0px, rgb(80 68 68 / 60%) 0.3em 0em 1em' }}>
                    {iconSelected.type === TYPE_IMAGE ? (
                        <ImageDesign />
                    ) : iconSelected.type === TYPE_TEXT ? (
                        <TextDesign />
                    ) : (
                        <div className={cx('pc-design-text')} >
                            <b>O<span>N</span>Y<span>O</span>U</b>
                        </div>
                    )}
                </div>
            ) : (
                null
            )
            }
        </div>
    );
}
export default DesignBoxNew;
