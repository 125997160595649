import axiosClient from '@/utils/axiosClient';

class GmailApi {
    getGmail = () => {
        const url = '/gmail';
        return axiosClient.get(url);
    };
    createGmail = data => {
        const url = '/gmail';
        return axiosClient.post(url, data);
    };
    updateGmail = (id, data) => {
        const url = `/gmail/${id}`;
        return axiosClient.put(url, data);
    };
    deleteGmail = (id) => {
        const url = `/gmail/${id}`;
        return axiosClient.delete(url);
    };
}
const gmailApi = new GmailApi();
export default gmailApi;
