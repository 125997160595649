import axiosClient from '@/utils/axiosClient';

class VoucherApi {
    getVoucher = () => {
        const url = '/voucher';
        return axiosClient.get(url);
    };
    createVoucher = data => {
        const url = '/voucher';
        return axiosClient.post(url, data);
    };
    updateVoucher = (id, data) => {
        const url = `/voucher/${id}`;
        return axiosClient.put(url, data);
    };
    deleteVoucher = (id) => {
        const url = `/voucher/${id}`;
        return axiosClient.delete(url);
    };
}
const voucherApi = new VoucherApi();
export default voucherApi;
