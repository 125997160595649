import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
const CssTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderRadius: '18px',
        },
    },
});
function MInput(prop) {
    return <CssTextField {...prop} />;
}
export default MInput;
