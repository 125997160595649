import { BORDER_SELECTED, BORDER_NO_SELECT } from '@/store/design/constant';
import { useDispatch } from 'react-redux';
import { changeColor } from '@/store/design/targetDesignSlice';
function RadioColorMobile({ value, data, onChangeColor }) {
    const dispatch = useDispatch();
    const handleChangeColor = (color) => {
        if (onChangeColor) {
            onChangeColor(color)
        } else dispatch(changeColor(color.value))
    }
    return (
        <div className="d-flex" style={{ width: '100%' }}>
            {data.map((item, index) => (
                <div style={{ marginRight: '10px' }} key={index}>
                    <div
                        style={{
                            padding: '2px',
                            border: value === item.value ? BORDER_SELECTED : BORDER_NO_SELECT,
                            borderRadius: '15%',
                        }}
                        onClick={() => handleChangeColor(item)}
                    >
                        <div
                            style={{
                                height: '22px',
                                width: '11vw',
                                borderRadius: '10%',
                                background: item.color,
                                boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
                                cursor: 'pointer',
                            }}
                        ></div>
                    </div>

                    {/* <div style={{ marginTop: '5px', paddingLeft: '0px', textAlign: 'center' }}>{item.label}</div> */}
                </div>
            ))}
        </div>
    );
}
export default RadioColorMobile;
