import axiosClient from '@/utils/axiosClient';
class ProductApi {
    getProduct = params => {
        const url = '/products';
        return axiosClient.get(url, { params });
    };
    getUsers = params => {
        const url = '/getUsers';
        return axiosClient.get(url, { params });
    };
    getProductShow = params => {
        const url = '/productsnothide';
        return axiosClient.get(url, { params });
    };
    getProductwithImage = params => {
        const url = '/getProductwithImage';
        return axiosClient.get(url, { params });
    };
    getProductInHomeCollection = params => {
        const url = '/getProductInHomeCollection';
        return axiosClient.get(url, { params });
    };
    
    getProductFlash = params => {
        const url = '/getflashsaleproduct';
        return axiosClient.get(url, { params });
    };
    getProductInCategory = params => {
        const url = '/products-in-category';
        return axiosClient.get(url, { params });
    };
    addProduct = data => {
        const url = '/products';
        return axiosClient.post(url, data);
    };
    updateProduct = (data, id) => {
        const url = `/products/${id}`;
        return axiosClient.put(url, data);
    };
    updateTenVaKho = (data, id) => {
        const url = `/updateTenVaKho/${id}`;
        return axiosClient.put(url, data);
    };
    getProductCart = (params) => {
        const url = `/product-cart`;
        return axiosClient.get(url, { params });
    };
    getSizeFromColor = (params) => {
        const url = `/size-id`;
        return axiosClient.get(url, { params });
    };
    deleteProduct = id => {
        const url = `/products/${id}`;
        return axiosClient.delete(url);
    };
    getDetailProduct = id => {
        const url = `/products/${id}`;
        return axiosClient.get(url);
    };
    getDetailProductByCode = code => {
        const url = `/detail-product/${code}`;
        return axiosClient.get(url);
    };
    getCategories = params => {
        const url = '/product-categories';
        return axiosClient.get(url, { params });
    };
    addCategory = data => {
        const url = '/product-categories';
        return axiosClient.post(url, data);
    };
    updateCategory = (data, id) => {
        const url = `/product-categories/${id}`;
        return axiosClient.put(url, data);
    };
    deleteCategory = (id) => {
        const url = `/product-categories/${id}`;
        return axiosClient.delete(url);
    };
    uploadImage = data => {
        const url = '/upload-image';
        return axiosClient.post(url, data, {headers: { "Content-Type": "multipart/form-data" }});
    };
    getColors = () => {
        const url = '/category-colors';
        return axiosClient.get(url);
    };
    uploadMultiple = (data) => {
        const url = '/upload-files';
        return axiosClient.post(url, data, {headers: { "Content-Type": "multipart/form-data" }});
    };
    deleteFile = (id) => {
        const url = `/remove-file/${id}`;
        return axiosClient.delete(url);
    };
    showProduct = (id) => {
        const url = `/showproducts/${id}`;
        return axiosClient.put(url);
    };
    hideProduct = (id) => {
        const url = `/hideproducts/${id}`;
        return axiosClient.put(url);
    }; 
    exportProduct = params => {
        const url = `/products-export`;
        return axiosClient.get(url,{ params, responseType: 'blob'});
    };     
}
const productApi = new ProductApi();
export default productApi;
