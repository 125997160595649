import classNames from 'classnames/bind';
import styles from './Button.module.scss';
import { useNavigate } from 'react-router-dom';

const cx = classNames.bind(styles);

function Button({ to, href, primary, outlined, children, onClick, passProps, style }) {
    const navigate = useNavigate();
    const goToLink = link => {
        navigate(link);
    };
    var onClickButton = onClick;
    var Comp = 'button';
    const props = {
        ...passProps,
    };
    if (to) {
        onClickButton = () => goToLink(to);
    } else if (href) {
        props.href = href;
        Comp = 'a';
    }
    const className = cx('wrapper-button', { primary, 'button-outlined': outlined });
    return (
        <Comp style={{ ...style }} className={className} {...props} onClick={onClickButton}>
            <span>{children}</span>
        </Comp>
    );
}
export default Button;
