import paymentApi from '@/api/payment';
import { useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import images from '@/assets/images';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { removeAllItem } from '@/store/cart/cartSlice';

function PaymentResult() {
    const [resultData, setResultData] = useState({});
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const getResult = useCallback(async () => {
        const searchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(searchParams.entries());
        const respon = await paymentApi.getResult(params);
        const orderId = respon?.data?.vpc_MerchTxnRef;
        setResultData(respon);
        if (orderId) {
            navigate(`/tra-cuu-don/${orderId}`);
        }
    }, []);
    useEffect(() => {
        getResult();
    }, [getResult]);
    dispatch(removeAllItem());
    return (
        <div className="d-flex justify-center bg-gray">
            <Box
                className="width-container c-flex align-center"
                sx={{
                    bgcolor: 'white',
                    paddingTop: '80px',
                    border: '1px solid #A6ACAF',
                    minHeight: '100vh',
                    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
                }}
            >
                <Box>
                    <img src={images.thankiu} style={{ height: '400px' }} alt="thank" />
                </Box>
                <Box sx={{ fontSize: '28px', fontWeight: 'bold', mt: 3 }}>{resultData.status}</Box>
                <Box sx={{ fontSize: '28px', fontWeight: 'bold', mt: 3 }}>
                    Đơn hàng: #{resultData?.data?.vpc_MerchTxnRef}
                </Box>
                <Button
                    sx={{ mt: 3, mb: 2, fontSize: '22px' }}
                    onClick={() => navigate(`/tra-cuu-don/${resultData?.data?.vpc_MerchTxnRef}`)}
                    variant="text"
                >
                    Theo dõi đơn hàng
                </Button>
                <Box sx={{ fontSize: '26px', mt: 2 }}>{resultData.message}</Box>
                <Button variant="contained" sx={{ mt: 4 }} onClick={() => navigate('/')}>
                    Trang chủ
                </Button>
            </Box>
        </div>
    );
}
export default PaymentResult;
