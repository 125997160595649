import { createSlice } from '@reduxjs/toolkit';
import { CART_PRODUCT } from '@/constants/product';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

const countTotalItem = () => {
    const cartProduct = Cookies.get(CART_PRODUCT) ? JSON.parse(Cookies.get(CART_PRODUCT)) : [];
    const totalItem = cartProduct.reduce((accValue, currentValue) => {
        return Number(accValue) + Number(currentValue.quantity);
    }, 0);
    return totalItem;
};
const countProvisional = () => {
    const cartProduct = Cookies.get(CART_PRODUCT) ? JSON.parse(Cookies.get(CART_PRODUCT)) : [];
    const countMoney = cartProduct.reduce((accvalue, currentValue) => {
        return Number(accvalue) + Number(currentValue.sale_price * currentValue.quantity);
    }, 0);
    return countMoney;
};

export const cartAction = createSlice({
    name: 'cart',
    initialState: {
        quantity: countTotalItem(),
        cartData: Cookies.get(CART_PRODUCT) ? JSON.parse(Cookies.get(CART_PRODUCT)) : [],
        provisionalMoney: countProvisional(),
    },
    reducers: {
        setTotalItem: state => {
            state.quantity = countTotalItem();
        },
        pushToCart: (state, action) => {
            const cartProduct = Cookies.get(CART_PRODUCT) ? JSON.parse(Cookies.get(CART_PRODUCT)) : [];
            const productAdd = action.payload;
            productAdd.uuid = uuidv4();
            const checkHasproduct = cartProduct.find(item => item.id === productAdd.id);
            var newProductCart = [];
            if (checkHasproduct) {
                newProductCart = cartProduct.map(item => {
                    if (item.id === productAdd.id) {
                        return { ...item, quantity: Number(item.quantity) + Number(productAdd.quantity) };
                    }
                    return item;
                });
            } else {
                newProductCart = [...cartProduct, productAdd];
            }
            Cookies.set(CART_PRODUCT, JSON.stringify(newProductCart), { expires: 7 });
            state.cartData = newProductCart;
            state.quantity = countTotalItem();
            state.provisionalMoney = countProvisional();
        },
        updateCartItem: (state, action) => {
            const product = action.payload;
            const cartProduct = Cookies.get(CART_PRODUCT) ? JSON.parse(Cookies.get(CART_PRODUCT)) : [];
            const type = product.type;
            switch (type) {
                case 'size':
                    var newProductCart = cartProduct.map(item => {
                        if (item.uuid === product.uuid) {
                            return {
                                ...item,
                                size_id: product.size_id,
                            };
                        }
                        return item;
                    });
                    break;
                case 'color':
                    var newProductCart = cartProduct.map(item => {
                        if (item.uuid === product.uuid) {
                            return {
                                ...item,
                                color_id: product.color_id,
                            };
                        }
                        return item;
                    });
                    break;
                case 'quantity':
                    if(product.quantity > 0 ) {
                        var newProductCart = cartProduct.map(item => {
                            if (item.uuid === product.uuid) {
                                return {
                                    ...item,
                                    quantity: product.quantity,
                                };
                            }
                            return item;
                        });
                    }else {
                        var newProductCart = cartProduct.filter(item => item.uuid !== product.uuid);
                    }
             
                    break;
            }
            Cookies.set(CART_PRODUCT, JSON.stringify(newProductCart), { expires: 7 });
            state.cartData = newProductCart;
            state.quantity = countTotalItem();
            state.provisionalMoney = countProvisional();
        },

        updateCartColorItem: (state, action) => {
            const product = action.payload;
            const cartProduct = Cookies.get(CART_PRODUCT) ? JSON.parse(Cookies.get(CART_PRODUCT)) : [];
            const newProductCart = cartProduct.map(item => {
                if (item.uuid === product.uuid) {
                    item['path'] = product.color_path;
                }
                return item;
            });

            Cookies.set(CART_PRODUCT, JSON.stringify(newProductCart), { expires: 7 });
            state.cartData = newProductCart;
            state.quantity = countTotalItem();
            state.provisionalMoney = countProvisional();
        },

        removeCartItem: (state, action) => {
            const product = action.payload;
            const cartProduct = Cookies.get(CART_PRODUCT) ? JSON.parse(Cookies.get(CART_PRODUCT)) : [];
            const newProductCart = cartProduct.filter(item => item.uuid !== product.uuid);
            Cookies.set(CART_PRODUCT, JSON.stringify(newProductCart), { expires: 7 });
            state.cartData = newProductCart;

            if (newProductCart.length == 1) {
                if (newProductCart[0].promotion) {
                    Cookies.remove(CART_PRODUCT);
                    state.quantity = 0;
                    state.cartData = [];
                    state.provisionalMoney = 0;
                }
            }

            state.quantity = countTotalItem();
            state.provisionalMoney = countProvisional();
        },
        removeCartPromotionItem: (state, action) => {
            const cartProduct = Cookies.get(CART_PRODUCT) ? JSON.parse(Cookies.get(CART_PRODUCT)) : [];
            const newProductCart = cartProduct.filter(item => item.promotion !== true);
            Cookies.set(CART_PRODUCT, JSON.stringify(newProductCart), { expires: 7 });
            state.cartData = newProductCart;
            state.quantity = countTotalItem();
            state.provisionalMoney = countProvisional();
        },
        removeAllItem: state => {
            Cookies.remove(CART_PRODUCT);
            state.quantity = 0;
            state.cartData = [];
            state.provisionalMoney = 0;
        },
    },
});

export const {
    setTotalItem,
    pushToCart,
    updateCartItem,
    updateCartColorItem,
    removeCartItem,
    removeAllItem,
    removeCartPromotionItem,
} = cartAction.actions;

export default cartAction.reducer;
